import { createAction, props } from '@ngrx/store';

import { Company } from '../../models/company';

export const fetchCompanies = createAction(
  '[Company API] Fetch',
  // props<{ payload: Country }>()
);

export const fetchCompaniesSuccess = createAction(
  '[Company API] Fetch Success',
  props<{ payload: Company[] }>()
);

export const fetchCompaniesError = createAction(
  '[Company API] Fetch Error',
  props<{ payload: Error }>()
);
