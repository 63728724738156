import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { UDDepartmentService } from '../../services/uddepartment.service';
import { Store } from '@ngrx/store';

@Injectable()
export class DepartmentEffects {
  constructor(
    private actions$: Actions,
    private departmentService: UDDepartmentService,
    private store: Store
  ) {}

  loadDepartments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Department API] Fetch'),
      switchMap(() => {
        return this.departmentService.getDepartments().pipe(
          map((data) => ({
            type: '[Department API] Fetch Success',
            payload: data,
          })),
          catchError((error: Error) =>
            of({ type: '[Department API] Fetch Error', payload: error })
          )
        );
      })
    );
  });
}
