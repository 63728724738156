import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { storeUser } from '../app/store/actions';
import { UserState } from './store/reducers';
import { AuthService } from './services/auth.service';

import { User } from './models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {

  constructor(
    public auth: AuthService,
    public router: Router,
    private store: Store<UserState>
  ) {}

  /*
   ** Returns true if authenticated, false if not
   */
  async canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.auth.checkAuthorization().subscribe({
        next: (response: User) => {
          if (response.response.result) {
            // console.log('canActivate() resolves to TRUE');
            this.store.dispatch(storeUser({ payload: response }));
            resolve(true);
          } else {
            // console.log('canActivate() resolves to FALSE');
            this.router.navigate(['/login']);
            resolve(false);
          }
        },
        error: (err: Error) => {
          console.error(err);
          resolve(false);
        }
      })
    });
  }

}
