import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { jsPDF } from 'jspdf';

import { DataService } from 'src/app/services/data.service';

import {
  selectAllCountries,
  selectAllCompanies,
  selectCompaniesByCountryAndGroup,
} from '../../../store/selectors';
import { Country } from '../../../models/country';
import { Company } from '../../../models/company';

@Component({
  selector: 'app-tetra-laval',
  templateUrl: './tetra-laval.component.html',
  styleUrls: ['./tetra-laval.component.scss'],
})
export class TetraLavalComponent implements OnInit {
  @Input() assignment: any = {};
  @Output("removeAssignment") removeAssignment = new EventEmitter<string>();
  @Output("setPostCommand") setPostCommand = new EventEmitter<string>();

  countries: Country[] = [];
  allCompanies: Company[] = [];
  companies: Company[] = [];
  minDate: string;
  loading = true;

  constructor(
    private store: Store,
    private router: Router,
    private toast: ToastrService,
    private datePipe: DatePipe,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.initComponent();
  }

  ngOnChanges() {
    this.initComponent();
  }

  initComponent() {
    const currentDate = new Date();
    this.minDate = currentDate.toISOString().slice(0, 10);
    this.getCountries()
      .then((res) => {
        this.countries = res;
        return this.getCompanies();
      })
      .then((res) => {
        this.allCompanies = res;
        this.getCompaniesForTetraLaval(
          this.assignment.data.content.extrainfo.UXPayingCountry,
          'TETRA'
        );
        this.setPostCommand.emit('CLEAR_CONFIRM_MAIL');
        this.loading = false;
      });
  }

  getCountries() {
    return new Promise<Country[]>((resolve) => {
      this.store.select(selectAllCountries).subscribe((data) => {
        resolve(data as Country[]);
      });
    });
  }

  getCompanies() {
    return new Promise<Company[]>((resolve) => {
      this.store.select(selectAllCompanies).subscribe((data) => {
        resolve(data as Company[]);
      });
    });
  }

  getCompaniesForTetraLaval(countrycode: string, groupcode: string): void {
    this.companies = this.allCompanies.filter((company) => {
      if (
        company.countrycode === countrycode &&
        company.groupcode === groupcode
      ) {
        return true;
      } else {
        return false;
      }
    });
    // console.log(this.companies);
  }

  onCountryChange(country: string): void {
    this.store
      .select(
        selectCompaniesByCountryAndGroup(
          this.assignment.data.content.extrainfo.UXPayingCountry,
          this.assignment.data.content.extrainfo.payingCompany.groupcode
        )
      )
      .subscribe((data) => {
        this.companies = data;
      });
    this.assignment.data.content.extrainfo.payingCountry = country;
  }

  onCompanyChange(companycode: string): void {
    // console.log(companycode);
    this.assignment.data.content.extrainfo.payingCompany.companynumber = companycode;
    this.assignment.data.content.extrainfo.payingCompany.companyname = this.getCompanyName(companycode);
  }

  onMoveOwnerChange(): void {
    // console.log('Move owner changed!');
    this.setPostCommand.emit('MOVE_OWNER_CHANGED');
  }

  getCountryName(countryCode: string): string {
    for (const country of this.countries) {
      if (country.countrycode === countryCode) {
        return country.countryname;
      }
    }
    return 'Unknown country';
  }

  getCompanyName(companyCode: string): string {
    for (const company of this.allCompanies) {
      if (company.companynumber == companyCode) {
        return company.companyname;
      }
    }
    return 'Unknown company';
  }

  /* Resets Alfa "Select assignment type" */
  FLH(volume) {
    // console.log(volume);
    if (
      volume == 'Single or unaccompanied 4 m3' ||
      volume == 'Accompanied 8 m3'
    ) {
      return (volume = '');
    } else return null;
  }

  getTetraAssignmentType(code: string): string {
    switch (code) {
      case 'TETRA1':
        return 'Short Term Assignment';
        break;
      case 'TETRA2':
        return 'Career Migration';
        break;
      case 'TETRA3':
        return 'Executive';
        break;
      case 'TETRA4':
        return 'Long Term Assignment';
        break;
      case 'TETRA5':
        return 'Swiss Trans';
        break;
      default:
        return 'Unknown assignment type';
        break;
    }
  }

  updateApprove(data: any) {
    /*  data.status = 5; */
    this.detailSubmit(data.data)
      .then(() => {
        return this.approvalUpdate(data.id, 5);
      })
      .then(() => {
        this.toast.success('Success !', 'Approved');
        this.removeAssignment.emit();
        if (
          this.assignment.data.content.extrainfo.CompanyName[0].toLocaleUpperCase() ===
          'T'
        ) {
          this.generatePDF();
        }
        // this.confirmList.content.list.splice(index, 1);
      })
      .catch((err) => {
        this.toast.error('Error !', JSON.stringify(err));
      });
  }

  updateReject(data: any) {
    /* data.status = 10; */
    this.approvalUpdate(data.id, 10)
      .then(() => {
        this.removeAssignment.emit();
        this.toast.success('Success !', 'Booking rejected');
      })
      .catch((err) => {
        this.toast.error('Error !', JSON.stringify(err));
      });
  }

  updateSave(data) {
    /* data.status = 10; */
    // console.log(this.assignment);
    this.approvalUpdate(data.id, 0, data.data)
      .then(() => {
        this.setPostCommand.emit('CLEAR_POSTCOMMAND');
        if (Object.prototype.hasOwnProperty.call(this.assignment.data.metaData, 'postCommand')) {
          if (Object.prototype.hasOwnProperty.call(this.assignment.data.metaData.postCommand, 'sendConfirmMail')) {
            this.assignment.data.metaData.postCommand.sendConfirmMail = false;
          }
        }
        this.toast.success('Success !', 'Booking saved');
      })
      .catch((err) => {
        // console.error(err);
        this.toast.error('Error !', JSON.stringify(err));
      });
  }

  // END

  detailSubmit(updatedData: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      updatedData.metaData.needConfirm = false;
      this.dataService.formSubmit(updatedData).subscribe({
        next: () => {
          resolve(true);
        },
        error: (error: Error) => {
          console.error(error);
          reject(error);
        },
      });
    });
  }

  approvalUpdate(_id: string, _status: number, _data?: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dataService.approvalUpdate(_id, _status, _data).subscribe({
        next: () => {
          // console.log(response);
          resolve(true);
        },
        error: (error: Error) => {
          console.error(error);
          reject(error);
        },
      });
    });
  }

  onFamilySizeChange(): void {
    // console.log(this.confirm.data.content.extrainfo);
    switch (this.assignment.data.content.extrainfo.FamilySize) {
      case 'Single':
        this.assignment.data.content.extrainfo.numberOfFamily = '1';
        break;
      case 'Couple':
        this.assignment.data.content.extrainfo.numberOfFamily = '2';
        break;
      case 'Couple or Single plus 1 Child':
        this.assignment.data.content.extrainfo.numberOfFamily = '3';
        break;
      case 'Couple or Single plus 2 Children':
        this.assignment.data.content.extrainfo.numberOfFamily = '4';
        break;
      case 'Couple or Single plus 3 Children':
        this.assignment.data.content.extrainfo.numberOfFamily = '5';
        break;
      case 'Couple or Single plus 4 or more Children':
        this.assignment.data.content.extrainfo.numberOfFamily = '6 or more';
        break;
      default:
        break;
    }
  }

  getResidenceType(id: string): string {
    switch(id) {
      case '1':
        return 'Apartment';
      case '2':
        return 'Villa';
      case '3':
        return 'Townhouse';
      case '4':
        return 'Warehouse';
      default:
        return 'Unknown';
    }
  }

  getWalkingDistance(id: string): string {
    switch(id) {
      case '1':
        return 'Within 30 meters';
      case '2':
        return '30 to 50 meter carry';
      case '3':
        return '51 to 75 meter carry';
      case '4':
        return '76 to 100 meter carry';
      case '5':
        return 'Not known at quotation';
      default:
        return 'Unknown';
    }
  }

  getElevator(id: string): string {
    switch(id) {
      case '0':
        return 'No elevator';
      case '1':
        return 'Small elevator';
      case '2':
        return 'Medium elevator';
      case '3':
        return 'Big elevator';
      case '4':
        return 'Elevator not useable for move';
      default:
        return 'Unknown';
    }
  }

  generatePDF() {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
      floatPrecision: 16, // or "smart", default is 16
    });

    // const f = doc.getFontList();
    // console.log(f);

    let x = 0;
    const y = 20;
    const font = 'Times';

    // ----------------------------------------
    // Company details
    doc.setFillColor('#e9ecef');
    doc.fill();
    doc.roundedRect(x + 5, y + 5, 200, 20, 2, 2, 'FD');

    // Header for company details
    doc.setFont(font, 'normal', 'bold');
    doc.setFontSize(14);
    doc.setTextColor('#1c958d');
    doc.text('Company details', x + 7, y + 11);

    doc.setFontSize(12);
    doc.setTextColor('Black');

    x = 3; // Indent

    // Country
    doc.setFont(font, 'normal', 'bold');
    doc.text('Country:', x + 7, y + 18);
    doc.setFont(font, 'normal', 'normal');
    if (Object.prototype.hasOwnProperty.call(this.assignment.data.content.extrainfo, 'UXPayingCountry')) {
      doc.text(this.getCountryName(this.assignment.data.content.extrainfo.UXPayingCountry), x + 50, y + 18);
    }

    // Company
    doc.setFont(font, 'normal', 'bold');
    doc.text('Company:', x + 100, y + 18);
    doc.setFont(font, 'normal', 'normal');
    if (Object.prototype.hasOwnProperty.call(this.assignment.data.content.extrainfo, 'UXPayingCompany')) {
      doc.text(this.getCompanyName(this.assignment.data.content.extrainfo.UXPayingCompany), x + 120, y + 18);
    }
    x = 0; // Reset indent

    // Your details
    doc.setFillColor('#e9ecef');
    doc.fill();
    doc.roundedRect(x + 5, y + 30, 200, 48, 2, 2, 'FD');

    // Header for your details
    doc.setFont(font, 'normal', 'bold');
    doc.setFontSize(14);
    doc.setTextColor('#1c958d');
    doc.text('Your details', x + 7, y + 36);

    doc.setFontSize(12);
    doc.setTextColor('Black');

    x = 3; // Indent

    // First name - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('First name:', x + 7, y + 43);
    doc.setFont(font, 'normal', 'normal');
    if (Object.prototype.hasOwnProperty.call(this.assignment.data.content, 'assigneeFirstName')) {
      doc.text(this.assignment.data.content.assigneeFirstName, x + 50, y + 43);
    }
    // Last name - columns 2
    doc.setFont(font, 'normal', 'bold');
    doc.text('Last name:', x + 100, y + 43);
    doc.setFont(font, 'normal', 'normal');
    if (Object.prototype.hasOwnProperty.call(this.assignment.data.content, 'assigneeLastName')) {
      doc.text(this.assignment.data.content.assigneeLastName, x + 150, y + 43);
    }

    // Work phone - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Work phone:', x + 7, y + 50);
    doc.setFont(font, 'normal', 'normal');
    if (Object.prototype.hasOwnProperty.call(this.assignment.data.content.orgdata, 'orgPhoneOffice')) {
      doc.text(this.assignment.data.content.orgdata.orgPhoneOffice, x + 50, y + 50);
    }
    // Mobile phone - column 2
    doc.setFont(font, 'normal', 'bold');
    doc.text('Mobile phone:', x + 100, y + 50);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.assignment.data.content.orgdata.orgMobilephone,
      x + 150,
      y + 50
    );

    // Email - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Email:', x + 7, y + 57);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.assignment.data.content.orgdata.orgMailPrimary,
      x + 50,
      y + 57
    );

    // Preferred way of contact - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Preferred way of contact:', x + 7, y + 64);
    doc.setFont(font, 'normal', 'normal');
    doc.text(this.assignment.data.content.orgdata.ContactWay, x + 70, y + 64);

    // Gender - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Gender:', x + 7, y + 71);
    doc.setFont(font, 'normal', 'normal');
    doc.text(this.assignment.data.content.extrainfo.Gender, x + 70, y + 71);
    // ----------------------------------------
    x = 0; // Reset indent
    // ----------------------------------------
    // Move details
    doc.setFillColor('#e9ecef');
    doc.fill();
    doc.roundedRect(x + 5, y + 83, 200, 116, 2, 2, 'FD');

    // Header for your details
    doc.setFont(font, 'normal', 'bold');
    doc.setFontSize(14);
    doc.setTextColor('#1c958d');
    doc.text('Move details', x + 7, y + 89);

    // Sub header 'Moving from'
    doc.setFont(font, 'normal', 'bold');
    doc.setFontSize(12);
    doc.setTextColor('#1c958d');
    doc.text('Moving from:', x + 7, y + 96);

    doc.setFontSize(12);
    doc.setTextColor('Black');
    x = 3; // Indent

    // Address - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Address:', x + 7, y + 103);
    doc.setFont(font, 'normal', 'normal');
    doc.text(this.assignment.data.content.orgdata.orgAddress1, x + 50, y + 103);

    // City - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('City:', x + 7, y + 110);
    doc.setFont(font, 'normal', 'normal');
    doc.text(this.assignment.data.content.orgdata.orgTown, x + 50, y + 110);
    // Zipcode - column 2
    doc.setFont(font, 'normal', 'bold');
    doc.text('Zipcode:', x + 100, y + 110);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.assignment.data.content.orgdata.orgPostcode,
      x + 150,
      y + 110
    );

    // Country - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Country:', x + 7, y + 117);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.getCountryName(this.assignment.data.content.orgdata.orgCountry),
      x + 50,
      y + 117
    );

    // Residence type - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Residence type:', x + 7, y + 124);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.getResidenceType(this.assignment.data.content.orgdata.orgResidenceType),
      x + 50,
      y + 124
    );

    // Walking distance - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Walking distance:', x + 7, y + 131);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.getWalkingDistance(this.assignment.data.content.orgdata.orgWalkingDistance),
      x + 50,
      y + 131
    );

    // Elevator - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Elevator:', x + 7, y + 138);
    doc.setFont(font, 'normal', 'normal');
    doc.text(this.getElevator(this.assignment.data.content.orgdata.orgElevator), x + 50, y + 138);

    // Door code - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Door code:', x + 7, y + 145);
    doc.setFont(font, 'normal', 'normal');
    doc.text(this.assignment.data.content.orgdata.orgDoorcode, x + 50, y + 145);
    // Floor - column 2
    doc.setFont(font, 'normal', 'bold');
    doc.text('Floor:', x + 100, y + 145);
    doc.setFont(font, 'normal', 'normal');
    doc.text(this.assignment.data.content.orgdata.orgFloor, x + 150, y + 145);

    // Number of rooms - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Number of rooms:', x + 7, y + 152);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.assignment.data.content.surveyinfo.NumberOfRooms,
      x + 50,
      y + 152
    );
    // Area - column 2
    doc.setFont(font, 'normal', 'bold');
    doc.text('Area (m²):', x + 100, y + 152);
    doc.setFont(font, 'normal', 'normal');
    if (!this.assignment.data.content.surveyinfo.Area) {
      this.assignment.data.content.surveyinfo.Area = 0;
    }
    doc.text(
      this.assignment.data.content.surveyinfo.Area.toString(),
      x + 150,
      y + 152
    );

    // Goods in basement - column 1
    let result = '';
    doc.setFont(font, 'normal', 'bold');
    doc.text('Goods in basement:', x + 7, y + 159);
    doc.setFont(font, 'normal', 'normal');
    if (!this.assignment.data.content.surveyinfo.Basement) {
      result = 'No';
    } else {
      if (this.assignment.data.content.surveyinfo.Basement) {
        result = 'Yes';
      }
    }
    doc.text(result, x + 50, y + 159);

    // Goods in attic - column 2
    result = '';
    doc.setFont(font, 'normal', 'bold');
    doc.text('Goods in attic:', x + 100, y + 159);
    doc.setFont(font, 'normal', 'normal');
    if (!this.assignment.data.content.surveyinfo.Attic) {
      result = 'No';
    } else {
      if (this.assignment.data.content.surveyinfo.Attic) {
        result = 'Yes';
      }
    }
    doc.text(result, x + 150, y + 159);

    x = 0;

    // Sub header 'Moving to'
    doc.setFont(font, 'normal', 'bold');
    doc.setFontSize(12);
    doc.setTextColor('#1c958d');
    doc.text('Moving to:', x + 7, y + 166);

    doc.setFontSize(12);
    doc.setTextColor('Black');

    x = 3; // Indent

    // Address - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Address:', x + 7, y + 173);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.assignment.data.content.destdata.destAddress,
      x + 50,
      y + 173
    );

    // City - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('City:', x + 7, y + 180);
    doc.setFont(font, 'normal', 'normal');
    doc.text(this.assignment.data.content.destdata.destTown, x + 50, y + 180);
    // Zipcode - column 2
    doc.setFont(font, 'normal', 'bold');
    doc.text('Zipcode:', x + 100, y + 180);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.assignment.data.content.destdata.destPostcode,
      x + 150,
      y + 180
    );

    // Country - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Country:', x + 7, y + 187);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.getCountryName(this.assignment.data.content.destdata.destCountry),
      x + 50,
      y + 187
    );

    // Returning to home country - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Returning home:', x + 7, y + 194);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.assignment.data.content.extrainfo.ReturningHome,
      x + 50,
      y + 194
    );
    // ----------------------------------------
    x = 0; // Reset indent
    // ----------------------------------------
    // Move dates
    doc.setFillColor('#e9ecef');
    doc.fill();
    doc.roundedRect(x + 5, y + 203, 200, 60, 2, 2, 'FD');

    // Header for your details
    doc.setFont(font, 'normal', 'bold');
    doc.setFontSize(14);
    doc.setTextColor('#1c958d');
    doc.text('Move dates:', x + 7, y + 210);

    x = 3; // Indent
    doc.setFontSize(12);
    doc.setTextColor('Black');

    // Preferred survey date - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Preferred survey date:', x + 7, y + 217);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.assignment.data.content.orgdata.orgSurveyDate,
      x + 80,
      y + 217
    );

    // Preferred packing date - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Preferred packing date:', x + 7, y + 224);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.assignment.data.content.dateinfo.PackingDate,
      x + 80,
      y + 224
    );

    // House must be empty by - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('House must be empty by:', x + 7, y + 231);
    doc.setFont(font, 'normal', 'normal');
    doc.text(this.assignment.data.content.dateinfo.EmptyDate, x + 80, y + 231);

    // Arrival date to the destination - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Arrival date to the destination:', x + 7, y + 238);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.assignment.data.content.dateinfo.ShipperArrivalDate,
      x + 80,
      y + 238
    );

    // Preferred date for delivery - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Preferred date for delivery:', x + 7, y + 245);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.assignment.data.content.dateinfo.AvailableDate,
      x + 80,
      y + 245
    );

    // Start of new assignment - column 1
    doc.setFont(font, 'normal', 'bold');
    doc.text('Start of new assignment:', x + 7, y + 252);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.assignment.data.content.extrainfo.AssignmentStartDate,
      x + 80,
      y + 252
    );
    // ----------------------------------------
    x = 0; // Reset indent

    doc.addPage('a4', 'portrait');

    doc.setFillColor('#e9ecef');
    doc.fill();
    doc.roundedRect(x + 5, y + 5, 200, 70, 2, 2, 'FD');

    // Header for other details
    doc.setFont(font, 'normal', 'bold');
    doc.setFontSize(14);
    doc.setTextColor('#1c958d');
    doc.text('Other details', x + 7, y + 11);

    doc.setFontSize(12);
    doc.setTextColor('Black');

    x = 3; // Indent

    // Assignment type
    doc.setFont(font, 'normal', 'bold');
    doc.text('Assignment type:', x + 7, y + 18);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.getTetraAssignmentType(
        this.assignment.data.content.extrainfo.PolicyTypeText
      ),
      x + 80,
      y + 18
    );

    // Family size
    doc.setFont(font, 'normal', 'bold');
    doc.text('Family size:', x + 7, y + 25);
    doc.setFont(font, 'normal', 'normal');
    doc.text(this.assignment.data.content.extrainfo.FamilySize, x + 80, y + 25);

    // Number of family members
    doc.setFont(font, 'normal', 'bold');
    doc.text('Number of family members:', x + 7, y + 32);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.assignment.data.content.extrainfo.numberOfFamily,
      x + 80,
      y + 32
    );

    // Global intensive plan
    doc.setFont(font, 'normal', 'bold');
    doc.text('GIP:', x + 7, y + 39);
    doc.setFont(font, 'normal', 'normal');
    doc.text(
      this.assignment.data.content.extrainfo.GlobalIntensivePlan,
      x + 80,
      y + 39
    );

    // Move owner
    doc.setFont(font, 'normal', 'bold');
    doc.text('Move owner:', x + 7, y + 46);
    doc.setFont(font, 'normal', 'normal');
    doc.text(this.assignment.data.content.extrainfo.moveOwner, x + 80, y + 46);

    // Additional information
    doc.setFont(font, 'normal', 'bold');
    doc.text('Addition information:', x + 7, y + 60);
    doc.setFont(font, 'normal', 'normal');
    doc.text(this.assignment.data.content.extrainfo.OtherInfo, x + 80, y + 60, {
      maxWidth: 110,
    });

    let pdfFileName = '';
    if (
      Object.prototype.hasOwnProperty.call(
        this.assignment.data.content.extrainfo,
        'submitDate'
      )
    ) {
      const formattedDate = this.datePipe.transform(
        this.assignment.data.content.extrainfo.submitDate,
        'yyyy-MM-dd HH:mm'
      );
      // Submitted
      doc.setFont(font, 'normal', 'bold');
      doc.text('Submitted at:', x + 7, y + 53);
      doc.setFont(font, 'normal', 'normal');
      doc.text(formattedDate, x + 80, y + 53, {
        maxWidth: 110,
      });
      pdfFileName = `${this.assignment.data.content.assigneeFirstName} ${this.assignment.data.content.assigneeLastName}-${this.assignment.data.content.orgdata.orgCountry}-${this.assignment.data.content.destdata.destCountry}-${formattedDate}.pdf`;
    } else {
      pdfFileName = `${this.assignment.data.content.assigneeFirstName} ${this.assignment.data.content.assigneeLastName}-${this.assignment.data.content.orgdata.orgCountry}-${this.assignment.data.content.destdata.destCountry}.pdf`;
    }
    doc.save(pdfFileName);
  }
}
