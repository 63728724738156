<div class="tetra-confirm-page">
  <!-- Assignment location -->
  <h3 *ngIf="assignment.status == '0'">Assignment country</h3>
  <h3 *ngIf="assignment.status != '0'">Assignment country (not possible to change)</h3>
  <hr />

  <div class="form-container">
    <label class="icontainer">
      <select
        class="iselect"
        id="countries"
        [(ngModel)]="assignment.data.content.extrainfo.UXPayingCountry"
        (change)="
          onCountryChange(assignment.data.content.extrainfo.UXPayingCountry)
        "
        #tetraCountry="ngModel"
        required
      >
        <option *ngFor="let option of countries" [value]="option.countrycode">
          {{ option.countryname }}
        </option>
      </select>
      <span
        class="itext"
        [ngClass]="{
          'itext-select':
            assignment.data.content.extrainfo.UXPayingCountry != undefined
        }"
        >Country*</span
      >
      <span class="erval" *ngIf="!tetraCountry.valid && tetraCountry.touched"
        >Please select a country.</span
      ></label
    >
    <!--
    {{ assignment.data.content.extrainfo.payingCompany | json }}<br>
    {{ assignment.data.content.extrainfo.UXPayingCountry }}<br>
    {{ assignment.data.content.extrainfo.UXPayingCompany }}
    -->
    <!-- HERE -->
    <label class="icontainer">
      <select
        class="iselect"
        name="payingCompany"
        [(ngModel)]="assignment.data.content.extrainfo.UXPayingCompany"
        (change)="onCompanyChange(assignment.data.content.extrainfo.UXPayingCompany)"
        required
        [disabled]="!assignment.data.content.extrainfo.UXPayingCountry"
        #payingCompany="ngModel"
      >
        <option
          *ngFor="let company of companies"
          [ngValue]="company.companynumber"
        >
          {{ company.companyname }}
        </option>
      </select>
      <span
        class="itext"
        [ngClass]="{
          'itext-select':
            assignment.data.content.extrainfo.payingCompany != undefined
        }"
        >Company*</span
      >
      <span class="erval" *ngIf="!payingCompany.valid && payingCompany.touched"
        >Please select a company.</span
      >
      <span class="erval" *ngIf="!payingCompany.valid && payingCompany.touched"
        >Please select a company after <b> first selecting a country.</b></span
      >
    </label>

    <h3>Your details</h3>
    <hr />

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="text"
        name="inputName"
        [(ngModel)]="assignment.data.content.assigneeFirstName"
        #inputName="ngModel"
        required
      />
      <span class="itext">First name*</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="text"
        name="lastName"
        [(ngModel)]="assignment.data.content.assigneeLastName"
        required
        #lastName="ngModel"
      />
      <span class="itext">Last name*</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="email"
        name="fromEmail"
        [(ngModel)]="assignment.data.content?.orgdata.orgMailPrimary"
        #fromEmail="ngModel"
        required
        email
      />
      <span class="itext">Email*</span>
      <span class="erval" *ngIf="!fromEmail.valid && fromEmail.touched"
        >Please enter a correct email</span
      >
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="tel"
        name="toWorkPhone"
        [(ngModel)]="assignment.data.content?.orgdata.orgPhoneOffice"
        #toWorkPhone="ngModel"
      />
      <span class="itext">Work phone</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        type="tel"
        placeholder="&nbsp;"
        name="toMobilePhone"
        [(ngModel)]="assignment.data.content?.orgdata.orgMobilephone"
        #toMobilePhone="ngModel"
      />
      <span class="itext">Mobile phone</span>
    </label>

    <p>Preferred way of contact:</p>

    <div class="row">
      <label class="radio">
        <input
          class="iradio"
          type="radio"
          value="workphone"
          name="preferredContact"
          [(ngModel)]="assignment.data.content?.orgdata.ContactWay"
          #preferredContact
        />
        <span>Work phone</span>
      </label>
    </div>

    <div class="row">
      <label class="radio">
        <input
          class="iradio"
          type="radio"
          value="mobilephone"
          name="preferredContact"
          [(ngModel)]="assignment.data.content?.orgdata.ContactWay"
          #preferredContact
        />
        <span>Mobile phone</span>
      </label>
    </div>

    <div class="row">
      <label class="radio">
        <input
          class="iradio"
          type="radio"
          value="email"
          name="preferredContact"
          [(ngModel)]="assignment.data.content?.orgdata.ContactWay"
          #preferredContact
        />
        <span>Email</span>
      </label>
    </div>

    <h3>Move details</h3>
    <hr />
    <h4>Moving from:</h4>

    <label class="icontainer">
      <input
        type="text"
        class="ifield"
        placeholder="&nbsp;"
        name="fromAddress"
        [(ngModel)]="assignment.data.content?.orgdata.orgAddress1"
        #fromAddress="ngModel"
        required
      />
      <span class="itext">Address*</span>
      <span class="erpattern" *ngIf="fromAddress.errors?.pattern"
        >Please review your input and remove any special characters</span
      >
    </label>

    <label class="icontainer">
      <input
        required
        type="text"
        class="ifield"
        placeholder="&nbsp;"
        name="fromCity"
        [(ngModel)]="assignment.data.content?.orgdata.orgTown"
        #fromCity="ngModel"
      />
      <span class="itext">City*</span>
    </label>

    <label class="icontainer">
      <input
        type="text"
        class="ifield"
        placeholder="&nbsp;"
        name="fromZipcode"
        [(ngModel)]="assignment.data.content?.orgdata.orgPostcode"
        #fromZipcode="ngModel"
      />
      <span class="itext">Zip code</span>
    </label>

    <label class="icontainer">
      <select
        class="iselect"
        name="fromCountry"
        [(ngModel)]="assignment.data.content?.orgdata.orgCountry"
        #fromCountry="ngModel"
        required
      >
      <option *ngFor="let country of countries" [value]="country.countrycode">
        {{ country.countryname }}
      </option>
      </select>
      <span class="erval" *ngIf="!fromCountry.valid && fromCountry.touched"
        >Please select a country.</span
      >
      <span
        class="itext"
        [ngClass]="{
          'itext-select':
            assignment.data.content?.orgdata.orgCountry != undefined
        }"
        >Country*</span
      >
    </label>

    <label class="icontainer">
      <select
        name="residenceType"
        class="iselect"
        [(ngModel)]="assignment.data.content?.orgdata.orgResidenceType"
        #residenceType
      >
        <option value="1">Apartment</option>
        <option value="2">Villa</option>
        <option value="3">Townhouse</option>
        <option value="4">Warehouse</option>
      </select>
      <span
        class="itext"
        [ngClass]="{
          'itext-select':
            assignment.data.content?.orgdata.orgResidenceType != undefined
        }"
      >
        Residence type
      </span>
    </label>

    <label class="icontainer">
      <select
        name="walkingDistance"
        class="iselect"
        [(ngModel)]="assignment.data.content?.orgdata.orgWalkingDistance"
        #walkingDistance
      >
        <option value="1">Within 30 meters</option>
        <option value="2">30 to 50 meter carry</option>
        <option value="3">51 to 75 meter carry</option>
        <option value="4">76 to 100 meter carry</option>
        <option value="5">Not known at quotation</option>
      </select>
      <span
        class="itext"
        [ngClass]="{
          'itext-select':
            assignment.data.content?.orgdata.orgWalkingDistance != undefined
        }"
      >
        Walking distance (from door to truck)
      </span>
    </label>

    <label class="icontainer">
      <select
        name="elevator"
        class="iselect"
        [(ngModel)]="assignment.data.content?.orgdata.orgElevator"
        #elevator
      >
        <option value="0">No elevator</option>
        <option value="1">Small elevator</option>
        <option value="2">Medium elevator</option>
        <option value="3">Big elevator</option>
        <option value="4">Elevator not useable for move</option>
      </select>
      <span
        class="itext"
        [ngClass]="{
          'itext-select':
            assignment.data.content?.orgdata.orgElevator != undefined
        }"
      >
        Elevator
      </span>
    </label>

    <label class="icontainer">
      <input
        type="text"
        placeholder="&nbsp;"
        class="ifield"
        name="fromDoorCode"
        [(ngModel)]="assignment.data.content?.orgdata.orgDoorcode"
        #fromDoorCode
      />
      <span class="itext">Door code</span>
    </label>

    <label class="icontainer">
      <input
        type="text"
        placeholder="&nbsp;"
        class="ifield"
        name="fromFloor"
        [(ngModel)]="assignment.data.content?.orgdata.orgFloor"
        #fromFloor
      />
      <span class="itext">Floor</span>
    </label>

    <label class="icontainer">
      <input
        type="text"
        placeholder="&nbsp;"
        class="ifield"
        name="fromRoomCount"
        [(ngModel)]="assignment.data.content?.surveyinfo.NumberOfRooms"
        #fromRoomCount
      />
      <span class="itext">Number of rooms</span>
    </label>

    <label class="icontainer">
      <input
        type="number"
        placeholder="&nbsp;"
        class="ifield"
        name="fromArea"
        [(ngModel)]="assignment.data.content?.surveyinfo.Area"
        #fromArea
      />
      <span class="itext">Area (m²)</span>
    </label>

    <p>I have goods in a:</p>

    <label class="checkbox">
      <input
        type="checkbox"
        name="basement"
        value="yes"
        [(ngModel)]="assignment.data.content?.surveyinfo.Basement"
        #basement
      />
      <span>Basement</span>
    </label>

    <label class="checkbox">
      <input
        type="checkbox"
        name="attic"
        value="yes"
        [(ngModel)]="assignment.data.content?.surveyinfo.Attic"
        #attic
      />
      <span>Attic</span>
    </label>

    <h4>Moving to:</h4>

    <label class="icontainer">
      <input
        type="text"
        class="ifield"
        name="toAddress"
        placeholder="&nbsp;"
        [(ngModel)]="assignment.data.content?.destdata.destAddress"
        #toAddress="ngModel"
      />
      <span class="itext">Address</span>
      <span class="erpattern" *ngIf="toAddress.errors?.pattern"
        >Please review your input and remove any special characters</span
      >
    </label>

    <label class="icontainer">
      <input
        type="text"
        placeholder="&nbsp;"
        class="ifield"
        name="toCity"
        [(ngModel)]="assignment.data.content?.destdata.destTown"
        #toCity="ngModel"
        required
      />
      <span class="itext">City*</span>

      <span class="erval" *ngIf="toCity.errors?.required && toCity.touched"
        >Please select a city.</span
      >
      <span class="erpattern" *ngIf="toCity.errors?.pattern"
        >Please review your input and remove any special characters</span
      >
    </label>

    <label class="icontainer">
      <input
        type="text"
        placeholder="&nbsp;"
        class="ifield"
        name="toZipcode"
        [(ngModel)]="assignment.data.content?.destdata.destPostcode"
        #toZipcode="ngModel"
      />
      <span class="itext">Zip code</span>
      <span class="erpattern" *ngIf="toZipcode.errors?.pattern"
        >Please review your input and remove any special characters</span
      >
    </label>

    <label class="icontainer">
      <select
        class="iselect"
        name="toCountry"
        [(ngModel)]="assignment.data.content?.destdata.destCountry"
        #toCountry="ngModel"
        required
      >
      <option *ngFor="let country of countries" [value]="country.countrycode">
        {{ country.countryname }}
      </option>
      </select>
      <span class="erval" *ngIf="!toCountry.valid && toCountry.touched"
        >Please select a country.</span
      >
      <span
        class="itext"
        [ngClass]="{
          'itext-select': assignment.data.content?.destdata.destCountry
        }"
        >Country*</span
      >
    </label>

    <div class="radio-container">
      <p>I am returning to my home country:</p>

      <div class="row">
        <label class="radio">
          <input
            type="radio"
            value="yes"
            [(ngModel)]="assignment.data.content?.extrainfo.ReturningHome"
            name="toReturning"
          />
          <span>Yes</span>
        </label>
      </div>

      <div class="row">
        <label class="radio">
          <input
            type="radio"
            value="no"
            [(ngModel)]="assignment.data.content?.extrainfo.ReturningHome"
            name="toReturning"
          />
          <span>No</span>
        </label>
      </div>
    </div>

    <h4>Move dates</h4>
    <div class="form-container">
      <label class="datelabel" for="surveyDate"> Preferred survey date </label>
      <input
        class="dateinput"
        placeholder="Please choose a date"
        type="date"
        id="surveyDate"
        name="surveyDate"
        [min]="minDate"
        [(ngModel)]="assignment.data.content?.orgdata.orgSurveyDate"
        #surveyDate
      />

      <label class="datelabel" for="packingDate">
        Preferred date of packing (if known)
      </label>
      <input
        class="dateinput"
        [min]="minDate"
        placeholder="Please choose a date"
        type="date"
        id="packingDate"
        name="packingDate"
        [(ngModel)]="assignment.data.content?.dateinfo.PackingDate"
        #packingDate
      />

      <label class="datelabel" for="houseEmpty">
        House must be empty by (if known)
      </label>
      <input
        class="dateinput"
        placeholder="Please choose a date"
        [min]="minDate"
        type="date"
        id="emptyDate"
        name="emptyDate"
        [(ngModel)]="assignment.data.content?.dateinfo.EmptyDate"
        #emptyDate
      />

      <label class="datelabel" for="shipperArrivalDate">
        Your own planned arrival date at the destination</label
      >
      <input
        class="dateinput"
        placeholder="Please choose a date"
        type="date"
        id="shipperArrivalDate"
        name="shipperArrivalDate"
        [(ngModel)]="assignment.data.content?.dateinfo.ShipperArrivalDate"
        #shipperArrivalDate
      />

      <label class="datelabel" for="availableDate">
        Preferred date for delivery
      </label>
      <input
        class="dateinput"
        placeholder="Please choose a date"
        [min]="minDate"
        type="date"
        id="availableDate"
        name="availableDate"
        [(ngModel)]="assignment.data.content?.dateinfo.AvailableDate"
        #availableDate
      />
    </div>

    <!-- TETRA other -->
    <div class="radio-container">
      <h4>Select assignment type</h4>
      <!-- TODO: fix required field -->

      <label class="radio">
        <input
          type="radio"
          value="TETRA1"
          name="assignmentType"
          [(ngModel)]="assignment.data.content?.extrainfo.PolicyTypeText"
        />
        <span>Short Term Assignment</span>
      </label>

      <label class="radio">
        <input
          type="radio"
          value="TETRA2"
          required
          #assignmentType="ngModel"
          name="assignmentType"
          [(ngModel)]="assignment.data.content?.extrainfo.PolicyTypeText"
        />
        <span>Career Migration</span>
      </label>

      <label class="radio">
        <input
          type="radio"
          value="TETRA3"
          name="assignmentType"
          [(ngModel)]="assignment.data.content?.extrainfo.PolicyTypeText"
        />
        <span>Executive</span>
      </label>

      <label class="radio">
        <input
          type="radio"
          value="TETRA4"
          name="assignmentType"
          [(ngModel)]="assignment.data.content?.extrainfo.PolicyTypeText"
        />
        <span>Long Term Assignment</span>
      </label>

      <label class="radio">
        <input
          type="radio"
          value="TETRA5"
          name="assignmentType"
          [(ngModel)]="assignment.data.content?.extrainfo.PolicyTypeText"
        />
        <span>Swiss Trans</span>
      </label>

      <h4>Family size*</h4>
      <!-- TODO: Fix a required field -->

      <label class="radio">
        <input
          required
          type="radio"
          value="Single"
          name="tetraFamily"
          #tetraFamily="ngModel"
          [(ngModel)]="assignment.data.content?.extrainfo.FamilySize"
          (ngModelChange)="onFamilySizeChange()"
        />
        <span>Single</span>
      </label>

      <label class="radio">
        <input
          type="radio"
          value="Couple"
          name="tetraFamily"
          [(ngModel)]="assignment.data.content?.extrainfo.FamilySize"
          (ngModelChange)="onFamilySizeChange()"
        />
        <span>Couple</span>
      </label>

      <label class="radio">
        <input
          type="radio"
          value="Couple or Single plus 1 Child"
          name="tetraFamily"
          [(ngModel)]="assignment.data.content?.extrainfo.FamilySize"
          (ngModelChange)="onFamilySizeChange()"
        />
        <span>Couple or single plus 1 child</span>
      </label>

      <label class="radio">
        <input
          type="radio"
          value="Couple or Single plus 2 Children"
          name="tetraFamily"
          [(ngModel)]="assignment.data.content?.extrainfo.FamilySize"
          (ngModelChange)="onFamilySizeChange()"
        />
        <span>Couple or single plus 2 children</span>
      </label>

      <label class="radio">
        <input
          type="radio"
          value="Couple or Single plus 3 Children"
          name="tetraFamily"
          [(ngModel)]="assignment.data.content?.extrainfo.FamilySize"
          (ngModelChange)="onFamilySizeChange()"
        />
        <span>Couple or single plus 3 children</span>
      </label>

      <label class="radio">
        <input
          type="radio"
          value="Couple or Single plus 4 or more Children"
          name="tetraFamily"
          [(ngModel)]="assignment.data.content?.extrainfo.FamilySize"
          (ngModelChange)="onFamilySizeChange()"
        />
        <span>Couple or single plus 4 or more children</span>
      </label>

      <label class="icontainer label-children">
        <select
          name="numberOfFamily"
          class="iselect"
          id="family"
          [(ngModel)]="assignment.data.content?.extrainfo.numberOfFamily"
          #numberOfFamily
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6 or more">6 or more</option>
        </select>
        <span
          class="itext"
          [ngClass]="{
            'itext-select':
              assignment.data.content?.extrainfo.numberOfFamily != undefined
          }"
        >
          Number of family members
        </span>
      </label>
      <span class="familymsg">Family members moving, incl. Yourself </span>
    </div>

    <!-- GIP -->
    <h4>Are you considered as a GIP or Non GIP - Global Intensive Plan</h4>

    <label class="radio">
      <input
        type="radio"
        value="yes"
        name="GlobalIntensivePlan"
        [(ngModel)]="assignment.data.content?.extrainfo.GlobalIntensivePlan"
      />
      <span>GIP</span>
    </label>

    <label class="radio">
      <input
        type="radio"
        value="no"
        name="GlobalIntensivePlan"
        [(ngModel)]="assignment.data.content?.extrainfo.GlobalIntensivePlan"
      />
      <span>Non GIP</span>
    </label>

    <!--
            <h4>Move owner</h4>

            <label class="icontainer">
              <input
                type="text"
                class="ifield"
                placeholder="&nbsp;"
                name="moveOwner"
                [(ngModel)]="assignment.data.content?.extrainfo.moveOwner"
              />
              <span class="itext">Name</span>
            </label>
             -->

    <h4>Move owner</h4>

    <label class="icontainer">
      <select
        name="moveOwner"
        class="iselect"
        [(ngModel)]="assignment.data.content?.extrainfo.moveOwner"
        (change)="onMoveOwnerChange()"
        #moveOwner
      >
        <option value="Mirella.Horvath&#64;tetrapak.com">
          Mirella Horvath (Mirella.Horvath&#64;tetrapak.com)
        </option>
        <option value="Barbara.Lovas&#64;tetrapak.com">
          Barbara Lovas (Barbara.Lovas&#64;tetrapak.com)
        </option>
        <option value="fernando.ruiz&#64;sidel.com">
          Fernando Ruiz (fernando.ruiz&#64;sidel.com)
        </option>
        <option value="Malgorzata.PtakKowalska&#64;delaval.com">
          Malgorzata Ptak Kowalska (Malgorzata.PtakKowalska&#64;delaval.com)
        </option>
        <option value="Europe.Mobility&#64;tetrapak.com">
          Europe Mobility (Europe.Mobility&#64;tetrapak.com)
        </option>
        <option value="Sweden.Mobility&#64;tetrapak.com">
          Sweden Mobility (Sweden.Mobility&#64;tetrapak.com)
        </option>
        <option value="Larissa.Arruda&#64;tetrapak.com">
          Larissa Arruda (Larissa.Arruda&#64;tetrapak.com)
        </option>
        <option value="Kyle.Turner&#64;tetrapak.com">
          Kyle Turner (Kyle.Turner&#64;tetrapak.com)
        </option>
        <option value="Marivel.Vela&#64;tetrapak.com">
          Marivel Vela (Marivel.Vela&#64;tetrapak.com)
        </option>
        <option value="Shirley.Yang&#64;tetrapak.com">
          Shirley Yang (Shirley.Yang&#64;tetrapak.com)
        </option>
        <option value="Preeti.Fernandes&#64;tetrapak.com">
          Preeti Fernandes (Preeti.Fernandes&#64;tetrapak.com)
        </option>
        <option value="Veronique.Hejl&#64;tetrapak.com">
          Veronique Hejl (Veronique.Hejl&#64;tetrapak.com)
        </option>
      </select>
      <span
        class="itext"
        [ngClass]="{
          'itext-select':
            assignment.data.content?.extrainfo.moveOwner != undefined
        }"
      >
        Name
      </span>
    </label>

    Submitted on:
    {{
      assignment.data.content?.extrainfo.submitDate | date : "yyyy-MM-dd HH:mm"
    }}
    <br /><br />

    <label for="textbox"><h4>Additional information</h4></label>
    <textarea
      class="itextfield"
      name="otherDetails"
      id="textbox"
      cols="30"
      rows="10"
      [(ngModel)]="assignment.data.content?.extrainfo.OtherInfo"
      placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
    ></textarea>

    <!-- Button row 1 -->
    <div *ngIf="assignment.status == '0'" class="button-container" id="btn-row-1">
      <button class="button btn-reject" (click)="updateReject(assignment)">
        Reject
      </button>
      <button class="button btn-approve" (click)="updateApprove(assignment)">
        Approve
      </button>
    </div>

    <!-- Button row 2 -->
    <div class="button-container" id="btn-row-2">
      <button class="button btn-approve" (click)="generatePDF()">
        Save as PDF
      </button>

      <button *ngIf="assignment.status == '0'" class="button btn-approve" (click)="updateSave(assignment)">
        Save
      </button>
    </div>

    <!-- Button row 3 -->
    <!-- <div class="button-container" id="btn-row-3">
      <button class="button" (click)="xDebug(ix)">Debug</button>
    </div> -->
  </div>
</div>
