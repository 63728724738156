import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-sida',
  templateUrl: './sida.component.html',
  styleUrls: ['./sida.component.scss']
})
export class SidaComponent {

  extrainfo : Extrainfo;
  content : Content;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.extrainfo.CompanyName = 'SIDA';
    this.content.SokID = '15208';
    this.extrainfo.CompanyAddress = 'Box 1271';
    this.extrainfo.CompanyCity = 'Visby';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = '621 23';
    this.extrainfo.insurance = 'Jag har tagit del av Sidas och Kammarkollegiets information om flyttgodsförsäkring. Jag är medveten om att Sida står för ett försäkringsvärde upp till 800 000 kronor och att jag själv bekostar eventuellt överskjutande värde liksom andra tilläggsförsäkringar.';
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.Avtal = 'Avtal UF 2009/19562/FAST 1-3';
    this.extrainfo.language = 'en';

    this.formConfig.confirm = false; //Determines if form need approval by manager

    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'Sida';
  }

}
