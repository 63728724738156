<!-- TODO: Overlook and fix margins etc. -->
<div>
  <h3>Insurance</h3>
  <hr />
  <p>
    All shipments are insured via Sandvik AB. Please contact Catarina Israelsson
    for more information.
  </p>
</div>

<div>
  <h3>Other details</h3>
  <hr />
</div>

<div class="radio-container">
  <h4>Do you have any goods in storage to be included in your move?</h4>

  <label class="radio">
    <input
      type="radio"
      value="Yes"
      [(ngModel)]="extrainfo.InStorage"
      name="sandvikStorageMove"
    />
    <span>Yes</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="No"
      [(ngModel)]="extrainfo.InStorage"
      name="sandvikStorageMove"
    />
    <span>No</span>
  </label>

  <!-- <div class="radio-container">
    <h4>Do you need storage of max 10 cbm</h4>

    <label class="radio">
      <input
        type="radio"
        value="yes"
        [(ngModel)]="extrainfo.ToStorage"
        name="sandvikRequireStorage"
      />
      <span>Yes</span>
    </label>

    <label class="radio">
      <input
        type="radio"
        value="no"
        [(ngModel)]="extrainfo.ToStorage"
        name="sandvikRequireStorage"
      />
      <span>No</span>
    </label>
  </div> -->

  <h4>Will any children be accompanying?</h4>

  <label class="icontainer label-children">
    <select
      name="sandvikKids"
      class="iselect"
      [(ngModel)]="extrainfo.NumberOfChildren"
      #sandvikKids
    >
      <option value="none">No kids</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6 or more">6 or more</option>
    </select>
    <span
      class="itext"
      [ngClass]="{ 'itext-select': extrainfo.NumberOfChildren != undefined }"
    >
      Number of children
    </span>
  </label>

  <h4>Shipment to UNFURNISHED residence</h4>

  <label class="radio">
    <input
      type="radio"
      value="30 cbm unfurnished"
      [(ngModel)]="extrainfo.Shipment"
      name="unfurnishedResidence"
    />
    <span>Family size 1: <b>30 cbm</b> </span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="45 cbm unfurnished"
      [(ngModel)]="extrainfo.Shipment"
      name="unfurnishedResidence"
    />
    <span>Family size 2: <b>45 cbm</b></span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="50 cbm unfurnished"
      [(ngModel)]="extrainfo.Shipment"
      name="unfurnishedResidence"
    />
    <span>Family size 3: <b>50 cbm</b> </span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="55 cbm unfurnished"
      [(ngModel)]="extrainfo.Shipment"
      name="unfurnishedResidence"
    />
    <span>Family size 4: <b>55 cbm</b> </span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="60 cbm unfurnished"
      [(ngModel)]="extrainfo.Shipment"
      name="unfurnishedResidence"
    />
    <span>Family size 5: <b>60 cbm</b></span>
  </label>

  <h4>Shipment to FURNISHED residence</h4>

  <label class="radio">
    <input
      type="radio"
      value="8 cbm furnished"
      [(ngModel)]="extrainfo.Shipment"
      name="furnishedResidence"
    />
    <span>Family size 1: <b>8 cbm</b></span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="12 cbm furnished"
      [(ngModel)]="extrainfo.Shipment"
      name="furnishedResidence"
    />
    <span>Family size 2: <b>12 cbm</b></span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="14 cbm furnished"
      [(ngModel)]="extrainfo.Shipment"
      name="furnishedResidence"
    />
    <span>Family size 3: <b>14 cbm</b></span>
  </label>


  <label class="radio">
    <input
      type="radio"
      value="16 cbm furnished"
      [(ngModel)]="extrainfo.Shipment"
      name="furnishedResidence"
    />
    <span>Family size 4: <b>16 cbm</b></span>
  </label>


 <!--  <label for="volumebox">
    <h4>
      Please specify your companies volume limit - if any. Check the Sandvik AB
      policy for information.
    </h4></label
  >
  <textarea
    class="itextfield other-text"
    name="otherDetails"
    id="volumebox"
    cols="30"
    rows="10"
    [(ngModel)]="extrainfo.OtherInfo"
  >
  </textarea> -->

  <label for="textbox"><h4>Other info</h4></label>
  <textarea
    class="itextfield"
    name="otherDetails"
    id="textbox"
    cols="30"
    rows="10"
    [(ngModel)]="extrainfo.OtherInfo"
    placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
  >
  </textarea>

  <div class="button-container">
    <input
      class="button btn-form"
      type="button"
      value="Back"
      routerLink="../movedetailsto"
    />
    <input
      class="button btn-form"
      type="button"
      value="Submit"
      (click)="formData.formSubmit()"
    />
  </div>
</div>
