<div class="alfa-confirm-page">
  <h3 *ngIf="assignment.status == '0'">Company details</h3>
  <h3 *ngIf="assignment.status != '0'">Company details (not possible to change)</h3>
  <hr />

  <div class="form-container">
    <label class="icontainer">
      <select
        class="iselect"
        id="countries"
        [(ngModel)]="assignment.data.content.extrainfo.UXPayingCountry"
        (change)="
          onCountryChange(assignment.data.content.extrainfo.UXPayingCountry)
        "
        #tetraCountry="ngModel"
        required
      >
        <option *ngFor="let option of countries" [value]="option.countrycode">
          {{ option.countryname }}
        </option>
      </select>
      <span
        class="itext"
        [ngClass]="{
          'itext-select':
            assignment.data.content.extrainfo.UXPayingCountry != undefined
        }"
        >Country*</span
      >
      <span class="erval" *ngIf="!tetraCountry.valid && tetraCountry.touched"
        >Please select a country.</span
      ></label
    >
    <!--
    {{ assignment.data.content.extrainfo.payingCompany | json }}<br>
    {{ assignment.data.content.extrainfo.UXPayingCountry }}<br>
    {{ assignment.data.content.extrainfo.UXPayingCompany }}
    -->
    <label class="icontainer">
      <select
        class="iselect"
        name="payingCompany"
        [(ngModel)]="assignment.data.content.extrainfo.UXPayingCompany"
        (change)="onCompanyChange(assignment.data.content.extrainfo.UXPayingCompany)"
        #payingCompany="ngModel"
        required
        [disabled]="!assignment.data.content.extrainfo.UXPayingCountry"
      >
        <option
          *ngFor="let company of companies"
          [ngValue]="company.companynumber"
        >
          {{ company.companyname }}
        </option>
      </select>
      <span
        class="itext"
        [ngClass]="{
          'itext-select':
            assignment.data.content.extrainfo.payingCompany != undefined
        }"
        >Company*</span
      >
      <span class="erval" *ngIf="!payingCompany.valid && payingCompany.touched"
        >Please select a company.</span
      >
      <span class="erval" *ngIf="!payingCompany.valid && payingCompany.touched"
        >Please select a company after <b> first selecting a country.</b></span
      >
    </label>

    <h3>Your details</h3>
    <hr />

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="text"
        name="inputName"
        [(ngModel)]="assignment.data.content.assigneeFirstName"
        #inputName="ngModel"
        required
      />
      <span class="itext">First name*</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="text"
        name="lastName"
        [(ngModel)]="assignment.data.content.assigneeLastName"
        required
        #lastName="ngModel"
      />
      <span class="itext">Last name*</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="email"
        name="fromEmail"
        [(ngModel)]="assignment.data.content?.orgdata.orgMailPrimary"
        #fromEmail="ngModel"
        required
        email
      />
      <span class="itext">Email*</span>
      <span class="erval" *ngIf="!fromEmail.valid && fromEmail.touched"
        >Please enter a correct email</span
      >
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="tel"
        name="toWorkPhone"
        [(ngModel)]="assignment.data.content?.orgdata.orgPhoneOffice"
        #toWorkPhone="ngModel"
      />
      <span class="itext">Work phone</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        type="tel"
        placeholder="&nbsp;"
        name="toMobilePhone"
        [(ngModel)]="assignment.data.content?.orgdata.orgMobilephone"
        #toMobilePhone="ngModel"
      />
      <span class="itext">Mobile phone</span>
    </label>

    <p>Preferred way of contact:</p>

    <div class="row">
      <label class="radio">
        <input
          class="iradio"
          type="radio"
          value="workphone"
          name="preferredContact"
          [(ngModel)]="assignment.data.content?.orgdata.ContactWay"
          #preferredContact
        />
        <span>Work phone</span>
      </label>
    </div>

    <div class="row">
      <label class="radio">
        <input
          class="iradio"
          type="radio"
          value="mobilephone"
          name="preferredContact"
          [(ngModel)]="assignment.data.content?.orgdata.ContactWay"
          #preferredContact
        />
        <span>Mobile phone</span>
      </label>
    </div>

    <div class="row">
      <label class="radio">
        <input
          class="iradio"
          type="radio"
          value="email"
          name="preferredContact"
          [(ngModel)]="assignment.data.content?.orgdata.ContactWay"
          #preferredContact
        />
        <span>Email</span>
      </label>
    </div>

    <h3>Move details</h3>
          <hr />
          <h4>Moving from:</h4>

          <label class="icontainer">
            <input
              type="text"
              class="ifield"
              placeholder="&nbsp;"
              name="fromAddress"
              [(ngModel)]="assignment.data.content?.orgdata.orgAddress1"
              #fromAddress="ngModel"
              required
            />
            <span class="itext">Address*</span>
          </label>

          <label class="icontainer">
            <input
              required
              type="text"
              class="ifield"
              placeholder="&nbsp;"
              name="fromCity"
              [(ngModel)]="assignment.data.content?.orgdata.orgTown"

              #fromCity="ngModel"
            />
            <span class="itext">City*</span>
            <span
              class="erval"
              *ngIf="fromCity.errors?.required && fromCity.touched"
              >Please select a city.</span
            >
          </label>

          <label class="icontainer">
            <input
              type="text"
              class="ifield"
              placeholder="&nbsp;"
              name="fromZipcode"
              [(ngModel)]="assignment.data.content?.orgdata.orgPostcode"
              #fromZipcode="ngModel"
            />
            <span class="itext">Zip code</span>
          </label>

          <label class="icontainer">
            <select
              class="iselect"
              name="fromCountry"
              [(ngModel)]="assignment.data.content?.orgdata.orgCountry"
              #fromCountry="ngModel"
              required
            >
            <option *ngFor="let country of countries" [value]="country.countrycode">
              {{ country.countryname }}
            </option>
            </select>
            <span
              class="erval"
              *ngIf="!fromCountry.valid && fromCountry.touched"
              >Please select a country.</span
            >
            <span
              class="itext"
              [ngClass]="{
                'itext-select':
                assignment.data.content?.orgdata.orgCountry != undefined
              }"
              >Country*</span
            >
          </label>

          <label class="icontainer">
            <select
              name="residenceType"
              class="iselect"
              [(ngModel)]="assignment.data.content?.orgdata.orgResidenceType"
              #residenceType
            >
              <option value="1">Apartment</option>
              <option value="2">Villa</option>
              <option value="3">Townhouse</option>
              <option value="4">Warehouse</option>
            </select>
            <span
              class="itext"
              [ngClass]="{
                'itext-select':
                assignment.data.content?.orgdata.orgResidenceType != undefined
              }"
            >
              Residence type
            </span>
          </label>

          <label class="icontainer">
            <select
              name="walkingDistance"
              class="iselect"
              [(ngModel)]="assignment.data.content?.orgdata.orgWalkingDistance"
              #walkingDistance
            >
              <option value="1">Within 30 meters</option>
              <option value="2">30 to 50 meter carry</option>
              <option value="3">51 to 75 meter carry</option>
              <option value="4">76 to 100 meter carry</option>
              <option value="5">Not known at quotation</option>
            </select>
            <span
              class="itext"
              [ngClass]="{
                'itext-select':
                assignment.data.content?.orgdata.orgWalkingDistance != undefined
              }"
            >
              Walking distance (from door to truck)
            </span>
          </label>

          <label class="icontainer">
            <select
              name="elevator"
              class="iselect"
              [(ngModel)]="assignment.data.content?.orgdata.orgElevator"
              #elevator
            >
              <option value="0">No elevator</option>
              <option value="1">Small elevator</option>
              <option value="2">Medium elevator</option>
              <option value="3">Big elevator</option>
              <option value="4">Elevator not useable for move</option>
            </select>
            <span
              class="itext"
              [ngClass]="{
                'itext-select':
                assignment.data.content?.orgdata.orgElevator != undefined
              }"
            >
              Elevator
            </span>
          </label>

          <label class="icontainer">
            <input
              type="text"
              placeholder="&nbsp;"
              class="ifield"
              name="fromDoorCode"
              [(ngModel)]="assignment.data.content?.orgdata.orgDoorcode"
              #fromDoorCode
            />
            <span class="itext">Door code</span>
          </label>

          <label class="icontainer">
            <input
              type="text"
              placeholder="&nbsp;"
              class="ifield"
              name="fromFloor"
              [(ngModel)]="assignment.data.content?.orgdata.orgFloor"
              #fromFloor
            />
            <span class="itext">Floor</span>
          </label>

          <label class="icontainer">
            <input
              type="text"
              placeholder="&nbsp;"
              class="ifield"
              name="fromRoomCount"
              [(ngModel)]="assignment.data.content?.surveyinfo.NumberOfRooms"
              #fromRoomCount
            />
            <span class="itext">Number of rooms</span>
          </label>

          <label class="icontainer">
            <input
              type="number"
              placeholder="&nbsp;"
              class="ifield"
              name="fromArea"
              [(ngModel)]="assignment.data.content?.surveyinfo.Area"
              #fromArea
            />
            <span class="itext">Area (m²)</span>
          </label>

          <p>I have goods in a:</p>

          <label class="checkbox">
            <input
              type="checkbox"
              name="basement"
              value="yes"
              [(ngModel)]="assignment.data.content?.surveyinfo.Basement"
              #basement
            />
            <span>Basement</span>
          </label>

          <label class="checkbox">
            <input
              type="checkbox"
              name="attic"
              value="yes"
              [(ngModel)]="assignment.data.content?.surveyinfo.Attic"
              #attic
            />
            <span>Attic</span>
          </label>

          <h4>Moving to:</h4>

          <label class="icontainer">
            <input
              type="text"
              class="ifield"
              name="toAddress"
              placeholder="&nbsp;"
              [(ngModel)]="assignment.data.content?.destdata.destAddress"
              #toAddress="ngModel"
            />
            <span class="itext">Address</span>
            <span class="erpattern" *ngIf="toAddress.errors?.pattern"
              >Please review your input and remove any special characters</span
            >
          </label>

          <label class="icontainer">
            <input
              type="text"
              placeholder="&nbsp;"
              class="ifield"
              name="toCity"
              [(ngModel)]="assignment.data.content?.destdata.destTown"
              #toCity="ngModel"
              required
            />
            <span class="itext">City*</span>

            <span
              class="erval"
              *ngIf="toCity.errors?.required && toCity.touched"
              >Please select a city.</span
            >
            <span class="erpattern" *ngIf="toCity.errors?.pattern"
              >Please review your input and remove any special characters</span
            >
          </label>

          <label class="icontainer">
            <input
              type="text"
              placeholder="&nbsp;"
              class="ifield"
              name="toZipcode"
              [(ngModel)]="assignment.data.content?.destdata.destPostcode"
              #toZipcode="ngModel"
            />
            <span class="itext">Zip code</span>
            <span class="erpattern" *ngIf="toZipcode.errors?.pattern"
              >Please review your input and remove any special characters</span
            >
          </label>

          <label class="icontainer">
            <select
              class="iselect"
              name="toCountry"
              [(ngModel)]="assignment.data.content?.destdata.destCountry"
              #toCountry="ngModel"
              required
            >
            <option *ngFor="let country of countries" [value]="country.countrycode">
              {{ country.countryname }}
            </option>
            </select>
            <span class="erval" *ngIf="!toCountry.valid && toCountry.touched"
              >Please select a country.</span
            >
            <span
              class="itext"
              [ngClass]="{
                'itext-select': assignment.data.content?.destdata.destCountry
              }"
              >Country*</span
            >
          </label>

          <div class="radio-container">
            <p>I am returning to my home country:</p>

            <div class="row">
              <label class="radio">
                <input
                  type="radio"
                  value="yes"
                  [(ngModel)]="assignment.data.content?.extrainfo.ReturningHome"
                  name="toReturning"
                />
                <span>Yes</span>
              </label>
            </div>

            <div class="row">
              <label class="radio">
                <input
                  type="radio"
                  value="no"
                  [(ngModel)]="assignment.data.content?.extrainfo.ReturningHome"
                  name="toReturning"
                />
                <span>No</span>
              </label>
            </div>
          </div>

          <h4>Move dates</h4>
          <div class="form-container">
            <label class="datelabel" for="surveyDate">
              Preferred survey date
            </label>
            <input
              class="dateinput"
              placeholder="Please choose a date"
              type="date"
              id="surveyDate"
              name="surveyDate"
              [min]="minDate"
              [(ngModel)]="assignment.data.content?.orgdata.orgSurveyDate"
              #surveyDate
            />

            <label class="datelabel" for="packingDate">
              Preferred date of packing (if known)
            </label>
            <input
              class="dateinput"
              [min]="minDate"
              placeholder="Please choose a date"
              type="date"
              id="packingDate"
              name="packingDate"
              [(ngModel)]="assignment.data.content?.dateinfo.PackingDate"
              #packingDate
            />

            <label class="datelabel" for="houseEmpty">
              House must be empty by (if known)
            </label>
            <input
              class="dateinput"
              placeholder="Please choose a date"
              [min]="minDate"
              type="date"
              id="emptyDate"
              name="emptyDate"
              [(ngModel)]="assignment.data.content?.dateinfo.EmptyDate"
              #emptyDate
            />

            <label class="datelabel" for="shipperArrivalDate">
              Your own planned arrival date at the destination</label
            >
            <input
              class="dateinput"
              placeholder="Please choose a date"
              type="date"
              id="shipperArrivalDate"
              name="shipperArrivalDate"
              [(ngModel)]="assignment.data.content?.dateinfo.ShipperArrivalDate"
              #shipperArrivalDate
            />

            <label class="datelabel" for="availableDate">
              Preferred date for delivery
            </label>
            <input
              class="dateinput"
              placeholder="Please choose a date"
              [min]="minDate"
              type="date"
              id="availableDate"
              name="availableDate"
              [(ngModel)]="assignment.data.content?.dateinfo.AvailableDate"
              #availableDate
            />

    <!-- ALFA others -->
    <h4>Select assignment type</h4>

    <label class="radio">
      <input
        type="radio"
        value="ALFALAVAL1"
        [(ngModel)]="assignment.data.content?.extrainfo.PolicyTypeText"
        name="alfalavalAssignment"
        #alfalavalAssignment="ngModel"
        required
        (click)="FLH(assignment.data.content?.volume.VolumeLimit)"
      />
      <span>Long term assignment</span>
    </label>

    <label class="radio">
      <input
        type="radio"
        name="alfalavalAssignment"
        [(ngModel)]="assignment.data.content?.extrainfo.PolicyTypeText"
        value="ALFALAVAL2"
        #alfalavalAssignment="ngModel"
        required
        (click)="FLH(assignment.data.content?.volume.VolumeLimit)"
      />
      <span>Foreign local hire</span>
    </label>

    <h4>Family Size</h4>

    <label class="radio">
      <input
        type="radio"
        [(ngModel)]="assignment.data.content?.extrainfo.FamilySize"
        name="alfalavalFamily"
        value="Single"
      />
      <span>Single / Unaccompanied</span>
    </label>

    <label class="radio">
      <input
        type="radio"
        [(ngModel)]="assignment.data.content?.extrainfo.FamilySize"
        name="alfalavalFamily"
        value="Couple"
      />
      <span>Couple</span>
    </label>

    <label class="radio">
      <input
        type="radio"
        [(ngModel)]="assignment.data.content?.extrainfo.FamilySize"
        name="alfalavalFamily"
        value="Family with children"
      />
      <span>Family with children</span>
    </label>

    <h4>Allowance</h4>
    <p>The allowance below is as per Alfa Laval Handbook and guidelines.</p>
    <ol>
      <h4>Unfurnished accommodation</h4>
      <span class="policymsg">(Long Term Assignment / Foreign Local Hire)</span>

      <div
        *ngIf="
          assignment.data.content?.extrainfo.FamilySize == 'Single' ||
          assignment.data.content?.extrainfo.FamilySize == undefined
        "
      >
        <!-- if SINGLE -->
        <label class="radio">
          <input
            type="radio"
            [(ngModel)]="assignment.data.content?.volume.VolumeLimit"
            name="alfalavalAllowance"
            value="Single or unaccompanied person 20 m3"
          />
          <span>Single or unaccompanied person 20 m3</span>
        </label>
      </div>

      <div
        *ngIf="
          assignment.data.content?.extrainfo.FamilySize == 'Couple' ||
          assignment.data.content?.extrainfo.FamilySize == undefined
        "
      >
        <!-- IF COUPLE-->
        <label class="radio">
          <input
            type="radio"
            [(ngModel)]="assignment.data.content?.volume.VolumeLimit"
            name="alfalavalAllowance"
            value="Couple 30 m3"
          />
          <span>Couple 30 m3</span>
        </label>
      </div>

      <div
        *ngIf="
          assignment.data.content?.extrainfo.FamilySize ==
            'Family with children' ||
          assignment.data.content?.extrainfo.FamilySize == undefined
        "
      >
        <!--IF FAMILY -->
        <label class="radio">
          <input
            type="radio"
            [(ngModel)]="assignment.data.content?.volume.VolumeLimit"
            name="alfalavalAllowance"
            value="Family with children 60 m3"
          />
          <span>Family with children 60 m3</span>
        </label>
      </div>

      <h4>Furnished accommodation</h4>
      <span class="policymsg">(Long Term Assignment)</span>

      <div
        *ngIf="
          assignment.data.content?.extrainfo.FamilySize == 'Single' ||
          assignment.data.content?.extrainfo.FamilySize == undefined
        "
      >
        <!--IF SINGLE -->
        <label class="radio">
          <input
            type="radio"
            [(ngModel)]="assignment.data.content?.volume.VolumeLimit"
            name="alfalavalAllowance"
            value="Single or unaccompanied 4 m3"
            [disabled]="
              assignment.data.content?.extrainfo.PolicyTypeText ==
              'foreign local hire'
            "
          />
          <span>Single or unaccompanied 4 m3</span>
        </label>
      </div>

      <div
        *ngIf="
          assignment.data.content?.extrainfo.FamilySize ==
            'Family with children' ||
          assignment.data.content?.extrainfo.FamilySize == 'Couple' ||
          assignment.data.content?.extrainfo.FamilySize == undefined
        "
      >
        <!--IF FAMILY OR COUPLE -->
        <label class="radio">
          <input
            type="radio"
            [(ngModel)]="assignment.data.content?.volume.VolumeLimit"
            name="alfalavalAllowance"
            value="Accompanied 8 m3"
            [disabled]="
              assignment.data.content?.extrainfo.PolicyTypeText ==
              'foreign local hire'
            "
          />
          <span>Accompanied 8 m3</span>
        </label>
      </div>
    </ol>

    Submitted on:
    {{
      assignment.data.content?.extrainfo.submitDate | date : "yyyy-MM-dd HH:mm"
    }}
    <br /><br />

    <label for="textbox"><h4>Additional information</h4></label>
    <textarea
      class="itextfield"
      name="extrainfo"
      id="textbox"
      cols="30"
      rows="10"
      [(ngModel)]="assignment.data.content?.extrainfo.OtherInfo"
      placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
    >
    </textarea>

    <!-- Button row 1 -->
    <div *ngIf="assignment.status == '0'" class="button-container" id="btn-row-1">
      <button class="button btn-reject" (click)="updateReject(assignment)">
        Reject
      </button>
      <button class="button btn-approve" (click)="updateApprove(assignment)">
        Approve
      </button>
    </div>

    <!-- Button row 2 -->
    <div class="button-container" id="btn-row-2">
      <button class="button btn-approve" (click)="generatePDF()">
        Save as PDF
      </button>

      <button *ngIf="assignment.status == '0'" class="button btn-approve" (click)="updateSave(assignment)">
        Save
      </button>
    </div>

    <!-- Button row 3 -->
    <!-- <div class="button-container" id="btn-row-3">
      <button class="button" (click)="xDebug(ix)">Debug</button>
    </div> -->
  </div>
</div>
