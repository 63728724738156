import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })

  // Used for transfering data in the form that aren't being sent.
export class FormConfig {
    formDisabled: boolean;
    formDisableAll: boolean;
    formCompanyName: string;
    confirm:boolean;
    getTotalPages: number;
    getCurrentPage: number;
    office: string;
    ericssonConfirm:boolean;
}
