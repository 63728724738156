export const countries = [
  { countrycode: 'AF', countryname: 'Afghanistan' },
  { countrycode: 'AL', countryname: 'Albania' },
  { countrycode: 'DZ', countryname: 'Algeria' },
  { countrycode: 'AS', countryname: 'American Samoa' },
  { countrycode: 'AD', countryname: 'Andorra' },
  { countrycode: 'AO', countryname: 'Angola' },
  { countrycode: 'AI', countryname: 'Anguilla' },
  { countrycode: 'AQ', countryname: 'Antarctica' },
  { countrycode: 'AG', countryname: 'Antigua and Barbuda' },
  { countrycode: 'AR', countryname: 'Argentina' },
  { countrycode: 'AM', countryname: 'Armenia' },
  { countrycode: 'AW', countryname: 'Aruba' },
  { countrycode: 'AU', countryname: 'Australia' },
  { countrycode: 'AT', countryname: 'Austria' },
  { countrycode: 'AZ', countryname: 'Azerbaijan' },
  { countrycode: 'BS', countryname: 'Bahamas' },
  { countrycode: 'BH', countryname: 'Bahrain' },
  { countrycode: 'BD', countryname: 'Bangladesh' },
  { countrycode: 'BB', countryname: 'Barbados' },
  { countrycode: 'BY', countryname: 'Belarus' },
  { countrycode: 'BE', countryname: 'Belgium' },
  { countrycode: 'BZ', countryname: 'Belize' },
  { countrycode: 'BJ', countryname: 'Benin' },
  { countrycode: 'BM', countryname: 'Bermuda' },
  { countrycode: 'BT', countryname: 'Bhutan' },
  { countrycode: 'BO', countryname: 'Bolivia' },
  { countrycode: 'BA', countryname: 'Bosnia and Herzegovina' },
  { countrycode: 'BW', countryname: 'Botswana' },
  { countrycode: 'BV', countryname: 'Bouvet Island' },
  { countrycode: 'BR', countryname: 'Brazil' },
  { countrycode: 'IO', countryname: 'British Indian Ocean Territory' },
  { countrycode: 'VG', countryname: 'British Virgin Islands' },
  { countrycode: 'BN', countryname: 'Brunei' },
  { countrycode: 'BG', countryname: 'Bulgaria' },
  { countrycode: 'BF', countryname: 'Burkina Faso' },
  { countrycode: 'BI', countryname: 'Burundi' },
  { countrycode: 'KH', countryname: 'Cambodia' },
  { countrycode: 'CM', countryname: 'Cameroon' },
  { countrycode: 'CA', countryname: 'Canada' },
  { countrycode: 'CV', countryname: 'Cape Verde' },
  { countrycode: 'BQ', countryname: 'Caribbean Netherlands' },
  { countrycode: 'KY', countryname: 'Cayman Islands' },
  { countrycode: 'CF', countryname: 'Central African Republic' },
  { countrycode: 'TD', countryname: 'Chad' },
  { countrycode: 'CL', countryname: 'Chile' },
  { countrycode: 'CN', countryname: 'China' },
  { countrycode: 'CX', countryname: 'Christmas Island' },
  { countrycode: 'CC', countryname: 'Cocos (Keeling) Islands' },
  { countrycode: 'CO', countryname: 'Colombia' },
  { countrycode: 'KM', countryname: 'Comoros' },
  { countrycode: 'CK', countryname: 'Cook Islands' },
  { countrycode: 'CR', countryname: 'Costa Rica' },
  { countrycode: 'HR', countryname: 'Croatia' },
  { countrycode: 'CU', countryname: 'Cuba' },
  { countrycode: 'CW', countryname: 'Curaçao' },
  { countrycode: 'CY', countryname: 'Cyprus' },
  { countrycode: 'CZ', countryname: 'Czechia' },
  { countrycode: 'CD', countryname: 'DR Congo' },
  { countrycode: 'DK', countryname: 'Denmark' },
  { countrycode: 'DJ', countryname: 'Djibouti' },
  { countrycode: 'DM', countryname: 'Dominica' },
  { countrycode: 'DO', countryname: 'Dominican Republic' },
  { countrycode: 'EC', countryname: 'Ecuador' },
  { countrycode: 'EG', countryname: 'Egypt' },
  { countrycode: 'SV', countryname: 'El Salvador' },
  { countrycode: 'GQ', countryname: 'Equatorial Guinea' },
  { countrycode: 'ER', countryname: 'Eritrea' },
  { countrycode: 'EE', countryname: 'Estonia' },
  { countrycode: 'SZ', countryname: 'Eswatini' },
  { countrycode: 'ET', countryname: 'Ethiopia' },
  { countrycode: 'FK', countryname: 'Falkland Islands' },
  { countrycode: 'FO', countryname: 'Faroe Islands' },
  { countrycode: 'FJ', countryname: 'Fiji' },
  { countrycode: 'FI', countryname: 'Finland' },
  { countrycode: 'FR', countryname: 'France' },
  { countrycode: 'GF', countryname: 'French Guiana' },
  { countrycode: 'PF', countryname: 'French Polynesia' },
  { countrycode: 'TF', countryname: 'French Southern and Antarctic Lands' },
  { countrycode: 'GA', countryname: 'Gabon' },
  { countrycode: 'GM', countryname: 'Gambia' },
  { countrycode: 'GE', countryname: 'Georgia' },
  { countrycode: 'DE', countryname: 'Germany' },
  { countrycode: 'GH', countryname: 'Ghana' },
  { countrycode: 'GI', countryname: 'Gibraltar' },
  { countrycode: 'GR', countryname: 'Greece' },
  { countrycode: 'GL', countryname: 'Greenland' },
  { countrycode: 'GD', countryname: 'Grenada' },
  { countrycode: 'GP', countryname: 'Guadeloupe' },
  { countrycode: 'GU', countryname: 'Guam' },
  { countrycode: 'GT', countryname: 'Guatemala' },
  { countrycode: 'GG', countryname: 'Guernsey' },
  { countrycode: 'GN', countryname: 'Guinea' },
  { countrycode: 'GW', countryname: 'Guinea-Bissau' },
  { countrycode: 'GY', countryname: 'Guyana' },
  { countrycode: 'HT', countryname: 'Haiti' },
  { countrycode: 'HM', countryname: 'Heard Island and McDonald Islands' },
  { countrycode: 'HN', countryname: 'Honduras' },
  { countrycode: 'HK', countryname: 'Hong Kong' },
  { countrycode: 'HU', countryname: 'Hungary' },
  { countrycode: 'IS', countryname: 'Iceland' },
  { countrycode: 'IN', countryname: 'India' },
  { countrycode: 'ID', countryname: 'Indonesia' },
  { countrycode: 'IR', countryname: 'Iran' },
  { countrycode: 'IQ', countryname: 'Iraq' },
  { countrycode: 'IE', countryname: 'Ireland' },
  { countrycode: 'IM', countryname: 'Isle of Man' },
  { countrycode: 'IL', countryname: 'Israel' },
  { countrycode: 'IT', countryname: 'Italy' },
  { countrycode: 'CI', countryname: 'Ivory Coast' },
  { countrycode: 'JM', countryname: 'Jamaica' },
  { countrycode: 'JP', countryname: 'Japan' },
  { countrycode: 'JE', countryname: 'Jersey' },
  { countrycode: 'JO', countryname: 'Jordan' },
  { countrycode: 'KZ', countryname: 'Kazakhstan' },
  { countrycode: 'KE', countryname: 'Kenya' },
  { countrycode: 'KI', countryname: 'Kiribati' },
  { countrycode: 'XK', countryname: 'Kosovo' },
  { countrycode: 'KW', countryname: 'Kuwait' },
  { countrycode: 'KG', countryname: 'Kyrgyzstan' },
  { countrycode: 'LA', countryname: 'Laos' },
  { countrycode: 'LV', countryname: 'Latvia' },
  { countrycode: 'LB', countryname: 'Lebanon' },
  { countrycode: 'LS', countryname: 'Lesotho' },
  { countrycode: 'LR', countryname: 'Liberia' },
  { countrycode: 'LY', countryname: 'Libya' },
  { countrycode: 'LI', countryname: 'Liechtenstein' },
  { countrycode: 'LT', countryname: 'Lithuania' },
  { countrycode: 'LU', countryname: 'Luxembourg' },
  { countrycode: 'MO', countryname: 'Macau' },
  { countrycode: 'MG', countryname: 'Madagascar' },
  { countrycode: 'MW', countryname: 'Malawi' },
  { countrycode: 'MY', countryname: 'Malaysia' },
  { countrycode: 'MV', countryname: 'Maldives' },
  { countrycode: 'ML', countryname: 'Mali' },
  { countrycode: 'MT', countryname: 'Malta' },
  { countrycode: 'MH', countryname: 'Marshall Islands' },
  { countrycode: 'MQ', countryname: 'Martinique' },
  { countrycode: 'MR', countryname: 'Mauritania' },
  { countrycode: 'MU', countryname: 'Mauritius' },
  { countrycode: 'YT', countryname: 'Mayotte' },
  { countrycode: 'MX', countryname: 'Mexico' },
  { countrycode: 'FM', countryname: 'Micronesia' },
  { countrycode: 'MD', countryname: 'Moldova' },
  { countrycode: 'MC', countryname: 'Monaco' },
  { countrycode: 'MN', countryname: 'Mongolia' },
  { countrycode: 'ME', countryname: 'Montenegro' },
  { countrycode: 'MS', countryname: 'Montserrat' },
  { countrycode: 'MA', countryname: 'Morocco' },
  { countrycode: 'MZ', countryname: 'Mozambique' },
  { countrycode: 'MM', countryname: 'Myanmar' },
  { countrycode: 'NA', countryname: 'Namibia' },
  { countrycode: 'NR', countryname: 'Nauru' },
  { countrycode: 'NP', countryname: 'Nepal' },
  { countrycode: 'NL', countryname: 'Netherlands' },
  { countrycode: 'NC', countryname: 'New Caledonia' },
  { countrycode: 'NZ', countryname: 'New Zealand' },
  { countrycode: 'NI', countryname: 'Nicaragua' },
  { countrycode: 'NE', countryname: 'Niger' },
  { countrycode: 'NG', countryname: 'Nigeria' },
  { countrycode: 'NU', countryname: 'Niue' },
  { countrycode: 'NF', countryname: 'Norfolk Island' },
  { countrycode: 'KP', countryname: 'North Korea' },
  { countrycode: 'MK', countryname: 'North Macedonia' },
  { countrycode: 'MP', countryname: 'Northern Mariana Islands' },
  { countrycode: 'NO', countryname: 'Norway' },
  { countrycode: 'OM', countryname: 'Oman' },
  { countrycode: 'PK', countryname: 'Pakistan' },
  { countrycode: 'PW', countryname: 'Palau' },
  { countrycode: 'PS', countryname: 'Palestine' },
  { countrycode: 'PA', countryname: 'Panama' },
  { countrycode: 'PG', countryname: 'Papua New Guinea' },
  { countrycode: 'PY', countryname: 'Paraguay' },
  { countrycode: 'PE', countryname: 'Peru' },
  { countrycode: 'PH', countryname: 'Philippines' },
  { countrycode: 'PN', countryname: 'Pitcairn Islands' },
  { countrycode: 'PL', countryname: 'Poland' },
  { countrycode: 'PT', countryname: 'Portugal' },
  { countrycode: 'PR', countryname: 'Puerto Rico' },
  { countrycode: 'QA', countryname: 'Qatar' },
  { countrycode: 'CG', countryname: 'Republic of the Congo' },
  { countrycode: 'RO', countryname: 'Romania' },
  { countrycode: 'RU', countryname: 'Russia' },
  { countrycode: 'RW', countryname: 'Rwanda' },
  { countrycode: 'RE', countryname: 'Réunion' },
  { countrycode: 'BL', countryname: 'Saint Barthélemy' },
  { countrycode: 'SH', countryname: 'Saint Helena, Ascension and Tristan da Cunha' },
  { countrycode: 'KN', countryname: 'Saint Kitts and Nevis' },
  { countrycode: 'LC', countryname: 'Saint Lucia' },
  { countrycode: 'MF', countryname: 'Saint Martin' },
  { countrycode: 'PM', countryname: 'Saint Pierre and Miquelon' },
  { countrycode: 'VC', countryname: 'Saint Vincent and the Grenadines' },
  { countrycode: 'WS', countryname: 'Samoa' },
  { countrycode: 'SM', countryname: 'San Marino' },
  { countrycode: 'SA', countryname: 'Saudi Arabia' },
  { countrycode: 'SN', countryname: 'Senegal' },
  { countrycode: 'RS', countryname: 'Serbia' },
  { countrycode: 'SC', countryname: 'Seychelles' },
  { countrycode: 'SL', countryname: 'Sierra Leone' },
  { countrycode: 'SG', countryname: 'Singapore' },
  { countrycode: 'SX', countryname: 'Sint Maarten' },
  { countrycode: 'SK', countryname: 'Slovakia' },
  { countrycode: 'SI', countryname: 'Slovenia' },
  { countrycode: 'SB', countryname: 'Solomon Islands' },
  { countrycode: 'SO', countryname: 'Somalia' },
  { countrycode: 'ZA', countryname: 'South Africa' },
  { countrycode: 'GS', countryname: 'South Georgia' },
  { countrycode: 'KR', countryname: 'South Korea' },
  { countrycode: 'SS', countryname: 'South Sudan' },
  { countrycode: 'ES', countryname: 'Spain' },
  { countrycode: 'LK', countryname: 'Sri Lanka' },
  { countrycode: 'SD', countryname: 'Sudan' },
  { countrycode: 'SR', countryname: 'Suriname' },
  { countrycode: 'SJ', countryname: 'Svalbard and Jan Mayen' },
  { countrycode: 'SE', countryname: 'Sweden' },
  { countrycode: 'CH', countryname: 'Switzerland' },
  { countrycode: 'SY', countryname: 'Syria' },
  { countrycode: 'ST', countryname: 'São Tomé and Príncipe' },
  { countrycode: 'TW', countryname: 'Taiwan' },
  { countrycode: 'TJ', countryname: 'Tajikistan' },
  { countrycode: 'TZ', countryname: 'Tanzania' },
  { countrycode: 'TH', countryname: 'Thailand' },
  { countrycode: 'TL', countryname: 'Timor-Leste' },
  { countrycode: 'TG', countryname: 'Togo' },
  { countrycode: 'TK', countryname: 'Tokelau' },
  { countrycode: 'TO', countryname: 'Tonga' },
  { countrycode: 'TT', countryname: 'Trinidad and Tobago' },
  { countrycode: 'TN', countryname: 'Tunisia' },
  { countrycode: 'TR', countryname: 'Turkey' },
  { countrycode: 'TM', countryname: 'Turkmenistan' },
  { countrycode: 'TC', countryname: 'Turks and Caicos Islands' },
  { countrycode: 'TV', countryname: 'Tuvalu' },
  { countrycode: 'UG', countryname: 'Uganda' },
  { countrycode: 'UA', countryname: 'Ukraine' },
  { countrycode: 'AE', countryname: 'United Arab Emirates' },
  { countrycode: 'GB', countryname: 'United Kingdom' },
  { countrycode: 'US', countryname: 'United States' },
  { countrycode: 'UM', countryname: 'United States Minor Outlying Islands' },
  { countrycode: 'VI', countryname: 'United States Virgin Islands' },
  { countrycode: 'UY', countryname: 'Uruguay' },
  { countrycode: 'UZ', countryname: 'Uzbekistan' },
  { countrycode: 'VU', countryname: 'Vanuatu' },
  { countrycode: 'VA', countryname: 'Vatican City' },
  { countrycode: 'VE', countryname: 'Venezuela' },
  { countrycode: 'VN', countryname: 'Vietnam' },
  { countrycode: 'WF', countryname: 'Wallis and Futuna' },
  { countrycode: 'EH', countryname: 'Western Sahara' },
  { countrycode: 'YE', countryname: 'Yemen' },
  { countrycode: 'ZM', countryname: 'Zambia' },
  { countrycode: 'ZW', countryname: 'Zimbabwe' },
  { countrycode: 'AX', countryname: 'Åland Islands' },
];
