import { createAction, props } from '@ngrx/store';

import { Country } from '../../models/country';

export const fetchCountries = createAction(
  '[Country API] Fetch',
  // props<{ payload: Country }>()
);

export const fetchCountriesSuccess = createAction(
  '[Country API] Fetch Success',
  props<{ payload: Country[] }>()
);

export const fetchCountriesError = createAction(
  '[Country API] Fetch Error',
  props<{ payload: Error }>()
);
