import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { environment } from '../../environments/environment';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<User> {
    const url = `${environment['backendUrl']}/api/portal/login`;
    const body = {
      userName: username,
      password
    };
    return this.http.post<User>(url, body);
  }

  checkAuthorization(): Observable<User> {
    const url = `${environment['backendUrl']}/api/portal/validate`;
    const body = {};
    return this.http.post<User>(url, body);
  }

  resetPassword(user: string): Observable<any> {
    const url = `${environment['backendUrl']}/api/portal/resetPw`;
    const body = {
      email: user,
      userName: user
    };
    return this.http.post<User>(url, body);
  }

}
