import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { NorthvoltComponent } from "./components/customers/northvolt/northvolt.component";
import { BaseLayoutComponent } from "./components/base-layout/base-layout.component";
import { SiteLayoutComponent } from "./components/site-layout/site-layout.component";
import { LoginComponent } from "./components/login/login.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { HomeComponent } from "./components/home/home.component";
import { PageNotFoundComponent } from "./components/pagenotfound/pagenotfound.component";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { AssignmentListComponent } from "./components/assignment-list/assignment-list.component";
import { Assignment2ListComponent } from "./components/assignment2-list/assignment2-list.component";
import { ResetpasswordComponent } from "./components/resetpassword/resetpassword.component";
import { NyListaComponent } from "./components/ny-lista/ny-lista.component";
import { CompanyDetailsComponent } from "./components/bookingform/company-details/company-details.component";
import { YourDetailsComponent } from "./components/bookingform/your-details/your-details.component";
import { MoveDetailsFromComponent } from "./components/bookingform/move-details-from/move-details-from.component";
import { MoveDetailsToComponent } from "./components/bookingform/move-details-to/move-details-to.component";
import { OtherDetailsComponent } from "./components/bookingform/other-details/other-details.component";
import { EndFormComponent } from "./components/bookingform/end-form/end-form.component";
import { LegalNoticeComponent } from "./components/bookingform/legal-notice/legal-notice.component";
import { TetralavalComponent } from "./components/customers/tetralaval/tetralaval.component";
import { GeneralBookingComponent } from "./components/customers/general-booking/general-booking.component";
import { HmComponent } from "./components/customers/hm/hm.component";
import { AiresComponent } from "./components/customers/aires/aires.component";
import { HandelsbankenComponent } from "./components/customers/handelsbanken/handelsbanken.component";
import { SAABComponent } from "./components/customers/saab/saab.component";
import { SandvikComponent } from "./components/customers/sandvik/sandvik.component";
import { CevtComponent } from "./components/customers/cevt/cevt.component";
import { SidaComponent } from "./components/customers/sida/sida.component";
import { OriflameComponent } from "./components/customers/oriflame/oriflame.component";
import { AbbComponent } from "./components/customers/abb/abb.component";
import { EfComponent } from "./components/customers/ef/ef.component";
import { SebComponent } from "./components/customers/seb/seb.component";
import { AlfalavalComponent } from "./components/customers/alfalaval/alfalaval.component";
import { SwedbankComponent } from "./components/customers/swedbank/swedbank.component";
import { UdComponent } from "./components/customers/ud/ud.component";
import { UmdkComponent } from "./components/customers/umdk/umdk.component";
import { UdnoComponent } from "./components/customers/udno/udno.component";
import { AbsolutComponent } from "./components/customers/absolut/absolut.component";
import { MtgComponent } from "./components/customers/mtg/mtg.component";
import { ScaniaComponent } from "./components/customers/scania/scania.component";
import { UniversityOfHelsinkiComponent } from "./components/customers/university-of-helsinki/university-of-helsinki.component";
import { OhrlingsPricewaterhousecoopersComponent } from "./components/customers/ohrlings-pricewaterhousecoopers/ohrlings-pricewaterhousecoopers.component";
import { EricssonComponent } from "./components/customers/ericsson/ericsson.component";
import { MigrationsverketComponent } from "./components/customers/migrationsverket/migrationsverket.component";
import { FolkeBernadotteAcademyComponent } from "./components/customers/folke-bernadotte-academy/folke-bernadotte-academy.component";
import { LundComponent } from "./components/customers/lund/lund.component";
import { TransfereeDetailsComponent } from "./components/customers/lund/transferee-details/transferee-details.component";
import { NeedConfirmListComponent } from "./components/need-confirm/need-confirm-list.component";
import { PolisenComponent } from "./components/customers/polisen/polisen.component";
import { PrivateBookingComponent } from "./components/finland/private-booking/private-booking.component";
import { SwedenComponent } from "./components/private/sweden/sweden.component";
import { NorwayComponent } from "./components/private/norway/norway.component";
import { DenmarkComponent } from "./components/private/denmark/denmark.component";
import { FinlandComponent } from "./components/private/finland/finland.component";
import { OthersComponent } from "./components/private/others/others.component";
import { AboutComponent } from "./components/about/about.component";

export const alfaFunctions = [
  {
    function: "assignment",
    link: "assignment-user",
    access: false,
  },
  {
    function: "Ass_Asigne",
    link: "assignment-admin",
    access: false,
  },
];

const childrenRoutes: Routes = [
  {
    path: "legalnotice",
    component: LegalNoticeComponent,
    title: "Alfa Mobility - Booking - Legal ",
  },
  {
    path: "companydetails",
    component: CompanyDetailsComponent,
    title: "Alfa Mobility - Booking - Company details ",
  },
  {
    path: "yourdetails",
    component: YourDetailsComponent,
    title: "Alfa Mobility - Booking - Your details ",
  },
  {
    path: "movedetailsfrom",
    component: MoveDetailsFromComponent,
    title: "Alfa Mobility - Booking - Move details: from ",
  },
  {
    path: "movedetailsto",
    component: MoveDetailsToComponent,
    title: "Alfa Mobility - Booking - Move details: to",
  },
  {
    path: "otherdetails",
    component: OtherDetailsComponent,
    title: "Alfa Mobility - Booking - Other details ",
  },

  //For University of Helsinki
  // {
  //   path: 'movedetails',
  //   component: MoveDetailsFromComponent,
  //   title: 'Move details',
  // },
  //For lunds
  {
    path: "transferee",
    component: TransfereeDetailsComponent,
    title: "Transferee details",
  },
];

const routes: Routes = [
  {
    path: "",
    component: BaseLayoutComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
      },
    ],
  },
  {
    path: "",
    component: SiteLayoutComponent,
    children: [
      {
        path: "home",
        component: HomeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "ny-lista",
        component: NyListaComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "assignment",
        component: AssignmentListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "ass_asigne",
        component: Assignment2ListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "exbook",
        component: NeedConfirmListComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "",
    children: [
      { path: "request", redirectTo: "request/legalnotice" },
      {
        path: "request",
        component: GeneralBookingComponent,
        children: childrenRoutes,
      },
      //Routes for bookings
      { path: "northvolt", redirectTo: "northvolt/legalnotice" },
      {
        path: "northvolt",
        component: NorthvoltComponent,
        children: childrenRoutes,
      },

      { path: "hm", redirectTo: "hm/legalnotice" },
      {
        path: "hm",
        component: HmComponent,
        children: childrenRoutes,
      },

      { path: "tetralaval", redirectTo: "tetralaval/legalnotice" },
      {
        path: "tetralaval",
        component: TetralavalComponent,
        children: childrenRoutes,
      },

      { path: "pwc", redirectTo: "pwc/legalnotice" },
      {
        path: "ohrlings-pricewaterhousecoopers",
        redirectTo: "pwc/legalnotice",
      },
      {
        path: "pwc",
        component: OhrlingsPricewaterhousecoopersComponent,
        children: childrenRoutes,
      },

      { path: "scania", redirectTo: "scania/legalnotice" },
      {
        path: "scania",
        component: ScaniaComponent,
        children: childrenRoutes,
      },

      { path: "absolut", redirectTo: "absolut/legalnotice" },
      {
        path: "absolut",
        component: AbsolutComponent,
        children: childrenRoutes,
      },

      { path: "mtg", redirectTo: "mtg/legalnotice" },
      {
        path: "mtg",
        component: MtgComponent,
        children: childrenRoutes,
      },

      { path: "aires", redirectTo: "aires/legalnotice" },
      {
        path: "aires",
        component: AiresComponent,
        children: childrenRoutes,
      },

      { path: "alfalaval", redirectTo: "alfalaval/legalnotice" },
      {
        path: "alfalaval",
        component: AlfalavalComponent,
        children: childrenRoutes,
      },

      { path: "lund", redirectTo: "lund/legalnotice" },
      {
        path: "lund",
        component: LundComponent,
        children: childrenRoutes,
      },

      { path: "ud", redirectTo: "ud/legalnotice" },
      {
        path: "ud",
        component: UdComponent,
        children: childrenRoutes,
      },

      { path: "migrationsverket", redirectTo: "migrationsverket/legalnotice" },
      {
        path: "migrationsverket",
        component: MigrationsverketComponent,
        children: childrenRoutes,
      },

      { path: "umdk", redirectTo: "umdk/legalnotice" },
      {
        path: "umdk",
        component: UmdkComponent,
        children: childrenRoutes,
      },

      { path: "udno", redirectTo: "udno/legalnotice" },
      {
        path: "udno",
        component: UdnoComponent,
        children: childrenRoutes,
      },

      { path: "swedbank", redirectTo: "swedbank/legalnotice" },
      {
        path: "swedbank",
        component: SwedbankComponent,
        children: childrenRoutes,
      },

      { path: "abb", redirectTo: "abb/legalnotice" },
      {
        path: "abb",
        component: AbbComponent,
        children: childrenRoutes,
      },

      { path: "seb", redirectTo: "seb/legalnotice" },
      {
        path: "seb",
        component: SebComponent,
        children: childrenRoutes,
      },

      { path: "ef", redirectTo: "ef/legalnotice" },
      {
        path: "ef",
        component: EfComponent,
        children: childrenRoutes,
      },

      { path: "oriflame", redirectTo: "oriflame/legalnotice" },
      {
        path: "oriflame",
        component: OriflameComponent,
        children: childrenRoutes,
      },

      { path: "sida", redirectTo: "sida/legalnotice" },
      {
        path: "sida",
        component: SidaComponent,
        children: childrenRoutes,
      },

      { path: "cevt", redirectTo: "cevt/legalnotice" },
      {
        path: "cevt",
        component: CevtComponent,
        children: childrenRoutes,
      },

      { path: "handelsbanken", redirectTo: "handelsbanken/legalnotice" },
      {
        path: "handelsbanken",
        component: HandelsbankenComponent,
        children: childrenRoutes,
      },

      { path: "saab", redirectTo: "saab/legalnotice" },
      {
        path: "saab",
        component: SAABComponent,
        children: childrenRoutes,
      },

      { path: "uoh", redirectTo: "uoh/legalnotice" },
      {
        path: "uoh",
        component: UniversityOfHelsinkiComponent,
        children: childrenRoutes,
      },

      { path: "sandvik", redirectTo: "sandvik/legalnotice" },
      {
        path: "sandvik",
        component: SandvikComponent,
        children: childrenRoutes,
      },
      {
        path: "folke_bernadotte_academy",
        redirectTo: "folke_bernadotte_academy/legalnotice",
      },
      { path: "fba", redirectTo: "folke_bernadotte_academy/legalnotice" },
      {
        path: "folke_bernadotte_academy",
        component: FolkeBernadotteAcademyComponent,
        children: childrenRoutes,
      },
      { path: "ericsson", redirectTo: "ericsson/legalnotice" },
      {
        path: "ericsson",
        component: EricssonComponent,
        children: childrenRoutes,
      },
      { path: "polis", redirectTo: "polis/legalnotice" },
      { path: "polisen", redirectTo: "polis/legalnotice" },
      {
        path: "polis",
        component: PolisenComponent,
        children: childrenRoutes,
      },

      {
        path: "university-of-helsinki",
        redirectTo: "university-of-helsinki/legalnotice",
      },
      {
        path: "university-of-helsinki",
        component: UniversityOfHelsinkiComponent,
        children: childrenRoutes,
      },

      /*  PRIVATE BOOKINGS */
      {
        path: "others",
        component: OthersComponent,
        title: "Alfa Mobility Booking - International ",
      },
      {
        path: "norway",
        component: NorwayComponent,
        title: "Alfa Mobility Booking - Norway ",
      },
      {
        path: "sweden",
        component: SwedenComponent,
        title: "Alfa Mobility Booking - Sweden ",
      },
      {
        path: "finland",
        component: FinlandComponent,
        title: "Alfa Mobility Booking - Finland ",
      },
      {
        path: "denmark",
        component: DenmarkComponent,
        title: "Alfa Mobility Booking - Denmark ",
      },
      //Finland Private booking
      {
        path: "fp",
        component: PrivateBookingComponent,
      },
    ],
  },
  {
    path: "done",
    component: EndFormComponent,
    title: "Thank you",
  },
  {
    path: "resetpw",
    component: ResetpasswordComponent,
  },
  {
    path: "logout",
    component: LogoutComponent,
  },
  {
    path: "unauthorized",
    component: UnauthorizedComponent,
  },
  {
    path: "about",
    component: AboutComponent,
  },
  // {
  //   path: 'notfound',
  //   component: PageNotFoundComponent,
  // },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false, useHash: false }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
