export class Content {
  //Your Details name
  assigneeFirstName: string; //corrected spelling of assignee
  assigneeLastName: string; //"Your details" first and last name

  payingCompanyCustomerNo: string;
  reloassistjobref: string;
  salesPersonId: number;
  typeOfAssignmentPart: string;

  alfaCompanyId: number;
  assignmentnovalue: number;
  bookingCompanyCustomerNo: string;
  customerGroupId: string;
  SokID: string; // Company ID, preassigned
  acceptLegal: string; // Legal notice confirmation
  confirmSubmitDate: string;
}

export class Orgdata {
  //Move Details From/Current
  orgAddress1: string; // Address
  orgTown: string; // City
  orgPostcode: string; // Zipcode
  orgCountry: string; // Country
  orgResidenceType: string; // Residence Type & Code
  orgResidenceText: string; // Residence Text
  orgWalkingDistance: string; // Walking distance (from door to truck) & Code
  orgWalkingDistanceText: string; // Walking distance Text
  orgElevator: string; // Elevator Code
  orgElevatorText: string; // Elevator Text
  orgDoorcode: string; // Door code
  orgFloor: string; // Floor

  //Move details: To (Move dates)
  orgSurveyDate: string;

  //Your Details
  orgMailPrimary: string; // Your email
  orgPhoneOffice: string; // Your office phone
  orgMobilephone: string; // Your cellphone
  ContactWay: string; // Preferred way of contact

  orgPhoneHome: string;
  orgAccess: string;
  orgAddress2: string;
  orgDeliveryDate: string;
  orgMailAlternative: string;
  orgPickupDate: string;
  orgSurveyTime: string;
}

export class Surveyinfo {
  //Move from details
  Area: string;
  Attic: string;
  Basement: string;
  NumberOfRooms: string;
}

export class Extrainfo {
  [x: string]: any;
  AccompanyingFamily: string;
  ApprovingManagerEmail: string;
  ApprovingManagerEmployeeNumber: string;
  ApprovingManagerName: string;
  ApprovingManagerPhone: string;
  AssignmentStartDate: string; // Move details to, Tetra
  AssignmentType: string;
  Avtal: string;
  BusinessUnitCode: string;
  BillingAddress: string;
  Citizenship: string;
  CitizenshipFamily: string;
  CleaningOffer: string; // Other details (Swedbank, SEB) clean current residence
  CompanyContact: string;
  CompanyAddress: string;
  CompanyName: string;
  CompanyCity: string;
  CompanyCountry: string;
  CompanyDepartment: string;
  CompanyEmail: string;
  CompanyZipCode: string;
  ContractType: string; // Other details
  CurrentForeignAuthority: string;
  CurrentWorkplace: string;
  CostCenter: string;
  DHLShipment: string;
  DiscountCode: string;
  EmployeeNumber: string;
  EricssonEmployeeSignum: string;
  EricssonCompany: string;
  EmploymentStartDate: string; // Transferee (lund)
  EmploymentEndDate: string; // Transferee (lund)
  FamilySize: string; // Other details
  EricssonLocalHiringContact: string;
  HiringManager: string;
  EricssonHiringManager: string;
  EricssonID: string;
  Faculty: string;
  Furnished: string; //Other details
  Gender: string; //Your Details (for tetra) gender
  GlobalIntensivePlan: string;
  ImmigrationServices: string; //private bookings
  InsuranceInfo: string;
  InterestedInRUT: string;
  KeepHomeCountryResidence: string; //Other Details HM
  LTAEnd: string;
  LastName: string;
  MaxBudget: string;
  MembershipNumber: string;
  NumberOfAdults: string;
  NumberOfChildren: string; //Other details
  OrderAppliesTo: string; //Transferee (lund)
  OtherInfo: string; //Other Details
  OwnBehalf: string;
  Pets: string; //Other Details
  PolicyTypeText: string; //Other Details (tetra)
  POManagerApproval: string;
  Sampackade: string; //Other details (sida)
  StorageReference: string;
  TetraPack: string;
  InStorage: string; //Other Details
  ToStorage: string; //Other Details
  ReturningHome: string; //Move Details
  VolumeLimitCustom: string;
  RelocationServices: string; //private bookings
  RFIRFP: string; //private bookings
  SCAReference: string;
  Shipment: string; //Other details
  UnpackingDestinationDate: string;

  //Not sure if these 4 below should be in content
  Legal: boolean;
  insurance: string;
  lockCompany: boolean;
  language: string;

  //New additions
  iAccept: string; //SIDA insurance accept/confirmation

  UXPayingCompany: any;
  UXPayingCountry: any;
  submitDate: string;

  // ericssonCountry: any;
  // ericssonCompany: any;

  // tetraCountry: any;
  // tetraCompany: any;
  numberOfFamily: string;

  moveOwner: any; //Tetra other

  scaniaCompany: any;

  payingCompany: any; //For Tetra,Ericsson and Northvolt company objects
  payingCountry: string; //Oriflame company details
  hrEmail: string; //CEVT HR
  hmChina: string; // HM Retail CSO or Production GBC choice in Other Details

  //Lund Ordered By section.
  orderedByName: string;
  orderedByFromEmail: string;
  orderedByWorkPhone: string;
  orderedByMobilePhone: string;

  //Finland private
  newCustomer: string;
  paymentTerms: string;
  ediCode: string;
  invoicingAdress: string;
  companyVAT: string;
  emailInvoice: string;

  ApprovedRelocationAllowance: string;
  ApprovedLongTermAllowance: string;

  CommencementDate: string;
  MaritalStatus: string;
}

export class Destdata {
  //Move Details: To
  destAddress: string; // Address
  destPostcode: string; //ZipCode
  destTown: string; //City
  destCountry: string; //Country

  //??????
  destAccess: string;
  destAddress2: string;
  destDeliveryDate: string;
  destDoorcode: string;
  destElevator: string;
  destFloor: string;
  destMailAlternative: string;
  destMailPrimary: string;
  destMobilephone: string;
  destPhoneHome: string;
  destPhoneOffice: string;
  destPickupDate: string;
  destResidenceType: string;
  destWalkingDistance: string;
  destSurveyDate: string;
  destSurveyTime: string;
}

export class Dateinfo {
  AvailableDate: string; //Requested date for delivery
  EmptyDate: string; //House must be empty by (if known)
  PackingDate: string; //Requested date for packing (if known)
  ShipperArrivalDate: string; //Your own planned arrival date to the destination
  RequestUnpack: string;
}

export class Volume {
  AirCourier: string;
  VolumeLimit: string; //Other Details (for Allowance)
  VolumeLimitOtherText: string; //Other Details HM
}

export class BussinessAgreementinfo {
  bussinessAgreementId: number;
  bookingsitereference: string;
  PolicyType: string; //Used by Ericsson
}

export class Services {
  immigration: boolean;
  settlingIn: boolean;
  homeSearch: boolean;
  tempAccomodationBooking: boolean;
  schoolSearch: boolean;
  spouseSupport: boolean;
  preVisitOrientation: boolean;
  consultation: boolean;
  outboundRelocation: boolean;
  otherSpecified: boolean;
  preHireRelocationConsultation: boolean;
}
