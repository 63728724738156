<h3>Your details</h3>
<hr />

<div class="form-container">
  <label class="icontainer">
    <input
      class="ifield"
      placeholder="&nbsp;"
      type="text"
      name="firstName"
      [(ngModel)]="content.assigneeFirstName"
      #firstName="ngModel"
      required
    />
    <span class="itext">First name*</span>
  </label>

  <label class="icontainer">
    <input
      class="ifield"
      placeholder="&nbsp;"
      type="text"
      name="lastName"
      [(ngModel)]="content.assigneeLastName"
      required
      #lastName="ngModel"
    />
    <span class="itext">Last name*</span>
  </label>

  <label class="icontainer">
    <input
      class="ifield"
      placeholder="&nbsp;"
      type="email"
      name="fromEmail"
      [(ngModel)]="orgdata.orgMailPrimary"
      #fromEmail="ngModel"
      required
      email
    />
    <span class="itext">Email*</span>
    <span class="erval" *ngIf="!fromEmail.valid && fromEmail.touched"
      >Please enter a correct email</span
    >
  </label>

  <!-- <label class="icontainer">
    <input
      class="ifield"
      placeholder="&nbsp;"
      type="tel"
      name="toWorkPhone"
      [(ngModel)]="orgdata.orgPhoneOffice"
      #toWorkPhone="ngModel"
    />
    <span class="itext">Work phone</span>
  </label> -->

  <label class="icontainer">
    <input
      class="ifield"
      type="tel"
      placeholder="&nbsp;"
      name="toMobilePhone"
      [(ngModel)]="orgdata.orgMobilephone"
      #toMobilePhone="ngModel"
      required
    />
    <span class="itext">Phone number</span>
  </label>
</div>
<!-- end of formcontainer -->

<p>Preferred way of contact:</p>

<!-- <div class="row">
  <label class="radio">
    <input
      class="iradio"
      type="radio"
      value="workphone"
      name="preferredContact"
      [(ngModel)]="orgdata.ContactWay"
      #preferredContact
    />
    <span>Work phone</span>
  </label>
</div> -->

<div class="row">
  <label class="radio">
    <input
      class="iradio"
      type="radio"
      value="phone"
      name="preferredContact"
      [(ngModel)]="orgdata.ContactWay"
      #preferredContact
    />
    <span>Phone</span>
  </label>
</div>

<div class="row">
  <label class="radio">
    <input
      class="iradio"
      type="radio"
      value="email"
      name="preferredContact"
      [(ngModel)]="orgdata.ContactWay"
      #preferredContact
    />
    <span>Email</span>
  </label>
</div>

<h3>Move details</h3>
<hr />
<h4>FROM ADDRESS</h4>

<div class="form-container">
  <label class="icontainer">
    <input
      type="text"
      class="ifield"
      placeholder="&nbsp;"
      name="fromAddress"
      [(ngModel)]="orgdata.orgAddress1"
      #fromAddress="ngModel"
      required
    />
    <span class="itext">Address*</span>
  </label>

  <label class="icontainer">
    <input
      required
      type="text"
      class="ifield"
      placeholder="&nbsp;"
      name="fromCity"
      [(ngModel)]="orgdata.orgTown"
      #fromCity="ngModel"
    />
    <span class="itext">City*</span>
    <span class="erval" *ngIf="fromCity.errors?.required && fromCity.touched"
      >Please select a city.</span
    >
  </label>

  <label class="icontainer">
    <input
      type="text"
      class="ifield"
      placeholder="&nbsp;"
      name="fromZipcode"
      [(ngModel)]="orgdata.orgPostcode"
      #fromZipcode="ngModel"
    />
    <span class="itext">Zip code</span>
  </label>

  <!-- TODO check improvements ISO 3166-1 -->
  <label class="icontainer">
    <select
      class="iselect"
      name="fromCountry"
      [(ngModel)]="orgdata.orgCountry"
      #fromCountry="ngModel"
      required
    >
      <option *ngFor="let country of countries" [value]="country.countrycode">
        {{ country.countryname }}
      </option>
    </select>
    <span class="erval" *ngIf="!fromCountry.valid && fromCountry.touched"
      >Please select a country.</span
    >
    <span
      class="itext"
      [ngClass]="{ 'itext-select': orgdata.orgCountry != undefined }"
      >Country*</span
    >
  </label>

  <!-- TODO: fix info field for types of houses -->

  <label class="icontainer">
    <select
      name="residenceType"
      class="iselect"
      [(ngModel)]="orgdata.orgResidenceType"
      #residenceType
    >
      <option value="1">Apartment</option>
      <option value="2">Villa</option>
      <option value="3">Townhouse</option>
      <option value="4">Warehouse</option>
    </select>
    <span
      class="itext"
      [ngClass]="{
        'itext-select': orgdata.orgResidenceType != undefined
      }"
    >
      Residence type
    </span>
  </label>

  <label class="icontainer">
    <select
      name="walkingDistance"
      class="iselect"
      [(ngModel)]="orgdata.orgWalkingDistance"
      #walkingDistance
    >
      <option value="1">Within 30 meters</option>
      <option value="2">30 to 50 meter carry</option>
      <option value="3">51 to 75 meter carry</option>
      <option value="4">76 to 100 meter carry</option>
      <option value="5">Not known at quotation</option>
    </select>
    <span
      class="itext"
      [ngClass]="{
        'itext-select': orgdata.orgWalkingDistance != undefined
      }"
    >
      Walking distance (from door to truck)
    </span>
  </label>

  <label class="icontainer">
    <select
      name="elevator"
      class="iselect"
      [(ngModel)]="orgdata.orgElevator"
      #elevator
    >
      <option value="0">No elevator</option>
      <option value="1">Small elevator</option>
      <option value="2">Medium elevator</option>
      <option value="3">Big elevator</option>
      <option value="4">Elevator not useable for move</option>
    </select>
    <span
      class="itext"
      [ngClass]="{ 'itext-select': orgdata.orgElevator != undefined }"
    >
      Elevator
    </span>
  </label>

  <label class="icontainer">
    <input
      type="text"
      placeholder="&nbsp;"
      class="ifield"
      name="fromDoorCode"
      [(ngModel)]="orgdata.orgDoorcode"
      #fromDoorCode
    />
    <span class="itext">Door code</span>
  </label>

  <label class="icontainer">
    <input
      type="text"
      placeholder="&nbsp;"
      class="ifield"
      name="fromFloor"
      [(ngModel)]="orgdata.orgFloor"
      #fromFloor
    />
    <span class="itext">Floor</span>
  </label>

  <!-- TODO: Explore options to have a slider or other forms of input for rooms and floor -->
  <label class="icontainer">
    <input
      type="text"
      placeholder="&nbsp;"
      class="ifield"
      name="fromRoomCount"
      [(ngModel)]="surveyinfo.NumberOfRooms"
      #fromRoomCount
    />
    <span class="itext">Number of rooms</span>
  </label>

  <label class="icontainer">
    <input
      type="number"
      placeholder="&nbsp;"
      class="ifield"
      name="fromArea"
      [(ngModel)]="surveyinfo.Area"
      #fromArea
    />
    <span class="itext">Area (m²)</span>
  </label>
</div>

<!-- Form-container end -->

<!-- Checkboxes -->
<p>I have goods in a:</p>

<label class="checkbox">
  <input
    type="checkbox"
    name="basement"
    value="yes"
    [(ngModel)]="surveyinfo.Basement"
    #basement
  />
  <span>Basement</span>
</label>

<label class="checkbox">
  <input
    type="checkbox"
    name="attic"
    value="yes"
    [(ngModel)]="surveyinfo.Attic"
    #attic
  />
  <span>Attic</span>
</label>

<h4>TO ADDRESS</h4>

<div class="form-container">
  <label class="icontainer">
    <input
      type="text"
      class="ifield"
      name="toAddress"
      placeholder="&nbsp;"
      [(ngModel)]="destdata.destAddress"
      #toAddress="ngModel"
    />
    <span class="itext">Address</span>
  </label>

  <label class="icontainer">
    <input
      type="text"
      placeholder="&nbsp;"
      class="ifield"
      name="toCity"
      [(ngModel)]="destdata.destTown"
      #toCity="ngModel"
      required
    />
    <span class="itext">City*</span>

    <span class="erval" *ngIf="toCity.errors?.required && toCity.touched"
      >Please select a city.</span
    >
  </label>

  <label class="icontainer">
    <input
      type="text"
      placeholder="&nbsp;"
      class="ifield"
      name="toZipcode"
      [(ngModel)]="destdata.destPostcode"
      #toZipcode="ngModel"
    />
    <span class="itext">Zip code</span>
  </label>

  <label class="icontainer">
    <select
      class="iselect"
      name="toCountry"
      [(ngModel)]="destdata.destCountry"
      #toCountry="ngModel"
      required
    >
      <option *ngFor="let country of countries" [value]="country.countrycode">
        {{ country.countryname }}
      </option>
    </select>
    <span class="erval" *ngIf="!toCountry.valid && toCountry.touched"
      >Please select a country.</span
    >
    <span class="itext" [ngClass]="{ 'itext-select': destdata.destCountry }"
      >Country*</span
    >
  </label>

  <label class="datelabel" for="packingDate">
    Preferred date for packing (if known)
  </label>
  <input
    class="dateinput"
    placeholder="Please choose a date"
    type="date"
    id="packingDate"
    name="packingDate"
    [(ngModel)]="dateinfo.PackingDate"
    #packingDate
  />

  <label class="datelabel" for="shipperArrivalDate">
    Your own planned arrival date at the destination</label
  >
  <input
    class="dateinput"
    type="date"
    id="shipperArrivalDate"
    name="shipperArrivalDate"
    [(ngModel)]="dateinfo.ShipperArrivalDate"
    #shipperArrivalDate
  />
</div>
<h3>Other details</h3>
<hr />
<div class="radio-container">
  <p>Do you need temporary storage for your goods during your move?</p>

  <div class="row">
    <label class="radio">
      <input
        type="radio"
        value="yes"
        [(ngModel)]="extrainfo.InStorage"
        name="InStorage"
      />
      <span>Yes</span>
    </label>
  </div>

  <div class="row">
    <label class="radio">
      <input
        type="radio"
        value="no"
        [(ngModel)]="extrainfo.InStorage"
        name="InStorage"
      />
      <span>No</span>
    </label>
  </div>
</div>

<div *ngIf="extrainfo.nordicCountry === 'se'" class="radio-container">
  <h3>RUT deduction</h3>
  <hr />

  <p>
    Use RUT deduction when moving to/from Sweden within Europe. If you move
    to/from Sweden and are a Swedish tax payer, you may be entitled to a tax
    deduction with RUT.
  </p>

  <div class="row">
    <label class="radio">
      <input
        type="radio"
        value="yes"
        [(ngModel)]="extrainfo.InterestedInRUT"
        name="InterestedInRUT"
      />
      <span>I would like information about RUT</span>
    </label>
  </div>
</div>

<label for="textbox"><h4>Additional information</h4></label>
<textarea
  class="itextfield"
  name="otherDetails"
  id="textbox"
  cols="30"
  rows="10"
  [(ngModel)]="extrainfo.OtherInfo"
  placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
></textarea>

<div class="button-container">
  <!-- BASIC BUTTONS -->
  <input
    class="button btn-form"
    type="button"
    value="Submit"
    (click)="formData.privateSubmit()"
    [disabled]="
      !firstName.valid ||
      !lastName.valid ||
      !fromEmail.valid ||
      !toMobilePhone.valid ||
      !fromAddress.valid ||
      !fromCity.valid ||
      !fromCountry.valid ||
      !toCity.valid ||
      !toCountry.valid
    "
  />
</div>
