import { Action, createReducer, on } from '@ngrx/store';
import { fetchCompanies, fetchCompaniesSuccess, fetchCompaniesError } from '../actions';

import { Company } from '../../models/company';

const initialCompanies: Company[] = [];

export interface CompanyState {
    companies: Company[];
}

export const initialCompanyState: CompanyState = {
  companies: initialCompanies
}

export const companyReducer = createReducer(
  initialCompanyState,
  on(fetchCompanies, (state: CompanyState) => {
    return state;
  }),
  on(fetchCompaniesSuccess, (state: CompanyState, action: any) => {
    return {
      ...state,
      companies: action.payload,
    };
  }),
  on(fetchCompaniesError, (state: CompanyState, action: any) => {
    return {
      ...state,
      companies: action.payload.error,
    };
  }),
);
