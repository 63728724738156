import { Component } from '@angular/core';
import { BussinessAgreementinfo, Destdata, Extrainfo, Orgdata } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-ericsson-other-details',
  templateUrl: './ericsson-other-details.component.html',
  styleUrls: ['./ericsson-other-details.component.scss']
})
export class EricssonOtherDetailsComponent {

  extrainfo:Extrainfo;
  orgdata:Orgdata;
  bussiness:BussinessAgreementinfo;
  destdata : Destdata;

  
constructor (
  private formDataService : FormDataService,  
  public formData : FormDataService
  ) 
  {

  this.extrainfo = formDataService.extrainfo;
  this.orgdata = formDataService.orgdata;
  this.bussiness = formDataService.bussiness;
  this.destdata = formDataService.destdata;

}

ericssonExceptions = ["AF", "AO", "BH", "BJ", "BW", "BF", "CM", "CA", "CF", "TD", "CG", "CD", 
"CI", "DJ", "EG", "ET", "GA", "GH", "GW", "GN", "IR", "IQ", "JO", "KE", "KW", "LB", "MG", "MW", 
"ML", "MU", "MA", "MZ", "NA", "NE", "NG", "OM", "PK", "PS", "QA", "RW", "SA", "SN", "SC", 
"SL", "ZA", "SD", "SS", "SZ", "SY", "TZ", "TG", "TR", "UG", "AE", "YE", "ZM", "ZW" ];


}
