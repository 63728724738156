<h3>Move details</h3>
<hr />
<div id="top"></div>
<div class="w3-border">
    <div class="w3-blue" style="height: 24px; width: 20%"></div>
</div>

<h4>Moving from:</h4>

<div class="form-container">
    <label *ngIf="formC.formCompanyName != 'umdk'" class="icontainer">
        <input
            type="text"
            class="ifield"
            placeholder="&nbsp;"
            name="fromAddress"
            [(ngModel)]="orgdata.orgAddress1"
            #fromAddress="ngModel"
            required
        />
        <span class="itext">Address*</span>
        <span class="erpattern" *ngIf="fromAddress.errors?.pattern"
            >Please review your input and remove any special characters</span
        >
    </label>

    <label class="icontainer">
        <input
            required
            type="text"
            class="ifield"
            placeholder="&nbsp;"
            name="fromCity"
            [(ngModel)]="orgdata.orgTown"
            #fromCity="ngModel"
        />
        <span class="itext">City*</span>
        <span
            class="erval"
            *ngIf="fromCity.errors?.required && fromCity.touched"
            >Please select a city.</span
        >
        <span class="erpattern" *ngIf="fromCity.errors?.pattern"
            >Please review your input and remove any special characters</span
        ></label
    >

    <label *ngIf="formC.formCompanyName != 'umdk'" class="icontainer">
        <input
            type="text"
            class="ifield"
            placeholder="&nbsp;"
            name="fromZipcode"
            [(ngModel)]="orgdata.orgPostcode"
            #fromZipcode="ngModel"
        />
        <span class="itext">Zip code</span>
        <div
            *ngIf="fromZipcode.invalid && (fromZipcode.dirty || fromZipcode.touched)"
        >
            <span class="erval" *ngIf="fromZipcode.errors.pattern"
                >Please review your input and remove any special
                characters</span
            >
        </div>
    </label>

    <!-- TODO check improvements ISO 3166-1 -->
    <label class="icontainer">
        <select
            class="iselect"
            name="fromCountry"
            [(ngModel)]="orgdata.orgCountry"
            #fromCountry="ngModel"
            required
        >
            <option
                *ngFor="let country of countries"
                [value]="country.countrycode"
            >
                {{ country.countryname }}
            </option>
        </select>
        <span class="erval" *ngIf="!fromCountry.valid && fromCountry.touched"
            >Please select a country.</span
        >
        <span
            class="itext"
            [ngClass]="{ 'itext-select': orgdata.orgCountry != undefined }"
            >Country*</span
        >
    </label>

    <!-- TODO: fix info field for types of houses -->

    <div
        *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'"
    >
        <label class="icontainer">
            <select
                name="residenceType"
                class="iselect"
                [(ngModel)]="orgdata.orgResidenceType"
                #residenceType
            >
                <option value="1">Apartment</option>
                <option value="2">Villa</option>
                <option value="3">Townhouse</option>
                <option value="4">Warehouse</option>
            </select>
            <span
                class="itext"
                [ngClass]="{
          'itext-select': orgdata.orgResidenceType != undefined
        }"
            >
                Residence type
            </span>
        </label>
    </div>

    <div
        *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'"
    >
        <label class="icontainer">
            <select
                name="walkingDistance"
                class="iselect"
                [(ngModel)]="orgdata.orgWalkingDistance"
                #walkingDistance
            >
                <option value="1">Within 30 meters</option>
                <option value="2">30 to 50 meter carry</option>
                <option value="3">51 to 75 meter carry</option>
                <option value="4">76 to 100 meter carry</option>
                <option value="5">Not known at quotation</option>
            </select>
            <span
                class="itext"
                [ngClass]="{
          'itext-select': orgdata.orgWalkingDistance != undefined
        }"
            >
                Walking distance (from door to truck)
            </span>
        </label>
    </div>

    <div
        *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'"
    >
        <label class="icontainer">
            <select
                name="elevator"
                class="iselect"
                [(ngModel)]="orgdata.orgElevator"
                #elevator
            >
                <option value="0">No elevator</option>
                <option value="1">Small elevator</option>
                <option value="2">Medium elevator</option>
                <option value="3">Big elevator</option>
                <option value="4">Elevator not useable for move</option>
            </select>
            <span
                class="itext"
                [ngClass]="{ 'itext-select': orgdata.orgElevator != undefined }"
            >
                Elevator
            </span>
        </label>
    </div>

    <div
        *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'"
    >
        <label class="icontainer">
            <input
                type="text"
                placeholder="&nbsp;"
                class="ifield"
                name="fromDoorCode"
                [(ngModel)]="orgdata.orgDoorcode"
                #fromDoorCode
            />
            <span class="itext">Door code</span>
        </label>
    </div>

    <div
        *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'"
    >
        <label class="icontainer">
            <input
                type="text"
                placeholder="&nbsp;"
                class="ifield"
                name="fromFloor"
                [(ngModel)]="orgdata.orgFloor"
                #fromFloor
            />
            <span class="itext">Floor</span>
        </label>
    </div>

    <!-- TODO: Explore options to have a slider or other forms of input for rooms and floor -->
    <div
        *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'"
    >
        <label class="icontainer">
            <input
                type="text"
                placeholder="&nbsp;"
                class="ifield"
                name="fromRoomCount"
                [(ngModel)]="surveyinfo.NumberOfRooms"
                #fromRoomCount
            />
            <span class="itext">Number of rooms</span>
        </label>
    </div>

    <div
        *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'"
    >
        <label class="icontainer">
            <input
                type="number"
                placeholder="&nbsp;"
                class="ifield"
                name="fromArea"
                [(ngModel)]="surveyinfo.Area"
                #fromArea
            />
            <span class="itext">Area (m²)</span>
        </label>
    </div>
</div>
<!-- Form-container end -->

<!-- Checkboxes -->
<div *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'">
    <p>I have goods in a/an:</p>

    <label class="checkbox">
        <input
            type="checkbox"
            name="basement"
            value="yes"
            [(ngModel)]="surveyinfo.Basement"
            #basement
        />
        <span>Basement</span>
    </label>

    <label class="checkbox">
        <input
            type="checkbox"
            name="attic"
            value="yes"
            [(ngModel)]="surveyinfo.Attic"
            #attic
        />
        <span>Attic</span>
    </label>
</div>

<div class="button-container">
    <input
        class="button btn-form"
        type="button"
        value="Back"
        routerLink="../yourdetails"
    />
    <input
        class="button btn-form"
        [disabled]="
      !fromCountry.valid ||
      !fromCity.valid
    "
        type="button"
        value="Next"
        routerLink="../movedetailsto"
    />
</div>
