import { Component} from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-ericsson',
  templateUrl: './ericsson.component.html',
  styleUrls: ['./ericsson.component.scss']
})
export class EricssonComponent {

  formC : FormConfig;
  extrainfo:Extrainfo;
  content:Content;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.formDataService.reset();
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //pre-assigned values for the form.
    this.content.SokID = '' //choice is dependant on FLH or SLTA, can be changed in extrainfo ts file
    this.extrainfo.CompanyName = ' ';
    this.extrainfo.CompanyAddress = ' ';
    this.extrainfo.CompanyCity = ' ';
    this.extrainfo.CompanyCountry = ' ';
    this.extrainfo.CompanyZipCode = ' ';
    this.extrainfo.insurance = 'All shipments and storage portions are insured through Alfa. A valuation form will be sent to you by our move mangers upon confirming your move. It is very important that this form is filled in and returned prior to packing.';
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = 'false';

    //Determines if form need approval by manager
    this.formConfig.confirm = false;

    //config
    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'ericsson';
  }
}
