import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fetchCountries, fetchCompanies } from '../../../store/actions';
import { Content } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss'],
})
export class LegalNoticeComponent implements OnInit {
  formC: FormConfig;
  content : Content;

  constructor(
    private formDataService: FormDataService,
    private formConfig: FormConfig,
    private store: Store
  ) {

    this.formC = formConfig;

    this.content = formDataService.content;
  }

  ngOnInit(): void {
    this.store.dispatch(fetchCountries());
    this.store.dispatch(fetchCompanies());
  }


  isReadMore = true;
  showText() {
    this.isReadMore = !this.isReadMore;
  }
}
