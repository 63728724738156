import { Action, createReducer, on } from '@ngrx/store';
import { fetchCountries, fetchCountriesSuccess, fetchCountriesError } from '../actions';

import { Country } from '../../models/country';

const initialCountries: Country[] = [];

export interface CountryState {
    countries: Country[];
}

export const initialCountryState: CountryState = {
  countries: initialCountries
}

export const countryReducer = createReducer(
  initialCountryState,
  on(fetchCountries, (state: CountryState) => {
    return state;
  }),
  on(fetchCountriesSuccess, (state: CountryState, action: any) => {
    return {
      ...state,
      countries: action.payload,
    };
  }),
  on(fetchCountriesError, (state: CountryState, action: any) => {
    return {
      ...state,
      countries: action.payload.error,
    };
  }),
);

