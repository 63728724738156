<div class="assignment-list-container" *ngIf="!loading">

  <!-- <div class="assignment-search">
    <input class="sbar" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" itext="&#61442;  Free text search">
  </div> -->

  <div class="assignment-list" *ngFor="let assignment of assignments">
    <app-assignment2 [assignment]="assignment"></app-assignment2>
  </div>
</div>
