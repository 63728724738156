import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { Content, Dateinfo, Destdata, Extrainfo, Orgdata, Surveyinfo } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';
import { selectAllCountries } from '../../../store/selectors';
import { Country } from '../../../models/country';
@Component({
  selector: 'app-private-form',
  templateUrl: './private-form.component.html',
  styleUrls: ['./private-form.component.scss']
})
export class PrivateFormComponent implements OnInit {

  orgdata: Orgdata;
  surveyinfo: Surveyinfo;
  destdata: Destdata;
  content: Content;
  dateinfo: Dateinfo;
  extrainfo: Extrainfo;

  countries: Country[];

  constructor(
    private route: ActivatedRoute,
    private formDataService: FormDataService,
    public formData: FormDataService,
    private store: Store
  ) {
    this.surveyinfo = formDataService.surveyinfo;
    this.destdata = formDataService.destdata;
    this.orgdata = formDataService.orgdata;
    this.content = formDataService.content;
    this.dateinfo = formDataService.dateinfo;
    this.extrainfo = formDataService.extrainfo;
  }

  ngOnInit(): void {
    this.store.select(selectAllCountries).subscribe((data) => {
      this.countries = data;
    });
  }

}
