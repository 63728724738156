<h3>Other details</h3>
<hr />
<div class="radio-container">
  <h4>Select assignment type</h4>
  <!-- TODO: fix required field -->

  <label class="radio">
    <input
      type="radio"
      value="TETRA1"
      name="assignmentType"
      [(ngModel)]="extrainfo.PolicyTypeText"
    />
    <span>Short Term Assignment</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="TETRA2"
      required
      #assignmentType="ngModel"
      name="assignmentType"
      [(ngModel)]="extrainfo.PolicyTypeText"
    />
    <span>Career Migration</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="TETRA3"
      name="assignmentType"
      [(ngModel)]="extrainfo.PolicyTypeText"
    />
    <span>Executive</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="TETRA4"
      name="assignmentType"
      [(ngModel)]="extrainfo.PolicyTypeText"
    />
    <span>Long Term Assignment</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="TETRA5"
      name="assignmentType"
      [(ngModel)]="extrainfo.PolicyTypeText"
    />
    <span>Swiss Transferee</span>
  </label>

  <h4>Family size*</h4>
  <!-- TODO: Fix a required field -->

  <label class="radio">
    <input
      required
      type="radio"
      value="Single"
      name="tetraFamily"
      #tetraFamily="ngModel"
      [(ngModel)]="extrainfo.FamilySize"
      (ngModelChange)="onFamilySizeChange()"
    />
    <span>Single</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Couple"
      name="tetraFamily"
      [(ngModel)]="extrainfo.FamilySize"
      (ngModelChange)="onFamilySizeChange()"
    />
    <span>Couple</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Couple or Single plus 1 Child"
      name="tetraFamily"
      [(ngModel)]="extrainfo.FamilySize"
      (ngModelChange)="onFamilySizeChange()"
    />
    <span>Couple or single plus 1 child</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Couple or Single plus 2 Children"
      name="tetraFamily"
      [(ngModel)]="extrainfo.FamilySize"
      (ngModelChange)="onFamilySizeChange()"
    />
    <span>Couple or single plus 2 children</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Couple or Single plus 3 Children"
      name="tetraFamily"
      [(ngModel)]="extrainfo.FamilySize"
      (ngModelChange)="onFamilySizeChange()"
    />
    <span>Couple or single plus 3 children</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Couple or Single plus 4 or more Children"
      name="tetraFamily"
      [(ngModel)]="extrainfo.FamilySize"
      (ngModelChange)="onFamilySizeChange()"
    />
    <span>Couple or single plus 4 or more children</span>
  </label>

  <label class="icontainer label-children">
    <select
      name="numberOfFamily"
      class="iselect"
      [(ngModel)]="extrainfo.numberOfFamily"
      #numberOfFamily
    >
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6 or more">6 or more</option>
    </select>
    <span
      class="itext"
      [ngClass]="{ 'itext-select': extrainfo.numberOfFamily != undefined }"
    >
      Number of family members
    </span>
  </label>
  <span class="familymsg">Family members moving, incl. Yourself </span>
</div>

<!-- GIP -->
<!-- <br>
<h4>Are you considered as a GIP or Non GIP - Global Insentive Plan</h4>

<label class="radio">
  <input
    type="radio"
    value="yes"
    name="GlobalIntensivePlan"
    [(ngModel)]="extrainfo.GlobalIntensivePlan"
  />
  <span>GIP</span>
</label>

<label class="radio">
  <input
    type="radio"
    value="no"
    name="GlobalIntensivePlan"
    [(ngModel)]="extrainfo.GlobalIntensivePlan"
  />
  <span>Non GIP</span>
</label> -->

<h4>Move owner</h4>

<label class="icontainer">
  <select
    name="moveOwner"
    class="iselect"
    [(ngModel)]="extrainfo.moveOwner"
    #moveOwner
  >
    <option value="Mirella.Horvath&#64;tetrapak.com">Mirella Horvath (Mirella.Horvath&#64;tetrapak.com)</option>
    <option value="Barbara.Lovas&#64;tetrapak.com">Barbara Lovas (Barbara.Lovas&#64;tetrapak.com)</option>
    <option value="fernando.ruiz&#64;sidel.com">Fernando Ruiz (fernando.ruiz&#64;sidel.com)</option>
    <option value="Malgorzata.PtakKowalska&#64;delaval.com">Malgorzata Ptak Kowalska (Malgorzata.PtakKowalska&#64;delaval.com)</option>
    <option value="Europe.Mobility&#64;tetrapak.com">Europe Mobility (Europe.Mobility&#64;tetrapak.com)</option>
    <option value="Sweden.Mobility&#64;tetrapak.com">Sweden Mobility (Sweden.Mobility&#64;tetrapak.com)</option>
    <option value="Larissa.Arruda&#64;tetrapak.com">Larissa Arruda (Larissa.Arruda&#64;tetrapak.com)</option>
    <option value="Kyle.Turner&#64;tetrapak.com">Kyle Turner (Kyle.Turner&#64;tetrapak.com)</option>
    <option value="Marivel.Vela&#64;tetrapak.com">Marivel Vela (Marivel.Vela&#64;tetrapak.com)</option>
    <option value="Shirley.Yang&#64;tetrapak.com">Shirley Yang (Shirley.Yang&#64;tetrapak.com)</option>
    <option value="Preeti.Fernandes&#64;tetrapak.com">Preeti Fernandes (Preeti.Fernandes&#64;tetrapak.com)</option>
    <option value="Veronique.Hejl&#64;tetrapak.com">Veronique Hejl (Veronique.Hejl&#64;tetrapak.com)</option>
  </select>
  <span
    class="itext"
    [ngClass]="{
      'itext-select': extrainfo.moveOwner != undefined
    }"
  >
    Name
  </span>
</label>

<label for="textbox"><h4>Additional information</h4></label>
<textarea
  class="itextfield"
  name="otherDetails"
  id="textbox"
  cols="30"
  rows="10"
  [(ngModel)]="extrainfo.OtherInfo"
  placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
></textarea>

<div class="button-container">
  <input
    class="button btn-form"
    type="button"
    value="Back"
    routerLink="../movedetailsto"
  />
  <input
    [disabled]="!tetraFamily.valid || !assignmentType.valid"
    class="button btn-form"
    type="button"
    value="Submit"
    (click)="formData.formSubmit()"
  />
</div>
