import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllCountries } from '../../../store/selectors';
import { Dateinfo, Destdata, Extrainfo, Orgdata, Surveyinfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { Country } from '../../../models/country';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-move-details-to',
  templateUrl: './move-details-to.component.html',
  styleUrls: ['./move-details-to.component.scss'],
})
export class MoveDetailsToComponent implements OnInit {
  formC: FormConfig;

  destdata: Destdata;
  surveyinfo: Surveyinfo;
  dateinfo: Dateinfo;
  extrainfo: Extrainfo;
  orgdata: Orgdata;

  minDate;

  countries: Country[];

  constructor(
    private formDataService: FormDataService,
    private formConfig: FormConfig,
    public formData: FormDataService,
    private store: Store
  ) {
    this.surveyinfo = formDataService.surveyinfo;
    this.destdata = formDataService.destdata;
    this.dateinfo = formDataService.dateinfo;
    this.extrainfo = formDataService.extrainfo;
    this.orgdata = formDataService.orgdata;

    this.formC = formConfig;

    const currentDate = new Date();
    this.minDate = currentDate.toISOString().slice(0, 10);
  }

  ngOnInit(): void {
    this.store.select(selectAllCountries).subscribe((data) => {
      this.countries = data;
    });
  }
}
