import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-sandvik',
  templateUrl: './sandvik.component.html',
  styleUrls: ['./sandvik.component.scss']
})
export class SandvikComponent {

  content:Content;
  extrainfo:Extrainfo;  
  
  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.extrainfo.CompanyName = 'Sandvik AB';
    this.content.SokID = '15044';
    this.extrainfo.CompanyAddress = 'Storgatan 2';
    this.extrainfo.CompanyCity = 'Sandviken';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = '811 81';
    this.extrainfo.CompanyEmail = 'pdfinvoices_se@sandvik.com';
    this.extrainfo.CompanyContact = '026-263000';
    this.extrainfo.insurance = 'All shipments are insured via Sandvik AB. Please contact Catarina Israelsson for more information.';
    this.extrainfo.Legal = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';
    this.extrainfo.lockCompany = true;

    this.formConfig.confirm = false; //Determines if form need approval by manager

    //form configuration
    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'Sandvik';
  }

}
