import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUserAllowFunctions } from '../../store/selectors';
import { DataService } from '../../services/data.service';
import { Data } from '../../models/data';
import { Assignment } from '../../models/assignment';

@Component({
  selector: 'app-assignment2-list',
  templateUrl: './assignment2-list.component.html',
  styleUrls: ['./assignment2-list.component.scss']
})
export class Assignment2ListComponent implements OnInit {

  assignments: Assignment[] = [];
  functions: string[] = [];
  loading = true;

  constructor(
    private dataService: DataService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.select(selectUserAllowFunctions).subscribe((f) => {
      // console.log('Allowed functions: ', f);
      // console.log('Function: ', f[0].Code);
      this.getData(f[0].Code, false);
    });
  }

  getData(_function: string, _detail: boolean) {
    this.dataService.getData(_function, _detail).subscribe({
      next: (response: Data) => {
        // console.log(response);
        if (response.response.result) {
          this.assignments = response.content.list;
          this.loading = false;
        } else {
          this.assignments = [];
          this.loading = false;
        }
      },
      error: (error: Error) => {
        console.error(error);
        this.assignments = [];
        this.loading = false;
      }
    });
  }

  getDataDetail(_function: string, _detail: boolean, _id: string) {
    this.dataService.getDataDetail(_function, _detail, _id).subscribe({
      next: (response: any) => {
        // console.log(response);
      },
      error: (error: Error) => {
        console.error(error);
      }
    });
  }

}
