<div class="error-container">
  <h3>Error</h3>
  <div class="error-text">
    The page is not available at the moment, please try again later or contact
    <a href="mailto:sales&#64;alfamoving.com">sales&#64;alfamoving.com</a> We apologize for any inconvenience.
  </div>
  <div class="error-message">
    <b>Error message: </b> <i>{{ errorObj.message }}</i>
  </div>
</div>
