import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { DataService } from '../../services/data.service';
import { select, Store } from '@ngrx/store';
import { UserContent } from '../../models/user';
import { UserState } from '../../store/reducers';
import { selectUserContent } from '../../store/selectors';
import { _store } from '../../localstore/localstore';

import { base64StringToBlob, blobToBase64String } from 'blob-util';
import { DomSanitizer } from '@angular/platform-browser';

import { User, ProfilePicture } from '../../models/user';
import { Data } from '../../models/data';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {


  userContent!: UserContent;
  profilePicture!: ProfilePicture;
  profileImage: string = '';
  alert = false;
  success = false;
  alertMsg: string = '';
  successMsg: string = '';

  public imagePath: any;
  imgURL: any;
  public message: string = '';
  profileImageMimeType: string = '';
  profileImageB64: string = '';
  profileImageChanged = false;

  updateProfileSubscription: Subscription = new Subscription();

  profileForm = new FormGroup({
    userDisplayName: new FormControl('', Validators.required),
    userEmail: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl(''),
    password2: new FormControl(''),
  });

  constructor(
    private store: Store<UserState>,
    private dataService: DataService,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    this.userContent = this.getValue(this.store.pipe(select(selectUserContent)));
    const lstore = new _store();
    const lstoreObj = lstore.pull();
    // console.log('lstoreObj: ', lstoreObj);
    this.profilePicture = <ProfilePicture>lstoreObj['profilePicture'];
    this.profileImage = this.decodePicture(this.profilePicture.content);
    this.setFormControls();
  }



  ngOnDestroy() {
    this.updateProfileSubscription.unsubscribe();
  }

  decodePicture(b64data: string): any {
    const contentType = 'image/png';
    const blob = base64StringToBlob(b64data, contentType);
    const unsafeImg = URL.createObjectURL(blob);
    const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
    return image;
  }

  encodePicture(blobdata: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      // console.log('typeof blobdata: ', typeof blobdata);
      resolve(blobToBase64String(blobdata as Blob));
    });
  }

  loadImage(files: any) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    this.profileImageMimeType = mimeType;
    // console.log('files: ', files);
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.profileImageChanged = true;
      this.profileImage = <any>reader.result;
    };
  }

  setFormControls() {
    this.profileForm.patchValue({
      userDisplayName: this.userContent.userDisplayName,
      userEmail: this.userContent.userEmail,
    });
  }

  submitForm() {
    let body: any = {};
    let password: string = '';
    let password2: string = '';

    this.success = false;
    this.successMsg = '';

    body['userName'] = this.userContent.userName;

    /* userDisplayName */
    if (this.profileForm.get('userDisplayName')?.touched) {
      // console.log('userDisplayName has been modified');
      body['userDisplayName'] = this.profileForm.get('userDisplayName')?.value;
    }
    /* userEmail */
    if (this.profileForm.get('userEmail')?.touched) {
      // console.log('userEmail has been modified');
      body['userEmail'] = this.profileForm.get('userEmail')?.value;
    }
    /* Password */
    if (this.profileForm.get('password')?.touched) {
      password = this.profileForm.get('password')?.value;
      if (!this.profileForm.get('password2')?.touched) {
        this.profileForm.controls['password'].setErrors({ invalid: true });
        this.profileForm.controls['password2'].setErrors({ invalid: true });
        this.setAlert('Both passwords are needed');
        return;
      } else {
        password2 = this.profileForm.get('password2')?.value;
        if (password === password2) {
          body['password'] = this.profileForm.get('password')?.value;
        } else {
          this.profileForm.controls['password'].setErrors({ invalid: true });
          this.profileForm.controls['password2'].setErrors({ invalid: true });
          this.setAlert('Passwords does not match');
          return;
        }
      }
    }
    /* ProfilePicture */
    if (this.profileImageChanged) {
      const elems = this.profileImage.split(',');
      const pObj: ProfilePicture = {
        content: elems[1],
        mimeType: this.profileImageMimeType,
      };
      body['profilePicture'] = pObj;
    }
    this.setSuccess('Form validated successfully');
    // console.log('body : ', body);
    this.saveProfile(body);
  }

  saveProfile(profile: User): void {
    this.updateProfileSubscription = this.dataService
      .saveProfile(profile)
      .subscribe({
        next: (response: Data) => {
          // console.log('Response: ', response);
        },
        error: (error: Error) => {
          console.error(error);
        },
      });
  }

  setAlert(msg: string): void {
    this.alertMsg = msg;
    this.alert = true;
    setTimeout(() => {
      this.alert = false;
    }, 3000);
  }

  setSuccess(msg: string): void {
    this.successMsg = msg;
    this.success = true;
    setTimeout(() => {
      this.success = false;
    }, 3000);
  }

  getValue(obj: Observable<any>) {
    let value: any;
    obj.subscribe((v) => (value = v));
    return value;
  }



}
