import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-folke-bernadotte-academy',
  templateUrl: './folke-bernadotte-academy.component.html',
  styleUrls: ['./folke-bernadotte-academy.component.scss']
})
export class FolkeBernadotteAcademyComponent {

  extrainfo:Extrainfo;
  content:Content;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.content.SokID = '33486'
    this.extrainfo.CompanyName = 'Folke Bernadotte Academy';
    this.extrainfo.CompanyAddress = 'FE 521';
    this.extrainfo.CompanyEmail = '';
    this.extrainfo.CompanyContact = '';
    this.extrainfo.CompanyCity = 'Frösön';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = '838 81';
    this.extrainfo.insurance = 'Insurance will be arranged by: Kammarkollegiet.';
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';

    //Determines if form need approval by manager
    this.formConfig.confirm = false; 

    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'fba';
  }

}
