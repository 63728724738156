import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { fetchCountries, fetchCompanies } from 'src/app/store/actions';
import {
  selectAllCountries,
  selectCompaniesByCountryAndGroup,
} from 'src/app/store/selectors';
import {
  BussinessAgreementinfo,
  Content,
  Extrainfo,
} from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';
import { Country } from 'src/app/models/country';
import { Company } from 'src/app/models/company';

@Component({
  selector: 'app-ericsson-company-details',
  templateUrl: './ericsson-company-details.component.html',
  styleUrls: ['./ericsson-company-details.component.scss'],
})
export class EricssonCompanyDetailsComponent {
  countries: Country[];
  companies: Company[];
  progress: number;

  formC: FormConfig;
  extrainfo: Extrainfo;
  bussiness: BussinessAgreementinfo;
  content: Content;

  constructor(
    private formDataService: FormDataService,
    private formConfig: FormConfig,
    private store: Store
  ) {
    this.formC = formConfig;

    this.bussiness = formDataService.bussiness;
    this.extrainfo = formDataService.extrainfo;
    this.content = formDataService.content;
  }

  ngOnInit() {
    this.progress = 0; //TODO remove if no progressbar

    this.store.dispatch(fetchCountries());
    this.store.dispatch(fetchCompanies());

    this.store.select(selectAllCountries).subscribe((data) => {
      this.countries = data;
    });

  }

  /*---------------------------------------------------------- */

  changeAssignment(value: string) {
    // Changes company/sokid for Ericsson depending on FLH or SLTA
    if (value === 'FLH') {
      this.content.SokID = '11677';
    } else if (value === 'SLTA') {
      this.content.SokID = '23448';
      this.extrainfo.UXPayingCountry = undefined;
      this.extrainfo.payingCompany = undefined;
    } else if (value === 'RLTA') {
      this.extrainfo.UXPayingCountry = undefined;
      this.extrainfo.payingCompany = undefined;
    }
  }

  onCountryChange(country, group) {
    this.store
      .select(selectCompaniesByCountryAndGroup(country, group))
      .subscribe((data) => {
        data.sort((a, b) => a.companyname.localeCompare(b.companyname));
        this.companies = data;
      });
  }

  onCompanyChange() {
    this.extrainfo.UXPayingCountry = this.extrainfo.payingCompany.countrycode;
    this.extrainfo.UXPayingCompany = this.extrainfo.payingCompany.companynumber;
  }

}
