import { Component } from '@angular/core';
import { Extrainfo } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-oriflame-other-details',
  templateUrl: './oriflame-other-details.component.html',
  styleUrls: ['./oriflame-other-details.component.scss']
})
export class OriflameOtherDetailsComponent {

  extrainfo:Extrainfo;
  
constructor (
  private formDataService : FormDataService,  
  public formData : FormDataService
  ) 
  {

  this.extrainfo = formDataService.extrainfo;

}

}
