import { Component } from '@angular/core';
import {
  BussinessAgreementinfo,
  Extrainfo,
  Volume,
} from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-hm-policy',
  templateUrl: './hm-policy.component.html',
  styleUrls: ['./hm-policy.component.scss'],
})
export class HmPolicyComponent {
  extrainfo: Extrainfo;
  bussiness: BussinessAgreementinfo;
  volume: Volume;

  constructor(
    private formDataService: FormDataService,
    public formData: FormDataService
  ) {
    this.extrainfo = formDataService.extrainfo;
    this.bussiness = formDataService.bussiness;
    this.volume = formDataService.volume;
  }
}
