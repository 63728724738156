import { Component } from '@angular/core';
import { Extrainfo, Orgdata, Volume } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-alfalaval-other-details',
  templateUrl: './alfalaval-other-details.component.html',
  styleUrls: ['./alfalaval-other-details.component.scss'],
})
export class AlfalavalOtherDetailsComponent {
  extrainfo: Extrainfo;
  orgdata: Orgdata;
  volume: Volume;

  constructor(
    private formDataService: FormDataService,
    public formData: FormDataService
  ) {
    this.extrainfo = formDataService.extrainfo;
    this.orgdata = formDataService.orgdata;
    this.volume = formDataService.volume;
  }

  //Deselects the Allowance when selecting an incompatible assignment type
  FLH() {
    if (
      this.volume.VolumeLimit == 'Single or unaccompanied 4 m3' ||
      this.volume.VolumeLimit == 'Accompanied 8 m3'
    ) {
      return (this.volume.VolumeLimit = '');
    } else return null;
  }
}
