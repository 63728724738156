import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';
import { Extrainfo } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';
import { fetchCountries, fetchCompanies } from '../../store/actions';
import { selectAllCountries, selectAllCompanies } from '../../store/selectors';
import { Country } from '../../models/country';
import { Company } from '../../models/company';

@Component({
  selector: 'app-need-confirm-list',
  templateUrl: './need-confirm-list.component.html',
  styleUrls: ['./need-confirm-list.component.scss'],
})
export class NeedConfirmListComponent implements OnInit {
  /* confirm: any; */

  id: string;
  confirmList: any;
  extrainfo: Extrainfo;
  companies: Company[] = [];
  allCompanies: Company[] = [];
  countries: Country[];
  selectedCompany: any;
  confirm: any = {};
  minDate: string;
  currentAssignment: any = null;
  currentIndex: number;
  customer = '';
  includeAll = false;
  loaded = false;
  error = false;
  errorObj: any = {};

  sort_aname = true; // Ascending order
  sort_move = true; // Ascending order
  sort_status = true; // Ascending order
  sort_moveowner = true; // Ascending order

  constructor(
    private dataService: DataService,
    private router: Router,
    private formDataService: FormDataService,
    private store: Store,
    private toast: ToastrService
  ) {}

  ngOnInit() {
    // Fill store with data
    this.store.dispatch(fetchCountries());
    this.store.dispatch(fetchCompanies());

    this.getConfirmData('ExBook', false, this.includeAll)
      .then((res) => {
        this.confirmList = res;
        this.sortOnAssigneeName();
        this.extrainfo = this.formDataService.extrainfo;
        const currentDate = new Date();
        this.minDate = currentDate.toISOString().slice(0, 10);

        this.store.select(selectAllCountries).subscribe((data) => {
          this.countries = data;
        });

        this.store.select(selectAllCompanies).subscribe((data) => {
          this.allCompanies = data;
        });
        // console.log(this.confirmList);
        this.loaded = true;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getConfirmData(
    _function: string,
    _detail: boolean,
    _full: boolean
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dataService.getConfirm(_function, _detail).subscribe({
        next: (response: any) => {
          const data = response;
          // Filter
          if (!_full) {
            // Get the list array
            const assignments = data.content.list;
            const filteredAssignments = assignments.filter(
              (o: any) => o.status == '0'
            );
            data.content.list = filteredAssignments;
          }
          // Add selected property to list members
          data.content.list.forEach((o: any) => (o.selected = false));
          // console.log(data);
          resolve(data);
        },
        error: (error: Error) => {
          // console.error(error);
          this.error = true;
          this.errorObj = error;
          reject(error);
        },
      });
    });
  }

  selectAssignment(index: number): void {
    this.currentAssignment = this.confirmList.content.list[index];
    this.currentIndex = index;
    // console.log(this.currentAssignment);
    this.resetSelected();
    this.currentAssignment.selected = true;
    switch (this.currentAssignment.data.content.extrainfo.CompanyName) {
      case 'Alfa Laval Corporate AB':
        this.customer = 'ALFALAVAL';
        break;
      case 'Tetra laval':
        this.customer = 'TETRALAVAL';
        break;
      default:
        // console.log('Unknown customer');
        break;
    }
  }

  toggleIncludeAll(): void {
    this.includeAll = !this.includeAll;
    // Reload from API
    this.getConfirmData('ExBook', false, this.includeAll)
      .then((res) => {
        this.confirmList = res;
        this.sortOnAssigneeName();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  resetSelected() {
    this.confirmList.content.list.forEach((o: any) => (o.selected = false));
  }

  getCountryName(countryCode: string): string {
    for (const country of this.countries) {
      if (country.countrycode === countryCode) {
        return country.countryname;
      }
    }
    return 'Unknown country';
  }

  getCompanyName(companyCode: string): string {
    for (const company of this.allCompanies) {
      if (company.companynumber === companyCode) {
        return company.companyname;
      }
    }
    return 'Unknown company';
  }

  translateStatus(statuscode: number): string {
    let statustext = '';
    switch (statuscode) {
      case 0:
        statustext = 'Pending';
        break;
      case 5:
        statustext = 'Approved';
        break;
      case 10:
        statustext = 'Rejected';
        break;
      default:
        statustext = 'Unknown';
        break;
    }

    return statustext;
  }

  sortOnAssigneeName(): void {
    if (!this.sort_aname) {
      // console.log('Sorting ASC');
      this.confirmList.content.list.sort((a: any, b: any) => a.data.content.assigneeLastName > b.data.content.assigneeLastName ? 1 : -1);
    }
    if (this.sort_aname) {
      // console.log('Sorting DESC');
      this.confirmList.content.list.sort((a: any, b: any) => a.data.content.assigneeLastName > b.data.content.assigneeLastName ? -1 : 1);
    }
    this.sort_aname = !this.sort_aname;
  }

  sortOnMove(): void {
    if (!this.sort_move) {
      // console.log('Sorting ASC');
      this.confirmList.content.list.sort((a: any, b: any) => a.data.content.orgdata.orgCountry > b.data.content.orgdata.orgCountry ? 1 : -1);
    }
    if (this.sort_move) {
      // console.log('Sorting DESC');
      this.confirmList.content.list.sort((a: any, b: any) => a.data.content.orgdata.orgCountry > b.data.content.orgdata.orgCountry ? -1 : 1);
    }
    this.sort_move = !this.sort_move;
  }

  sortOnMoveOwner(): void {
    if (!this.sort_moveowner) {
      // console.log('Sorting ASC');
      this.confirmList.content.list.sort((a: any, b: any) => a.data.content.extrainfo.moveOwner > b.data.content.extrainfo.moveOwner ? 1 : -1);
    }
    if (this.sort_moveowner) {
      // console.log('Sorting DESC');
      this.confirmList.content.list.sort((a: any, b: any) => a.data.content.extrainfo.moveOwner > b.data.content.extrainfo.moveOwner ? -1 : 1);
    }
    this.sort_moveowner = !this.sort_moveowner;
  }

  sortOnStatus(): void {
    // console.log(this.confirmList.content.list[0]);
    // console.log(typeof this.confirmList.content.list[0].status);
    if (!this.sort_status) {
      // console.log('Sorting ASC');
      this.confirmList.content.list.sort((a: any, b: any) => a.status.toString().localeCompare(b.status.toString()));
    }
    if (this.sort_status) {
      // console.log('Sorting DESC');
      this.confirmList.content.list.sort((a: any, b: any) => b.status.toString().localeCompare(a.status.toString()));
    }
    this.sort_status = !this.sort_status;
  }

  removeAssignment(): void {
    // console.log('Going to remove element with id ', id);
    // console.log('Current index is ', this.currentIndex);
    this.confirmList.content.list.splice(this.currentIndex, 1);
    this.currentAssignment = null;
  }

  setPostCommand(cmd: any): void {
    // console.log('Received postCommand ', cmd);
    switch(cmd) {
      case "MOVE_OWNER_CHANGED":
        if (Object.prototype.hasOwnProperty.call(this.confirmList.content.list[this.currentIndex].data.metaData, 'postCommand')) {
          this.confirmList.content.list[this.currentIndex].data.metaData.postCommand['sendConfirmMail'] = true;
        } else {
          const obj = {
            sendConfirmMail: true
          };
          this.confirmList.content.list[this.currentIndex].data.metaData['postCommand'] = obj;
        }
        break;
      case "CLEAR_CONFIRM_MAIL":
        if (Object.prototype.hasOwnProperty.call(this.confirmList.content.list[this.currentIndex].data.metaData, 'postCommand')) {
          if (Object.prototype.hasOwnProperty.call(this.confirmList.content.list[this.currentIndex].data.metaData.postCommand, 'sendConfirmMail')) {
            delete this.confirmList.content.list[this.currentIndex].data.metaData.postCommand.sendConfirmMail;
          }
        }
        break;
      default:
        break;
    }
  }
}
