import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';

import { Country } from '../models/country';

import { countries } from '../../data/countries';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private countries$: Observable<Country[]> = of(countries);

  getCountries(): Observable<Country[]> {
    return this.countries$;
  }

}
