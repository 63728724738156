<div class="assignment-detail-container" *ngIf="!loading">
  <div class="assignment-detail-header">
    Details for assignment {{ id }}
  </div>
  <div class="assignment-details">
    <div class="row">

 <!-- 
      <div class="common-detail-formgroup">
        <div class="assignment-formgroup-header"><h2>Assignee</h2></div>
        <hr>
       
        <div style="white-space: nowrap">
          <label class="assignment-detail-label" for="assigneeid">Assignee ID:</label>
          <input class="assignment-detail-input" type="text" name="assigneeid" [value]="detail.assignee.id" [readonly]="true" />
        </div>
        
        <div style="white-space: nowrap">
          <label class="assignment-detail-label" for="assigneename">Assignee name:</label>
          <input class="assignment-detail-input" type="text" name="assigneename" [value]="detail.assignee.completeName" [readonly]="true"/>
        </div>
        
        <div style="white-space: nowrap">
          <label class="assignment-detail-label" for="gender">Gender:</label>
          <input class="assignment-detail-input" type="text" name="gender" [value]="detail.assignee.gender" [readonly]="true"/>
        </div>
        
        <div style="white-space: nowrap">
          <label class="assignment-detail-label" for="dob">Date of birth:</label>
          <input class="assignment-detail-input" type="text" name="dob" [value]="detail.assignee.dateOfBirth" [readonly]="true"/>
        </div>
        
        <div style="white-space: nowrap">
          <label class="assignment-detail-label" for="gender">Nationality:</label>
          <input class="assignment-detail-input" type="text" name="gender" [value]="detail.assignee.nationality" [readonly]="true"/>
        </div>
        
        <div style="white-space: nowrap">
          <label class="assignment-detail-label" for="passportnumber">Passport #:</label>
          <input class="assignment-detail-input" type="text" name="passportnumber" [value]="detail.assignee.passportNumber" [readonly]="true"/>
        </div>
        
        <div style="white-space: nowrap">
          <label class="assignment-detail-label" for="socialsecuritynumber">Social sec. #:</label>
          <input class="assignment-detail-input" type="text" name="socialsecuritynumber" [value]="detail.assignee.socialSecurityNumber" [readonly]="true"/>
        </div>
      </div> -->

      <!-- Valuation forms -->
<!--       <div class="valuationform-detail-formgroup">
        <div class="assignment-formgroup-header"><h2>Valuation forms</h2></div>
        <hr>
        <div  *ngFor="let vform of detail.valuationforms; let i = index;">
          <table>
            <thead>
              <tr>
                <th >ID</th>
                <th >Total value</th>
                <th>Currency</th>
                <th>Submit date</th>
                <th >Link</th>
              </tr>
            </thead>
            <tbody *ngFor="let vform of detail.valuationforms; let i = index;">
              <tr>
                <td>{{ vform.id }}</td>
                <td>{{ vform.totalValue }}</td>
                <td>{{ vform.currencyCode }}</td>
                <td>{{ vform.submitDate }}</td>
                <td><a href="{{ vform.valuationFormLink }}" target="_blank" rel="noopener">open</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->


      <div class="valuationform-detail-formgroup">
        <div class="assignment-formgroup-header"><h2>Assignee</h2></div>
        <hr>
        <div>
          <table class="valuation-table">
            <tr>
              <th>Assignee ID:</th>
              <td [textContent]="detail?.assignee?.id || 'Not available'"></td>
            </tr>

            <tr>
              <th>Assignee name:</th>
              <td [textContent]="detail?.assignee?.completeName || 'Not available'"></td>
            </tr>

            <tr>
              <th>Gender:</th>
              <td [textContent]="detail?.assignee?.gender || 'Not available'"></td>
            </tr>

            <tr>
              <th>Date of birth:</th>
              <td [textContent]="detail?.assignee?.dateOfBirth || 'Not available'"></td>
            </tr>

            <tr>
              <th>Nationality:</th>
              <td [textContent]="detail?.assignee?.nationality || 'Not available'"></td>
            </tr>

            <tr>
              <th>Passport #:</th>
              <td [textContent]="detail?.assignee?.passportNumber || 'Not available'"></td>
            </tr>

            <tr>
              <th>Social sec. #:</th>
              <td [textContent]="detail?.assignee?.socialSecurityNumber || 'Not available'"></td>
            </tr>
              

          </table>
        </div>
      </div>

      <!-- VALUATION -->

      <div class="valuationform-detail-formgroup">
        <div class="assignment-formgroup-header"><h2>Valuation Test</h2></div>
        <hr>
        <div *ngFor="let vform of detail.valuationforms; let i = index;">
          <table class="valuation-table">
            <tr>
              <th>ID</th>
              <td>{{ vform.id }}</td>
            </tr>

            <tr>
              <th>Total value</th>
              <td>{{ vform.totalValue }}</td>
            </tr>

            <tr>
              <th>Currency</th>
              <td>{{ vform.currencyCode }}</td>
            </tr>

            <tr>
              <th>Sumbit date</th>
              <td>{{ vform.submitDate }}</td>
            </tr>

            <tr>
              <th>Link</th>
              <td><a href="{{ vform.valuationFormLink }}" target="_blank" rel="noopener">open</a></td>
            </tr>
              

          </table>
        </div>
      </div>

      <!-- Claim forms -->
      <!--
      <div class="valuationform-detail-formgroup">
        <div class="assignment-formgroup-header">Valuation forms</div>
        <div style="white-space: nowrap" *ngFor="let vform of detail.valuationforms; let i = index;">
          <table>
            <thead>
              <tr>
                <th style="width: 30%;">ID</th>
                <th style="width: 20%;">Total value</th>
                <th style="width: 20%;">Currency</th>
                <th style="width: 20%;">Submit date</th>
                <th style="width: 10%;">Link</th>
              </tr>
            </thead>
            <tbody *ngFor="let vform of detail.valuationforms; let i = index;">
              <tr>
                <td>{{ vform.id }}</td>
                <td>{{ vform.totalValue }}</td>
                <td>{{ vform.currencyCode }}</td>
                <td>{{ vform.submitDate }}</td>
                <td><a href="{{ vform.valuationFormLink }}" target="_blank" rel="noopener">open</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    -->

    </div>
  </div>
</div>

