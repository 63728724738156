import { createSelector } from '@ngrx/store';
// import { CompanyState } from '../reducers/company.reducer';
import { Company } from '../../models/company';

export const selectCompanies = (state: any) => {
  // console.log('State: ', state);
  return state.companies;
}

/* Entire state */
export const selectAllCompanies = createSelector(
  selectCompanies,
  (state: any) => state.companies
);

export const selectCompanyByNumber = (companyNumber: string) =>
  createSelector(selectAllCompanies, (companies: Company[]) => {
    let companyName = '';
    for (let ix = 0; ix < companies.length; ix++) {
      if (companies[ix].companynumber === companyNumber) {
        companyName = companies[ix].companyname;
        break;
      }
    }
    return companyName as string;
  });

  export const selectCompaniesByCountry = (countryCode: string) =>
  createSelector(selectAllCompanies, (companies: Company[]) => {
    // console.log('Country in: ', countryCode);
    const newCompanies: Company[] = [];
    for (let ix = 0; ix < companies.length; ix++) {
      if (companies[ix].countrycode === countryCode) {
        // console.log('match');
        newCompanies.push(companies[ix]);
      }
    }
    return newCompanies as Company[];
  });

  export const selectCompaniesByCountryAndGroup = (countryCode: string, groupCode) =>
  createSelector(selectAllCompanies, (companies: Company[]) => {
    // console.log('Country in: ', countryCode);
    // console.log('.. and group: ', groupCode);
    const newCompanies: Company[] = [];
    for (let ix = 0; ix < companies.length; ix++) {
      if ((companies[ix].countrycode === countryCode) && (companies[ix].groupcode === groupCode)) {
        // console.log('match');
        newCompanies.push(companies[ix]);
      }
    }
    return newCompanies as Company[];
  });
