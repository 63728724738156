import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { _store } from '../../localstore/localstore';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  myStore = new _store();

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.myStore.remove('user');
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 3000);
  }

}
