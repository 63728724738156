import { Component, OnInit } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-university-of-helsinki',
  templateUrl: './university-of-helsinki.component.html',
  styleUrls: ['./university-of-helsinki.component.scss']
})
export class UniversityOfHelsinkiComponent implements OnInit {

  content:Content;
  extrainfo:Extrainfo;

  constructor(
    private messageService: MessageService,
    private formDataService : FormDataService,
    private formConfig : FormConfig
  ) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.extrainfo.CompanyName = 'University of Helsinki';
    this.content.SokID = '33178';
    this.extrainfo.CompanyAddress = 'Fabianinkatu 33';
    this.extrainfo.CompanyCity = 'Helsingin Yliopisto';
    this.extrainfo.CompanyCountry = 'FI';
    this.extrainfo.CompanyZipCode = '00014';
    this.extrainfo.Avtal = '';
    this.extrainfo.language = 'en';

    //TODO confirm approval should be true below.
    this.formConfig.confirm = false; //Determines if form need approval by manager

    //Form configuration
    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'uoh';
  }

  ngOnInit(): void {
    this.messageService.sendMessage(1);
  }


}
