<div>
    <h3>Insurance</h3>
    <hr />
    
    <p>
      All shipments and storage portions are insured through Alfa. A valuation
      form will be sent to you by our move mangers upon confirming your move. It
      is very important that this form is filled in and returned prior to packing.
    </p>
  
    <h3>Other details</h3>
    <hr />
  
    <div *ngIf="bussiness.PolicyType == 'SLTA'">
      <h4>Storage</h4>
      <p>
        Please note! According to the Ericsson policy, Ericsson will pay verified
        storage costs, approved in advance, for normal household goods remaining
        in the Home Country during the Assignment and for a maximum of three
        months after its termination. Delivery from storage to your residence is
        covered by Ericsson if done at the latest five months after termination of
        the LTA.
      </p>
  
      <h4>
        Do you have a current storage portion at destination that shall be
        delivered upon your arrival?
      </h4>
  
      <label class="radio">
        <input
          type="radio"
          value="yes"
          name="inStorage"
          [(ngModel)]="extrainfo.InStorage"
        />
        <span>Yes</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="no"
          name="inStorage"
          [(ngModel)]="extrainfo.InStorage"
        />
        <span>No</span> </label
      >
  
      <h4>Do you have any goods to be placed in long term storage?*</h4>
  
      <label class="radio">
        <input
          type="radio"
          value="yes"
          name="toStorage"
          [(ngModel)]="extrainfo.ToStorage"
          #toStorage="ngModel"
          required
        />
        <span>Yes</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="no"
          name="toStorage"
          [(ngModel)]="extrainfo.ToStorage"
          #toStorage="ngModel"
          required
        />
        <span>No</span> </label
      >
  </div>

    <h4>Shipment to UNFURNISHED residence</h4>

    <div
      *ngIf="
        ericssonExceptions.includes(orgdata.orgCountry) ||
          ericssonExceptions.includes(destdata.destCountry);
        else elseBlock
      "
    >
      <!--If specific countries in ericssonExceptions matches chosen move to/from-->
  
     
      <label class="radio">
        <input
          type="radio"
          value="unfurnished Single person 10cbm"
          name="shipment"
          [(ngModel)]="extrainfo.Shipment"
        />
        <span>Single person (10 cbm)</span> </label
      >
  
     
      <label class="radio">
        <input
          type="radio"
          value="unfurnished Accompanied family 15cbm"
          name="shipment"
          [(ngModel)]="extrainfo.Shipment"
        />
        <span>Accompanied family (15 cbm)</span> </label
      >
    </div>
    <!--------------------------------------------------------------------------->
    <ng-template #elseBlock>
      <!--If they dont match-->
    
      <label class="radio">
        <input
          type="radio"
          value="unfurnished Single person 15cbm"
          name="shipment"
          [(ngModel)]="extrainfo.Shipment"
        />
        <span>Single person (15 cbm)</span> </label
      >
  
      
      <label class="radio">
        <input
          type="radio"
          value="unfurnished Accompanied family 20cbm"
          name="shipment"
          [(ngModel)]="extrainfo.Shipment"
        />
        <span>Accompanied family (20 cbm)</span> </label
      >
    </ng-template>
  
   
    <label class="radio">
      <input
        type="radio"
        value="unfurnished Accompanied family with one child 24cbm"
        name="shipment"
        [(ngModel)]="extrainfo.Shipment"
      />
      <span>Accompanied family with one child (24 cbm)</span> </label
    >
  

    <label class="radio">
      <input
        type="radio"
        value="unfurnished Accompanied family with two children 28cbm"
        name="shipment"
        [(ngModel)]="extrainfo.Shipment"
      />
      <span>Accompanied family with two children (28 cbm)</span> </label
    >
  

    <label class="radio">
      <input
        type="radio"
        value="unfurnished Accompanied family with three children 32cbm"
        name="shipment"
        [(ngModel)]="extrainfo.Shipment"
      />
      <span>Accompanied family with three children (32 cbm)</span> </label
    >
  
    <h4>Shipment to FURNISHED residence</h4>
  

    <label class="radio">
      <input
        type="radio"
        value="furnished 3cbm Single person"
        name="shipment"
        [(ngModel)]="extrainfo.Shipment"
      />
      <span>Single person (3 cbm)</span> </label
    >
  

    <label class="radio">
      <input
        type="radio"
        value="furnished 5cbm Accompanied family"
        name="shipment"
        [(ngModel)]="extrainfo.Shipment"
      />
      <span>Accompanied family (5 cbm)</span> </label
    >
  
  
    <label class="radio">
      <input
        type="radio"
        value="furnished 6cbm Accompanied family with one child"
        name="shipment"
        [(ngModel)]="extrainfo.Shipment"
      />
      <span>Accompanied family with one child (6 cbm)</span> </label
    >
  

    <label class="radio">
      <input
        type="radio"
        value="furnished 7cbm Accompanied family with two children"
        name="shipment"
        [(ngModel)]="extrainfo.Shipment"
      />
      <span>Accompanied family with two children (7 cbm)</span> </label
    >
  
   
    <label class="radio">
      <input
        type="radio"
        value="furnished 8cbm Accompanied family with three children"
        name="shipment"
        [(ngModel)]="extrainfo.Shipment"
      />
      <span>Accompanied family with three children (8 cbm)</span> </label
    >

    <h4>Please select if any children will be accompanying</h4>
  
   
    <label class="icontainer label-children">
      <select
        name="inputChildren"
        class="iselect"
        [(ngModel)]="extrainfo.NumberOfChildren"
        #inputChildren="ngModel"
        required
      >
        <option value="none">No kids</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6 or more">6 or more</option>
      </select>
      <span
        class="itext"
        [ngClass]="{ 'itext-select': extrainfo.NumberOfChildren != undefined }"
      >
        Number of children
      </span>
    </label>
  
    <label for="textbox"><h4>Other info</h4></label>
    <textarea
      class="itextfield"
      name="otherDetails"
      id="textbox"
      cols="30"
      rows="10"
      [(ngModel)]="extrainfo.OtherInfo"
      placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
    >
    </textarea>
  
    <div class="button-container">
      
      <div *ngIf="bussiness.PolicyType == 'SLTA'">
        <!--Button with validation for SLTA -->
        
        <input
          class="button btn-form"
          type="button"
          value="Back"
          routerLink="../movedetailsto"
        />
        <input
          [disabled]="extrainfo.ToStorage == undefined"
          class="button btn-form"
          type="button"
          value="Submit"
          (click)="formData.formSubmit()"
        />
      </div>
  
      <div *ngIf="bussiness.PolicyType != 'SLTA'">
     
      <input
        class="button btn-form"
        type="button"
        value="Back"
        routerLink="../movedetailsto"
      /> 
      <input
        class="button btn-form"
        type="button"
        value="Submit"
        (click)="formData.formSubmit()"
      />
    </div>
    </div>
  </div>