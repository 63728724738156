import { Action, createReducer, on } from '@ngrx/store';
import { fetchDepartments, fetchDepartmentsSuccess, fetchDepartmentsError } from '../actions';

import { UDdepartment } from '../../models/ud-department';

const initialDepartments: UDdepartment[] = [];

export interface DepartmentState {
    departments: UDdepartment[];
}

export const initialDepartmentState: DepartmentState = {
  departments: initialDepartments
}

export const departmentReducer = createReducer(
  initialDepartmentState,
  on(fetchDepartments, (state: DepartmentState) => {
    return state;
  }),
  on(fetchDepartmentsSuccess, (state: DepartmentState, action: any) => {
    return {
      ...state,
      departments: action.payload,
    };
  }),
  on(fetchDepartmentsError, (state: DepartmentState, action: any) => {
    return {
      ...state,
      departments: action.payload.error,
    };
  }),
);
