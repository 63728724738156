import { Component } from '@angular/core';
import { FormDataService } from 'src/app/services/form-data.service';
import { FormConfig } from 'src/app/services/form-config';
import { Content, Extrainfo } from 'src/app/models/form/book-data';

@Component({
  selector: 'app-alfalaval',
  templateUrl: './alfalaval.component.html',
  styleUrls: ['./alfalaval.component.scss'],
})
export class AlfalavalComponent {
  extrainfo: Extrainfo;
  content: Content;

  constructor(
    private formDataService: FormDataService,
    private formConfig: FormConfig
  ) {}

  ngOnInit() {
    // console.log('Initialize alfalaval component');
    this.formDataService.reset();

    this.content = this.formDataService.content;
    this.extrainfo = this.formDataService.extrainfo;

    //pre-assigned values for form.
    this.extrainfo.CompanyName = 'Alfa Laval Corporate AB';
    this.content.SokID = '16373';
    this.extrainfo.CompanyContact = '';
    this.extrainfo.CompanyEmail = 'AL@ipmglobal.com';
    this.extrainfo.CompanyAddress = 'Box 74';
    this.extrainfo.CompanyCity = 'Lund';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = '221 00';
    this.extrainfo.insurance = '';
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';

    this.formConfig.confirm = true;

    //Config
    this.formConfig.formDisabled = true;
    // this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'alfalaval';

    // this.formDataService.dump();
  }
}
