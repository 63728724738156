import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Content,
  Dateinfo,
  Destdata,
  Extrainfo,
  Orgdata,
  Surveyinfo,
} from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.scss'],
})
export class OthersComponent {
  orgdata: Orgdata;
  surveyinfo: Surveyinfo;
  destdata: Destdata;
  content: Content;
  dateinfo: Dateinfo;
  extrainfo: Extrainfo;

  country: string;

  accepted = false;

  constructor(
    private route: ActivatedRoute,
    private formDataService: FormDataService,
    public formData: FormDataService,
    private formConfig : FormConfig
  ) {
    this.surveyinfo = formDataService.surveyinfo;
    this.destdata = formDataService.destdata;
    this.orgdata = formDataService.orgdata;
    this.content = formDataService.content;
    this.dateinfo = formDataService.dateinfo;
    this.extrainfo = formDataService.extrainfo;

        //preassigned

        this.content.SokID = '00000';
        this.formConfig.office = "0";

        //Not sure if this was necessary
        this.extrainfo.Legal = true;
        this.extrainfo.lockCompany = true;


        this.formConfig.confirm = false; //Determines if form need approval by manager
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        // console.log(params);
        this.country = params.country;
        // console.log('Country =', this.country);
      }
    );
  }
}
