import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';

import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-absolut',
  templateUrl: './absolut.component.html',
  styleUrls: ['./absolut.component.scss']
})
export class AbsolutComponent {

  content: Content;
  extrainfo : Extrainfo;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;

    this.content.SokID = '33610';
    this.extrainfo.CompanyName = 'The Absolut Company AB';
    this.extrainfo.CompanyEmail = 'anna.mondway@pernod-ricard.com';
    this.extrainfo.CompanyContact = '08-7447410';
    this.extrainfo.CompanyAddress = 'Fack 170001';
    this.extrainfo.CompanyCity = 'Stockholm';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = '106 37';
    this.extrainfo.insurance = 'All shipments and storage portions are insured through Alfa. A valuation form will be sent to you by our move mangers upon confirming your move. It is very important that this form is filled in and returned prior to packing.'
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';

    this.formConfig.confirm = false;

    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'absolut';
  }

}
