import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { Assignment } from 'src/app/models/assignment';

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss']
})
export class AssignmentComponent implements OnInit {

  @Input()
  assignment!: Assignment;
  fromCountryClass = '';
  toCountryClass = '';

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.fromCountryClass = `flag-icon flag-icon-${this.assignment.fromCountry.toLocaleLowerCase()} flag-icon-squared` ;
    this.toCountryClass = `flag-icon flag-icon-${this.assignment.toCountry.toLocaleLowerCase()} flag-icon-squared` ;
  }

  fetchDetail(): void {
    this.getDataDetail('assignment', true, this.assignment.id);
  }

  getDataDetail(_function: string, _detail: boolean, _id: string) {
    this.dataService.getDataDetail(_function, _detail, _id).subscribe({
      next: (response: any) => {
        console.log(response);
      },
      error: (error: Error) => {
        console.error(error);
        // this.assignments = [];
      }
    });
  }

}
