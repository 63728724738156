import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-ef',
  templateUrl: './ef.component.html',
  styleUrls: ['./ef.component.scss']
})
export class EfComponent {

  extrainfo:Extrainfo;
  content:Content;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned values for CompanyDetails
    this.content.SokID = '11548'
    this.extrainfo.CompanyName = 'EF';
    this.extrainfo.CompanyAddress = ' ';
    this.extrainfo.CompanyCity = ' ';
    this.extrainfo.CompanyCountry = ' ';
    this.extrainfo.CompanyZipCode = ' ';
    this.extrainfo.insurance = ' ';
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';

    //requires approval?
    this.formConfig.confirm = false;

    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'EF';
  }

}
