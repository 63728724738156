<div class="login-container">
    <div class="login-form" *ngIf="!error">
      <div class="login-header">Please log in</div>
      <form [formGroup]="loginForm">
        <div class="login-info">
          <!--Username-->
          <label class="icontainer" for="username">
            <input
              placeholder="&nbsp;"
              class="ifield"
              type="text"
              formControlName="username"
            />
            <span class="itext">Username</span>
          </label>

          <!--Password-->
          <label class="icontainer" for="password">
            <input
              placeholder="&nbsp;"
              class="ifield"
              type="password"
              formControlName="password"
            />
            <span class="itext">Password</span>
          </label>
        </div>
        <div class="button-container">
          <button class="btn-form button" id="login-button" (click)="login()">
            Log in
          </button>
          <span id="forgot-password" (click)="forgotPassword()"
            >Forgot password?</span
          >
        </div>
      </form>
  </div>
  <div *ngIf="error">
    <app-error [errorObj]="errorObj"></app-error>
  </div>
</div>
