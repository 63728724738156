import { Component} from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-seb',
  templateUrl: './seb.component.html',
  styleUrls: ['./seb.component.scss']
})
export class SebComponent  {


  extrainfo : Extrainfo;
  content : Content;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.extrainfo.CompanyName = 'SEB Accounts Payable';
    this.content.SokID = '15165';
    this.extrainfo.CompanyAddress = ' ';
    this.extrainfo.CompanyCity = 'Stockholm';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = '106 40';
    this.extrainfo.CompanyContact = '08-7638000';
    this.extrainfo.CompanyEmail = 'AccountsPayableSEB@seb.se';
    this.extrainfo.insurance = 'All shipments and storage portions are insured through Alfa. A valuation form will be sent to you by our move manager upon confirming your move. It is very important that this form is completed and returned prior to packing.'
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = 'en'; 

    this.formConfig.confirm = false; //Determines if form need approval by manager

    //Form configuration
    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'SEB';
  }

}
