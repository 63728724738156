<div class="profile-container">
  <h3>Your profile</h3>
  <hr>
  <form [formGroup]="profileForm">
    <div class="form-container profile-form">
    <div class="profile-pic-section">
      <div class="profile-form-image">
        <img
          #imageRef
          [src]="profileImage"
          class="img-fluid rounded placeholder-image"
          alt="Profile image"
          width="200"
          height="200"
          onerror="this.src='../../../assets/image/default-picture.jpg'"
        />
      </div>
      <div class="profile-image-upload">
        <span style="color:red;" *ngIf="message">{{message}}</span>
        <!-- Upload profile pic button-->
        <label class="uploadPicBtn">
        <input class="uploadFile" #file type="file" accept='image/*' (change)="loadImage(file.files)" />
        <!-- <img [src]="imgURL" height="200" *ngIf="imgURL"> -->
        Upload picture
      </label>
      </div>
    </div>
        
          <!-- userDisplayName -->
          <label class="icontainer" for="userDisplaName">
          <input
            type="text"
            placeholder="&nbsp;"
            id="userDisplayName"
            name="userDisplayName"
            class="ifield"
            formControlName="userDisplayName"
          />
        <span class="itext">Display name</span>
        </label>

          <!-- userEmail -->
          <label class="icontainer" for="userEmail">
          <input
            type="email"
            id="userEmail"
            name="userEmail"
            placeholder="&nbsp;"
            class="ifield"
            formControlName="userEmail"
          />
          <span class="itext">Email</span>
        </label>

<!-- 
          <label class="icontainer" for="userEmail">Email:
            <input
              type="email"
              id="userEmail"
              name="userEmail"
              class="form-control"
              formControlName="userEmail"
            /></label> -->


          <!-- password -->
          <label class="icontainer" for="password">
          <input
            placeholder="&nbsp;"
            type="password"
            id="password"
            name="password"
            class="ifield"
            formControlName="password"
          /> 
          <span class="itext">Password</span>
          </label>

          <label class="icontainer" for="password2">
          <input
            placeholder="&nbsp;"
            type="password"
            id="password2"
            name="password2"
            class="ifield"
            formControlName="password2"
          />
          <span class="itext">Password again</span>
          </label>


          <div id="success-msg" class="alert alert-success" *ngIf="success">{{ successMsg }}</div>
          <div id="alert-msg" class="alert alert-danger" *ngIf="alert">{{ alertMsg }}</div>
      </div>
        <div class="button-container">
          <input
            *ngIf="profileForm.controls['userEmail'].valid"
            type="button"
            class="button btn-form"
            (click)="submitForm()"
          value="SAVE"
          >
          <input
            *ngIf="profileForm.controls['userEmail'].invalid"
            type="button"
            class="button btn-form"
            disabled
            aria-disabled="true"
            value="SAVE"
            >
          <button type="button" class="button btn-form">CANCEL</button>
        </div>
  </form>
  <!-- <p>Form Status: {{ profileForm.status }}</p>
  <p>userEmail: {{ profileForm.controls['userEmail'].status }}</p> -->
</div>
