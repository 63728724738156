import { Component } from '@angular/core';
import { Extrainfo, Orgdata } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-sida-other-details',
  templateUrl: './sida-other-details.component.html',
  styleUrls: ['./sida-other-details.component.scss']
})
export class SidaOtherDetailsComponent {


  extrainfo:Extrainfo;
  orgdata:Orgdata;
  
constructor (
  private formDataService : FormDataService,  
  public formData : FormDataService  

  ) 
  {

  this.extrainfo = formDataService.extrainfo;
  this.orgdata = formDataService.orgdata;
}


}
