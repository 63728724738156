<div>
    <!-- UD SECTION -->
  
    <h3>Insurance</h3>
    <hr />
  
    <!--   <p>Please follow the instructions from Kammarkollegiet regarding
          the insurance of the household goods. For any upcoming questions, please 
          contact Kammarkollegiet directly. 
      </p> -->
    <p>
      I have taken part of UD:s and Kammarkollegiets information regarding the
      insurance of goods. I am aware that UD covers an insurance value of up to
      800 000 SEK and that you will have to cover any cost above that yourself
      including additional insurances.*
    </p>

    <label class="checkbox">
        <input
          type="checkbox"
          name="iAccept"
          value="yes"
          [(ngModel)]="extrainfo.iAccept"
          #iAccept="ngModel"
          required
        />
        <span>I understand</span> </label>
  
    <h3>Other details</h3>
    <hr />
  
    <div class="radio-container">
      <h4>Do you have goods stored that you wish to include in the move?</h4>
      
      <label class="radio">
        <input
          type="radio"
          value="Yes"
          name="InStorage"
          [(ngModel)]="extrainfo.InStorage"
        />
        <span>Yes</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="No"
          name="InStorage"
          [(ngModel)]="extrainfo.InStorage"
        />
        <span>No</span> </label
      >
  
      <h4>
        Do you wish for part of your move goods to be stored during your stay
        abroad?
      </h4>
      
      <label class="radio">
        <input
          type="radio"
          value="Yes"
          name="ToStorage"
          [(ngModel)]="extrainfo.ToStorage"
        />
        <span>Yes</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="No"
          name="ToStorage"
          [(ngModel)]="extrainfo.ToStorage"
        />
        <span>No</span> </label
      >
  
      <h4>
        Will you be staying in furnished accomdations abroad? (Don't fill for home
        stationing)
      </h4>
      
      <label class="radio">
        <input
          type="radio"
          value="Yes"
          name="Furnished"
          [(ngModel)]="extrainfo.Furnished"
        />
        <span>Yes</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="No"
          name="Furnished"
          [(ngModel)]="extrainfo.Furnished"
        />
        <span>No</span> </label
      >
    </div>
  
    <label for="textbox"><h4>Other info</h4></label>
    <textarea
      class="itextfield"
      name="otherDetails"
      id="textbox"
      cols="30"
      rows="10"
      [(ngModel)]="extrainfo.OtherInfo"
      placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
    >
    </textarea>
  
   
    <div class="button-container">
      
      <input
        class="button btn-form"
        type="button"
        value="Back"
        routerLink="../movedetailsto"
      />
      <input
        [disabled]="!iAccept.valid"
        class="button btn-form"
        type="button"
        value="Submit"
        (click)="formData.formSubmit()"
      />
    </div>
  </div>