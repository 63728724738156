import { Component } from '@angular/core';
import { Extrainfo, Volume } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-seb-other-details',
  templateUrl: './seb-other-details.component.html',
  styleUrls: ['./seb-other-details.component.scss']
})
export class SebOtherDetailsComponent {

  extrainfo:Extrainfo;
  volume:Volume;

constructor (
  private formDataService : FormDataService ,  
  public formData : FormDataService
  ) 
  {

  this.extrainfo = formDataService.extrainfo;
  this.volume = formDataService.volume;

}

}
