import { createSelector } from '@ngrx/store';
// import { CompanyState } from '../reducers/company.reducer';
import { UDdepartment } from '../../models/ud-department';

export const selectDepartments = (state: any) => {
  // console.log('State: ', state);
  return state.departments;
}

/* Entire state */
export const selectAllDepartments = createSelector(
  selectDepartments,
  (state: any) => state.departments
);

export const selectDepartmentByID = (id: number) =>
  createSelector(selectAllDepartments, (departments: UDdepartment[]) => {
    let d = {};
    for (let ix = 0; ix < departments.length; ix++) {
      if (departments[ix].id === id) {
        d = departments[ix];
        break;
      }
    }
    return d as UDdepartment;
  });

  export const selectDepartmentByName = (name: string) =>
  createSelector(selectAllDepartments, (departments: UDdepartment[]) => {
    let d = {};
    for (let ix = 0; ix < departments.length; ix++) {
      if (departments[ix].departmentName === name) {
        d = departments[ix];
        break;
      }
    }
    return d as UDdepartment;
  });

  // export const selectCompaniesByCountry = (countryCode: string) =>
  // createSelector(selectAllCompanies, (companies: Company[]) => {
  //   // console.log('Country in: ', countryCode);
  //   const newCompanies: Company[] = [];
  //   for (let ix = 0; ix < companies.length; ix++) {
  //     if (companies[ix].countrycode === countryCode) {
  //       // console.log('match');
  //       newCompanies.push(companies[ix]);
  //     }
  //   }
  //   return newCompanies as Company[];
  // });

  // export const selectCompaniesByCountryAndGroup = (countryCode: string, groupCode) =>
  // createSelector(selectAllCompanies, (companies: Company[]) => {
  //   // console.log('Country in: ', countryCode);
  //   // console.log('.. and group: ', groupCode);
  //   const newCompanies: Company[] = [];
  //   for (let ix = 0; ix < companies.length; ix++) {
  //     if ((companies[ix].countrycode === countryCode) && (companies[ix].groupcode === groupCode)) {
  //       // console.log('match');
  //       newCompanies.push(companies[ix]);
  //     }
  //   }
  //   return newCompanies as Company[];
  // });
