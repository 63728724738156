import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxCaptchaModule } from "ngx-captcha";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgHttpLoaderModule } from "ng-http-loader";
import { NgbModule, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { CustomHttpInterceptor } from "../app/interceptors/interceptor";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { DatePipe } from "@angular/common";

import { userReducer } from "./store/reducers/user.reducer";
import { countryReducer } from "./store/reducers/country.reducer";
import { companyReducer } from "./store/reducers/company.reducer";
import { departmentReducer } from "./store/reducers/ud-department.reducer";
import { CountryEffects } from "./store/effects/country.effects";
import { CompanyEffects } from "./store/effects/company.effects";
import { DepartmentEffects } from "./store/effects/ud-department.effects";

// import { environment } from '../environments/environment';

import { EndFormComponent } from "./components/bookingform/end-form/end-form.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BaseLayoutComponent } from "./components/base-layout/base-layout.component";
import { SiteLayoutComponent } from "./components/site-layout/site-layout.component";
import { LoginComponent } from "./components/login/login.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";
import { PageNotFoundComponent } from "./components/pagenotfound/pagenotfound.component";
import { HomeComponent } from "./components/home/home.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { AssignmentListComponent } from "./components/assignment-list/assignment-list.component";
import { AssignmentComponent } from "./components/assignment-list/subcomponents/assignment/assignment.component";
import { AssignmentDetailComponent } from "./components/assignment-list/subcomponents/assignment-detail/assignment-detail.component";
import { Assignment2ListComponent } from "./components/assignment2-list/assignment2-list.component";
import { Assignment2Component } from "./components/assignment2-list/subcomponents/assignment2/assignment2.component";
import { Assignment2DetailComponent } from "./components/assignment2-list/subcomponents/assignment2-detail/assignment2-detail.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { UploadComponent } from "./components/upload/upload.component";
import { NyListaComponent } from "./components/ny-lista/ny-lista.component";
import { ResetpasswordComponent } from "./components/resetpassword/resetpassword.component";
import { CompanyDetailsComponent } from "./components/bookingform/company-details/company-details.component";

import { NorthvoltComponent } from "./components/customers/northvolt/northvolt.component";
import { LegalNoticeComponent } from "./components/bookingform/legal-notice/legal-notice.component";
import { MoveDetailsFromComponent } from "./components/bookingform/move-details-from/move-details-from.component";
import { MoveDetailsToComponent } from "./components/bookingform/move-details-to/move-details-to.component";
import { OtherDetailsComponent } from "./components/bookingform/other-details/other-details.component";
import { YourDetailsComponent } from "./components/bookingform/your-details/your-details.component";
import { TetralavalComponent } from "./components/customers/tetralaval/tetralaval.component";
import { GeneralBookingComponent } from "./components/customers/general-booking/general-booking.component";
import { HmComponent } from "./components/customers/hm/hm.component";
import { EricssonComponent } from "./components/customers/ericsson/ericsson.component";
import { MigrationsverketComponent } from "./components/customers/migrationsverket/migrationsverket.component";
import { UdComponent } from "./components/customers/ud/ud.component";
import { UmdkComponent } from "./components/customers/umdk/umdk.component";
import { UdnoComponent } from "./components/customers/udno/udno.component";
import { AiresComponent } from "./components/customers/aires/aires.component";
import { HandelsbankenComponent } from "./components/customers/handelsbanken/handelsbanken.component";
import { SAABComponent } from "./components/customers/saab/saab.component";
import { SandvikComponent } from "./components/customers/sandvik/sandvik.component";
import { ScaniaComponent } from "./components/customers/scania/scania.component";
import { SebComponent } from "./components/customers/seb/seb.component";
import { SidaComponent } from "./components/customers/sida/sida.component";
import { AbsolutComponent } from "./components/customers/absolut/absolut.component";
import { EfComponent } from "./components/customers/ef/ef.component";
import { FolkeBernadotteAcademyComponent } from "./components/customers/folke-bernadotte-academy/folke-bernadotte-academy.component";
import { LundComponent } from "./components/customers/lund/lund.component";
import { OriflameComponent } from "./components/customers/oriflame/oriflame.component";
import { OhrlingsPricewaterhousecoopersComponent } from "./components/customers/ohrlings-pricewaterhousecoopers/ohrlings-pricewaterhousecoopers.component";
import { SwedbankComponent } from "./components/customers/swedbank/swedbank.component";
import { HmPolicyComponent } from "./components/customers/hm/hm-policy/hm-policy.component";
import { CevtComponent } from "./components/customers/cevt/cevt.component";
import { AbbComponent } from "./components/customers/abb/abb.component";
import { AlfalavalComponent } from "./components/customers/alfalaval/alfalaval.component";
import { MtgComponent } from "./components/customers/mtg/mtg.component";
import { UniversityOfHelsinkiComponent } from "./components/customers/university-of-helsinki/university-of-helsinki.component";
import { AlfalavalOtherDetailsComponent } from "./components/customers/alfalaval/alfalaval-other-details/alfalaval-other-details.component";
import { ScaniaCompanyDetailsComponent } from "./components/customers/scania/scania-company-details/scania-company-details.component";
import { ScaniaOtherDetailsComponent } from "./components/customers/scania/scania-other-details/scania-other-details.component";
import { MoveDetailsComponent } from "./components/customers/university-of-helsinki/move-details/move-details.component";
import { TransfereeDetailsComponent } from "./components/customers/lund/transferee-details/transferee-details.component";
import { AiresOtherDetailsComponent } from "./components/customers/aires/aires-other-details/aires-other-details.component";
import { SidaOtherDetailsComponent } from "./components/customers/sida/sida-other-details/sida-other-details.component";
import { TetralavalOtherDetailsComponent } from "./components/customers/tetralaval/tetralaval-other-details/tetralaval-other-details.component";
import { OriflameOtherDetailsComponent } from "./components/customers/oriflame/oriflame-other-details/oriflame-other-details.component";
import { MigrationsverketOtherDetailsComponent } from "./components/customers/migrationsverket/migrationsverket-other-details/migrationsverket-other-details.component";
import { EricssonOtherDetailsComponent } from "./components/customers/ericsson/ericsson-other-details/ericsson-other-details.component";
import { EfOtherDetailsComponent } from "./components/customers/ef/ef-other-details/ef-other-details.component";
import { SwedbankOtherDetailsComponent } from "./components/customers/swedbank/swedbank-other-details/swedbank-other-details.component";
import { SandvikOtherDetailsComponent } from "./components/customers/sandvik/sandvik-other-details/sandvik-other-details.component";
import { SebOtherDetailsComponent } from "./components/customers/seb/seb-other-details/seb-other-details.component";
import { HandelsbankenOtherDetailsComponent } from "./components/customers/handelsbanken/handelsbanken-other-details/handelsbanken-other-details.component";
import { SAABOtherDetailsComponent } from "./components/customers/saab/saab-other-details/saab-other-details.component";
import { CevtOtherDetailsComponent } from "./components/customers/cevt/cevt-other-details/cevt-other-details.component";
import { UdOtherDetailsComponent } from "./components/customers/ud/ud-other-details/ud-other-details.component";
import { LundOtherDetailsComponent } from "./components/customers/lund/lund-other-details/lund-other-details.component";
import { PwcOtherDetailsComponent } from "./components/customers/ohrlings-pricewaterhousecoopers/pwc-other-details/pwc-other-details.component";
import { NorthvoltOtherDetailsComponent } from "./components/customers/northvolt/northvolt-other-details/northvolt-other-details.component";
import { NeedConfirmListComponent } from "./components/need-confirm/need-confirm-list.component";
import { MatCardModule } from "@angular/material/card";
import { PolisenComponent } from "./components/customers/polisen/polisen.component";
import { PrivateBookingComponent } from "./components/finland/private-booking/private-booking.component";
import { SwedenComponent } from "./components/private/sweden/sweden.component";
import { NorwayComponent } from "./components/private/norway/norway.component";
import { DenmarkComponent } from "./components/private/denmark/denmark.component";
import { OthersComponent } from "./components/private/others/others.component";
import { FinlandComponent } from "./components/private/finland/finland.component";
import { PrivateFormComponent } from "./components/private/private-form/private-form.component";
import { PrivateLegalComponent } from "./components/private/private-legal/private-legal.component";
import { EricssonCompanyDetailsComponent } from "./components/customers/ericsson/ericsson-company-details/ericsson-company-details.component";
import { FooterComponent } from "./components/footer/footer.component";
import { AlfaLavalComponent } from "./components/need-confirm/forms/alfa-laval.component";
import { TetraLavalComponent } from "./components/need-confirm/forms/tetra-laval.component";
import { AboutComponent } from "./components/about/about.component";
import { ErrorComponent } from "./components/error/error.component";

@NgModule({
  declarations: [
    AppComponent,
    BaseLayoutComponent,
    SiteLayoutComponent,
    CompanyDetailsComponent,
    LoginComponent,
    LogoutComponent,
    UnauthorizedComponent,
    PageNotFoundComponent,
    HomeComponent,
    ProfileComponent,
    AssignmentListComponent,
    NavbarComponent,
    AssignmentComponent,
    AssignmentDetailComponent,
    Assignment2ListComponent,
    Assignment2Component,
    Assignment2DetailComponent,
    UploadComponent,
    NyListaComponent,
    ResetpasswordComponent,
    NorthvoltComponent,
    LegalNoticeComponent,
    MoveDetailsFromComponent,
    MoveDetailsToComponent,
    OtherDetailsComponent,
    YourDetailsComponent,
    EndFormComponent,
    TetralavalComponent,
    GeneralBookingComponent,
    HmComponent,
    EricssonComponent,
    UdComponent,
    UmdkComponent,
    UdnoComponent,
    AiresComponent,
    HandelsbankenComponent,
    SAABComponent,
    SandvikComponent,
    ScaniaComponent,
    SebComponent,
    SidaComponent,
    AbsolutComponent,
    EfComponent,
    FolkeBernadotteAcademyComponent,
    LundComponent,
    OriflameComponent,
    OhrlingsPricewaterhousecoopersComponent,
    SwedbankComponent,
    HmPolicyComponent,
    CevtComponent,
    AbbComponent,
    AlfalavalComponent,
    MtgComponent,
    UniversityOfHelsinkiComponent,
    AlfalavalOtherDetailsComponent,
    ScaniaCompanyDetailsComponent,
    ScaniaOtherDetailsComponent,
    MoveDetailsComponent,
    TransfereeDetailsComponent,
    AiresOtherDetailsComponent,
    SidaOtherDetailsComponent,
    TetralavalOtherDetailsComponent,
    OriflameOtherDetailsComponent,
    MigrationsverketOtherDetailsComponent,
    EricssonOtherDetailsComponent,
    MigrationsverketComponent,
    EfOtherDetailsComponent,
    SwedbankOtherDetailsComponent,
    SandvikOtherDetailsComponent,
    SebOtherDetailsComponent,
    HandelsbankenOtherDetailsComponent,
    SAABOtherDetailsComponent,
    CevtOtherDetailsComponent,
    UdOtherDetailsComponent,
    LundOtherDetailsComponent,
    PwcOtherDetailsComponent,
    NorthvoltOtherDetailsComponent,
    NeedConfirmListComponent,
    PolisenComponent,
    PrivateBookingComponent,
    SwedenComponent,
    NorwayComponent,
    DenmarkComponent,
    OthersComponent,
    FinlandComponent,
    PrivateFormComponent,
    PrivateLegalComponent,
    EricssonCompanyDetailsComponent,
    FooterComponent,
    AlfaLavalComponent,
    TetraLavalComponent,
    AboutComponent,
    ErrorComponent,
  ],
  imports: [
    MatSnackBarModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    MatCardModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: "toast-bottom-center",
      preventDuplicates: true,
    }),
    StoreModule.forRoot({
      user: userReducer,
      countries: countryReducer,
      companies: companyReducer,
      departments: departmentReducer,
    }),
    EffectsModule.forRoot([CountryEffects, CompanyEffects, DepartmentEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, autoPause: true }),
    NgbModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    NgbActiveModal,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
