import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-swedbank',
  templateUrl: './swedbank.component.html',
  styleUrls: ['./swedbank.component.scss']
})
export class SwedbankComponent {

  content:Content;
  extrainfo:Extrainfo;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.extrainfo.CompanyName = 'Swedbank AB';
    this.content.SokID = '15571';
    this.extrainfo.CompanyAddress = 'Fakturagruppen / FE 508';
    this.extrainfo.CompanyCity = 'Stockholm';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = '105 69';
    this.extrainfo.lockCompany = true;
    this.extrainfo.Legal = true;
    this.extrainfo.language = 'en';
    this.extrainfo.insurance = 'Alfa will cover insurance.';

    this.formConfig.confirm = false; //Determines if form need approval by manager
    
    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'Swedbank';
  }
}
