<div class="assignment-card">
  <div class="assignment-card-header">Name: {{ assignment.assigneeName }}<hr></div>
  <div class="assignment-card-body">
    <div class="row card-row" (click)="openDetail()" data-toggle="tooltip" data-placement="top" title="Click to open">
      <div class="col-md-4">
        <label>Assignee Name:</label>
        {{ assignment.assigneeName }}
      </div>
      <div class="col-md-4">
        <label>Booking ref:</label>
        {{ assignment.bookingReference }}
      </div>
      <div class="col-md-4">
        <label>Assignment Type:</label>
        {{ assignment.assignmentType}}
      </div>
      <div class="col-md-4">
        <label>Status:</label>
        {{ assignment.assignmentStatus }}
      </div>
      <div class="col-md-4">
        <label>ID:</label>
        {{ assignment.id }}
      </div>
      <div class="col-md-4">
        <label>From country:</label>
        {{ assignment.fromCountry }}
        <span [ngClass]=fromCountryClass></span> 
      </div>
      <div class="col-md-4">
        <label>To country:</label>
        {{ assignment.toCountry }}
        <span [ngClass]=toCountryClass></span> 
      </div>
      <div class="col-md-4">
        <label>Booking company:</label>
        {{ assignment.bookingCompany }}
      </div>
    </div>
  </div>
</div>


