import { Component } from "@angular/core";
import { Content, Extrainfo } from "src/app/models/form/book-data";
import { FormConfig } from "src/app/services/form-config";
import { FormDataService } from "src/app/services/form-data.service";

@Component({
  selector: "app-saab",
  templateUrl: "./saab.component.html",
  styleUrls: ["./saab.component.scss"],
})
export class SAABComponent {
  extrainfo: Extrainfo;
  content: Content;

  constructor(
    private formDataService: FormDataService,
    private formConfig: FormConfig,
  ) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.content.SokID = "15018";
    this.extrainfo.CompanyName = "SAAB AB";
    this.extrainfo.CompanyEmail = "";
    this.extrainfo.CompanyAddress = "";
    this.extrainfo.CompanyCity = "";
    this.extrainfo.CompanyCountry = "";
    this.extrainfo.CompanyZipCode = "";
    this.extrainfo.CompanyContact = "";
    this.extrainfo.insurance = "";

    // this.extrainfo.CompanyName = 'Handelsbanken';
    // this.extrainfo.CompanyEmail = 'maau01@handelsbanken.se';
    // this.extrainfo.CompanyAddress = 'HCP';
    // this.extrainfo.CompanyCity = 'Stockholm';
    // this.extrainfo.CompanyCountry = 'SE';
    // this.extrainfo.CompanyZipCode = '106 70';
    // this.extrainfo.CompanyContact = '';
    // this.extrainfo.insurance = 'Alfa will cover insurance.';

    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = "en";
    this.extrainfo.Avtal = "";

    this.formConfig.confirm = false; //Determines if form need approval by manager

    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = "saab";
  }
}
