<h3>Other details</h3>
<hr>
<br>
<div >
    <h4>Do you have any goods in storage to be included in your move?</h4>

    <label class="radio">
      <input
        type="radio"
        value="Yes"
        name="inStorage"
        [(ngModel)]="extrainfo.InStorage"
      />
      <span>Yes</span> </label
    >

    <label class="radio">
      <input
        type="radio"
        value="No"
        name="inStorage"
        [(ngModel)]="extrainfo.InStorage"
      />
      <span>No</span> </label
    >

    <!-- <h4>Do you need a storage of max 10 cbm?</h4>

    <label class="radio">
      <input
        type="radio"
        value="Yes"
        name="toStorage"
        [(ngModel)]="extrainfo.ToStorage"
      />
      <span>Yes</span> </label>

    <label class="radio">
      <input
        type="radio"
        value="No"
        name="toStorage"
        [(ngModel)]="extrainfo.ToStorage"
      />
      <span>No</span> </label> -->


<div>
<label for="textbox"><h4>Other info</h4></label>
<textarea
  class="itextfield"
  name="otherDetails"
  id="textbox"
  cols="30"
  rows="10"
  [(ngModel)]="extrainfo.OtherInfo"
  placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
>
</textarea>
</div>


<div class="button-container" >

  <input
    class="button btn-form"
    type="button"
    value="Back"
    routerLink="../movedetailsto"
  />
  <input
    class="button btn-form"
    type="button"
    value="Submit"
    (click)="formData.formSubmit()"
  />
</div>

  </div>
