<div class="nav-container">
  <!--   <nav class='navbar navbar-expand-md navbar-light bg-light fixed-top'>  -->
  <!--   <nav class='navbar navbar-expand-md navbar-light'>

    <button class='navbar-toggler hidden-sm-up' type='button' (click)='isNavbarCollapsed = !isNavbarCollapsed' data-target='#navbarsDefault' aria-controls='navbarsDefault' aria-expanded='false' aria-label='Toggle navigation'>
      <span class='navbar-toggler-icon'></span>
    </button>
    <div [ngbCollapse]='isNavbarCollapsed' class='collapse navbar-collapse' id='navbarsDefault'>
      <ul class='navbar-nav mr-auto'>
        <li class='nav-item dropdown' ngbDropdown>
          <a class='nav-link dropdown-toggle' id='id01' ngbDropdownToggle>Functions</a>
          <div class='dropdown-menu' aria-labelledby='id01' ngbDropdownMenu *ngFor='let link of links; let i = index;'>
            <a class='dropdown-item' href='{{ link.fragment }}'>{{ link.title }}</a>
          </div>
        </li>
        <li class="nav-item"><a class="nav-link" href="#">Link</a></li>
        <li class="nav-item"><a class="nav-link" href="#">Link</a></li>
      </ul>
    </div>
    <a class='nav-link' style="margin-right:10px;" href='/profile'>Profile</a>
    <a class='nav-link' href='/logout'>Logout</a>
  </nav>  -->

  <nav class="navbar navbar-expand-lg navbar-light">
    <button
      class="navbar-toggler hidden-sm-up"
      type="button"
      (click)="isNavbarCollapsed = !isNavbarCollapsed"
      data-target="#navbarsDefault"
      aria-controls="navbarsDefault"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      [ngbCollapse]="isNavbarCollapsed"
      class="collapse navbar-collapse"
      id="navbarsDefault"
    >
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="">Home</a>
        </li>
        <li class="nav-item" *ngFor="let link of links; let i = index">
          <a class="nav-link" href="{{ link.fragment }}">{{ link.title }}</a>
        </li>
      </ul>
    </div>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" style="margin-right: 10px" href="profile"
          >Profile</a
        >
      </li>
      <li class="nav-item"><a class="nav-link" href="logout">Logout</a></li>
    </ul>
  </nav>
</div>
