import { Action, createReducer, on } from '@ngrx/store';
import { storeUser } from '../actions';

import { User } from '../../models/user';

const initialUser: User = {
  context: {
    contentVersion: 0,
    contentType: '',
    sessionId: '',
    whoCalls: ''
  },
  content: {
    userName: '',
    userDisplayName: '',
    userEmail: '',
    profilePicture: {
      content: '',
      mimeType: ''
    },
    allowFunctions: [''],
  },
  response: {
    result: false,
    errorCode: 0,
    errorMessage: '',
  },
}

export interface UserState {
    user: User;
}

export const initialState: UserState = {
  user: initialUser
}

export const userReducer = createReducer(
  initialState,
  on(storeUser, (state: UserState, action): any => {
    // console.log('Payload: ', action.payload);
    // console.log('State: ', state);
    return {
      ...state,
      user: action.payload
    }
  })
);

