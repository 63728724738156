import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-cevt',
  templateUrl: './cevt.component.html',
  styleUrls: ['./cevt.component.scss']
})
export class CevtComponent {


extrainfo:Extrainfo;
content:Content;
  
  
  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned values
    this.extrainfo.CompanyName = 'CEVT AB';
    this.content.SokID = '44190';
    this.extrainfo.CompanyEmail = 'info@cevt.se';
    this.extrainfo.CompanyContact = '+ 46 (0)31 309 75 70';
    this.extrainfo.CompanyAddress = 'Theres Svenssons Gata 7';
    this.extrainfo.CompanyCity = 'Göteborg';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = '417 55';
    this.extrainfo.insurance = '';
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';
    
    this.formConfig.confirm = false;

    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'CEVT';
  }

}
