// Test
export const environment = {
  production: false,
  backendUrl: 'https://portaltest.alfamoving.com',
  proxyUrl: 'https://portaltest.alfamoving.com',
  secretKey: 'QVffRyVT8evpYp5z2GHybpMBtXDMZVR3',
  recaptcha: {
    siteKey: '6LfIbUQeAAAAACw_nRyGCmE1bs-sDvtwugjZ9dhg',
  }
};
