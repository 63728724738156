<div>
  <h3>Transferee details</h3>
  <hr />
  <div class="form-container">
    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="text"
        name="inputName"
        [(ngModel)]="content.assigneeFirstName"
        #inputName="ngModel"
        required
      />
      <span class="itext">First name*</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="text"
        name="lastName"
        [(ngModel)]="content.assigneeLastName"
        #lastName="ngModel"
        required
      />
      <span class="itext">Last name*</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="email"
        name="orgMailPrimary"
        [(ngModel)]="orgdata.orgMailPrimary"
        #orgMailPrimary="ngModel"
        required
        email
      />
      <span class="itext">Email*</span>
      <span
        class="erval"
        *ngIf="!orgMailPrimary.valid && orgMailPrimary.touched"
        >Please enter a correct email</span
      >
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        type="tel"
        placeholder="&nbsp;"
        name="orgMobilephone"
        [(ngModel)]="orgdata.orgMobilephone"
        #orgMobilephone="ngModel"
      />
      <span class="itext">Phone number*</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="date"
        name="EmploymentStartDate"
        [(ngModel)]="extrainfo.EmploymentStartDate"
        #EmploymentStartDate="ngModel"
      />
      <span class="itext">Employment start date</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="date"
        name="EmploymentEndDate"
        [(ngModel)]="extrainfo.EmploymentEndDate"
        #EmploymentEndDate="ngModel"
      />
      <span class="itext">Employment end date</span>
    </label>
  </div>

  <div>
    <h4>This order applies to:</h4>

    <label class="radio">
      <input
        class="iradio"
        type="radio"
        value="single"
        name="OrderAppliesTo"
        [(ngModel)]="extrainfo.OrderAppliesTo"
      />
      <span>Single</span>
    </label>

    <label class="radio">
      <input
        class="iradio"
        type="radio"
        value="couple"
        name="OrderAppliesTo"
        [(ngModel)]="extrainfo.OrderAppliesTo"
      />
      <span>Couple</span>
    </label>

    <label class="radio">
      <input
        class="iradio"
        type="radio"
        value="family"
        name="OrderAppliesTo"
        [(ngModel)]="extrainfo.OrderAppliesTo"
      />
      <span>Family</span>
    </label>
  </div>

  <div class="form-container">
    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="text"
        name="Citizenship"
        [(ngModel)]="extrainfo.Citizenship"
      />
      <span class="itext">Citizenship</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="text"
        name="CitizenshipFamily"
        [(ngModel)]="extrainfo.CitizenshipFamily"
      />
      <span class="itext">Citizenship family</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="text"
        name="AccompanyingFamily"
        [(ngModel)]="extrainfo.AccompanyingFamily"
      />
      <span class="itext">Accompanying family</span>
    </label>

    <label class="icontainer">
      <select
        class="iselect"
        name="orgCountry"
        [(ngModel)]="orgdata.orgCountry"
        #orgCountry
      >
        <option *ngFor="let country of countries" [value]="country.countrycode">
          {{ country.countryname }}
        </option>
      </select>
      <span
        class="itext"
        [ngClass]="{
          'itext-select': orgdata.orgCountry != undefined
        }"
        >From country</span
      >
    </label>
  </div>

  <div class="button-container">
    <input
      class="button btn-form"
      type="button"
      value="Back"
      routerLink="../companydetails"
    />
    <input
      class="button btn-form"
      [disabled]="
        !orgMailPrimary.valid ||
        !orgMobilephone.valid ||
        !lastName.valid ||
        !inputName.valid
      "
      type="button"
      value="Next"
      routerLink="../otherdetails"
    />
  </div>
</div>
