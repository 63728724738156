import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-scania',
  templateUrl: './scania.component.html',
  styleUrls: ['./scania.component.scss']
})
export class ScaniaComponent {

  content:Content;
  extrainfo:Extrainfo;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.content.SokID = '15127'
    this.extrainfo.CompanyName = '';
    this.extrainfo.CompanyAddress = '';
    this.extrainfo.CompanyCity = '';
    this.extrainfo.CompanyCountry = '';
    this.extrainfo.CompanyZipCode = '';
    this.extrainfo.CompanyEmail = '';
    this.extrainfo.CompanyContact = '';
    this.extrainfo.insurance = ``;
    this.extrainfo.Legal = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';
    this.extrainfo.lockCompany = true;

    this.formConfig.confirm = false; //Determines if form need approval by manager

    //Form configuration
    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'scania';
  }

}
