import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-scania-company-details',
  templateUrl: './scania-company-details.component.html',
  styleUrls: ['./scania-company-details.component.scss'],
})
export class ScaniaCompanyDetailsComponent {
  extrainfo: Extrainfo;
  content: Content;

  companies;

  constructor(public formDataService: FormDataService) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
  }

  scaniaCompanies = [
    {
      Address:
        'S&S, Scania Argentina S.A. Piedrabuena 5400 1615 Grand Bourg, Partido Malvinas Provincia de Buenos Aires Argentina',
      Company: 'S&S, SCANIA ARGENTINA S.A.',
      ContactEmail: 'Lisandro.Echeverria@scania.com',
      ContactName: 'Lisandro Echeverria',
      Country: 'Argentina',
      Depart: '',
      DisplayName: 'Argentina S&S, SCANIA ARGENTINA S.A.',
      groupcode: 'SCANIA',
      id: 0,
    },
    {
      Address:
        'Scania Argentina S.A. Casilla Correo Central 3 4000 San Miguel de Tucuman Argentina',
      Company: 'PRU, SCANIA ARGENTINA S.A.',
      ContactEmail: 'marcos.roldan@scania.com',
      ContactName: 'Marcos Roldan',
      Country: 'Argentina',
      Depart: '',
      DisplayName: 'Argentina PRU, SCANIA ARGENTINA S.A.',
      groupcode: 'SCANIA',
      id: 1,
    },
    {
      Address:
        'Scania Australia Pty Ltd 212-216 Northbourne Road, Campbellfield 3061 Victoria Australia',
      Company: 'SCANIA AUSTRALIA PTY LTD',
      ContactEmail: 'sumith.vellaikal@scania.com',
      ContactName: 'Sumith Vellaikal',
      Country: 'Australia',
      Depart: '',
      DisplayName: 'Australia SCANIA AUSTRALIA PTY LTD',
      groupcode: 'SCANIA',
      id: 2,
    },
    {
      Address:
        'Scania Österreich Ges.M.B.H. Johan Steinböckstrasse 4 Postfach 50 2345 Brunn am Gebirge Österreich',
      Company: 'SCANIA ÖSTERREICH GES.M.B.H.',
      ContactEmail: '',
      ContactName: '',
      Country: 'Austria',
      Depart: '',
      DisplayName: 'Austria SCANIA ÖSTERREICH GES.M.B.H.',
      groupcode: 'SCANIA',
      id: 3,
    },
    {
      Address:
        'Scania CV AB (publ) 2250 Scania Parts Logistics Bedrijsstraat 1208 3660 Opglabbeek Belgium',
      Company: 'SCANIA CV AB, Parts Logistics',
      ContactEmail: 'bernice.heyligen@scania.com',
      ContactName: 'Bernice Heyligen',
      Country: 'Belgium',
      Depart: '',
      DisplayName: 'Belgium SCANIA CV AB, Parts Logistics',
      groupcode: 'SCANIA',
      id: 4,
    },
    {
      Address:
        'Scania Bus Belgium N.V.-S.A. A. Van Osslaan 1 bus 28 1120 Brussel - Neder-Over-Hembeek Belgium',
      Company: 'SCANIA BUS BELGIUM N.V.-S.A.',
      ContactEmail: '',
      ContactName: '',
      Country: 'Belgium',
      Depart: '',
      DisplayName: 'Belgium SCANIA BUS BELGIUM N.V.-S.A.',
      groupcode: 'SCANIA',
      id: 5,
    },
    {
      Address:
        'Scania Treasury Belgium S.A. A. Van Osslaan 1 bus 23 1120 Brussel - Neder-Over-Hembeek Belgium',
      Company: 'SCANIA TREASURY BELGIUM S.A',
      ContactEmail: '',
      ContactName: '',
      Country: 'Belgium',
      Depart: '',
      DisplayName: 'Belgium SCANIA TREASURY BELGIUM S.A',
      groupcode: 'SCANIA',
      id: 6,
    },
    {
      Address:
        'Scania Finance Belgium NV Industriezone Gallilei, A. Van Osslaan 1 bus 28 1120 Brussel - Neder-Over-Hembeek Belgium',
      Company: 'SCANIA FINANCE BELGIUM NV',
      ContactEmail: '',
      ContactName: '',
      Country: 'Belgium',
      Depart: '',
      DisplayName: 'Belgium SCANIA FINANCE BELGIUM NV',
      groupcode: 'SCANIA',
      id: 7,
    },
    {
      Address:
        'Scania BH D.O.O. Rakovicka Cesta 180A 71210 Ilidza  Sarajevo Bosnia and Herzegovina',
      Company: 'SCANIA BH D.O.O.',
      ContactEmail: '',
      ContactName: '',
      Country: 'Bosnia and Herzegovina',
      Depart: '',
      DisplayName: 'Bosnia and Herzegovina SCANIA BH D.O.O.',
      groupcode: 'SCANIA',
      id: 8,
    },
    {
      Address: 'Scania Botswana (Pyt) Ltd.  P.O. BOX 602079 Gaborone Botswana',
      Company: 'SCANIA BOTSWANA (Pty) Ltd',
      ContactEmail: '',
      ContactName: '',
      Country: 'Botswana',
      Depart: '',
      DisplayName: 'Botswana SCANIA BOTSWANA (Pty) Ltd',
      groupcode: 'SCANIA',
      id: 9,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'SCANIA LATIN AMERICA LTDA',
      ContactEmail: '',
      ContactName: '',
      Country: 'Brazil',
      Depart: '',
      DisplayName: 'Brazil SCANIA LATIN AMERICA LTDA',
      groupcode: 'SCANIA',
      id: 10,
    },
    {
      Address:
        'Scania Bulgaria Co. Ltd.  German-Sreden Pat. No. 1 1186 Sofia Bulgaria',
      Company: 'SCANIA BULGARIA CO.,LTD.',
      ContactEmail: 'bistra.kuteva@scania.bg',
      ContactName: 'Bistra Kuteva',
      Country: 'Bulgaria',
      Depart: '',
      DisplayName: 'Bulgaria SCANIA BULGARIA CO.,LTD.',
      groupcode: 'SCANIA',
      id: 11,
    },
    {
      Address:
        'Scania Chile S.A. (S&S) Panamericana Norte 9850 8720004 Santiago Chile',
      Company: 'Scania Chile S.A. (S&S)',
      ContactEmail: 'claudio.pinto@scania.com',
      ContactName: 'Claudio Pinto',
      Country: 'Chile',
      Depart: '',
      DisplayName: 'Chile Scania Chile S.A. (S&S)',
      groupcode: 'SCANIA',
      id: 12,
    },
    {
      Address:
        'Scania Finance Chile S.A. Panamericana Norte 9850, Quilicura Santiago de Chile Chile',
      Company: 'SCANIA FINANCE CHILE S.A.',
      ContactEmail: 'bernt.lindstedt@scania.com',
      ContactName: 'Bernt Lindstedt',
      Country: 'Chile',
      Depart: '',
      DisplayName: 'Chile SCANIA FINANCE CHILE S.A.',
      groupcode: 'SCANIA',
      id: 13,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'SCANIA SALES (CHINA) CO. LTD',
      ContactEmail: '',
      ContactName: '',
      Country: 'China',
      Depart: '',
      DisplayName: 'China SCANIA SALES (CHINA) CO. LTD',
      groupcode: 'SCANIA',
      id: 14,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'SCANIA SALES AND SERVICES GUANGZHOU LTD',
      ContactEmail: '',
      ContactName: '',
      Country: 'China',
      Depart: '',
      DisplayName: 'China SCANIA SALES AND SERVICES GUANGZHOU LTD',
      groupcode: 'SCANIA',
      id: 15,
    },
    {
      Address:
        'Scania Colombia S.A.S. Calle 20 No 68c-82/90 Bogota DC Colombia',
      Company: 'SCANIA COLOMBIA S.A.S',
      ContactEmail: 'johanna.gydingsgard@scania.com',
      ContactName: 'Johanna Gydingsgård',
      Country: 'Colombia',
      Depart: '',
      DisplayName: 'Colombia SCANIA COLOMBIA S.A.S',
      groupcode: 'SCANIA',
      id: 16,
    },
    {
      Address:
        'Scania Hrvatska D.O.O. Karlovacka cesta 96 1025 50 Lucko Croatia',
      Company: 'SCANIA HRVATSKA D.O.O',
      ContactEmail: '',
      ContactName: '',
      Country: 'Croatia',
      Depart: '',
      DisplayName: 'Croatia SCANIA HRVATSKA D.O.O',
      groupcode: 'SCANIA',
      id: 17,
    },
    {
      Address:
        'Scania Czech Republic S.R.O. Chrastany 186 252 19 Rudna u Prahy Czech Republic',
      Company: 'SCANIA CZECH REP. S.R.O.',
      ContactEmail: 'Peter.berner@scania.cz',
      ContactName: 'Peter Berner',
      Country: 'Czech Republic',
      Depart: '',
      DisplayName: 'Czech Republic SCANIA CZECH REP. S.R.O.',
      groupcode: 'SCANIA',
      id: 18,
    },
    {
      Address:
        'Scania Finance CER SPOL.S.R.O. Chrastany 186 P.O. Box 21 252 19 Rudná u Prahy Czech Republic',
      Company: 'SCANIA FINANCE CZECH REPUBLIC SPOL.S.R.O.',
      ContactEmail: 'michel.vanriet@scania.cz',
      ContactName: 'Michel van Riet',
      Country: 'Czech Republic',
      Depart: '',
      DisplayName: 'Czech Republic SCANIA FINANCE CZECH REPUBLIC SPOL.S.R.O.',
      groupcode: 'SCANIA',
      id: 19,
    },
    {
      Address: 'Scania Finans AB by 260 151 87 Södertälje Sweden',
      Company: 'SCANIA DANMARK A/S',
      ContactEmail: 'accounting_sfa@scania.com',
      ContactName: 'Martin Strand Nilsson',
      Country: 'Denmark',
      Depart: '',
      DisplayName: 'Denmark SCANIA DANMARK A/S',
      groupcode: 'SCANIA',
      id: 20,
    },
    {
      Address: 'SCANIA DANMARK A/S Industribuen 19 2635 Ishøj Denmark',
      Company: 'SCANIA DANMARK A/S',
      ContactEmail: 'Janet.fransen@scania.dk',
      ContactName: 'Janet Fransen',
      Country: 'Denmark',
      Depart: '',
      DisplayName: 'Denmark SCANIA DANMARK A/S',
      groupcode: 'SCANIA',
      id: 21,
    },
    {
      Address: 'Scania Biler A/S Bredskiftevej 30 8210 Aarhus Denmark',
      Company: 'SCANIA BILER A/S',
      ContactEmail: '',
      ContactName: '',
      Country: 'Denmark',
      Depart: '',
      DisplayName: 'Denmark SCANIA BILER A/S',
      groupcode: 'SCANIA',
      id: 22,
    },
    {
      Address: 'Scania Eesti A.S. Peterburi Tee 72 EE 11415 Tallinn Estonia',
      Company: 'SCANIA EESTI AS',
      ContactEmail: '',
      ContactName: '',
      Country: 'Estonia',
      Depart: '',
      DisplayName: 'Estonia SCANIA EESTI AS',
      groupcode: 'SCANIA',
      id: 23,
    },
    {
      Address:
        'SOE Bus Production Finland Oy Räissinkatu 1 15700 Lahti Finland VATcode: FI26121679',
      Company: 'SOE BUSPRODUCTION FINLAND OY',
      ContactEmail: 'Satu.Sapattinen@scaniabusproduction.fi',
      ContactName: 'Satu Sapattinen',
      Country: 'Finland',
      Depart: '',
      DisplayName: 'Finland SOE BUSPRODUCTION FINLAND OY',
      groupcode: 'SCANIA',
      id: 24,
    },
    {
      Address:
        "Scania Production Angers S.A.S. 2 Boulevard de l'Industrie Z.I. d'Ecouflant B.P. 846 49008 Angers Cedex 01 France",
      Company: 'SCANIA PRODUCTION ANGERS S.A.S.',
      ContactEmail: 'Frederique.Pineau@scania.com',
      ContactName: 'Frederique Pineau',
      Country: 'France',
      Depart: '',
      DisplayName: 'France SCANIA PRODUCTION ANGERS S.A.S.',
      groupcode: 'SCANIA',
      id: 25,
    },
    {
      Address:
        "Scania Finance France 2 Boulevard de l'Industrie Z.I. d'Ecouflant B.P. 928 49009 Angers Cedex 01 France",
      Company: 'Scania France S.A.S',
      ContactEmail: 'Nadine.Jouan@scania.com',
      ContactName: 'Nadine Jouan',
      Country: 'France',
      Depart: '',
      DisplayName: 'France Scania France S.A.S',
      groupcode: 'SCANIA',
      id: 26,
    },
    {
      Address:
        "Scania Finance France 2 Boulevard de l'Industrie Z.I. d'Ecouflant B.P. 928 49009 Angers Cedex 01 France",
      Company: 'SCANIA FINANCE FRANCE S.A.S',
      ContactEmail: 'Nadine.Jouan@scania.com',
      ContactName: 'Nadine Jouan',
      Country: 'France',
      Depart: '',
      DisplayName: 'France SCANIA FINANCE FRANCE S.A.S',
      groupcode: 'SCANIA',
      id: 27,
    },
    {
      Address:
        'Scania France S.A.S. Succursale Midi Pyrenees 3 Avenue de Fontréal  Eurocentre 31620 Villeneuve Les Bouloc France',
      Company: 'SCANIA France S.A.S SUCCURSALE MIDI PYRENEES',
      ContactEmail: '',
      ContactName: '',
      Country: 'France',
      Depart: '',
      DisplayName: 'France SCANIA France S.A.S SUCCURSALE MIDI PYRENEES',
      groupcode: 'SCANIA',
      id: 28,
    },
    {
      Address: 'MAN Truck & Bus AG Dachauer Str. 667 80995 Munich Germany',
      Company: 'MAN Truck & Bus AG',
      ContactEmail: 'julia.biermann@man.eu',
      ContactName: 'Julia Biermann',
      Country: 'Germany',
      Depart: '',
      DisplayName: 'Germany MAN Truck & Bus AG',
      groupcode: 'SCANIA',
      id: 29,
    },
    {
      Address:
        'Volkswagen AG FRE-2 Kontokorrente Kreditoren Brieffach 1852 38436 Wolfsburg Germany',
      Company: 'Volkswagen AG',
      ContactEmail: 'silke.ben.saied@volkswagen.de',
      ContactName: 'Silke Ben Saied',
      Country: 'Germany',
      Depart: '',
      DisplayName: 'Germany Volkswagen AG',
      groupcode: 'SCANIA',
      id: 30,
    },
    {
      Address:
        'Scania Deutschland GmbH August-Horch-Strasse 10 Postfach 10 04 26 56034 Koblenz Germany',
      Company: 'SCANIA DEUTSCHLAND GMBH',
      ContactEmail: 'manuela.hohlin@scania.de',
      ContactName: 'Manuela Hohlin',
      Country: 'Germany',
      Depart: '',
      DisplayName: 'Germany SCANIA DEUTSCHLAND GMBH',
      groupcode: 'SCANIA',
      id: 31,
    },
    {
      Address:
        'Scania West Africa Ltd.  5, Osu Badu Street, Dzorwulu KA DTD 20023 Accra Ghana',
      Company: 'SCANIA WEST AFRICA LTD - GHANA',
      ContactEmail: 'invoices.swa@scania.com',
      ContactName: 'Leif Lybeck',
      Country: 'Ghana',
      Depart: '',
      DisplayName: 'Ghana SCANIA WEST AFRICA LTD - GHANA',
      groupcode: 'SCANIA',
      id: 32,
    },
    {
      Address:
        'Scania (Hong Kong) Limited RM 328 HI-TECH CENTRE 9, CHOI YUEN ROAD SHEUNG SHUI Hong Kong',
      Company: 'SCANIA (HONG KONG) LIMITED',
      ContactEmail: 'Frankie.Wong@scania.com.hk',
      ContactName: 'Frankie Wong',
      Country: 'Hong Kong',
      Depart: '',
      DisplayName: 'Hong Kong SCANIA (HONG KONG) LIMITED',
      groupcode: 'SCANIA',
      id: 33,
    },
    {
      Address:
        'Scania Credit (Hong Kong) Limited Unit G, 7/F, King Palace Plaza, 55, King Yip Street Kwun Tong, Kowloon Hong Kong',
      Company: 'SCANIA CREDIT (HONG KONG) LIMITED',
      ContactEmail: '',
      ContactName: '',
      Country: 'Hong Kong',
      Depart: '',
      DisplayName: 'Hong Kong SCANIA CREDIT (HONG KONG) LIMITED',
      groupcode: 'SCANIA',
      id: 34,
    },
    {
      Address: 'Scania Hungária Kft. Rozália Park 1  H-2051 Biatorbágy Hungary',
      Company: 'SCANIA HUNGARIA KFT.',
      ContactEmail: '',
      ContactName: '',
      Country: 'Hungary',
      Depart: '',
      DisplayName: 'Hungary SCANIA HUNGARIA KFT.',
      groupcode: 'SCANIA',
      id: 35,
    },
    {
      Address:
        'Scania Commercial Vehicles India Private Ltd PVT 1 Ltd, Plot No: 63 (Part) 64-66, 88-97 Narsapura KIADB Industrial Area Achhatanahalli Village Narsapura Hobli 563133 Taluk / District - Kolar India',
      Company: 'SCANIA COMMERCIAL VEHICLES INDIA PRIVATE LIMITED',
      ContactEmail: 'ulf.fromholz@scania.com',
      ContactName: 'Ulf Fromholz',
      Country: 'India',
      Depart: '',
      DisplayName: 'India SCANIA COMMERCIAL VEHICLES INDIA PRIVATE LIMITED',
      groupcode: 'SCANIA',
      id: 36,
    },
    {
      Address:
        'PT. Scania Parts Indonesia  Kawasan Industri Kariangan, JI. Soekarno Hatta km 13, Pulau Balang 763136 Balikpapan Indonesia',
      Company: 'PT. SCANIA PARTS INDONESIA',
      ContactEmail: '',
      ContactName: '',
      Country: 'Indonesia',
      Depart: '',
      DisplayName: 'Indonesia PT. SCANIA PARTS INDONESIA',
      groupcode: 'SCANIA',
      id: 37,
    },
    {
      Address:
        'Scania Representative Office  Menara 165,14th floor Jl. TB Simatupang Kav.1 Cilandak Timur Jakarta 12560',
      Company: 'SCANIA REPRESENTATIVE OFFICE',
      ContactEmail: 'monica.dame@scania.com',
      ContactName: 'Monica Dame',
      Country: 'Indonesia',
      Depart: '',
      DisplayName: 'Indonesia SCANIA REPRESENTATIVE OFFICE',
      groupcode: 'SCANIA',
      id: 38,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Qanadeel Al Rafidain Automotive Trading Company',
      ContactEmail: '',
      ContactName: '',
      Country: 'Iraq',
      Depart: '',
      DisplayName: 'Iraq Qanadeel Al Rafidain Automotive Trading Company',
      groupcode: 'SCANIA',
      id: 39,
    },
    {
      Address: 'Scania Milano S.p.A. CORSO EUROPA 20020 LAINATE (MI) Italy',
      Company: 'SCANIA MILANO SPA',
      ContactEmail: '',
      ContactName: '',
      Country: 'Italy',
      Depart: '',
      DisplayName: 'Italy SCANIA MILANO SPA',
      groupcode: 'SCANIA',
      id: 40,
    },
    {
      Address:
        'Scania Finance Italy S.P.A. Via Lombardini No 13 20143 Milano Italy',
      Company: 'SCANIA FINANCE ITALY S.P.A.',
      ContactEmail: 'riccardo.pacifici@scania.it',
      ContactName: 'Riccardo Pacifici',
      Country: 'Italy',
      Depart: '',
      DisplayName: 'Italy SCANIA FINANCE ITALY S.P.A.',
      groupcode: 'SCANIA',
      id: 41,
    },
    {
      Address:
        'Scania Bus Italy SRL Zi Spini Di Gardolo 125/E 38014 Trento Italy',
      Company: 'SCANIA BUS ITALY SRL',
      ContactEmail: '',
      ContactName: '',
      Country: 'Italy',
      Depart: '',
      DisplayName: 'Italy SCANIA BUS ITALY SRL',
      groupcode: 'SCANIA',
      id: 42,
    },
    {
      Address: 'ITALSCANIA S.p.A. Via di Spini, 21 38121 Trento Italy',
      Company: 'ITALSCANIA S.p.A.',
      ContactEmail: 'Dino.cecconi@scania.it',
      ContactName: 'Dino Cecconi',
      Country: 'Italy',
      Depart: '',
      DisplayName: 'Italy ITALSCANIA S.p.A.',
      groupcode: 'SCANIA',
      id: 43,
    },
    {
      Address:
        'Scania Japan Ltd. 7th Floor, Googolplex Millenium Building 4-20 Shiba, 4-chome, Minato-ku Tokyo 108-0014  Japan',
      Company: 'SCANIA JAPAN LTD',
      ContactEmail: 'arjan.de.nijs@scania.com',
      ContactName: 'Arjan de Nijs',
      Country: 'Japan',
      Depart: '',
      DisplayName: 'Japan SCANIA JAPAN LTD',
      groupcode: 'SCANIA',
      id: 44,
    },
    {
      Address:
        'Scania Central Asia LLP 3 Sultan-Beibarys St.  040704 Almaty Baiserke Vill., ILI Area',
      Company: 'SCANIA CENTRAL ASIA LLP',
      ContactEmail: 'vika.shvyrkova@scania.kz',
      ContactName: 'Victoria Shvyrkova',
      Country: 'Kazackstan',
      Depart: '',
      DisplayName: 'Kazackstan SCANIA CENTRAL ASIA LLP',
      groupcode: 'SCANIA',
      id: 45,
    },
    {
      Address:
        'Scania East Africa Ltd. 17 Kitul Road, Off Kampala, Industrial Area P.O. Box 19066-00500 Nairobi Kenya',
      Company: 'SCANIA EAST AFRICA Ltd',
      ContactEmail: 'per.holmstrom@scania.co.ke',
      ContactName: 'Per Holmström',
      Country: 'Kenya',
      Depart: '',
      DisplayName: 'Kenya SCANIA EAST AFRICA Ltd',
      groupcode: 'SCANIA',
      id: 46,
    },
    {
      Address:
        'Scania Korea Ltd. 18th Floor, Construction Building 71-2 Nonhyun-Dong Kangnam-Ku Seoul 135-010 Republic of Korea',
      Company: 'SCANIA KOREA LTD',
      ContactEmail: 'hyunah.choi@scania.com kaj.farm@scania.com',
      ContactName: 'Hyunah Choi Kaj Färm',
      Country: 'Korea',
      Depart: '',
      DisplayName: 'Korea SCANIA KOREA LTD',
      groupcode: 'SCANIA',
      id: 47,
    },
    {
      Address: 'SIA Scania Latvia Tiraines Iela 13 LV 1058  Riga Latvia',
      Company: 'SIA SCANIA LATVIA',
      ContactEmail: '',
      ContactName: '',
      Country: 'Latvia',
      Depart: '',
      DisplayName: 'Latvia SIA SCANIA LATVIA',
      groupcode: 'SCANIA',
      id: 48,
    },
    {
      Address: 'UAB Scania Lietuva Lentvario 14 B 02300 Vilnius Lithuania',
      Company: 'UAB SCANIA LIETUVA',
      ContactEmail: '',
      ContactName: '',
      Country: 'Lithuania',
      Depart: '',
      DisplayName: 'Lithuania UAB SCANIA LIETUVA',
      groupcode: 'SCANIA',
      id: 49,
    },
    {
      Address:
        'Scania Luxembourg S.A. Syrdall 3 23 Rue Gabriel lippmann 5365 Münsbach Luxembourg',
      Company: 'SCANIA LUXEMBOURG S.A.',
      ContactEmail: 'sara.edstrom@scania.com',
      ContactName: 'Sara Edström',
      Country: 'Luxembourg',
      Depart: '',
      DisplayName: 'Luxembourg SCANIA LUXEMBOURG S.A.',
      groupcode: 'SCANIA',
      id: 50,
    },
    {
      Address:
        'Scania Treasury Luxembourg Luxembourg/City 26 Boulevard Royal 2449 Luxembourg Luxembourg',
      Company: 'SCANIA TREASURY LUXEMBOURG',
      ContactEmail: '',
      ContactName: 'Emil Folkesson',
      Country: 'Luxembourg',
      Depart: '',
      DisplayName: 'Luxembourg SCANIA TREASURY LUXEMBOURG',
      groupcode: 'SCANIA',
      id: 51,
    },
    {
      Address:
        'Scania (Malaysia) Sdn. Bhd. No. 1 Jalan Tiang U8/93 Bukit Jelutong Industrial Park 401 50 Shah Alam, Selangor D.E. Malaysia',
      Company: 'Scania (Malaysia) Sdn. Bhd.',
      ContactEmail: 'Annalakshmi.Paidithally@scania.com',
      ContactName: 'Annalakshmi Paidithally',
      Country: 'Malaysia',
      Depart: '',
      DisplayName: 'Malaysia Scania (Malaysia) Sdn. Bhd.',
      groupcode: 'SCANIA',
      id: 52,
    },
    {
      Address:
        'Scania Credit (Malaysia) Sdn. Bhd. No. 1 Jalan Tiang U8/93 Bukit Jelutong Industrial Park 401 50 Shah Alam, Selangor D.E. Malaysia',
      Company: 'Scania Credit (Malaysia) Sdn Bhd',
      ContactEmail: 'yechoong.looi@scania.com',
      ContactName: 'Ye Choong Looi (Ms.)',
      Country: 'Malaysia',
      Depart: '',
      DisplayName: 'Malaysia Scania Credit (Malaysia) Sdn Bhd',
      groupcode: 'SCANIA',
      id: 53,
    },
    {
      Address:
        'Scania de Mexico S.A. de C.V. Circuito El Marques Norte No 12, Parque Industrial El Marque, Km 195.5 Aut. Mex-Qro CP 762 46 Queretaro Mexiko',
      Company: 'Scania Comercial S.A. de C.V',
      ContactEmail: 'Maria.delgadillo@scania.com',
      ContactName: 'Maria Delgadillo',
      Country: 'Mexiko',
      Depart: '',
      DisplayName: 'Mexiko Scania Comercial S.A. de C.V',
      groupcode: 'SCANIA',
      id: 54,
    },
    {
      Address:
        'Scania Maroc S.A. Rue Capitaine Vuillanier KM 7 R.P. 1 Aïn Sebaâ Casablanca MA-20250 Morocco',
      Company: 'SCANIA MAROC S.A.',
      ContactEmail: 'Hans.andersson@scania.co.ma',
      ContactName: 'Hans Andersson',
      Country: 'Morocco',
      Depart: '',
      DisplayName: 'Morocco SCANIA MAROC S.A.',
      groupcode: 'SCANIA',
      id: 55,
    },
    {
      Address:
        'Scania  Truck Namibia (Pty) Ltd. P.O. Box 1488 Windhoek Namibia',
      Company: 'SCANIA TRUCK NAMIBIA (PTY) LTD.',
      ContactEmail: 'Johan.vanschalkwyk@trucknamibia.com',
      ContactName: 'Johan van Schalkwyk',
      Country: 'Namibia',
      Depart: '',
      DisplayName: 'Namibia SCANIA TRUCK NAMIBIA (PTY) LTD.',
      groupcode: 'SCANIA',
      id: 56,
    },
    {
      Address:
        'Scania Production Zwolle B.V. Russenweg 5 P.O. Box 310 8000 AH Zwolle Netherlands',
      Company: 'Scania Production Zwolle B.V.',
      ContactEmail: 'John.Bouw@scania.com',
      ContactName: 'John Bouw',
      Country: 'Netherlands',
      Depart: '',
      DisplayName: 'Netherlands Scania Production Zwolle B.V.',
      groupcode: 'SCANIA',
      id: 57,
    },
    {
      Address:
        'Scania Nederland B.V. Financiële administratie P.O. Box 9598 4801 LN Breda Netherlands',
      Company: 'Scania Nederland B.V.',
      ContactEmail: 'Marie-Jose.Rutten@scania.com',
      ContactName: 'Marie-José Rutten',
      Country: 'Netherlands',
      Depart: '',
      DisplayName: 'Netherlands Scania Nederland B.V.',
      groupcode: 'SCANIA',
      id: 58,
    },
    {
      Address: 'Scania IT Nederland B.V. Russenweg 5 8041 AL  ZWOLLE',
      Company: 'Scania IT Nederland B.V.',
      ContactEmail: 'invoice.9237@scania.com',
      ContactName: 'Han Gunnink',
      Country: 'Netherlands',
      Depart: '',
      DisplayName: 'Netherlands Scania IT Nederland B.V.',
      groupcode: 'SCANIA',
      id: 59,
    },
    {
      Address: 'Norsk Scania A/S Postboks 345 Alnabru N-0212 0614 Oslo Norway',
      Company: 'Norsk Scania A/S',
      ContactEmail: 'cristin.wexelsen@scania.no',
      ContactName: 'Cristin Wexelsen',
      Country: 'Norway',
      Depart: '',
      DisplayName: 'Norway Norsk Scania A/S',
      groupcode: 'SCANIA',
      id: 60,
    },
    {
      Address:
        'Scania del Peru S.A. Autopista Ramiro Prialé Km. 7.5 Urb. La Capitana Santa Maria de Huachipa - Lurigancho Lima PE - LIMA 15 Perú',
      Company: 'SCANIA DEL PERU S.A',
      ContactEmail: 'dalia.alegria@scania.com',
      ContactName: 'Dalia Alegria',
      Country: 'Peru',
      Depart: '',
      DisplayName: 'Peru SCANIA DEL PERU S.A',
      groupcode: 'SCANIA',
      id: 61,
    },
    {
      Address: 'Scania Polska S.A. Aleja Katowicka 316 05-830 Nadarzyn Poland',
      Company: 'SCANIA POLSKA S.A.',
      ContactEmail: 'agata.kokoszczynska@scania.pl',
      ContactName: 'Agata Kokoszczynska',
      Country: 'Poland',
      Depart: '',
      DisplayName: 'Poland SCANIA POLSKA S.A.',
      groupcode: 'SCANIA',
      id: 62,
    },
    {
      Address:
        'Scania Production Slupsk S.A. Ul. Grundwaldzka 12 76-200 Slupsk Poland',
      Company: 'SCANIA PRODUCTION SLUPSK S.A.',
      ContactEmail: 'marcin.jachym@scania.com',
      ContactName: 'Marcin Jachym',
      Country: 'Poland',
      Depart: '',
      DisplayName: 'Poland SCANIA PRODUCTION SLUPSK S.A.',
      groupcode: 'SCANIA',
      id: 63,
    },
    {
      Address: 'Scania Power Polska SP.Z.O.O. Olkuska 7 02-604 Warsaw',
      Company: 'SCANIA POWER POLSKA SP. Z O.O',
      ContactEmail: '',
      ContactName: '',
      Country: 'Poland',
      Depart: '',
      DisplayName: 'Poland SCANIA POWER POLSKA SP. Z O.O',
      groupcode: 'SCANIA',
      id: 64,
    },
    {
      Address:
        'Scania Portugal S.A. Rua dos Caniços nº 39 2625-253 Vialonga Portugal',
      Company: 'SCANIA PORTUGAL S.A.',
      ContactEmail: 'Ana.sintra@scania.com',
      ContactName: 'Ana Bela Sintra',
      Country: 'Portugal',
      Depart: '',
      DisplayName: 'Portugal SCANIA PORTUGAL S.A.',
      groupcode: 'SCANIA',
      id: 65,
    },
    {
      Address:
        'Scania Romania S.R.L. SOS. Bucuresti Nr. 261 Ciorogarla, JUD. ILFOV Romania',
      Company: 'SCANIA ROMANIA S.R.L',
      ContactEmail: 'hakan.sundstrom@scania.ro',
      ContactName: 'Håkan Sundström',
      Country: 'Romania',
      Depart: '',
      DisplayName: 'Romania SCANIA ROMANIA S.R.L',
      groupcode: 'SCANIA',
      id: 66,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania Peter LLC (Produktion) BTR',
      ContactEmail: '',
      ContactName: '',
      Country: 'Russia',
      Depart: '',
      DisplayName: 'Russia Scania Peter LLC (Produktion) BTR',
      groupcode: 'SCANIA',
      id: 67,
    },
    {
      Address:
        'Scania-Rus LLC SRU 30/1 Obrucheva Street, 5th Floor  117485 Moscow Russia',
      Company: 'SCANIA - RUS LLC SRU (Sales & Services)',
      ContactEmail: 'Olga.Kurochkina@scania.ru',
      ContactName: 'Olga Kurochkina',
      Country: 'Russia',
      Depart: '',
      DisplayName: 'Russia SCANIA - RUS LLC SRU (Sales & Services)',
      groupcode: 'SCANIA',
      id: 68,
    },
    {
      Address:
        'Scania Srbija D.O.O. Industrijska zona bb 22314 Krnjesevci Serbia',
      Company: 'SCANIA SRBIJA D.O.O.',
      ContactEmail: '',
      ContactName: '',
      Country: 'Serbia',
      Depart: '',
      DisplayName: 'Serbia SCANIA SRBIJA D.O.O.',
      groupcode: 'SCANIA',
      id: 69,
    },
    {
      Address: 'Scania Singapore Pte Ltd. 40, Senoko Road 758112 Singapore',
      Company: 'SCANIA SINGAPORE PTE LTD',
      ContactEmail: 'esther.lim@scania.com',
      ContactName: 'Esther Lim',
      Country: 'Singapore',
      Depart: '',
      DisplayName: 'Singapore SCANIA SINGAPORE PTE LTD',
      groupcode: 'SCANIA',
      id: 70,
    },
    {
      Address:
        'Scania Finance Slovak Republic S.R.O. Pestovatelska 10 82104 Bratislava Slovakia',
      Company: 'SCANIA FINANCE SLOVAK REPUBLIC S.R.O.',
      ContactEmail: '',
      ContactName: '',
      Country: 'Slovakia',
      Depart: '',
      DisplayName: 'Slovakia SCANIA FINANCE SLOVAK REPUBLIC S.R.O.',
      groupcode: 'SCANIA',
      id: 71,
    },
    {
      Address:
        'Scania Slovakia S.R.O. Dialnicna Cesta 4570/2A 903 01 Senec Slovakia',
      Company: 'SCANIA SLOVAKIA SRO',
      ContactEmail: '',
      ContactName: '',
      Country: 'Slovakia',
      Depart: '',
      DisplayName: 'Slovakia SCANIA SLOVAKIA SRO',
      groupcode: 'SCANIA',
      id: 72,
    },
    {
      Address:
        'Scania Slovenija D.O.O. Cesta V Gorice 28 1000 Ljubljana Slovenia',
      Company: 'SCANIA SLOVENIJA D.O.O',
      ContactEmail: 'Anusa.stepic@scania.si',
      ContactName: 'Anusa Stepic',
      Country: 'Slovenia',
      Depart: '',
      DisplayName: 'Slovenia SCANIA SLOVENIJA D.O.O',
      groupcode: 'SCANIA',
      id: 73,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'SCANIA SOUTH AFRICA (PTY) LTD',
      ContactEmail: '',
      ContactName: '',
      Country: 'South Africa',
      Depart: '',
      DisplayName: 'South Africa SCANIA SOUTH AFRICA (PTY) LTD',
      groupcode: 'SCANIA',
      id: 74,
    },
    {
      Address:
        'Spanien Hispania S.A. Apdo De Correos 1 066 Parque Empressarial 28830 San Fernando de Henares Spain',
      Company: 'Scania Hispania S.A.',
      ContactEmail: 'Rafael.Vara@scania.com',
      ContactName: 'Rafael Vara',
      Country: 'Spain',
      Depart: '',
      DisplayName: 'Spain Scania Hispania S.A.',
      groupcode: 'SCANIA',
      id: 75,
    },
    {
      Address:
        'Scania Finance Hispania EFC S.A. Avda Castilla 29 288 30 San Fernando de Henares Madrid Spain',
      Company: 'SCANIA FINANCE HISPANIA EFC S.A.',
      ContactEmail: 'lars_j.sjostrom@scania.com',
      ContactName: 'Lars Sjöström',
      Country: 'Spain',
      Depart: '',
      DisplayName: 'Spain SCANIA FINANCE HISPANIA EFC S.A.',
      groupcode: 'SCANIA',
      id: 76,
    },
    {
      Address: 'Scania Schweiz AG Steinackerstrasse 57 8302 Kloten Switzerland',
      Company: 'SCANIA SCHWEIZ AG',
      ContactEmail: 'Ursi.hug@scania.ch',
      ContactName: 'Ursi Hug',
      Country: 'Switzerland',
      Depart: '',
      DisplayName: 'Switzerland SCANIA SCHWEIZ AG',
      groupcode: 'SCANIA',
      id: 77,
    },
    {
      Address:
        'Scania Delivery Center Aktiebolag, Taiwan No. 66 Tai-Ping E. Rd, Ping Chen City 32466 Taoyuan County Taiwan',
      Company: 'SCANIA DELIVERY CENTER AKTIEBOLAG, TAIWAN',
      ContactEmail: 'carol.yu@scania.tw',
      ContactName: 'Carol Yu',
      Country: 'Taiwan',
      Depart: '',
      DisplayName: 'Taiwan SCANIA DELIVERY CENTER AKTIEBOLAG, TAIWAN',
      groupcode: 'SCANIA',
      id: 78,
    },
    {
      Address:
        'Griffin Automotive, Taiwan Branch (B.V.I.) 13th Floor, No. 77, Sec. 3 Nanjing E. Rd. Zhongshan District Taipei City 10487 Taiwan',
      Company: 'GRIFFIN AUTOMOTIVE, TAIWAN BRANCH (B.V.I.)',
      ContactEmail: 'per.lilljequist@scania.tw',
      ContactName: 'Per Lilljequist',
      Country: 'Taiwan',
      Depart: '',
      DisplayName: 'Taiwan GRIFFIN AUTOMOTIVE, TAIWAN BRANCH (B.V.I.)',
      groupcode: 'SCANIA',
      id: 79,
    },
    {
      Address:
        'Scania Tanzania Ltd. Nyerere Road P.O. BOX 9324 Dar Es Salaam United Republic of Tanzania',
      Company: 'SCANIA TANZANIA LTD.',
      ContactEmail: 'anders.friberg@scaniatz.com',
      ContactName: 'Anders Friberg',
      Country: 'Tanzania',
      Depart: '',
      DisplayName: 'Tanzania SCANIA TANZANIA LTD.',
      groupcode: 'SCANIA',
      id: 80,
    },
    {
      Address:
        'Scania Siam Co. Ltd. 999/19 MOO 9 BANGNA-TRAD RD., KM.19 Amphur Bangplee Samutprakarn 10540 Thailand',
      Company: 'Scania Siam Co. Ltd.',
      ContactEmail: 'chollada.mangmee@scania.com',
      ContactName: 'Chollada Mangmee',
      Country: 'Thailand',
      Depart: '',
      DisplayName: 'Thailand Scania Siam Co. Ltd.',
      groupcode: 'SCANIA',
      id: 81,
    },
    {
      Address:
        'Scania Finance Turkey  Polaris Plaza Ahi Evran Caddesi, No:21, Kat:17 Maslak 34398 Istanbul Turkey',
      Company: 'Scania Finance Turkey',
      ContactEmail: 'cinla.sezer@scania.com.tr',
      ContactName: 'Cinla Sezer',
      Country: 'Turkey',
      Depart: '',
      DisplayName: 'Turkey Scania Finance Turkey',
      groupcode: 'SCANIA',
      id: 82,
    },
    {
      Address:
        'Scania Ukraine Ltd. Kyivska 37, Str.  50 km Kyiv-Chop Road v. Kalynivka Makarivskiy District Kyiv 08004 Ukraine',
      Company: 'SCANIA UKRAINE LTD',
      ContactEmail: 'polina.panaschenko@scania-ua.com',
      ContactName: 'Polina Panaschenko',
      Country: 'Ukraine',
      Depart: '',
      DisplayName: 'Ukraine SCANIA UKRAINE LTD',
      groupcode: 'SCANIA',
      id: 83,
    },
    {
      Address:
        'Scania CV AB Middle East (1799) South West Ring Road 1 Plots S21507 + 21509 P.O. BOX 262796 Jebel Ali Free Zone Dubai United Arab Emirates',
      Company: 'SCANIA CV AB MIDDLE EAST',
      ContactEmail: '',
      ContactName: '',
      Country: 'United Arab Emirates',
      Depart: '',
      DisplayName: 'United Arab Emirates SCANIA CV AB MIDDLE EAST',
      groupcode: 'SCANIA',
      id: 84,
    },
    {
      Address:
        'Scania (Great Britain) Ltd.  Purchase Ledger Department Delaware Drive Tongwell  Milton Keynes MK15 8HB Buckinghamshire United Kingdom',
      Company: 'SCANIA (GREAT BRITAIN) LTD.',
      ContactEmail: 'Sarah.taylor@scania.com',
      ContactName: 'Sarah Taylor',
      Country: 'United Kingdom',
      Depart: '',
      DisplayName: 'United Kingdom SCANIA (GREAT BRITAIN) LTD.',
      groupcode: 'SCANIA',
      id: 85,
    },
    {
      Address:
        'Scania Finance Great Britain Ltd. Regus House Fairbourne Drive MK10 9RG Milton Keynes United Kingdom',
      Company: 'SCANIA FINANCE GREAT BRITAIN LTD.',
      ContactEmail: 'Jayne.Allan@scania.com',
      ContactName: 'Jayne Allan',
      Country: 'United Kingdom',
      Depart: '',
      DisplayName: 'United Kingdom SCANIA FINANCE GREAT BRITAIN LTD.',
      groupcode: 'SCANIA',
      id: 86,
    },
    {
      Address:
        'Scanexpo International S/A RUTA 8, KM 17500 Dificio Mercosur Plaza I - Oficina 1 Montevideo Uruguay',
      Company: 'SCANEXPO INTERNATIONAL S/A',
      ContactEmail: '',
      ContactName: '',
      Country: 'Uruguay',
      Depart: '',
      DisplayName: 'Uruguay SCANEXPO INTERNATIONAL S/A',
      groupcode: 'SCANIA',
      id: 87,
    },
    {
      Address:
        'Scania USA Inc. 121 INTERPARK BLVD., SUITE 1002 TX 78216 San Antonio United States',
      Company: 'SCANIA USA INC',
      ContactEmail: 'debbie.albright@scania.com',
      ContactName: 'Debbie Albright',
      Country: 'USA',
      Depart: '',
      DisplayName: 'USA SCANIA USA INC',
      groupcode: 'SCANIA',
      id: 88,
    },
    {
      Address:
        'Scania de Venezuela S.A. Urb. Parque Ind. Castillito - Distribuidor Divenca Prolongacion Av. Branger - NL 4 Valencia - Estado Carabobo Bolivarian Republic of Venezuela',
      Company: 'SCANIA DE VENEZUELA S.A.',
      ContactEmail: 'rosaura.poleo@scania.com',
      ContactName: 'Rosaura Poleo',
      Country: 'Venezuela',
      Depart: '',
      DisplayName: 'Venezuela SCANIA DE VENEZUELA S.A.',
      groupcode: 'SCANIA',
      id: 89,
    },
    {
      Address:
        'Scania CV AB (publ) Commercial Operations, unit 0067 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'BB',
      DisplayName: 'Sweden Scania CV AB, department: BB',
      groupcode: 'SCANIA',
      id: 90,
    },
    {
      Address:
        'Scania CV AB (publ) Commercial Operations, unit 0067 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'BC',
      DisplayName: 'Sweden Scania CV AB, department: BC',
      groupcode: 'SCANIA',
      id: 91,
    },
    {
      Address:
        'Scania CV AB (publ) Commercial Operations, unit 0067 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'BH',
      DisplayName: 'Sweden Scania CV AB, department: BH',
      groupcode: 'SCANIA',
      id: 92,
    },
    {
      Address:
        'Scania CV AB (publ) Commercial Operations, unit 0067 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'BR',
      DisplayName: 'Sweden Scania CV AB, department: BR',
      groupcode: 'SCANIA',
      id: 93,
    },
    {
      Address:
        'Scania CV AB (publ) Commercial Operations, unit 0067 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'BT',
      DisplayName: 'Sweden Scania CV AB, department: BT',
      groupcode: 'SCANIA',
      id: 94,
    },
    {
      Address:
        'Scania CV AB (publ) Commercial Operations, unit 0067 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'BV',
      DisplayName: 'Sweden Scania CV AB, department: BV',
      groupcode: 'SCANIA',
      id: 95,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'C',
      DisplayName: 'Sweden Scania CV AB, department: C',
      groupcode: 'SCANIA',
      id: 96,
    },
    {
      Address:
        'Scania CV AB (publ) Industrial Control, unit 0003 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'D  (except DE, DL DM, DT, DX)',
      DisplayName:
        'Sweden Scania CV AB, department: D  (except DE, DL DM, DT, DX)',
      groupcode: 'SCANIA',
      id: 97,
    },
    {
      Address:
        'Scania CV AB (publ) Scania Engine, unit 0056 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'DE',
      DisplayName: 'Sweden Scania CV AB, department: DE',
      groupcode: 'SCANIA',
      id: 98,
    },
    {
      Address:
        'Ferruform AB Box 815 SE-971 25 LULEÅ Sweden VAT number: SE556528912001',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'DL',
      DisplayName: 'Sweden Scania CV AB, department: DL',
      groupcode: 'SCANIA',
      id: 99,
    },
    {
      Address:
        'Scania CV AB (publ) Scania Engine, unit 0056 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'DM',
      DisplayName: 'Sweden Scania CV AB, department: DM',
      groupcode: 'SCANIA',
      id: 100,
    },
    {
      Address:
        'Scania CV AB (publ) Scania Transmission, unit 0057 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'DT',
      DisplayName: 'Sweden Scania CV AB, department: DT',
      groupcode: 'SCANIA',
      id: 101,
    },
    {
      Address:
        'Scania CV AB (publ) Scania Transmission, unit 0057 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'DX',
      DisplayName: 'Sweden Scania CV AB, department: DX',
      groupcode: 'SCANIA',
      id: 102,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'F',
      DisplayName: 'Sweden Scania CV AB, department: F',
      groupcode: 'SCANIA',
      id: 103,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'G',
      DisplayName: 'Sweden Scania CV AB, department: G',
      groupcode: 'SCANIA',
      id: 104,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'H',
      DisplayName: 'Sweden Scania CV AB, department: H',
      groupcode: 'SCANIA',
      id: 105,
    },
    {
      Address:
        'Scania IT AB Box 624 SE-151 27 SÖDERTÄLJE Sweden VAT number: SE556084120601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'I',
      DisplayName: 'Sweden Scania CV AB, department: I',
      groupcode: 'SCANIA',
      id: 106,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'J  (except JF)',
      DisplayName: 'Sweden Scania CV AB, department: J  (except JF)',
      groupcode: 'SCANIA',
      id: 107,
    },
    {
      Address:
        'Scania CV AB (publ) Industrial Real Estate, unit 0054 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'JF',
      DisplayName: 'Sweden Scania CV AB, department: JF',
      groupcode: 'SCANIA',
      id: 108,
    },
    {
      Address:
        'Scania CV AB (publ) Sales and Marketing, unit 0052 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'KAI',
      DisplayName: 'Sweden Scania CV AB, department: KAI',
      groupcode: 'SCANIA',
      id: 109,
    },
    {
      Address:
        'Scania CV AB (publ) Sales and Marketing, unit 0052 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'KB',
      DisplayName: 'Sweden Scania CV AB, department: KB',
      groupcode: 'SCANIA',
      id: 110,
    },
    {
      Address:
        'Scania CV AB (publ) Sales and Marketing, unit 0052 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'KC',
      DisplayName: 'Sweden Scania CV AB, department: KC',
      groupcode: 'SCANIA',
      id: 111,
    },
    {
      Address:
        'Scania CV AB (publ) Sales and Marketing, unit 0052 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'KE',
      DisplayName: 'Sweden Scania CV AB, department: KE',
      groupcode: 'SCANIA',
      id: 112,
    },
    {
      Address:
        'Scania CV AB (publ) Sales and Marketing, unit 0052 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'KH',
      DisplayName: 'Sweden Scania CV AB, department: KH',
      groupcode: 'SCANIA',
      id: 113,
    },
    {
      Address:
        'Scania CV AB (publ) Sales and Marketing, unit 0052 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'KM',
      DisplayName: 'Sweden Scania CV AB, department: KM',
      groupcode: 'SCANIA',
      id: 114,
    },
    {
      Address:
        'Scania CV AB (publ) Sales and Marketing, unit 0052 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'KP',
      DisplayName: 'Sweden Scania CV AB, department: KP',
      groupcode: 'SCANIA',
      id: 115,
    },
    {
      Address:
        'Scania CV AB (publ) Sales and Marketing, unit 0052 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'KR',
      DisplayName: 'Sweden Scania CV AB, department: KR',
      groupcode: 'SCANIA',
      id: 116,
    },
    {
      Address:
        'Scania CV AB (publ) Sales and Marketing, unit 0052 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'KT',
      DisplayName: 'Sweden Scania CV AB, department: KT',
      groupcode: 'SCANIA',
      id: 117,
    },
    {
      Address:
        'Scania CV AB (publ) Sales and Marketing, unit 0052 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'KX',
      DisplayName: 'Sweden Scania CV AB, department: KX',
      groupcode: 'SCANIA',
      id: 118,
    },
    {
      Address:
        'Scania CV AB (publ) Industrial Control, unit 0003 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'M',
      DisplayName: 'Sweden Scania CV AB, department: M',
      groupcode: 'SCANIA',
      id: 119,
    },
    {
      Address:
        'Scania CV AB (publ) Scania Cab, unit 0014 Box 903 SE-572 29 OSKARSHAMN Sweden',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'MB',
      DisplayName: 'Sweden Scania CV AB, department: MB',
      groupcode: 'SCANIA',
      id: 120,
    },
    {
      Address:
        'Scania CV AB (publ) Scania Cab, unit 0014 Box 903 SE-572 29 OSKARSHAMN Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'MC',
      DisplayName: 'Sweden Scania CV AB, department: MC',
      groupcode: 'SCANIA',
      id: 121,
    },
    {
      Address:
        'Scania CV AB (publ) Scania Cab, unit 0014 Box 903 SE-572 29 OSKARSHAMN Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'MPC',
      DisplayName: 'Sweden Scania CV AB, department: MPC',
      groupcode: 'SCANIA',
      id: 122,
    },
    {
      Address:
        'Scania CV AB (publ) Scania Chassis, unit 0055 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'MS',
      DisplayName: 'Sweden Scania CV AB, department: MS',
      groupcode: 'SCANIA',
      id: 123,
    },
    {
      Address:
        'Scania CV AB (publ) Industrial Control, unit 0003 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'N',
      DisplayName: 'Sweden Scania CV AB, department: N',
      groupcode: 'SCANIA',
      id: 124,
    },
    {
      Address:
        'Scania CV AB (publ) Industrial Control, unit 0003 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'O',
      DisplayName: 'Sweden Scania CV AB, department: O',
      groupcode: 'SCANIA',
      id: 125,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'P',
      DisplayName: 'Sweden Scania CV AB, department: P',
      groupcode: 'SCANIA',
      id: 126,
    },
    {
      Address:
        'Scania CV AB (publ) Commercial Operations, unit 0067 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'P, Christian Levin',
      DisplayName: 'Sweden Scania CV AB, department: P, Christian Levin',
      groupcode: 'SCANIA',
      id: 127,
    },
    {
      Address:
        'Scania CV AB (publ) Industrial Control, unit 0003 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'R',
      DisplayName: 'Sweden Scania CV AB, department: R',
      groupcode: 'SCANIA',
      id: 128,
    },
    {
      Address:
        'Scania CV AB (publ) Industrial Control, unit 0003 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'S  (except SL)',
      DisplayName: 'Sweden Scania CV AB, department: S  (except SL)',
      groupcode: 'SCANIA',
      id: 129,
    },
    {
      Address:
        'Scania CV AB (publ) Scania Parts Logistics, unit 2250 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'SL',
      DisplayName: 'Sweden Scania CV AB, department: SL',
      groupcode: 'SCANIA',
      id: 130,
    },
    {
      Address:
        'Scania CV AB (publ) Industrial Control, unit 0003 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'T',
      DisplayName: 'Sweden Scania CV AB, department: T',
      groupcode: 'SCANIA',
      id: 131,
    },
    {
      Address:
        'Scania CV AB (publ) Industrial Control, unit 0003 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'U',
      DisplayName: 'Sweden Scania CV AB, department: U',
      groupcode: 'SCANIA',
      id: 132,
    },
    {
      Address:
        'Scania CV AB (publ) Commercial Operations, unit 0067 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'W',
      DisplayName: 'Sweden Scania CV AB, department: W',
      groupcode: 'SCANIA',
      id: 133,
    },
    {
      Address:
        'Scania CV AB (publ) Scania Academy, unit 0070 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'XA',
      DisplayName: 'Sweden Scania CV AB, department: XA',
      groupcode: 'SCANIA',
      id: 134,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'XC',
      DisplayName: 'Sweden Scania CV AB, department: XC',
      groupcode: 'SCANIA',
      id: 135,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'XS',
      DisplayName: 'Sweden Scania CV AB, department: XS',
      groupcode: 'SCANIA',
      id: 136,
    },
    {
      Address:
        'Scania CV AB (publ) Industrial Control, unit 0003 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'Y',
      DisplayName: 'Sweden Scania CV AB, department: Y',
      groupcode: 'SCANIA',
      id: 137,
    },
    {
      Address:
        'STD10000-8 Scania CV AB (publ) Group Treasury, unit 0058 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'ZF',
      DisplayName: 'Sweden Scania CV AB, department: ZF',
      groupcode: 'SCANIA',
      id: 138,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'ZH',
      DisplayName: 'Sweden Scania CV AB, department: ZH',
      groupcode: 'SCANIA',
      id: 139,
    },
    {
      Address:
        'Scania CV AB (publ) Head Office, unit 0051 SE-151 87 SÖDERTÄLJE Sweden VAT number: SE556084097601',
      Company: 'Scania CV AB',
      ContactEmail: '',
      ContactName: '',
      Country: 'Sweden',
      Depart: 'ZT',
      DisplayName: 'Sweden Scania CV AB, department: ZT',
      groupcode: 'SCANIA',
      id: 140,
    },
  ];

  scaniaCountries = [
    { Country: 'Argentina' },
    { Country: 'Australia' },
    { Country: 'Austria' },
    { Country: 'Belgium' },
    { Country: 'Bosnia and Herzegovina' },
    { Country: 'Botswana' },
    { Country: 'Brazil' },
    { Country: 'Bulgaria' },
    { Country: 'Chile' },
    { Country: 'China' },
    { Country: 'Colombia' },
    { Country: 'Croatia' },
    { Country: 'Czech Republic' },
    { Country: 'Denmark' },
    { Country: 'Estonia' },
    { Country: 'Finland' },
    { Country: 'France' },
    { Country: 'Germany' },
    { Country: 'Ghana' },
    { Country: 'Hong Kong' },
    { Country: 'Hungary' },
    { Country: 'India' },
    { Country: 'Indonesia' },
    { Country: 'Iraq' },
    { Country: 'Italy' },
    { Country: 'Japan' },
    { Country: 'Kazackstan' },
    { Country: 'Kenya' },
    { Country: 'Korea' },
    { Country: 'Latvia' },
    { Country: 'Lithuania' },
    { Country: 'Luxembourg' },
    { Country: 'Malaysia' },
    { Country: 'Mexiko' },
    { Country: 'Morocco' },
    { Country: 'Namibia' },
    { Country: 'Netherlands' },
    { Country: 'Norway' },
    { Country: 'Peru' },
    { Country: 'Poland' },
    { Country: 'Portugal' },
    { Country: 'Romania' },
    { Country: 'Russia' },
    { Country: 'Serbia' },
    { Country: 'Singapore' },
    { Country: 'Slovakia' },
    { Country: 'Slovenia' },
    { Country: 'South Africa' },
    { Country: 'Spain' },
    { Country: 'Switzerland' },
    { Country: 'Taiwan' },
    { Country: 'Tanzania' },
    { Country: 'Thailand' },
    { Country: 'Turkey' },
    { Country: 'Ukraine' },
    { Country: 'United Arab Emirates' },
    { Country: 'United Kingdom' },
    { Country: 'Uruguay' },
    { Country: 'USA' },
    { Country: 'Venezuela' },
    { Country: 'Sweden' },
  ];

  onCountryChange() {
    this.companies = this.scaniaCompanies.filter(
      (x) => x.Country === this.extrainfo.CompanyCountry
    );
  }
}
