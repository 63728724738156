import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  BussinessAgreementinfo,
  Content,
  Dateinfo,
  Destdata,
  Extrainfo,
  Orgdata,
  Surveyinfo,
  Volume,
  Services
} from '../models/form/book-data';
import { DataService } from './data.service';
import { FormConfig } from './form-config';

@Injectable({
  providedIn: 'root',
})

// Service for holding all the form data
export class FormDataService {
  formC: FormConfig;

  orgdata: Orgdata;
  extrainfo: Extrainfo;
  content: Content;
  destdata: Destdata;
  volume: Volume;
  surveyinfo: Surveyinfo;
  dateinfo: Dateinfo;
  bussiness: BussinessAgreementinfo;
  services: Services;

  constructor(
    private dataService: DataService,
    public formConfig: FormConfig,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.formC = formConfig;

    this.content = new Content();
    this.extrainfo = new Extrainfo();
    this.destdata = new Destdata();
    this.volume = new Volume();
    this.surveyinfo = new Surveyinfo();
    this.dateinfo = new Dateinfo();
    this.bussiness = new BussinessAgreementinfo();
    this.orgdata = new Orgdata();
    this.services = new Services();
  }

  // Reset all properties in the all objects
  reset() {
    // Content object
    this.content.assigneeFirstName = '';
    this.content.assigneeLastName = '';
    this.content.payingCompanyCustomerNo = '';
    this.content.reloassistjobref = '';
    this.content.salesPersonId = null;
    this.content.typeOfAssignmentPart = '';
    this.content.alfaCompanyId = null;
    this.content.assignmentnovalue = null;
    this.content.bookingCompanyCustomerNo = '';
    this.content.customerGroupId = '';
    this.content.SokID = '';
    this.content.acceptLegal = '';
    this.content.confirmSubmitDate = '';

    // Orgdata object
    this.orgdata.orgAddress1 = '';
    this.orgdata.orgTown = '';
    this.orgdata.orgPostcode = '';
    this.orgdata.orgCountry = '';
    this.orgdata.orgResidenceType = '';
    this.orgdata.orgResidenceText = '';
    this.orgdata.orgWalkingDistance = '';
    this.orgdata.orgWalkingDistanceText = '';
    this.orgdata.orgElevator = '';
    this.orgdata.orgElevatorText = '';
    this.orgdata.orgDoorcode = '';
    this.orgdata.orgFloor = '';
    this.orgdata.orgSurveyDate = '';
    this.orgdata.orgMailPrimary = '';
    this.orgdata.orgPhoneOffice = '';
    this.orgdata.orgMobilephone = '';
    this.orgdata.ContactWay = '';
    this.orgdata.orgPhoneHome = '';
    this.orgdata.orgAccess = '';
    this.orgdata.orgAddress2 = '';
    this.orgdata.orgDeliveryDate = '';
    this.orgdata.orgMailAlternative = '';
    this.orgdata.orgPickupDate = '';
    this.orgdata.orgSurveyTime = '';

    // Surveyinfo object
    this.surveyinfo.Area = '';
    this.surveyinfo.Attic = '';
    this.surveyinfo.Basement = '';
    this.surveyinfo.NumberOfRooms = '';

    // Extrainfo object
    this.extrainfo.AccompanyingFamily = '';
    this.extrainfo.ApprovingManagerEmail = '';
    this.extrainfo.ApprovingManagerEmployeeNumber = '';
    this.extrainfo.ApprovingManagerName = '';
    this.extrainfo.ApprovingManagerPhone = '';
    this.extrainfo.AssignmentStartDate = '';
    this.extrainfo.AssignmentType = '';
    this.extrainfo.Avtal = '';
    this.extrainfo.BusinessUnitCode = '';
    this.extrainfo.BillingAddress = '';
    this.extrainfo.Citizenship = '';
    this.extrainfo.CitizenshipFamily = '';
    this.extrainfo.CleaningOffer = '';
    this.extrainfo.CompanyContact = '';
    this.extrainfo.CompanyAddress = '';
    this.extrainfo.CompanyName = '';
    this.extrainfo.CompanyCity = '';
    this.extrainfo.CompanyCountry = '';
    this.extrainfo.CompanyDepartment = '';
    this.extrainfo.CompanyEmail = '';
    this.extrainfo.CompanyZipCode = '';
    this.extrainfo.ContractType = '';
    this.extrainfo.CurrentForeignAuthority = '';
    this.extrainfo.CurrentWorkplace = '';
    this.extrainfo.CostCenter = '';
    this.extrainfo.DHLShipment = '';
    this.extrainfo.DiscountCode = '';
    this.extrainfo.EmployeeNumber = '';
    this.extrainfo.EricssonEmployeeSignum = '';
    this.extrainfo.EricssonCompany = '';
    this.extrainfo.EmploymentStartDate = '';
    this.extrainfo.EmploymentEndDate = '';
    this.extrainfo.FamilySize = '';
    this.extrainfo.EricssonLocalHiringContact = '';
    this.extrainfo.HiringManager = '';
    this.extrainfo.EricssonHiringManager = '';
    this.extrainfo.EricssonID = '';
    this.extrainfo.Faculty = '';
    this.extrainfo.Furnished = '';
    this.extrainfo.Gender = '';
    this.extrainfo.GlobalIntensivePlan = '';
    this.extrainfo.ImmigrationServices = '';
    this.extrainfo.InsuranceInfo = '';
    this.extrainfo.InterestedInRUT = '';
    this.extrainfo.KeepHomeCountryResidence = '';
    this.extrainfo.LTAEnd = '';
    this.extrainfo.LastName = '';
    this.extrainfo.MaxBudget = '';
    this.extrainfo.MembershipNumber = '';
    this.extrainfo.NumberOfAdults = '';
    this.extrainfo.NumberOfChildren = '';
    this.extrainfo.OrderAppliesTo = '';
    this.extrainfo.OtherInfo = '';
    this.extrainfo.OwnBehalf = '';
    this.extrainfo.Pets = '';
    this.extrainfo.PolicyTypeText = '';
    this.extrainfo.POManagerApproval = '';
    this.extrainfo.Sampackade = '';
    this.extrainfo.StorageReference = '';
    this.extrainfo.TetraPack = '';
    this.extrainfo.InStorage = '';
    this.extrainfo.ToStorage = '';
    this.extrainfo.ReturningHome = '';
    this.extrainfo.VolumeLimitCustom = '';
    this.extrainfo.RelocationServices = '';
    this.extrainfo.RFIRFP = '';
    this.extrainfo.SCAReference = '';
    this.extrainfo.Shipment = '';
    this.extrainfo.UnpackingDestinationDate = '';
    this.extrainfo.Legal = false;
    this.extrainfo.insurance = '';
    this.extrainfo.lockCompany = false;
    this.extrainfo.language = '';
    this.extrainfo.iAccept = '';
    this.extrainfo.UXPayingCompany = '';
    this.extrainfo.UXPayingCountry = '';
    this.extrainfo.submitDate = '';
    this.extrainfo.numberOfFamily = '';
    this.extrainfo.moveOwner = '';
    this.extrainfo.scaniaCompany = '';
    this.extrainfo.payingCompany = '';
    this.extrainfo.payingCountry = '';
    this.extrainfo.hrEmail = '';
    this.extrainfo.hmChina = '';
    this.extrainfo.orderedByName = '';
    this.extrainfo.orderedByFromEmail = '';
    this.extrainfo.orderedByWorkPhone = '';
    this.extrainfo.orderedByMobilePhone = '';
    this.extrainfo.newCustomer = '';
    this.extrainfo.paymentTerms = '';
    this.extrainfo.ediCode = '';
    this.extrainfo.invoicingAdress = '';
    this.extrainfo.companyVAT = '';
    this.extrainfo.emailInvoice = '';
    this.extrainfo.ApprovedRelocationAllowance = '';
    this.extrainfo.ApprovedLongTermAllowance = '';
    this.extrainfo.CommencementDate = '';
    this.extrainfo.MaritalStatus = '';

    // Destdata object
    this.destdata.destAddress = '';
    this.destdata.destPostcode = '';
    this.destdata.destTown = '';
    this.destdata.destCountry = '';
    this.destdata.destAccess = '';
    this.destdata.destAddress2 = '';
    this.destdata.destDeliveryDate = '';
    this.destdata.destDoorcode = '';
    this.destdata.destElevator = '';
    this.destdata.destFloor = '';
    this.destdata.destMailAlternative = '';
    this.destdata.destMailPrimary = '';
    this.destdata.destMobilephone = '';
    this.destdata.destPhoneHome = '';
    this.destdata.destPhoneOffice = '';
    this.destdata.destPickupDate = '';
    this.destdata.destResidenceType = '';
    this.destdata.destWalkingDistance = '';
    this.destdata.destSurveyDate = '';
    this.destdata.destSurveyTime = '';

    // Dateinfo object
    this.dateinfo.AvailableDate = '';
    this.dateinfo.EmptyDate = '';
    this.dateinfo.PackingDate = '';
    this.dateinfo.ShipperArrivalDate = '';

    // Volume object
    this.volume.AirCourier = '';
    this.volume.VolumeLimit = '';
    this.volume.VolumeLimitOtherText = '';

    // BussinessAgreementinfo object
    this.bussiness.bussinessAgreementId = null;
    this.bussiness.bookingsitereference = '';
    this.bussiness.PolicyType = '';

    // Services object
    this.services.immigration = false;
    this.services.settlingIn = false;
    this.services.homeSearch = false;
    this.services.tempAccomodationBooking = false;
    this.services.schoolSearch = false;
    this.services.spouseSupport = false;
    this.services.preVisitOrientation = false;
    this.services.consultation = false;
    this.services.outboundRelocation = false;
    this.services.otherSpecified = false;
    this.services.preHireRelocationConsultation = false;
  }

  // dump() {
  //   console.log('Fan och hans moster !');
  //   console.log('content  : ', this.content);
  //   console.log('extrainfo: ', this.extrainfo);
  // }

  postData(needConfirm: boolean, officeCode = '') {
    const newCombinedObject = {
      metaData: {
        whoCalls: 'webPortal',
        officeCode: officeCode,
        replyEmail: 'hakan.andersson@alfamoving.com', // Fill for trace data
        needConfirm: needConfirm,
        postCommand: {},
      },
      content: {
        ...this.content,
        orgdata: {
          ...this.orgdata,
        },
        destdata: {
          ...this.destdata,
        },
        volume: {
          ...this.volume,
        },
        surveyinfo: {
          ...this.surveyinfo,
        },
        dateinfo: {
          ...this.dateinfo,
        },
        extrainfo: {
          ...this.extrainfo,
        },
        bussinessAgreementinfo: {
          ...this.bussiness,
        },
        services: {
          ...this.services,
        }
      },
      result: {
        result: '',
      },
    };

    return newCombinedObject;
  }

  /* Submit  for corporate pages */

  formSubmit(): void {
    const body = this.postData(this.formC.confirm, '0');
    // Submit date
    const now = new Date();
    body.content.extrainfo.submitDate = now.toISOString();
    // Post processing
    if (body.content.extrainfo.Shipment) {
      body.content.volume.VolumeLimitOtherText = body.content.extrainfo.Shipment;
      body.content.volume.VolumeLimit = body.content.extrainfo.Shipment.replace(/m3/g, '').replace(/\D/g, '');
    }
    // ->
    console.log(body.content);
    // <-
    this.dataService.formSubmit(body).subscribe({
      next: () => {
        // console.log('Response: ', response);
        this.snackBar.open('Form submitted successfully!', 'Close', {
          duration: 5000,
          panelClass: ['success-snackbar'],
        });
        this.router.navigate(['/done'], { queryParams: { company: body.content.extrainfo.CompanyName } });
      },
      error: (error: Error) => {
        console.error(error);
        this.snackBar.open(
          'Error submitting form. Please try again later.',
          'Close',
          {
            panelClass: ['error-snackbar'],
          }
        );
      },
    });
  }

  /* For private.. */

  privateSubmit(): void {
    const body = this.postData(this.formC.confirm, this.formC.office);
    // Submit date
    const now = new Date();
    body.content.extrainfo.submitDate = now.toISOString();
    // Post processing
    if (body.content.extrainfo.Shipment) {
      body.content.volume.VolumeLimitOtherText = body.content.extrainfo.Shipment;
      body.content.volume.VolumeLimit = body.content.extrainfo.Shipment.replace(/m3/g, '').replace(/\D/g, '');
    }
    // ->
    // console.log(body.content);
    // <-
    this.dataService.formSubmit(body).subscribe({
      next: () => {
        // console.log('Response: ', response);
        this.snackBar.open('Form submitted successfully!', 'Close', {
          duration: 5000,
          panelClass: ['success-snackbar'],
        });
        this.router.navigate(['/done'], { queryParams: { company: body.content.extrainfo.CompanyName } }); // navigate to /done on success
      },
      error: (error: Error) => {
        console.error(error);
        this.snackBar.open(
          'Error submitting form. Please try again later.',
          'Close',
          {
            panelClass: ['error-snackbar'],
          }
        );
      },
    });
  }
}
