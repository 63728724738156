import { Component } from '@angular/core';
import { Extrainfo } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-northvolt-other-details',
  templateUrl: './northvolt-other-details.component.html',
  styleUrls: ['./northvolt-other-details.component.scss']
})
export class NorthvoltOtherDetailsComponent {

  extrainfo:Extrainfo;

constructor (
  private formDataService : FormDataService ,
  public formData : FormDataService
  )
  {

  this.extrainfo = formDataService.extrainfo;

}


}
