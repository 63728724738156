<div >
    <div>
        <h3>Other details</h3>
        <hr>
    </div>

    <h4>Do you have any goods in storage to be included in your move?</h4>

    <label class="radio">
      <input
        type="radio"
        value="Yes"
        name="InStorage"
        [(ngModel)]="extrainfo.InStorage"
      />
      <span>Yes</span> </label
    >

    <label class="radio">
      <input
        type="radio"
        value="No"
        name="InStorage"
        [(ngModel)]="extrainfo.InStorage"
      />
      <span>No</span> </label
    >

    <!-- <h4>Do you need storage of max 10 cbm</h4>

    <label class="radio">
      <input
        type="radio"
        value="Yes"
        name="ToStorage"
        [(ngModel)]="extrainfo.ToStorage"
      />
      <span>Yes</span> </label
    >

    <label class="radio">
      <input
        type="radio"
        value="No"
        name="ToStorage"
        [(ngModel)]="extrainfo.ToStorage"
      />
      <span>No</span> </label
    > -->

    <h4>Family size*</h4>

    <div class="form-container">
      <label class="icontainer label-children">
        <select
          name="NumberOfAdults"
          class="iselect"
          [(ngModel)]="extrainfo.NumberOfAdults"
          #NumberOfAdults="ngModel"
          required
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
        <span
          class="itext"
          [ngClass]="{ 'itext-select': extrainfo.NumberOfAdults != undefined }"
        >
          Number of adults*
        </span>
        <span class="erval" *ngIf="!NumberOfAdults.valid && NumberOfAdults.touched">
          Please make a selection.
        </span> </label>

      <label class="icontainer label-children">
        <select
          name="NumberOfChildren"
          class="iselect"
          [(ngModel)]="extrainfo.NumberOfChildren"
          #NumberOfChildren="ngModel"
          required
        >
          <option value="none">No kids</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6 or more">6 or more</option>
        </select>
        <span
          class="itext"
          [ngClass]="{ 'itext-select': extrainfo.NumberOfChildren != undefined }"
        >
          Number of children*
        </span>
        <span class="erval" *ngIf="!NumberOfChildren.valid && NumberOfChildren.touched">
          Please make a selection.
        </span> </label
      >

      <label for="textbox"><h4>Other info</h4></label>
      <textarea
        class="itextfield"
        name="otherDetails"
        id="textbox"
        cols="30"
        rows="10"
        [(ngModel)]="extrainfo.OtherInfo"
        placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
      >
      </textarea>

      <div class="button-container">

        <input
          class="button btn-form"
          type="button"
          value="Back"
          routerLink="../movedetailsto"
        />
        <input
          [disabled]="!NumberOfChildren.valid || !NumberOfAdults"
          class="button btn-form"
          type="button"
          value="Submit"
          (click)="formData.formSubmit()"
        />
      </div>
    </div>

  </div>
