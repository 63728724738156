<h3 *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'">
    Your details
</h3>
<h3 *ngIf="formC.formCompanyName == 'uoh'">Mover's details</h3>
<h3 *ngIf="formC.formCompanyName == 'umdk'">Assignee details</h3>
<hr />

<div class="form-container">
    <label class="icontainer">
        <input
            class="ifield"
            placeholder="&nbsp;"
            type="text"
            name="inputName"
            [(ngModel)]="content.assigneeFirstName"
            #inputName="ngModel"
            required
        />
        <span class="itext">First name*</span>
    </label>

    <label class="icontainer">
        <input
            class="ifield"
            placeholder="&nbsp;"
            type="text"
            name="lastName"
            [(ngModel)]="content.assigneeLastName"
            required
            #lastName="ngModel"
        />
        <span class="itext">Last name*</span>
    </label>

    <label class="icontainer">
        <input
            class="ifield"
            placeholder="&nbsp;"
            type="email"
            name="fromEmail"
            [(ngModel)]="orgdata.orgMailPrimary"
            #fromEmail="ngModel"
            required
            email
        />
        <span class="itext">Email*</span>
        <span class="erval" *ngIf="!fromEmail.valid && fromEmail.touched"
            >Please enter a correct email</span
        >
    </label>

    <label class="icontainer">
        <input
            class="ifield"
            type="tel"
            placeholder="&nbsp;"
            name="toMobilePhone"
            [(ngModel)]="orgdata.orgMobilephone"
            #toMobilePhone="ngModel"
            required
        />
        <span *ngIf="formC.formCompanyName != 'umdk'" class="itext"
            >Phone number*</span
        >
        <span *ngIf="formC.formCompanyName == 'umdk'" class="itext"
            >Phone number</span
        >
    </label>
</div>
<!-- end of formcontainer -->

<p>Preferred way of contact:</p>

<div class="row">
    <label class="radio">
        <input
            class="iradio"
            type="radio"
            value="phone"
            name="preferredContact"
            [(ngModel)]="orgdata.ContactWay"
            #preferredContact
        />
        <span>Phone</span>
    </label>
</div>

<div class="row">
    <label class="radio">
        <input
            class="iradio"
            type="radio"
            value="email"
            name="preferredContact"
            [(ngModel)]="orgdata.ContactWay"
            #preferredContact
        />
        <span>Email</span>
    </label>
</div>

<div *ngIf="formC.formCompanyName == 'umdk'">
    <label class="icontainer">
        <input
            class="ifield"
            type="itext"
            placeholder="&nbsp;"
            name="MaritalStatus"
            [(ngModel)]="extrainfo.MaritalStatus"
            #maritalStatus="ngModel"
            required
        />
        <span class="itext">Marital status</span>
    </label>
</div>

<div *ngIf="formC.formCompanyName === 'Tetralaval'">
    <p>Gender:</p>
    <div class="row">
        <label class="radio">
            <input
                class="iradio"
                type="radio"
                value="male"
                name="yourGender"
                [(ngModel)]="extrainfo.Gender"
                #yourGender
            />
            <span>Male</span>
        </label>
    </div>

    <div class="row">
        <label class="radio">
            <input
                class="iradio"
                type="radio"
                value="female"
                name="yourGender"
                [(ngModel)]="extrainfo.Gender"
                #yourGender
            />
            <span>Female</span>
        </label>
    </div>

    <div class="row">
        <label class="radio">
            <input
                class="iradio"
                type="radio"
                value="other"
                name="yourGender"
                [(ngModel)]="extrainfo.Gender"
                #yourGender
            />
            <span>Other &nbsp;</span>
        </label>
    </div>
    <!-- TODO: Improve on input gender or find another way.. if we're even using "other" -->
</div>

<div class="button-container">
    <input
        class="button btn-form"
        type="button"
        value="Back"
        routerLink="../companydetails"
    />
    <input
        *ngIf="formC.formCompanyName != 'umdk'"
        class="button btn-form"
        [disabled]="!fromEmail.valid || !inputName.valid || !lastName.valid || !toMobilePhone.valid"
        type="button"
        value="Next"
        routerLink="../movedetailsfrom"
    />
    <input
        *ngIf="formC.formCompanyName == 'umdk'"
        class="button btn-form"
        [disabled]="!fromEmail.valid || !inputName.valid || !lastName.valid"
        type="button"
        value="Next"
        routerLink="../movedetailsfrom"
    />
</div>
