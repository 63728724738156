import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-ud',
  templateUrl: './ud.component.html',
  styleUrls: ['./ud.component.scss']
})
export class UdComponent {

  content:Content;
  extrainfo:Extrainfo;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.extrainfo.CompanyName = ' ';
    this.content.SokID = '16070';
    this.extrainfo.CompanyAddress = ' ';
    this.extrainfo.CompanyCity = ' ';
    this.extrainfo.CompanyCountry = ' ';
    this.extrainfo.CompanyZipCode = ' ';
    this.extrainfo.Avtal = 'UD2017/09257/FAST';
    this.extrainfo.language = 'en'; 

    this.formConfig.confirm = false; //Determines if form need approval by manager

    //Form configuration
    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'ud';
  }

}
