<div>
  <h3>Move details</h3>
  <hr />

  <h4>CURRENT ADDRESS</h4>
  <div class="form-container">
    <label class="icontainer">
      <input
        type="text"
        placeholder="&nbsp;"
        class="ifield"
        name="orgTown"
        [(ngModel)]="orgdata.orgTown"
        #destTown="ngModel"
      />
      <span class="itext">City</span>
    </label>

    <label class="icontainer">
      <select
        class="iselect"
        name="orgCountry"
        [(ngModel)]="orgdata.orgCountry"
        #orgCountry="ngModel"
        required
      >
      <option *ngFor="let country of countries" [value]="country.countrycode">
        {{ country.countryname }}
      </option>
      </select>
      <span class="erval" *ngIf="!orgCountry.valid && orgCountry.touched"
        >Please select a country.</span
      >
      <span class="itext" [ngClass]="{ 'itext-select': orgdata.orgCountry }"
        >Country*</span
      >
    </label>
  </div>

  <h4>TO ADDRESS</h4>

  <div class="form-container">
    <label class="icontainer">
      <input
        type="text"
        placeholder="&nbsp;"
        class="ifield"
        name="destTown"
        [(ngModel)]="destdata.destTown"
        #destTown="ngModel"
      />
      <span class="itext">City</span>
    </label>

    <label class="icontainer">
      <select
        class="iselect"
        name="destCountry"
        [(ngModel)]="destdata.destCountry"
        #destCountry="ngModel"
        required
      >
      <option *ngFor="let country of countries" [value]="country.countrycode">
        {{ country.countryname }}
      </option>
      </select>
      <span class="erval" *ngIf="!destCountry.valid && destCountry.touched"
        >Please select a country.</span
      >
      <span class="itext" [ngClass]="{ 'itext-select': destdata.destCountry }"
        >Country*</span
      >
    </label>
  </div>

  <div class="button-container">
    <input
      class="button btn-form"
      type="button"
      value="Back"
      routerLink="../yourdetails"
    />

    <input
      [disabled]=""
      class="button btn-form"
      type="button"
      value="Next"
      routerLink="../movedetailsfrom"
    />
  </div>
</div>
