import { Component } from '@angular/core';
import { Extrainfo, Orgdata, Volume } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-tetralaval-other-details',
  templateUrl: './tetralaval-other-details.component.html',
  styleUrls: ['./tetralaval-other-details.component.scss'],
})
export class TetralavalOtherDetailsComponent {
  extrainfo: Extrainfo;
  orgdata: Orgdata;
  volume: Volume;

  constructor(
    private formDataService: FormDataService,
    public formData: FormDataService
  ) {
    this.extrainfo = formDataService.extrainfo;
    this.orgdata = formDataService.orgdata;
    this.volume = formDataService.volume;
  }

  //Deselects the Allowance when selecting an incompatible assignment type
  FLH() {
    if (
      this.volume.VolumeLimit == 'Single or unaccompanied 4 m3' ||
      this.volume.VolumeLimit == 'Accompanied 8 m3'
    ) {
      return (this.volume.VolumeLimit = '');
    } else return null;
  }

  onFamilySizeChange(): void {
    // console.log(this.extrainfo.FamilySize)
    switch (this.extrainfo.FamilySize) {
      case 'Single':
        this.extrainfo.numberOfFamily = '1';
        break;
      case 'Couple':
        this.extrainfo.numberOfFamily = '2';
        break;
      case 'Couple or Single plus 1 Child':
        this.extrainfo.numberOfFamily = '3';
        break;
      case 'Couple or Single plus 2 Children':
        this.extrainfo.numberOfFamily = '4';
        break;
      case 'Couple or Single plus 3 Children':
        this.extrainfo.numberOfFamily = '5';
        break;
      case 'Couple or Single plus 4 or more Children':
        this.extrainfo.numberOfFamily = '6 or more';
        break;
      default:
        break;
    }
  }
}
