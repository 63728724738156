import { Component } from '@angular/core';
import { Extrainfo } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-ud-other-details',
  templateUrl: './ud-other-details.component.html',
  styleUrls: ['./ud-other-details.component.scss']
})
export class UdOtherDetailsComponent {

  extrainfo:Extrainfo;

constructor (
  private formDataService : FormDataService ,  
  public formData : FormDataService 
  ) 
  {

  this.extrainfo = formDataService.extrainfo;

}

}
