import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { CompanyService } from '../../services/company.service';
import { Store } from '@ngrx/store';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
    private store: Store
  ) {}

  loadCompanies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Company API] Fetch'),
      switchMap(() => {
        return this.companyService.getCompanies().pipe(
          map((data) => ({
            type: '[Company API] Fetch Success',
            payload: data,
          })),
          catchError((error: Error) =>
            of({ type: '[Company API] Fetch Error', payload: error })
          )
        );
      })
    );
  });
}
