export const departments = [
  { id: 1, departmentName: 'Arbetsmarknadsdepartementet', alfaCustomerNo: '10518' },
  { id: 2, departmentName: 'Finansdepartementet', alfaCustomerNo: '11942' },
  { id: 3, departmentName: 'Försvarsdepartementet', alfaCustomerNo: '12072' },
  { id: 4, departmentName: 'Justitiedepartementet', alfaCustomerNo: '13129' },
  { id: 5, departmentName: 'Klimat- och näringslivsdepartementet', alfaCustomerNo: '14423' },
  { id: 6, departmentName: 'Kulturdepartementet', alfaCustomerNo: '26735' },
  { id: 7, departmentName: 'Landsbygds- och infrastrukturdepartementet', alfaCustomerNo: '38022' },
  { id: 8, departmentName: 'Socialdepartementet', alfaCustomerNo: '20638' },
  { id: 9, departmentName: 'Statsrådsberedningen', alfaCustomerNo: '14858' },
  { id: 10, departmentName: 'Utbildningsdepartementet', alfaCustomerNo: '16066' },
  { id: 11, departmentName: 'Utrikesdepartementet', alfaCustomerNo: '16070' },
];
