import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { fetchCountries } from '../../../store/actions';
import { Content } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';


@Component({
  selector: 'app-private-legal',
  templateUrl: './private-legal.component.html',
  styleUrls: ['./private-legal.component.scss']
})
export class PrivateLegalComponent implements OnInit {

  @Output() accept = new EventEmitter<void>();

  formC: FormConfig;

  accepted = false;
  content : Content;

  constructor(
    private formDataService: FormDataService  ,
    private formConfig: FormConfig,
    private store: Store,
  ) {

    this.formC = formConfig;

    this.content = formDataService.content;
  }

  ngOnInit(): void {
      this.store.dispatch(fetchCountries());
  }


  isReadMore = true;
  showText() {
    this.isReadMore = !this.isReadMore;
  }

}
