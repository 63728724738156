import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-end-form",
  templateUrl: "./end-form.component.html",
  styleUrls: ["./end-form.component.scss"],
})
export class EndFormComponent {
  company: any = "";

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.company = this.route.snapshot.queryParamMap.get("company");
    if (
      this.company != "Tetra laval" &&
      this.company != "Alfa Laval Corporate AB" &&
      this.company != "Finland Private" &&
      this.company != "Udenrigsministeriet"
    ) {
      this.company = "Others";
    }
    console.log(this.company);
  }
}
