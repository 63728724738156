<div *ngIf="company == 'Tetra laval'">
    <h3>Thank you!</h3>
    <hr />
    <p>Thank you for your initiation on Alfa Mobility booking site.</p>
    <p>
        Your booking has been sent to Tetra Laval Global Mobility for policy
        approval.
    </p>
    <p>
        If you have any questions, please contact your HR / Global Mobility
        department.
    </p>
</div>

<div *ngIf="company == 'Alfa Laval Corporate AB'">
    <h3>Thank you!</h3>
    <hr />
    <p>Thank you for your initiation on Alfa Mobility booking site.</p>
    <p>Your booking has been sent to IPM for policy approval.</p>
    <p>If you have any questions, please contact IPM or your HR.</p>
</div>

<div *ngIf="company == 'Finland Private'">
    <h3>Thank you!</h3>
    <hr />
    <p>Thank you for your initiation on Alfa Mobility booking site.</p>
    <p>
        You'll receive a confirmation e-mail shortly and you will be contacted
        by an Alfa representative within 2 working days.
    </p>
    <p>
        If you have any further questions, please don’t hesitate to reach out to
        us at newordersFI&#64;alfamoving.com or call us at +358(0)207931100.
    </p>
</div>

<div *ngIf="company == 'Udenrigsministeriet'">
    <h3>Thank you!</h3>
    <hr />
    <p>Thank you for your initiation on Alfa Mobility booking site.</p>
    <p>We will contact your assignee shortly.</p>
    <p>
        If you need to send us additional information, please email us on
        UM&#64;alfamoving.com
    </p>
    <p>
        Alfa Mobility A/S<br />
        Helseholmen 10<br />
        DK - 2650 Hvidovre<br />
        um&#64;alfamoving.com<br />
        Tel +45 43 53 06 40<br />
    </p>
</div>

<div *ngIf="company == 'Others'">
    <h3>Thank you!</h3>
    <hr />
    <p>Thank you for your initiation on Alfa Mobility booking site.</p>
    <p>
        You'll receive a confirmation e-mail shortly and you will be contacted
        by an Alfa representative within 2 working days.
    </p>
    <p>
        If you have any further questions, please don’t hesitate to reach out to
        us at info&#64;alfamoving.com or call us at +46 (0) 10 331 38 00.
    </p>
</div>
