import { Component } from '@angular/core';
import { Extrainfo } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-handelsbanken-other-details',
  templateUrl: './handelsbanken-other-details.component.html',
  styleUrls: ['./handelsbanken-other-details.component.scss']
})
export class HandelsbankenOtherDetailsComponent {

  extrainfo:Extrainfo;

constructor (
  private formDataService : FormDataService ,  
  public formData : FormDataService
  ) 
  {

  this.extrainfo = formDataService.extrainfo;

}

}
