import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-oriflame',
  templateUrl: './oriflame.component.html',
  styleUrls: ['./oriflame.component.scss']
})
export class OriflameComponent {

  extrainfo:Extrainfo;
  content:Content;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.content.SokID = '14496';
    this.extrainfo.CompanyName = 'Oriflame Cosmetics AB';
    this.extrainfo.CompanyAddress = ' ';
    this.extrainfo.CompanyCity = ' ';
    this.extrainfo.CompanyCountry = ' ';
    this.extrainfo.CompanyZipCode = ' ';
    this.extrainfo.CompanyContact = '08 586 32 300';
    this.extrainfo.insurance = 'All shipments and storage portions are insured through Alfa. A valuation form will be sent to you by our move mangers upon confirming your move. It is very important that this form is filled in and returned prior to packing.';
    this.extrainfo.Legal = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';
    this.extrainfo.lockCompany = true;

    this.formConfig.confirm = false; //Determines if form need approval by manager

  //Form configuraiton
    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'Oriflame';
  }

}
