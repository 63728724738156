import { Component } from '@angular/core';
import { Content, Extrainfo, Orgdata } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-your-details',
  templateUrl: './your-details.component.html',
  styleUrls: ['./your-details.component.scss'],
})
export class YourDetailsComponent {
  formC: FormConfig;
  orgdata: Orgdata;
  content: Content;
  extrainfo: Extrainfo;

  constructor(
    private formDataService: FormDataService,
    private formConfig: FormConfig
  ) {
    this.content = formDataService.content;
    this.orgdata = formDataService.orgdata;
    this.extrainfo = formDataService.extrainfo;
    this.formC = formConfig;
  }

  // ngOnInit() {
  //   console.log(this.extrainfo);
  // }
}
