<h3>Insurance</h3>
<hr />

<p>
  Please follow the instructions from Kammarkollegiet regarding the insurance of
  the household goods. For any upcoming questions, please contact
  Kammarkollegiet directly.
</p>
<p>
  I have taken part of Sidas and Kammarkollegiets information regarding the
  insurance of goods. I am aware that Sida covers an insurance value of up to
  800 000 SEK and that you will have to cover any cost above that yourself
  including additional insurances.
</p>

<label class="checkbox">
  <input
    type="checkbox"
    name="insurance"
    value="yes"
    [(ngModel)]="extrainfo.iAccept"
    #insurance="ngModel"
    required
  />
  <span>I understand</span>
</label>

<h3>Other details</h3>
<hr />

<div class="radio-container">
  <h4>Do you have goods stored that you wish to include in the move?</h4>

  <label class="radio">
    <input
      type="radio"
      value="Yes"
      name="sidaMoveStorage"
      [(ngModel)]="extrainfo.InStorage"
    />
    <span>Yes</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="No"
      name="sidaMoveStorage"
      [(ngModel)]="extrainfo.InStorage"
    />
    <span>No</span>
  </label>

  <h4>
    Do you wish for part of your move goods to be stored during your stay
    abroad?
  </h4>

  <label class="radio">
    <input
      type="radio"
      value="Yes"
      name="sidaRequireStorage"
      [(ngModel)]="extrainfo.ToStorage"
    />
    <span>Yes</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="No"
      name="sidaRequireStorage"
      [(ngModel)]="extrainfo.ToStorage"
    />
    <span>No</span>
  </label>

  <h4>
    Will you be staying in furnished accomdations abroad? (Don't fill for home
    stationing)
  </h4>

  <label class="radio">
    <input
      type="radio"
      value="Yes"
      name="sidaFurnished"
      [(ngModel)]="extrainfo.Furnished"
    />
    <span>Yes</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="No"
      name="sidaFurnished"
      [(ngModel)]="extrainfo.Furnished"
    />
    <span>No</span>
  </label>

  <h4>
    Will someone pack together with your goods? (Please observe any civil
    servant packing together will have to cover any potential shipping costs or
    insurances themselves)
  </h4>

  <label class="radio">
    <input
      type="radio"
      value="Yes"
      name="sidaPacking"
      [(ngModel)]="extrainfo.Sampackade"
    />
    <span>Yes</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="No"
      name="sidaPacking"
      [(ngModel)]="extrainfo.Sampackade"
    />
    <span>No</span>
  </label>

  <h4>Type of contract</h4>

  <label class="radio">
    <input
      type="radio"
      value="Short term"
      name="sidaContractType"
      [(ngModel)]="extrainfo.ContractType"
    />
    <span>Short term</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Expat"
      name="sidaContractType"
      [(ngModel)]="extrainfo.ContractType"
    />
    <span>Expat</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Local"
      name="sidaContractType"
      [(ngModel)]="extrainfo.ContractType"
    />
    <span>Local</span>
  </label>
</div>

<label for="textbox"><h4>Other info</h4></label>
<textarea
  class="itextfield"
  name="otherDetails"
  id="textbox"
  cols="30"
  rows="10"
  [(ngModel)]="extrainfo.OtherInfo"
  itext="Please type here if you have something to add.."
>
</textarea>

<div class="button-container">
  <input
    class="button btn-form"
    type="button"
    value="Back"
    routerLink="../movedetailsto"
  />
  <input
    [disabled]="!insurance.valid"
    class="button btn-form"
    type="button"
    value="Submit"
    (click)="formData.formSubmit()"
  />
</div>
