<div>
    <div>
        <h3>Other details</h3>
        <hr />
        
    </div>
    <h4>Allowance</h4>
    
    <label class="radio">
      <input
        type="radio"
        value="Single"
        [(ngModel)]="volume.VolumeLimit"
        name="volumeLimit"
      />
      <span>Single</span> </label
    >
  
    <label class="radio">
      <input
        type="radio"
        value="Accompanied spouse/life partner"
        [(ngModel)]="volume.VolumeLimit"
        name="volumeLimit"
      />
      <span>Accompanied spouse/life partner</span> </label
    >
  
    <label class="radio">
      <input
        type="radio"
        value="Accompanied with 1 child"
        [(ngModel)]="volume.VolumeLimit"
        name="volumeLimit"
      />
      <span>Accompanied with 1 child</span> </label
    >
  
    <label class="radio">
      <input
        type="radio"
        value="Accompanied with 2 children or more"
        [(ngModel)]="volume.VolumeLimit"
        name="volumeLimit"
      />
      <span>Accompanied with 2 children or more</span> </label
    >

    <div>

<label for="textbox"><h4>Other info</h4></label>
<textarea
  class="itextfield"
  name="otherDetails"
  id="textbox"
  cols="30"
  rows="10"
  [(ngModel)]="extrainfo.OtherInfo"
  placeholder="Please let us know here here if there's anything we should know to best help you.."
>
</textarea>

<div class="button-container"
  >
  
  <input
    class="button btn-form"
    type="button"
    value="Back"
    routerLink="../movedetailsto"
  />
  <input
    class="button btn-form"
    type="button"
    value="Submit"
    (click)="formData.formSubmit()"
  />
</div>
  </div>