import { createSelector } from '@ngrx/store';
import { UserState } from '../reducers/user.reducer';
import { User } from '../../models/user';

export const selectUser = (state: any) => {
  // console.log('State: ', state);
  return state.user;
}

/* Entire state */
export const selectUserState = createSelector(
  selectUser,
  (state: any) => state.user
);

/* Content */
export const selectUserContent = createSelector(
  selectUser,
  (state: any) => state.user.content
);

/* Allowed functions only */
export const selectUserAllowFunctions = createSelector(
  selectUser,
  (state: any) => {
    // console.log('* state : ', state);
    return state.user.content.allowFunctions;
  }
);
