import { Component } from '@angular/core';
import { Extrainfo, Volume } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-pwc-other-details',
  templateUrl: './pwc-other-details.component.html',
  styleUrls: ['./pwc-other-details.component.scss']
})
export class PwcOtherDetailsComponent {

  extrainfo:Extrainfo;
  volume:Volume;

  constructor (
    private formDataService : FormDataService ,  
    public formData : FormDataService
    ) 
    {
  
    this.extrainfo = formDataService.extrainfo;
    this.volume = formDataService.volume;
  
  }

}
