<div>
  <h3>Insurance</h3>
  <hr />
  <p>Alfa will cover insurance</p>

  <h3>Other details</h3>
  <hr />
</div>

<div class="radio-container">
  <!-- HANDELSBANKEN SECTION 2 -->
  <h4>Do you have any goods in storage to be included in your move?</h4>
  <label class="radio">
    <input
      type="radio"
      value="Yes"
      [(ngModel)]="extrainfo.InStorage"
      name="InStorage"
    />
    <span>Yes</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="No"
      [(ngModel)]="extrainfo.InStorage"
      name="InStorage"
    />
    <span>No</span>
  </label>
</div>

<!-- <h4>Do you need storage of max 10 cbm</h4>
<div class="radio-container">
  <label class="radio">
    <input
      type="radio"
      value="yes"
      [(ngModel)]="extrainfo.ToStorage"
      name="ToStorage"
    />
    <span>Yes</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="no"
      [(ngModel)]="extrainfo.ToStorage"
      name="ToStorage"
    />
    <span>No</span>
  </label>
</div> -->

<h4>
  Would you like for us to arrange move-out cleaning of your current residence?
</h4>

<div class="radio-container">
  <label class="radio">
    <input
      type="radio"
      value="yes"
      [(ngModel)]="extrainfo.CleaningOffer"
      name="CleaningOffer"
    />
    <span>Yes</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="no"
      [(ngModel)]="extrainfo.CleaningOffer"
      name="CleaningOffer"
    />
    <span>No</span>
  </label>
</div>

<label for="textbox"><h4>Other info</h4></label>
<textarea
  class="itextfield"
  name="otherDetails"
  id="textbox"
  cols="30"
  rows="10"
  [(ngModel)]="extrainfo.OtherInfo"
  placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
>
</textarea>

<div class="button-container">
  <input
    class="button btn-form"
    type="button"
    value="Back"
    routerLink="../movedetailsto"
  />
  <input
    class="button btn-form"
    type="button"
    value="Submit"
    (click)="formData.formSubmit()"
  />
</div>
