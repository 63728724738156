import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllCountries } from '../../../../store/selectors';
import { Destdata, Extrainfo, Orgdata } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';
import { Country } from '../../../../models/country';

@Component({
  selector: 'app-move-details',
  templateUrl: './move-details.component.html',
  styleUrls: ['./move-details.component.scss'],
})
export class MoveDetailsComponent implements OnInit {
  extrainfo: Extrainfo;
  orgdata: Orgdata;
  destdata: Destdata;

  countries: Country[];

  constructor(
    private formDataService: FormDataService,
    public formData: FormDataService,
    private store: Store
  ) {
    this.extrainfo = formDataService.extrainfo;
    this.orgdata = formDataService.orgdata;
    this.destdata = formDataService.destdata;
  }

  ngOnInit(): void {
    this.store.select(selectAllCountries).subscribe((data) => {
      this.countries = data;
    });
  }
}
