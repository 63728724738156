import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';

import { Company } from '../models/company';

import { companies } from '../../data/companies';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private companies$: Observable<Company[]> = of(companies);

  getCompanies(): Observable<Company[]> {
    return this.companies$;
  }

}
