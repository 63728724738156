import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fetchCountries } from 'src/app/store/actions';
import { selectAllCountries } from 'src/app/store/selectors';
import {
  BussinessAgreementinfo,
  Content,
  Destdata,
  Extrainfo,
  Orgdata,
  Services,
} from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';
import { Country } from 'src/app/models/country';

@Component({
  selector: 'app-private-booking',
  templateUrl: './private-booking.component.html',
  styleUrls: ['./private-booking.component.scss'],
})
export class PrivateBookingComponent implements OnInit {
  formC: FormConfig;
  extrainfo: Extrainfo;
  bussiness: BussinessAgreementinfo;
  content: Content;
  orgdata: Orgdata;
  destdata: Destdata;
  services: Services;
  accepted = false;

  countries: Country[];

  constructor(
    private formDataService: FormDataService,
    private formConfig: FormConfig,
    public formData: FormDataService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.dispatch(fetchCountries());
    this.store.select(selectAllCountries).subscribe((data) => {
      this.countries = data;
    });
    this.formDataService.reset();
    this.formC = this.formConfig;
    this.formConfig.confirm = true;

    this.bussiness = this.formDataService.bussiness;
    this.extrainfo = this.formDataService.extrainfo;
    this.content = this.formDataService.content;
    this.orgdata = this.formDataService.orgdata;
    this.destdata = this.formDataService.destdata;
    this.services = this.formDataService.services;

    this.content.SokID = '00000';
    this.extrainfo.language = 'en';
    this.extrainfo.CompanyName = 'Finland Private';
    this.formConfig.office = '2';
  }
}
