<div class="welcome-header">
  <h2>Peace of mind for people on the move</h2>
</div>
<h3>Welcome!</h3>
<hr />
<p>Thank you for your interest in our services at Alfa.</p>

<p>
  There is no substitute for experience. Alfa has delivered mobility and
  assignment services since 1995. That gives us a rare perspective - and a sense
  of responsibility.
</p>
<p>
  We have a strong assignment management focus. It is due to our obsession with
  details for people on the move. We believe that distinctive productivity
  enhances everyday life. Our focus on quality, care and sustainability adds
  value. It enriches experience. It makes a difference.
</p>

<p>
  Once you have completed this online form, you will be contacted by an Alfa
  representative within 2 working days.
</p>
<p>
  Our core purpose: To deliver sustainable global mobility for people and
  businesses.
</p>
<p>Fields marked with * are required.</p>

<h3>Privacy notice</h3>
<hr />
<p>
  This notice explains our practices with regard to the European Union’s General
  Data Protection Regulation (GDPR) principles and describes how Alfa processes
  your personal information.
</p>
<!-- TODO: Style the read more text and headers -->
<div [ngClass]="{ hideText: isReadMore, showText: !isReadMore }">
  <h4>General information</h4>
  <p>
    Alfa Mobility (Alfa) is the Data Controller when you as an individual buy
    our services and trust us your privacy information to process on your behalf
    while delivering services. Alfa is the Data Processor in those cases when
    your employer hires Alfa to deliver agreed services. In those cases, your
    employer acts as the Data Controller and shall inform you more about the
    processing activities (including Privacy Policy) that take place during
    delivered services and risks related to them. As Data Processor Alfa
    processes your personal information only according to the instructions
    received from your employer to fulfil contractual obligations. If you have
    any questions, concerns, or comments about our Privacy Notice or how we use
    your information, you may contact us using the information below:
    <a href="mailto:dpoteam&#64;alfamoving.com">dpoteam&#64;alfamoving.com</a>
  </p>

  <h5>Choice and consent</h5>
  <p>
    By becoming a customer, you give your explicit consent with respect to the
    collection, use, and disclosure of your personal information as described in
    this Privacy Notice. Explicit consent here means you were clearly presented
    with an option to agree or disagree with the collection, use, or disclosure
    of your personal information.
  </p>

  <h5>Categories of Personal Data</h5>

  <p>
    Alfa processes such personal information as name, email, personal ID-number,
    bank account information, passport copy and other details or documentation
    that are required to deliver relevant services. Regulatory and legislative
    requirements extend or limit the amount of information that Alfa may need to
    process. We may process also sensitive information if it is required to
    comply with applicable law.
  </p>

  <h5>Categories of Data Subjects</h5>

  <p>
    You as customer, your family members and other contact persons provided by
    you.
  </p>

  <h5>The purpose of the processing</h5>

  <p>
    Alfa collects and processes appropriate information only to the extent that
    is needed to fulfil contractual and legal obligations.
  </p>

  <h5>The legal basis for processing</h5>

  <p>Legal basis for collecting and processing your personal information are:</p>
  <ul>
    <li>
      <p>
        your consent for the purposes listed in this Privacy Notice
      </p>
    </li>
    <li>
      <p>
        it is necessary for carrying out contractual obligations and
        exercising specific rights
      </p>
    </li>
    <li>
      <p>
        Alfa may be required to comply with certain legal or governmental
        obligations.
      </p>
    </li>
  </ul>

  <h5>Collection</h5>

  <p>
    We shall obtain and process personal data fairly and in accordance with
    statutory and other legal obligations. We collect personal information for
    the sole purposes to accommodate customers before, during and after their
    assignment has been carried out.
  </p>

  <h5>Cookies</h5>

  <p>
    A ‘cookie’ is a small text file that your device is asked to save when you
    visit a particular web-site. On our web-site alfamoving.com, Alfa uses
    strictly necessary cookies which ensure technical security and functionality
    of the web-site. No cookies are involved when a visitor opens the home page
    of our website. Nor are cookies used to collect or analyse your personal
    information. No cookies are shared with third parties.
  </p>

  <h5>Use, retention, and disposal</h5>

  <p>
    We limit the use of personal information to the sole purpose of executing
    your assignment and for which you have given your implicit consent. We
    retain personal information for only as long as necessary to fulfil the
    stated purposes or as required by law or regulations and thereafter
    appropriately dispose of such information. For example, we will retain your
    personal information as long as you are a customer and therefore subject of
    an Alfa assignment.
  </p>

  <h5>Categories of recipients</h5>

  <p>
    We will only share your personal information with those third parties that
    are necessary for delivering of services. For example, suppliers of
    services, suppliers of IT-services, Tax, Customs and other relevant
    authorities, banks, etc. We will never sell your personal information to
    third parties. We ensure that everyone processing personal information
    understands that they are contractually responsible for following good data
    protection practice aligned with internal procedures and legal requirements.
    We collect, process and transfer personal information about customer through
    digital and paper-based data processing systems. We have established routine
    processing functions. We ensure that all processing and transfers of
    personal information are subject to reasonable confidentiality and privacy
    safeguards.
  </p>

  <h5>Transfer to third parties</h5>

  <p>
    In those cases when we help you relocate to countries outside of the EU/EEA
    when we need to cooperate and share your personal information with our
    suppliers in those specific countries, it is done in accordance with this
    policy and with the applicable law. Alfa uses Standard Contractual Clauses
    (SCC) undertaken by the EU Commission for doing a secure transfer of your
    personal information to a third party outside of the EU/EEA.
  </p>

  <h5>Security for privacy</h5>

  <p>
    We protect personal data against unauthorized access (both physical and
    logical) aligned with our internal information security policy and
    procedures. Our Information Security Management System is certified against
    ISO/IEC 27001 by an accredited independent certification body. We shall take
    appropriate security measures against unauthorised access to, or alteration,
    disclosure or destruction of personal data and against its accidental loss
    or destruction. Personal data will only be accessible to authorised and
    trained staff. To make sure you share your personal information in a secure
    way we encourage you to consider using the available technological solutions
    (platforms and systems) that Alfa provides you with. If you encounter
    difficulties in being able to do that let your assignment coordinator at
    Alfa know about it.
  </p>

  <h5>Quality</h5>

  <p>
    We maintain accurate, complete, and relevant personal information as
    reasonable as possible and only for the purposes identified in this notice.
    You may reasonably access and update your personal information by contacting
    Alfa. Contact details: dpoteam&#64;alfamoving.com We retrieve your personal data
    from our business systems and corresponding e-mails. Please note that we
    have shared responsibility with regard to the accuracy of your personal
    information. Please let us know of any changes to your personal information.
  </p>

  <h5>Monitoring and enforcement</h5>

  <p>
    We monitor compliance with our privacy policies and procedures and have
    procedures to address privacy related complaints and disputes. All Alfa
    staff is aware that a breach of the rules and procedures identified in this
    policy may lead to disciplinary action being taken against them. If you
    believe that your personal information is not handled in accordance with the
    applicable law or our Privacy Notice, you may submit a complaint to Alfa’s
    DPO Team who will investigate the complaint. You may also submit a complaint
    directly to the data protection authority where Alfa has offices in Nordics.
  </p>
</div>

<button class="btn-read" type="button" (click)="isReadMore = !isReadMore">
  {{ isReadMore ? 'Read More' : 'Hide' }}
</button>


<div class="legal-confirmation">
  <p>Do you accept?</p>
  <label class="checkbox">
    <input
      type="checkbox"
      name="acceptLegal"
      value="yes"
      [(ngModel)]="content.acceptLegal"
      #acceptLegal="ngModel"
      required
    />
    <span>Yes, I accept</span> </label>
</div>

<div class="button-container">
  <div >
    <input
      [disabled]="!acceptLegal.valid"
      class="button btn-form"
      type="button"
      value="Next"
      (click)="accept.emit()"
    />
  </div>



  <div
    [ngClass]="{ hideText: acceptLegal.valid }"
    class="legal-box"
  >
    <p
      [ngClass]="{ hideText: content.acceptLegal === 'yes' }"
      class="legal-accept"
    >
      Please accept the legal terms in order to proceed.
    </p>
  </div>
</div>
