import { Component } from '@angular/core';
import { FormDataService } from 'src/app/services/form-data.service';
import { FormConfig } from 'src/app/services/form-config';
import { Content, Extrainfo } from 'src/app/models/form/book-data';


@Component({
  selector: 'app-hm',
  templateUrl: './hm.component.html',
  styleUrls: ['./hm.component.scss']
})
export class HmComponent {

  content:Content;
  extrainfo:Extrainfo;

  
  
  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.extrainfo.CompanyName = 'H&M Hennes & Mauritz GBC AB';
    this.content.SokID = '12487';
    this.extrainfo.CompanyContact = '08-7965500';
    this.extrainfo.CompanyAddress = 'Att: 5590';
    this.extrainfo.CompanyCity = 'Stockholm';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = '105 47';
    this.extrainfo.insurance = 'All shipments and storage portions are insured through Alfa. A valuation form will be sent to you by our move managers upon confirming your move. It is very important that this form is completed and returned prior to packing.';
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';

    this.formConfig.confirm = false; //Determines if form need approval by manager

    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'hm';
  }
}
