import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';

import { UDdepartment } from '../models/ud-department';

import { departments } from '../../data/ud-departement';

@Injectable({
  providedIn: 'root'
})
export class UDDepartmentService {

  private _departments$: Observable<UDdepartment[]> = of(departments);

  getDepartments(): Observable<UDdepartment[]> {
    return this._departments$;
  }

}
