<div>
  <div>
    <h3>Other details</h3>
    <hr />
    <br />
  </div>

  <div class="form-container">
    <label class="icontainer">
      Approved relocation allowance covered by Northvolt *:
      <input
        class="ifield"
        type="text"
        name="ApprovedRelocationAllowance"
        [(ngModel)]="extrainfo.ApprovedRelocationAllowance"
        #ApprovedRelocationAllowance="ngModel"
      />
    </label>

    <label class="icontainer">
      Cost center *:
      <input
        class="ifield"
        type="text"
        name="CostCenter"
        [(ngModel)]="extrainfo.CostCenter"
        #ApprovedRelocationAllowance="ngModel"
      />
    </label>

    <label class="icontainer">
      Paying company *:
      <input
        class="ifield"
        type="text"
        name="payingCompany"
        [(ngModel)]="extrainfo.payingCompany"
        #ApprovedRelocationAllowance="ngModel"
      />
    </label>
  </div>

  <label for="textbox"><h4>Additional information</h4></label>
  <textarea
    class="itextfield"
    name="otherDetails"
    id="textbox"
    cols="30"
    rows="10"
    [(ngModel)]="extrainfo.OtherInfo"
    placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
  ></textarea>

  <div class="button-container">
    <!-- BASIC BUTTONS -->
    <input
      class="button btn-form"
      type="button"
      value="Back"
      routerLink="../movedetailsto"
    />
    <input
      class="button btn-form"
      type="button"
      value="Submit"
      (click)="formData.formSubmit()"
      [disabled]="
        extrainfo.ApprovedRelocationAllowance == undefined ||
        extrainfo.ApprovedRelocationAllowance.length === 0 ||
        extrainfo.CostCenter == undefined ||
        extrainfo.CostCenter.length === 0 ||
        extrainfo.payingCompany == undefined ||
        extrainfo.payingCompany.length === 0
      "
    />
  </div>
</div>
