import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { Data } from '../../../../models/data';
import { Detail } from '../../../../models/detail';

@Component({
  selector: 'app-assignment2-detail',
  templateUrl: './assignment2-detail.component.html',
  styleUrls: ['./assignment2-detail.component.scss']
})
export class Assignment2DetailComponent implements OnInit {

  @Input() public id!: string;

  data!: Data;
  detail!: Detail;
  loading = true;

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.getDataDetail('Ass_Asigne', true, this.id);
  }

  getDataDetail(_function: string, _detail: boolean, _id: string) {
    this.dataService.getDataDetail(_function, _detail, _id).subscribe({
      next: (response: Data) => {
        // console.log('Detail : ', response.content.detail);
        this.detail = response.content.detail;
        this.loading = false;
      },
      error: (error: Error) => {
        console.error(error);
        this.loading = false;
      }
    });
  }

}
