import { Component } from '@angular/core';
import { Extrainfo } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-aires-other-details',
  templateUrl: './aires-other-details.component.html',
  styleUrls: ['./aires-other-details.component.scss']
})
export class AiresOtherDetailsComponent {

  extrainfo:Extrainfo;
  
constructor (
  private formDataService : FormDataService ,  
  public formData : FormDataService
  ) 
  {

  this.extrainfo = formDataService.extrainfo;

}


}
