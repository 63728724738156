<!-- TODO: Improve logic for ngif, not basing it on an input -->
<div
    *ngIf="
        formC.formCompanyName != 'Tetralaval' &&
        formC.formCompanyName != 'alfalaval' &&
        formC.formCompanyName != 'ericsson' &&
        formC.formCompanyName != 'scania' &&
        formC.formCompanyName != 'uoh' &&
        formC.formCompanyName != 'ud' &&
        formC.formCompanyName != 'migrationsverket' &&
        formC.formCompanyName != 'saab'
    "
    class="animate-fadeIn"
>
    <h3>Company details</h3>
    <hr />
    <!-- TODO: fix so that company name is determined by other means -->
    <div class="form-container">
        <label class="icontainer">
            <input
                [disabled]="formC.formDisabled"
                type="text"
                class="ifield"
                placeholder="&nbsp;"
                name="companyName"
                [(ngModel)]="extrainfo.CompanyName"
                #companyName
            />
            <span class="itext">Company name*</span>
        </label>

        <label class="icontainer">
            <input
                [disabled]="formC.formDisableAll"
                type="text"
                class="ifield"
                placeholder="&nbsp;"
                name="companyAddress"
                [(ngModel)]="extrainfo.CompanyAddress"
                #companyAdress
            />
            <span class="itext">Address</span>
        </label>

        <label class="icontainer">
            <input
                [disabled]="formC.formDisableAll"
                type="text"
                class="ifield"
                placeholder="&nbsp;"
                name="companyCity"
                [(ngModel)]="extrainfo.CompanyCity"
                #companyCity
            />
            <span class="itext">City</span>
        </label>

        <label class="icontainer">
            <select
                class="iselect"
                name="companyCountry"
                [disabled]="formC.formDisableAll"
                [(ngModel)]="extrainfo.CompanyCountry"
                #companyCountry
            >
                <option
                    *ngFor="let country of countries"
                    [value]="country.countrycode"
                >
                    {{ country.countryname }}
                </option>
            </select>
            <span
                class="itext"
                [ngClass]="{
                    'itext-select': extrainfo.CompanyCountry != undefined
                }"
                >Country</span
            >
        </label>

        <label class="icontainer">
            <input
                [disabled]="formC.formDisableAll"
                type="text"
                class="ifield"
                placeholder="&nbsp;"
                name="companyZipcode"
                [(ngModel)]="extrainfo.CompanyZipCode"
                #companyZipcode
            />
            <span class="itext">Zip code</span>
        </label>
    </div>
</div>
<!-- form container end -->

<div
    *ngIf="
        formC.formCompanyName == 'Handelsbanken' ||
        formC.formCompanyName == 'mtg' ||
        formC.formCompanyName == 'ABB' ||
        formC.formCompanyName == 'Oriflame' ||
        formC.formCompanyName == 'Sandvik' ||
        formC.formCompanyName == 'Swedbank'
    "
>
    <!-- APPROVAL EMAIL -->
    <h3>Manager details</h3>
    <hr />

    <label class="icontainer">
        <input
            class="ifield"
            placeholder="&nbsp;"
            type="text"
            name="approvingManagerName"
            [(ngModel)]="extrainfo.ApprovingManagerName"
            #approvingManagerName
        />
        <span class="itext">Approving manager name*</span>
    </label>

    <label class="icontainer">
        <input
            class="ifield"
            placeholder="&nbsp;"
            type="email"
            name="approvingManagerEmail"
            [(ngModel)]="extrainfo.ApprovingManagerEmail"
            #approvingManagerEmail="ngModel"
            required
            email
        />
        <span class="itext">Approving manager email*</span>
        <span
            class="erval"
            *ngIf="
                !approvingManagerEmail.valid && approvingManagerEmail.touched
            "
            >Please enter a correct email</span
        >
    </label>

    <div class="button-container">
        <input
            class="button btn-form"
            type="button"
            value="Back"
            routerLink="../legalnotice"
        />
        <input
            [disabled]="!approvingManagerEmail.valid"
            class="button btn-form"
            type="button"
            value="Next"
            routerLink="../yourdetails"
        />
    </div>
    <!-- APPROVAL EMAIL END -->
</div>

<div *ngIf="formC.formCompanyName == 'SEB'">
    <!-- *HR* APPROVAL EMAIL -->
    <h3>HR Manager details</h3>
    <label class="icontainer">
        <input
            class="ifield"
            placeholder="&nbsp;"
            type="email"
            name="approvingManagerEmail"
            [(ngModel)]="extrainfo.ApprovingManagerEmail"
            #approvingManagerEmail="ngModel"
            required
            email
        />
        <span class="itext">Email to approving HR Manager*</span>
        <span
            class="erval"
            *ngIf="
                !approvingManagerEmail.valid && approvingManagerEmail.touched
            "
            >Please enter a correct email</span
        >
    </label>

    <div class="button-container">
        <input
            class="button btn-form"
            type="button"
            value="Back"
            routerlink="../legalnotice"
        />
        <input
            [disabled]="!approvingManagerEmail.valid"
            class="button btn-form"
            type="button"
            value="Next"
            routerLink="../yourdetails"
        />
    </div>
    <!-- *HR* APPROVAL EMAIL END -->
</div>

<!-- Skip company-details and jump to your-details -->
<div *ngIf="formC.formCompanyName == 'saab'">
    <app-your-details></app-your-details>
</div>

<!-- <div *ngIf="formC.formCompanyName == 'saab'">
    <div class="button-container">
        <input
            class="button btn-form"
            type="button"
            value="Back"
            routerLink="../legalnotice"
        />
        <input
            class="button btn-form"
            type="button"
            value="Next"
            routerLink="../yourdetails"
        />
    </div>
</div> -->

<div *ngIf="formC.formCompanyName == 'ericsson'">
    <app-ericsson-company-details></app-ericsson-company-details>
</div>

<div *ngIf="formC.formCompanyName == 'pwc'">
    <!-- PWC -->
    <h3>Manager details</h3>
    <div class="form-container">
        <label class="icontainer">
            <input
                class="ifield"
                placeholder="&nbsp;"
                type="email"
                name="approvingManagerEmail"
                [(ngModel)]="extrainfo.ApprovingManagerEmail"
                #approvingManagerEmail="ngModel"
                required
                email
            />
            <span class="itext">Email to the approving manager*</span>
            <span
                class="erval"
                *ngIf="
                    !approvingManagerEmail.valid &&
                    approvingManagerEmail.touched
                "
                >Please enter a correct email</span
            >
        </label>

        <label class="icontainer">
            <input
                class="ifield"
                placeholder="&nbsp;"
                type="text"
                name="approvingManagerName"
                [(ngModel)]="extrainfo.ApprovingManagerName"
                #approvingManagerName
            />
            <span class="itext">Name of the approving manager</span>
        </label>

        <label class="icontainer">
            <input
                class="ifield"
                placeholder="&nbsp;"
                type="text"
                name="oriflameManagerName"
                [(ngModel)]="extrainfo.ApprovingManagerName"
                #oriflameManagerName
            />
            <span class="itext">Approving manager employee number</span>
        </label>
    </div>

    <h3>Paying country</h3>

    <label class="radio">
        <input
            type="radio"
            value="Sweden"
            name="payingCountry"
            [(ngModel)]="extrainfo.payingCountry"
        />
        <span>Sweden</span>
    </label>

    <label class="radio">
        <input
            type="radio"
            value="Norway"
            name="payingCountry"
            [(ngModel)]="extrainfo.payingCountry"
        />
        <span>Norway</span>
    </label>

    <label class="radio">
        <input
            type="radio"
            value="I am not sure"
            name="payingCountry"
            [(ngModel)]="extrainfo.payingCountry"
        />
        <span>I'm not sure</span>
    </label>

    <div class="button-container">
        <input
            class="button btn-form"
            type="button"
            value="Back"
            routerlink="../legalnotice"
        />
        <input
            [disabled]="!approvingManagerEmail.valid"
            class="button btn-form"
            type="button"
            value="Next"
            routerLink="../yourdetails"
        />
    </div>
    <!-- PWC END -->
</div>

<div *ngIf="formC.formCompanyName == 'CEVT'">
    <!-- CEVT -->
    <h3>Approving details</h3>
    <hr />
    <label class="icontainer">
        <input
            class="ifield"
            placeholder="&nbsp;"
            type="email"
            name="cevtApprovalEmail"
            [(ngModel)]="extrainfo.ApprovingManagerEmail"
            #cevtApprovalEmail="ngModel"
            required
            email
        />
        <span class="itext">Email to approving manager*</span>
        <span
            class="erval"
            *ngIf="!cevtApprovalEmail.valid && cevtApprovalEmail.touched"
            >Please enter a correct email</span
        >
    </label>

    <h3>HR rep details</h3>
    <hr />
    <div class="form-container">
        <label class="icontainer">
            <input
                class="ifield"
                placeholder="&nbsp;"
                type="email"
                name="cevtHrEmail"
                [(ngModel)]="extrainfo.hrEmail"
                #cevtHrEmail="ngModel"
                required
                email
            />
            <span class="itext">Email to HR rep*</span>
            <span
                class="erval"
                *ngIf="!cevtHrEmail.valid && cevtHrEmail.touched"
                >Please enter a correct email</span
            >
        </label>
        <label class="icontainer">
            <input
                class="ifield"
                placeholder="&nbsp;"
                type="number"
                name="cevtInvoicing"
                [(ngModel)]="extrainfo.CostCenter"
                #cevtInvoicing="ngModel"
                required
            />
            <span class="itext"
                >Cost centre & purchase order number for invoicing*</span
            >
            <span
                class="erval"
                *ngIf="!cevtInvoicing.valid && cevtInvoicing.touched"
                >Please enter a cost centre and/or purchase order number for
                invoicing.</span
            >
        </label>
    </div>
    <div class="button-container">
        <input
            class="button btn-form"
            type="button"
            value="Back"
            routerLink="../legalnotice"
        />
        <input
            [disabled]="
                !cevtApprovalEmail.valid ||
                !cevtInvoicing.valid ||
                !cevtHrEmail.valid
            "
            class="button btn-form"
            type="button"
            value="Next"
            routerLink="../yourdetails"
        />
    </div>
    <!-- CEVT END-->
</div>

<div *ngIf="formC.formCompanyName == 'hm'">
    <!-- HM company page -->
    <h3>HR Manager details</h3>
    <p>Email to approving HR Manager (HR in new/host country)</p>
    <label class="icontainer">
        <input
            class="ifield"
            placeholder="&nbsp;"
            type="email"
            name="hmApprovalEmail"
            [(ngModel)]="extrainfo.ApprovingManagerEmail"
            #hmApprovalEmail="ngModel"
            required
            email
        />
        <span class="itext">Email*</span>
        <span
            class="erval"
            *ngIf="!hmApprovalEmail.valid && hmApprovalEmail.touched"
            >Please enter a correct email</span
        >
    </label>

    <!-- TODO: check back at this. error styling-->
    <label class="icontainer">
        <input
            type="text"
            placeholder="&nbsp;"
            class="ifield"
            name="hmInvoicing"
            [(ngModel)]="extrainfo.CostCenter"
            #hmInvoicing="ngModel"
            required
        />
        <span class="itext">Cost Center*</span>
        <span class="erval" *ngIf="!hmInvoicing.valid && hmInvoicing.touched"
            >Please enter a Cost centre and/or purchase order number for
            invoicing</span
        >
    </label>

    <div class="button-container">
        <input
            class="button btn-form"
            type="button"
            value="Back"
            routerLink="../legalnotice"
        />
        <input
            [disabled]="!hmApprovalEmail.valid || !hmInvoicing.valid"
            class="button btn-form"
            type="button"
            value="Next"
            routerLink="../yourdetails"
        />
    </div>
    <!-- HM END -->
</div>

<div *ngIf="formC.formCompanyName === 'Tetralaval'">
    <!--  Tetra page -->
    Tetra Laval company page
    <h3>Assignment country</h3>
    <hr />
    <div class="form-container">
        <label class="icontainer">
            <select
                class="iselect"
                id="countries"
                [(ngModel)]="extrainfo.UXPayingCountry"
                (change)="onCountryChange(extrainfo.UXPayingCountry, 'TETRA')"
                #tetraCountry="ngModel"
                required
            >
                <option
                    *ngFor="let country of countries"
                    [value]="country.countrycode"
                >
                    {{ country.countryname }}
                </option>
            </select>
            <span
                class="itext"
                [ngClass]="{
                    'itext-select': extrainfo.UXPayingCountry != undefined
                }"
                >Country*</span
            >
            <span
                class="erval"
                *ngIf="!tetraCountry.valid && tetraCountry.touched"
                >Please select a country.</span
            ></label
        >

        <label class="icontainer">
            <select
                class="iselect"
                name="payingCompany"
                [(ngModel)]="extrainfo.payingCompany"
                (change)="onCompanyChange()"
                #payingCompany="ngModel"
                required
                [disabled]="!extrainfo.UXPayingCountry"
            >
                <option *ngFor="let company of companies" [ngValue]="company">
                    {{ company.companyname }}
                </option>
            </select>
            <span
                class="itext"
                [ngClass]="{
                    'itext-select': extrainfo.payingCompany != undefined
                }"
                >Company*</span
            >
            <span
                class="erval"
                *ngIf="!payingCompany.valid && payingCompany.touched"
                >Please select a company.</span
            >
            <span
                class="erval"
                *ngIf="!payingCompany.valid && payingCompany.touched"
                >Please select a company after
                <b> first selecting a country.</b></span
            >
        </label>
    </div>

    <!--
  payingCompany: {{ extrainfo.payingCompany | json }}<br>
  UXPayingCountry: {{ extrainfo.UXPayingCountry | json }}<br>
  UXPayingCompanyy: {{ extrainfo.UXPayingCompany | json }}
  -->

    <div class="button-container">
        <input
            class="button btn-form"
            type="button"
            value="Back"
            routerLink="../legalnotice"
        />
        <input
            [disabled]="!payingCompany.valid || !tetraCountry.valid"
            class="button btn-form"
            type="button"
            value="Next"
            routerLink="../yourdetails"
        />
    </div>
    <!--  Tetra END -->
</div>

<!-- If Migrationsverket then redirect to 'your details' -->
<div *ngIf="formC.formCompanyName == 'migrationsverket'">
    <app-your-details></app-your-details>
</div>

<div *ngIf="formC.formCompanyName === 'ud'">
    <!--  UD company page -->
    <!-- TODO: decide if options should be translated -->
    <h3>Select a department</h3>

    <label class="icontainer">
        <select
            class="iselect"
            name="udDepartment"
            [(ngModel)]="extrainfo.CurrentWorkplace"
            (change)="onDepartmentChange(extrainfo.CurrentWorkplace)"
            #udDepartment="ngModel"
            required
        >
            <option
                *ngFor="let dept of departments"
                [value]="dept.departmentName"
            >
                {{ dept.departmentName }}
            </option>
        </select>
        <span
            class="itext"
            [ngClass]="{
                'itext-select': extrainfo.CurrentWorkplace != undefined
            }"
            >Current workplace*</span
        >
    </label>

    <!--TODO: fix validation for udDepartmentForeign-->
    <div class="button-container">
        <input
            class="button btn-form"
            type="button"
            value="Back"
            routerLink="../legalnotice"
        />
        <input
            [disabled]="!udDepartment.valid"
            class="button btn-form"
            type="button"
            value="Next"
            routerLink="../yourdetails"
        />
    </div>
    <!--  UD END -->
</div>

<div *ngIf="formC.formCompanyName === 'alfalaval'">
    Alfalaval company page
    <h3>Alfa Laval</h3>
    <hr />
    <div class="form-container">
        <label class="icontainer">
            <select
                class="iselect"
                id="countries"
                [(ngModel)]="extrainfo.UXPayingCountry"
                (change)="onCountryChange(extrainfo.UXPayingCountry, 'LAVALQ')"
                #inputCountry="ngModel"
                required
            >
                <option
                    *ngFor="let country of countries"
                    [value]="country.countrycode"
                >
                    {{ country.countryname }}
                </option>
            </select>
            <span
                class="itext"
                [ngClass]="{
                    'itext-select': extrainfo.UXPayingCountry != undefined
                }"
                >Assignment country*</span
            >
            <span
                class="erval"
                *ngIf="!inputCountry.valid && inputCountry.touched"
                >Please select a country.</span
            >
        </label>
        <!-- HERE -->
        <label class="icontainer">
            <select
                class="iselect"
                name="payingCompany"
                [(ngModel)]="extrainfo.payingCompany"
                (change)="onCompanyChange()"
                #payingCompany="ngModel"
                required
                [disabled]="!extrainfo.UXPayingCountry"
                [selectedIndex]="0"
            >
                <option *ngFor="let company of companies" [ngValue]="company">
                    {{ company.companyname }}
                </option>
            </select>
            <span
                class="itext"
                [ngClass]="{ 'itext-select': payingCompany != undefined }"
                >Company*</span
            >
            <span
                class="erval"
                *ngIf="!payingCompany.valid && payingCompany.touched"
                >Please select a company.</span
            >
            <span
                class="erval"
                *ngIf="!payingCompany.valid && payingCompany.touched"
                >Please select a company after
                <b> first selecting a country.</b></span
            >
        </label>

        <!--
    payingCompany: {{ extrainfo.payingCompany | json }}<br>
    UXPayingCountry: {{ extrainfo.UXPayingCountry | json }}<br>
    UXPayingCompany: {{ extrainfo.UXPayingCompany | json }}
    --></div>

    <div class="button-container">
        <input
            class="button btn-form"
            type="button"
            value="Back"
            routerLink="../legalnotice"
        />
        <input
            [disabled]="!payingCompany"
            class="button btn-form"
            type="button"
            value="Next"
            routerLink="../yourdetails"
        />
    </div>
</div>

<div *ngIf="formC.formCompanyName == 'aires'">
    <!-- AIRES -->
    <div class="form-container">
        <label class="icontainer">
            <input
                type="text"
                class="ifield"
                placeholder="&nbsp;"
                name="ericssonID"
                [(ngModel)]="extrainfo.EricssonID"
            />
            <span class="itext">Aires ref for move</span>
        </label>

        <label class="icontainer">
            <input
                type="text"
                class="ifield"
                placeholder="&nbsp;"
                name="storageRef"
                [(ngModel)]="extrainfo.StorageReference"
            />
            <span class="itext">Aires ref for storage</span>
        </label>

        <!--     <label class="icontainer">
      <input
        type="text"
        class="ifield"
        placeholder="&nbsp;"
        name="ltaEnd"
        [(ngModel)]="extrainfo.LTAEnd"
      />
      <span class="itext">End date of your LTA contract*</span>
      <span class="erval">*If returning to your home country</span> </label
    > -->

        <label class="datelabel" for="packingDate">
            End date of your LTA contract (If returning to your home country*)
        </label>

        <input
            class="dateinput"
            placeholder="Please choose a date"
            type="date"
            id="ltaEnd"
            name="ltaEnd"
            [(ngModel)]="extrainfo.LTAEnd"
            #packingDate
        />
        <span class="erval">*If returning to your home country</span>
    </div>
    <div class="button-container">
        <input
            class="button btn-form"
            type="button"
            value="Back"
            routerLink="../legalnotice"
        />

        <input
            class="button btn-form"
            type="button"
            value="Next"
            routerLink="../yourdetails"
        />
    </div>
    <!-- AIRES END -->
</div>

<div *ngIf="formC.formCompanyName == 'uoh'">
    <!-- University of Helsinki -->

    <h3>Faculty / Unit</h3>

    <!--
  <p>
    Alfa Mobility takes great pride in being the trusted partner of the
    University of Helsinki and assisting you with all your relocation
    requirements. To commence the faculty staff relocation procedure, kindly
    complete the following request form. Please note that this request form does
    not constitute a binding agreement. We will provide you with an estimated
    relocation cost and seek your final approval from the requesting party
    before initiating any cost-related arrangements.
  </p>

  <div>
    Prior to completing the order form, ensure you have the following
    information readily available:
    <ul style="margin-top: 10px">
      <li>Name of the faculty member & unit requesting the move</li>
      <li>WBS</li>
      <li>
        Full name, contact information, current address, and destination details
        of the person moving
      </li>
      <li>Maximum budget allocated for the relocation</li>
    </ul>
  </div>
  -->

    <div class="form-container">
        <label class="icontainer">
            <select
                class="iselect"
                name="Faculty"
                [(ngModel)]="extrainfo.Faculty"
                #Faculty
            >
                <option selected="selected" [ngValue]="undefined">
                    Please select a faculty*
                </option>
                <option>Faculty of Agriculture and Forestry</option>
                <option>Faculty of Arts</option>
                <option>
                    Faculty of Biological and Environmental Sciences
                </option>
                <option>Faculty of Educational Sciences</option>
                <option>Faculty of Law</option>
                <option>Faculty of Medicine</option>
                <option>Faculty of Pharmacy</option>
                <option>Faculty of Science</option>
                <option>Faculty of Social Sciences</option>
                <option>Faculty of Theology</option>
                <option>Faculty of Veterinary Medicine</option>
                <option>Helsinki Collegium for Advanced Studies</option>
                <option>Helsinki University Library</option>
                <option>HiLIFE Helsinki Institute of Life Science</option>
                <option>Language Centre</option>
                <option>Other Independent institutes</option>
                <option>Swedish School of Political Science</option>
            </select>
            <!--
      <span
        class="itext"
        [ngClass]="{
          'itext-select': extrainfo.Faculty != undefined
        }"
        >Faculty*
      </span>
      -->
        </label>

        <label class="icontainer">
            <input
                type="email"
                class="ifield"
                placeholder="&nbsp;"
                name="approvingManagerEmail"
                [(ngModel)]="extrainfo.ApprovingManagerEmail"
            />
            <span class="itext">Contact person email</span>
        </label>

        <label class="icontainer">
            <input
                type="text"
                class="ifield"
                placeholder="&nbsp;"
                name="businessUnitCode"
                [(ngModel)]="extrainfo.BusinessUnitCode"
            />
            <span class="itext">WBS*</span>
        </label>
    </div>
    <!-- END University of Helsinki -->
</div>

<div *ngIf="formC.formCompanyName == 'scania'">
    <app-scania-company-details></app-scania-company-details>
</div>

<div *ngIf="formC.formCompanyName == 'lunds'">
    <!-- LUNDS start -->
    <h4>Faculty</h4>
    <div class="form-container">
        <!-- <label class="icontainer">
      <input
        type="text"
        class="ifield"
        placeholder="&nbsp;"
        name="approvingManagerName"
        [(ngModel)]="extrainfo.ApprovingManagerName"
      />
      <span class="itext">Name of representative</span>
    </label> -->

        <label class="icontainer">
            <input
                type="text"
                class="ifield"
                placeholder="&nbsp;"
                name="faculty"
                [(ngModel)]="extrainfo.Faculty"
            />
            <span class="itext">Faculty and department</span>
        </label>

        <h4> HR contact at Lund University for quoting</h4>

        <label class="icontainer">
            <input
                type="text"
                class="ifield"
                placeholder="&nbsp;"
                name="approvingManagerName"
                [(ngModel)]="extrainfo.ApprovingManagerName"
            />
            <span class="itext">Name</span>
        </label>

        <label class="icontainer">
            <input
                class="ifield"
                placeholder="&nbsp;"
                type="email"
                name="approvingManagerEmail"
                [(ngModel)]="extrainfo.ApprovingManagerEmail"
                #approvingManagerEmail="ngModel"
                required
                email
            />
            <span class="itext">Approving manager email*</span>
            <span
                class="erval"
                *ngIf="
                    !approvingManagerEmail.valid &&
                    approvingManagerEmail.touched
                "
                >Please enter a correct email</span
            >
        </label>

        <label class="icontainer">
            <input
                type="tel"
                class="ifield"
                placeholder="&nbsp;"
                name="approvingManagerPhone"
                [(ngModel)]="extrainfo.ApprovingManagerPhone"
            />
            <span class="itext">Phone number</span>
        </label>
    </div>

    <div class="button-container">
        <input
            class="button btn-form"
            type="button"
            value="Back"
            routerLink="../legalnotice"
        />
        <input
            [disabled]="
                extrainfo.ApprovingManagerEmail == undefined ||
                !approvingManagerEmail.valid
            "
            class="button btn-form"
            type="button"
            value="Next"
            routerLink="../yourdetails"
        />
    </div>
    <!-- end lund-->
</div>

<div
    *ngIf="
        formC.formCompanyName == 'general' ||
        formC.formCompanyName == 'Northvolt' ||
        formC.formCompanyName == 'fba' ||
        formC.formCompanyName == 'Sida' ||
        formC.formCompanyName == 'EF' ||
        formC.formCompanyName == 'Polisen' ||
        formC.formCompanyName == 'umdk' ||
        formC.formCompanyName == 'udno'
    "
    class="button-container"
>
    <!-- Normal buttons-->
    <input
        class="button btn-form"
        type="button"
        value="Back"
        routerLink="../legalnotice"
    />
    <input
        [disabled]="
            extrainfo.CompanyName == undefined ||
            extrainfo.CompanyName.length === 0
        "
        class="button btn-form"
        type="button"
        value="Next"
        routerLink="../yourdetails"
    />
</div>

<div *ngIf="formC.formCompanyName == 'uoh'" class="button-container">
    <!-- Normal buttons-->
    <input
        class="button btn-form"
        type="button"
        value="Back"
        routerLink="../legalnotice"
    />
    <input
        [disabled]="
            extrainfo.Faculty == undefined ||
            extrainfo.Faculty.length === 0 ||
            extrainfo.BusinessUnitCode == undefined ||
            extrainfo.BusinessUnitCode.length === 0
        "
        class="button btn-form"
        type="button"
        value="Next"
        routerLink="../yourdetails"
    />
</div>
