<div>
  <h3>Company details</h3>
  <hr />

  <h4>Host company details</h4>

  <div class="form-container">
    <label class="icontainer">
      <select
        class="iselect"
        id="countries"
        [(ngModel)]="extrainfo.CompanyCountry"
        (ngModelChange)="onCountryChange()"
        #inputCountry="ngModel"
        required
      >
        <option *ngFor="let option of scaniaCountries" [value]="option.Country">
          {{ option.Country }}
        </option>
      </select>
      <span class="itext" [ngClass]="{ 'itext-select': inputCountry.touched }"
        >Country*</span
      >
      <span class="erval" *ngIf="!inputCountry.valid && inputCountry.touched"
        >Please select a country.</span
      >
    </label>

    <label class="icontainer">
      <select
        class="iselect"
        name="inputCompany"
        [(ngModel)]="extrainfo.scaniaCompany"
        #inputCompany="ngModel"
        required
      >
        <option *ngFor="let company of companies" [ngValue]="company">
          {{ company.DisplayName }}
        </option>
      </select>
      <span class="itext" [ngClass]="{ 'itext-select': inputCompany.touched }"
        >Select company name*</span
      >
      <span class="erval" *ngIf="!inputCompany.valid && inputCompany.touched"
        >Please select a company after <b> first selecting a country.</b></span
      >
    </label>
  </div>

  <div class="form-container">
    <h4>Manager details</h4>

    <label class="icontainer">
      <input
        type="text"
        class="ifield"
        placeholder="&nbsp;"
        name="managerName"
        [(ngModel)]="extrainfo.ApprovingManagerName"
        #managerName="ngModel"
        required
      />
      <span class="itext">Approving manager name*</span>
    </label>

    <label class="icontainer">
      <input
        type="email"
        class="ifield"
        placeholder="&nbsp;"
        name="managerEmail"
        [(ngModel)]="extrainfo.ApprovingManagerEmail"
        #managerEmail="ngModel"
        required
        email
      />
      <span class="itext">Email to approving manager*</span>
    </label>
  </div>

  <div class="button-container">
    <input
      class="button btn-form"
      type="button"
      value="Back"
      routerLink="../legalnotice"
    />

    <input
      [disabled]="
        !managerEmail.valid ||
        !managerName.valid ||
        !inputCountry.valid ||
        !inputCompany.valid
      "
      class="button btn-form"
      type="button"
      value="Next"
      routerLink="../yourdetails"
    />
  </div>
</div>
