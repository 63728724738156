import { Component } from "@angular/core";
import { Content, Extrainfo } from "src/app/models/form/book-data";
import { FormConfig } from "src/app/services/form-config";
import { FormDataService } from "src/app/services/form-data.service";

@Component({
  selector: "app-umdk",
  templateUrl: "./umdk.component.html",
  styleUrls: ["./umdk.component.scss"],
})
export class UmdkComponent {
  content: Content;
  extrainfo: Extrainfo;

  constructor(
    private formDataService: FormDataService,
    private formConfig: FormConfig,
  ) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.extrainfo.CompanyName = "Udenrigsministeriet";
    this.content.SokID = "32463";
    this.extrainfo.CompanyAddress = "Asiatisk Pl. 2";
    this.extrainfo.CompanyCity = "Copenhagen";
    this.extrainfo.CompanyCountry = "DK";
    this.extrainfo.CompanyZipCode = "1448";
    this.extrainfo.Avtal = "";
    this.extrainfo.language = "en";

    this.formConfig.confirm = false; //Determines if form need approval by manager

    //Form configuration
    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = "umdk";
  }
}
