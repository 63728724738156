import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { _store } from '../localstore/localstore';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const myStore = new _store();
    const userObj = myStore.pull();

    if (userObj.sessionId.length === 0) {
      // console.log('Empty sessionId');
      req = req.clone({
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': '0123456789ABCDEF0000000000000000'
        })
      });
      return next.handle(req);
    }

    // console.log('userObj = ', userObj);
    // console.log('Intercepting http request ...');
    // console.log(`Going to set session id to ${userObj.sessionId}`);
    if (req.url.endsWith('/api/portal/login')) {
      // console.log('Login API call skipped');
    } else {
      // console.log('Setting headers');
      req = req.clone({
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': userObj.sessionId
        })
      });
    }
    return next.handle(req);
  }
}
