import { Component } from "@angular/core";
import { Extrainfo } from "src/app/models/form/book-data";
import { FormDataService } from "src/app/services/form-data.service";

@Component({
  selector: "app-saab-other-details",
  templateUrl: "./saab-other-details.component.html",
  styleUrls: ["./saab-other-details.component.scss"],
})
export class SAABOtherDetailsComponent {
  extrainfo: Extrainfo;

  constructor(
    private formDataService: FormDataService,
    public formData: FormDataService,
  ) {
    this.extrainfo = formDataService.extrainfo;
  }
}
