<h3>Move details</h3>
<hr />
<h4>Moving to:</h4>
<div class="form-container">
    <label class="icontainer" *ngIf="formC.formCompanyName != 'umdk'">
        <input
            type="text"
            class="ifield"
            name="toAddress"
            placeholder="&nbsp;"
            [(ngModel)]="destdata.destAddress"
            #toAddress="ngModel"
        />
        <span class="itext">Address</span>
        <span class="erpattern" *ngIf="toAddress.errors?.pattern"
            >Please review your input and remove any special characters</span
        >
    </label>

    <label class="icontainer">
        <input
            type="text"
            placeholder="&nbsp;"
            class="ifield"
            name="toCity"
            [(ngModel)]="destdata.destTown"
            #toCity="ngModel"
            required
        />
        <span class="itext">City*</span>

        <span class="erval" *ngIf="toCity.errors?.required && toCity.touched"
            >Please select a city.</span
        >
        <span class="erpattern" *ngIf="toCity.errors?.pattern"
            >Please review your input and remove any special characters</span
        >
    </label>

    <label class="icontainer" *ngIf="formC.formCompanyName != 'umdk'">
        <input
            type="text"
            placeholder="&nbsp;"
            class="ifield"
            name="toZipcode"
            [(ngModel)]="destdata.destPostcode"
            #toZipcode="ngModel"
        />
        <span class="itext">Zip code</span>
        <span class="erpattern" *ngIf="toZipcode.errors?.pattern"
            >Please review your input and remove any special characters</span
        >
    </label>

    <label class="icontainer">
        <select
            class="iselect"
            name="toCountry"
            [(ngModel)]="destdata.destCountry"
            #toCountry="ngModel"
            required
        >
            <option
                *ngFor="let country of countries"
                [value]="country.countrycode"
            >
                {{ country.countryname }}
            </option>
        </select>
        <span class="erval" *ngIf="!toCountry.valid && toCountry.touched"
            >Please select a country.</span
        >
        <span class="itext" [ngClass]="{ 'itext-select': destdata.destCountry }"
            >Country*</span
        >
    </label>

    <!-- MOD -->
    <div
        *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'"
    >
        <label class="icontainer">
            <select
                name="residenceType"
                class="iselect"
                [(ngModel)]="destdata.destResidenceType"
                #residenceType
            >
                <option value="1">Apartment</option>
                <option value="2">Villa</option>
                <option value="3">Townhouse</option>
                <option value="4">Warehouse</option>
            </select>
            <span
                class="itext"
                [ngClass]="{
          'itext-select': destdata.destResidenceType != undefined
        }"
            >
                Residence type
            </span>
        </label>
    </div>

    <div
        *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'"
    >
        <label class="icontainer">
            <select
                name="walkingDistance"
                class="iselect"
                [(ngModel)]="destdata.destWalkingDistance"
                #walkingDistance
            >
                <option value="1">Within 30 meters</option>
                <option value="2">30 to 50 meter carry</option>
                <option value="3">51 to 75 meter carry</option>
                <option value="4">76 to 100 meter carry</option>
                <option value="5">Not known at quotation</option>
            </select>
            <span
                class="itext"
                [ngClass]="{
          'itext-select': destdata.destWalkingDistance != undefined
        }"
            >
                Walking distance (from door to truck)
            </span>
        </label>
    </div>

    <div
        *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'"
    >
        <label class="icontainer">
            <select
                name="elevator"
                class="iselect"
                [(ngModel)]="destdata.destElevator"
                #elevator
            >
                <option value="0">No elevator</option>
                <option value="1">Small elevator</option>
                <option value="2">Medium elevator</option>
                <option value="3">Big elevator</option>
                <option value="4">Elevator not useable for move</option>
            </select>
            <span
                class="itext"
                [ngClass]="{ 'itext-select': destdata.destElevator != undefined }"
            >
                Elevator
            </span>
        </label>
    </div>

    <div
        *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'"
    >
        <label class="icontainer">
            <input
                type="text"
                placeholder="&nbsp;"
                class="ifield"
                name="fromDoorCode"
                [(ngModel)]="destdata.destDoorcode"
                #fromDoorCode
            />
            <span class="itext">Door code</span>
        </label>
    </div>

    <div
        *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'"
    >
        <label class="icontainer">
            <input
                type="text"
                placeholder="&nbsp;"
                class="ifield"
                name="fromFloor"
                [(ngModel)]="destdata.destFloor"
                #fromFloor
            />
            <span class="itext">Floor</span>
        </label>
    </div>
    <!-- MOD -->

    <!-- Radio  -->
    <div
        class="radio-container"
        *ngIf="formC.formCompanyName != 'uoh' && formC.formCompanyName != 'umdk'"
    >
        <p>I am returning to my home country:</p>

        <div class="row">
            <label class="radio">
                <input
                    type="radio"
                    value="yes"
                    [(ngModel)]="extrainfo.ReturningHome"
                    name="toReturning"
                />
                <span>Yes</span>
            </label>
        </div>

        <div class="row">
            <label class="radio">
                <input
                    type="radio"
                    value="no"
                    [(ngModel)]="extrainfo.ReturningHome"
                    name="toReturning"
                />
                <span>No</span>
            </label>
        </div>
    </div>
</div>
<!-- TODO fix date styles. Especially center on phones.
     Key is 100% on input and change % on labels.
    Use spans on text and wrap the entire thing in a label.  -->
<h4>Move dates!</h4>
<div class="form-container">
    <div class="form-container">
        <div
            *ngIf="
         formC.formCompanyName != 'uoh' &&
         formC.formCompanyName != 'ud' &&
         formC.formCompanyName != 'umdk'
      "
            style="display: contents"
        >
            <label class="datelabel" for="surveyDate">
                Preferred survey date
            </label>
            <input
                class="dateinput"
                placeholder="Please choose a date"
                type="date"
                id="surveyDate"
                name="surveyDate"
                [(ngModel)]="orgdata.orgSurveyDate"
                #surveyDate
                [min]="minDate"
            />
        </div>

        <div
            *ngIf="
      formC.formCompanyName != 'umdk'
      "
            style="display: contents"
        >
            <label class="datelabel" for="packingDate">
                Preferred date of packing (if known)
            </label>
            <input
                class="dateinput"
                placeholder="Please choose a date"
                type="date"
                id="packingDate"
                name="packingDate"
                [(ngModel)]="dateinfo.PackingDate"
                #packingDate
                [min]="minDate"
            />
        </div>

        <div
            *ngIf="
        formC.formCompanyName != 'uoh' &&
        formC.formCompanyName != 'umdk'
      "
            style="display: contents"
        >
            <label class="datelabel" for="houseEmpty">
                House must be empty by (if known)
            </label>
            <input
                class="dateinput"
                type="date"
                id="emptyDate"
                name="emptyDate"
                [(ngModel)]="dateinfo.EmptyDate"
                #emptyDate
                [min]="minDate"
            />
        </div>

        <div
            *ngIf="
        formC.formCompanyName != 'uoh' &&
        formC.formCompanyName != 'umdk'
      "
        >
            <label class="datelabel" for="shipperArrivalDate">
                Your own planned arrival date at the destination
            </label>
            <input
                class="dateinput"
                type="date"
                id="shipperArrivalDate"
                name="shipperArrivalDate"
                [(ngModel)]="dateinfo.ShipperArrivalDate"
                #shipperArrivalDate
            />
        </div>

        <div
            *ngIf="
      formC.formCompanyName != 'umdk'
    "
        >
            <label class="datelabel" for="RequestUnpack">
                Preferred date for delivery
            </label>
            <br />
            <input
                class="dateinput"
                type="date"
                id="RequestUnpack"
                name="RequestUnpack"
                [(ngModel)]="dateinfo.RequestUnpack"
                #RequestUnpack
                [min]="minDate"
            />
        </div>

        <label
            class="datelabel"
            for="AvailableDate"
            *ngIf="
        formC.formCompanyName != 'Tetralaval' &&
        formC.formCompanyName != 'alfalaval' &&
        formC.formCompanyName != 'umdk'
      "
        >
            New residence available
        </label>
        <input
            *ngIf="
        formC.formCompanyName != 'Tetralaval' &&
        formC.formCompanyName != 'alfalaval' &&
        formC.formCompanyName != 'umdk'
      "
            class="dateinput"
            type="date"
            id="AvailableDate"
            name="AvailableDate"
            [(ngModel)]="dateinfo.AvailableDate"
            #AvailableDate
            [min]="minDate"
        />

        <!-- MOD -->
        <div
            *ngIf="
      formC.formCompanyName == 'ud' ||
      formC.formCompanyName == 'udno' ||
      formC.formCompanyName == 'umdk'
    "
        >
            <label class="datelabel" for="CommencementDate">
                Start or end date of assignment abroad*
            </label>
            <br />
            <input
                class="dateinput"
                type="date"
                id="CommencementDate"
                name="CommencementDate"
                [(ngModel)]="extrainfo.CommencementDate"
                #CommencementDate
                [min]="minDate"
            />
        </div>
        <!-- MOD -->

        <div *ngIf="formC.formCompanyName === 'uoh'">
            <label class="icontainer">
                <input
                    type="number"
                    placeholder="&nbsp;"
                    class="ifield"
                    name="MaxBudget"
                    [(ngModel)]="extrainfo.MaxBudget"
                    #MaxBudget="ngModel"
                />
                <span class="itext"
                    >Maximum budget for the move (in EURO)*</span
                >
            </label>
        </div>

        <!--TETRA -->
        <label
            *ngIf="formC.formCompanyName === 'Tetralaval'"
            class="datelabel"
            for="AssignmentStartDate"
        >
            Start of new assignment
        </label>
        <input
            *ngIf="formC.formCompanyName === 'Tetralaval'"
            class="dateinput"
            type="date"
            id="AssignmentStartDate"
            name="AssignmentStartDate"
            [(ngModel)]="extrainfo.AssignmentStartDate"
            #AssignmentStartDate
        />
    </div>

    <!-- end of formcontainer -->

    <div class="button-container">
        <input
            class="button btn-form"
            type="button"
            value="Back"
            routerLink="../movedetailsfrom"
        />

        <input
            *ngIf="
        formC.formCompanyName != 'Polisen' && formC.formCompanyName != 'uoh'
      "
            class="button btn-form"
            [disabled]="
        !toCity.valid ||
        !toCountry.valid
      "
            type="button"
            value="Next"
            routerLink="../otherdetails"
        />

        <input
            *ngIf="formC.formCompanyName === 'uoh'"
            class="button btn-form"
            [disabled]="
        !toCity.valid ||
        !toCountry.valid ||
        !extrainfo.MaxBudget
      "
            type="button"
            value="Next"
            routerLink="../otherdetails"
        />

        <input
            *ngIf="formC.formCompanyName === 'Polisen'"
            class="button btn-form"
            [disabled]="
        !toCity.valid ||
        !toCountry.valid
      "
            type="button"
            value="Submit"
            (click)="formData.formSubmit()"
        />
    </div>
</div>
