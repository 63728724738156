import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { CountryService } from '../../services/country.service';
import { Store } from '@ngrx/store';

@Injectable()
export class CountryEffects {
  constructor(
    private actions$: Actions,
    private countryService: CountryService,
    private store: Store
  ) {}

  loadCountries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Country API] Fetch'),
      switchMap(() => {
        return this.countryService.getCountries().pipe(
          map((data) => ({
            type: '[Country API] Fetch Success',
            payload: data,
          })),
          catchError((error: Error) =>
            of({ type: '[Country API] Fetch Error', payload: error })
          )
        );
      })
    );
  });
}
