import { createSelector } from '@ngrx/store';
// import { CountryState } from '../reducers/country.reducer';
import { Country } from '../../models/country';

export const selectCountries = (state: any) => {
  // console.log('State: ', state);
  return state.countries;
}

/* Entire state */
export const selectAllCountries = createSelector(
  selectCountries,
  (state: any) => state.countries
);

export const selectCountryByCode = (countryCode: string) =>
  createSelector(selectAllCountries, (countries: Country[]) => {
    let countryName = '';
    for (let ix = 0; ix < countries.length; ix++) {
      if (countries[ix].countrycode === countryCode) {
        countryName = countries[ix].countryname;
        break;
      }
    }
    return countryName as string;
  });
