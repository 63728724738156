import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-abb',
  templateUrl: './abb.component.html',
  styleUrls: ['./abb.component.scss']
})
export class AbbComponent {

 content:Content;
 extrainfo : Extrainfo;

 progress = 50;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;

    this.content.SokID = '36849';
    this.extrainfo.CompanyName = 'ABB AB 6610';
    this.extrainfo.CompanyEmail = 'utredningsgruppen@se.abb.com';
    this.extrainfo.CompanyContact = '021-32 50 00';
    this.extrainfo.CompanyAddress = 'FE 6610';
    this.extrainfo.CompanyCity = 'Östersund';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = '839 84';
    this.extrainfo.insurance = 'Alfa will cover insurance.';
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';

    this.formConfig.confirm = false;

    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'ABB';

    this.formConfig.getTotalPages = 5;

  }

}
