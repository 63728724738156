import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-handelsbanken',
  templateUrl: './handelsbanken.component.html',
  styleUrls: ['./handelsbanken.component.scss']
})
export class HandelsbankenComponent {

  extrainfo:Extrainfo;
  content:Content;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.content.SokID = '12385'
    this.extrainfo.CompanyName = 'Handelsbanken';
    this.extrainfo.CompanyEmail = 'maau01@handelsbanken.se';
    this.extrainfo.CompanyAddress = 'HCP';
    this.extrainfo.CompanyCity = 'Stockholm';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = '106 70';
    this.extrainfo.CompanyContact = '';
    this.extrainfo.insurance = 'Alfa will cover insurance.';
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';
    
    this.formConfig.confirm = false; //Determines if form need approval by manager

    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'Handelsbanken';
  }

}
