<div >
    <div>
        <h3>Insurance</h3>
        <hr>
        <br>
        <p>All shipments and storage portions are insured through Alfa. A valuation form will be sent to you by our move manager upon confirming your move. It is very important that this form is completed and returned prior to packing.</p>
    </div>
<div>
    <h3>Other details</h3>
    <hr>
    <br>
</div>

    <h4>
      Do you have any current storage portion which shall be delivered upon
      arrival to your home country (valid if repatriating)
    </h4>
    
    <label class="radio">
      <input
        type="radio"
        value="Yes"
        name="InStorage"
        [(ngModel)]="extrainfo.InStorage"
      />
      <span>Yes</span> </label
    >
  
    <label class="radio">
      <input
        type="radio"
        value="No"
        name="InStorage"
        [(ngModel)]="extrainfo.InStorage"
      />
      <span>No</span> </label
    >
  
    <h4>
      Do you have items in your current residence which needs to be placed at long
      term storage during your assignment (valid if expatriating)
    </h4>
    
    <label class="radio">
      <input
        type="radio"
        value="Yes"
        name="ToStorage"
        [(ngModel)]="extrainfo.ToStorage"
      />
      <span>Yes</span> </label
    >
  
    <label class="radio">
      <input
        type="radio"
        value="No"
        name="ToStorage"
        [(ngModel)]="extrainfo.ToStorage"
      />
      <span>No</span> </label
    >
  
    <h4>Type of contract</h4>
    
    <label class="radio">
      <input
        type="radio"
        value="Short term"
        name="ContractType"
        [(ngModel)]="extrainfo.ContractType"
      />
      <span>Short term</span> </label
    >
  
    <label class="radio">
      <input
        type="radio"
        value="Expat"
        name="ContractType"
        [(ngModel)]="extrainfo.ContractType"
      />
      <span>Expat</span> </label
    >
  
    <label class="radio">
      <input
        type="radio"
        value="Local"
        name="ContractType"
        [(ngModel)]="extrainfo.ContractType"
      />
      <span>Local</span> </label
    >
  
    <h4>Allowance</h4>
    
    <label class="radio">
      <input
        type="radio"
        value="2 cbm (short term)"
        name="VolumeLimit"
        [(ngModel)]="volume.VolumeLimit"
      />
      <span>2 cbm (short term)</span> </label
    >
  
    <label class="radio">
      <input
        type="radio"
        value="Single 30 cbm"
        name="VolumeLimit"
        [(ngModel)]="volume.VolumeLimit"
      />
      <span>Single 30 cbm</span> </label
    >
  
    <label class="radio">
      <input
        type="radio"
        value="With partner 40 cbm"
        name="VolumeLimit"
        [(ngModel)]="volume.VolumeLimit"
      />
      <span>With partner 40 cbm</span> </label
    >
  
    <label class="radio">
      <input
        type="radio"
        value="With family 60 cbm"
        name="VolumeLimit"
        [(ngModel)]="volume.VolumeLimit"
      />
      <span>With family 60 cbm</span> </label
    >
  
    <h4>
      Would you like for us to arrange move-out cleaning of your current
      residence?
    </h4>
    
    <label class="radio">
      <input
        type="radio"
        value="Yes"
        name="CleaningOffer"
        [(ngModel)]="extrainfo.CleaningOffer"
      />
      <span>Yes</span> </label
    >
  
    <label class="radio">
      <input
        type="radio"
        value="No"
        name="CleaningOffer"
        [(ngModel)]="extrainfo.CleaningOffer"
      />
      <span>No</span> </label
    >
  
    <label for="textbox"><h4>Other info</h4></label>
    <textarea
      class="itextfield"
      name="otherDetails"
      id="textbox"
      cols="30"
      rows="10"
      [(ngModel)]="extrainfo.OtherInfo"
      itext="Please type here if you have something to add.."
    >
    </textarea>
    
    <div class="button-container">
      <input
        class="button btn-form"
        type="button"
        value="Back"
        routerLink="../movedetailsto"
      />
      <input
        class="button btn-form"
        type="button"
        value="Submit"
        (click)="formData.formSubmit()"
      />
    </div>
    
  </div>