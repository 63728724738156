import { createAction, props } from '@ngrx/store';

import { UDdepartment } from '../../models/ud-department';

export const fetchDepartments = createAction(
  '[Department API] Fetch',
  // props<{ payload: Country }>()
);

export const fetchDepartmentsSuccess = createAction(
  '[Department API] Fetch Success',
  props<{ payload: UDdepartment[] }>()
);

export const fetchDepartmentsError = createAction(
  '[Department API] Fetch Error',
  props<{ payload: Error }>()
);
