<h3>Insurance</h3>
<hr />
<p>
  All shipments and storage portions are insured through Alfa. A valuation form
  will be sent to you by our move managers upon confirming your move. It is very
  important that this form is completed and returned prior to packing.
</p>

<h3>Policy / Handbook type</h3>
<hr />
<!--TODO: revisit this entire thing. -->
<label class="radio">
  <input
    type="radio"
    value="Long-Term Assignment"
    [(ngModel)]="bussiness.PolicyType"
    name="hmLTA"
    #hmLTA
  />
  <span>Long-Term Assignment (LTA-handbook)</span>
</label>

<label class="radio">
  <input
    type="radio"
    value="Globetrotter Assignment"
    [(ngModel)]="bussiness.PolicyType"
    name="hmGT"
    #hmGT
  />
  <span>Globetrotter Assignment (GT-handbook)</span>
</label>

<label class="radio">
  <input
    type="radio"
    value="International Local Employment"
    [(ngModel)]="bussiness.PolicyType"
    name="hmILE"
    #hmILE
  />
  <span>International Local Employment (ILE-handbook)</span>
</label>

<label class="radio">
  <input
    type="radio"
    value="Other"
    [(ngModel)]="bussiness.PolicyType"
    name="hmOther"
    #hmOther
  />
  <span
    >Other (e.g. former General Conditions for International Assignments)</span
  >
</label>

<div
  *ngIf="
    bussiness.PolicyType == 'Other' ||
    bussiness.PolicyType == 'International Local Employment' ||
    bussiness.PolicyType == 'Globetrotter Assignment' ||
    bussiness.PolicyType == 'Long-Term Assignment'
  "
>
  <h4>Contract Type</h4>
  <hr />
</div>

<!--TODO: revisit this entire thing. -->
<div *ngIf="bussiness.PolicyType == 'Other'">
  <label class="radio">
    <input
      type="radio"
      value="Same country"
      [(ngModel)]="extrainfo.ReturningHome"
      name="hmSameCountry"
      #hmSameCountry
    />
    <span>I am moving within a country</span>
  </label>
</div>

<div
  *ngIf="
    bussiness.PolicyType == 'Other' ||
    bussiness.PolicyType == 'International Local Employment' ||
    bussiness.PolicyType == 'Globetrotter Assignment' ||
    bussiness.PolicyType == 'Long-Term Assignment'
  "
>
  <label class="radio">
    <input
      type="radio"
      value="I am moving to a new country"
      [(ngModel)]="extrainfo.ReturningHome"
      name="hmNewCountry"
      #hmNewCountry
    />
    <span>I am moving to a new country</span>
  </label>
</div>

<div
  *ngIf="
    bussiness.PolicyType == 'International Local Employment' ||
    bussiness.PolicyType == 'Globetrotter Assignment'
  "
>
  <label class="radio">
    <input
      type="radio"
      value="I am returning to my home country"
      [(ngModel)]="extrainfo.ReturningHome"
      name="hmReturning"
      #hmReturning
    />
    <span>I am returning to my home country</span>
  </label>
</div>

<div
  *ngIf="
    bussiness.PolicyType == 'Other' ||
    bussiness.PolicyType == 'Long-Term Assignment'
  "
>
  <label class="radio">
    <input
      type="radio"
      value="Yes, under 2y"
      [(ngModel)]="extrainfo.ReturningHome"
      name="hmReturningU2"
      #hmReturningU2
    />
    <span
      >I am returning to my home country (<b>less</b> than 2 years abroad)</span
    >
  </label>
</div>

<div
  *ngIf="
    bussiness.PolicyType == 'Other' ||
    bussiness.PolicyType == 'Long-Term Assignment'
  "
>
  <label class="radio">
    <input
      type="radio"
      value="Yes, over 2y"
      [(ngModel)]="extrainfo.ReturningHome"
      name="hmReturningO2"
      #hmReturningO2
    />
    <span
      >I am returning to my home country (<b>more</b> than 2 years abroad)</span
    >
  </label>
</div>

<div *ngIf="extrainfo.ReturningHome">
  <h4>Allowance</h4>
  <hr />
</div>

<!-- OTHER SECTION -->
<div *ngIf="bussiness.PolicyType == 'Other' && extrainfo.ReturningHome">
  <h4 style="margin-bottom: 30px">Other</h4>

  <label class="radio">
    <input
      type="radio"
      value="former general conditions"
      [(ngModel)]="volume.VolumeLimit"
      name="hmAllowanceFGC"
      #hmAllowanceFGC
    />
    <span>
      Assignments under the former General Conditions for International
      Assignments</span
    >

    <p style="margin-top: 20px; font-size: 14px; margin-left: 30px">
      <i>
        The allowances specified in the General Conditions are applicable. If
        you are ordering shipping for your repatriation, the same shipment
        limits approved at your commencement of service in the last country of
        service will apply. Please refer to the General Conditions which can be
        found on Inside under “Employment -> Working Abroad -> Global Mobility”
        or contact the H&M group Global Mobility team at:
        globalmobility&#64;hm.com.</i
      >
    </p>
  </label>

  <label class="radio" style="margin-bottom: 0px">
    <input
      type="radio"
      value="not former general conditions"
      [(ngModel)]="volume.VolumeLimit"
      name="hmAllowanceNotF"
      #hmAllowanceNotF
    />
    <span>
      Assignments under the former General Conditions for International
      Assignments</span
    >

    <p style="margin-top: 20px; font-size: 14px; margin-left: 30px">
      <i>
        Please seek approval from your manager and/or contact the H&M group
        Global Mobility team at: globalmobility&#64;hm.com..</i
      >
    </p>
  </label>
  <!-- TODO: decide if this is better as a text area.-->
  <label
    style="width: 100%"
    *ngIf="volume.VolumeLimit == 'not former general conditions'"
    class="icontainer"
  >
    <input
      style="width: 100%"
      type="text"
      placeholder="&nbsp;"
      class="ifield"
      name="otherHmAllowanceText"
      [(ngModel)]="volume.VolumeLimitOtherText"
    />
    <span class="itext">Details</span>
  </label>
  <p style="font-size: 14px; margin-top: 20px">
    For details about shipping allowances, please find the applicable handbook
    with terms and conditions on the Global Mobility SharePoint Site or contact
    the H&M group Global Mobility team at: globalmobility&#64;hm.com
  </p>
</div>
<!-- END OF OTHERS-->

<div
  *ngIf="
    bussiness.PolicyType == 'International Local Employment' &&
    extrainfo.ReturningHome
  "
>
  <!-- IF INTERNATIONAL LOCAL EMPLOYMENT-->
  <h4>
    Removals when H&M Group does not provide furniture for the accommodation
  </h4>
  <label class="radio">
    <input
      type="radio"
      value="Unaccompanied 20m3"
      name="unaccompanied"
      #unaccompanied
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Unaccompanied employee 20m3.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied 30m3"
      name="accompanied"
      #accompanied
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Employee accompanied by spouse/cohabitant 30m3.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied with a child/children 5m3"
      #accompaniedByChild
      name="accompaniedByChild"
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Employee accompanied by child/children +5m3 for each child.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied by spouse/cohabitant and child/children 5m3"
      name="accompaniedByCohabitantAndChild"
      #accompaniedByCohabitantAndChild
      [(ngModel)]="volume.VolumeLimit"
    />
    <span
      >Employee accompanied by spouse/cohabitant and child/children +5m3 for
      each child.</span
    >
  </label>

  <label
    *ngIf="
      (bussiness.PolicyType == 'International Local Employment' &&
        volume.VolumeLimit == 'Accompanied with a child/children 5m3') ||
      volume.VolumeLimit ==
        'Accompanied by spouse/cohabitant and child/children 5m3'
    "
    class="icontainer label-children"
  >
    <select
      name="hmChildren label-children"
      class="iselect"
      [(ngModel)]="extrainfo.NumberOfChildren"
      #hmChildren
    >
      <option value="none">No kids</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6 or more">6 or more</option>
    </select>
    <span
      class="itext"
      [ngClass]="{ 'itext-select': extrainfo.NumberOfChildren != undefined }"
    >
      Number of children
    </span>
  </label>

  <h4>None of the above</h4>

  <label class="radio">
    <input
      type="radio"
      value="None of the options, check details"
      #hmNone
      name="hmNone"
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>None of the above options are applicable for my move.</span>
  </label>

  <label
    style="width: 100%"
    *ngIf="volume.VolumeLimit == 'None of the options, check details'"
    class="icontainer"
  >
    <input
      style="width: 100%"
      type="text"
      placeholder="&nbsp;"
      class="ifield"
      name="otherHmAllowanceText"
      [(ngModel)]="volume.VolumeLimitOtherText"
    />
    <span class="itext">Details</span>
  </label>

  <!-- END OF INTERNATIONAL LOCAL EMPLOYMENT-->
</div>

<div
  *ngIf="
    bussiness.PolicyType == 'Globetrotter Assignment' && extrainfo.ReturningHome
  "
>
  <!-- IF GLOBETROTTER ASSIGNMENT -->

  <h4>Removals when H&M Group provides furniture for the accommodation</h4>

  <label class="radio">
    <input
      type="radio"
      value="Unaccompanied 3m3"
      name="unaccompanied"
      #unaccompanied
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Unaccompanied employee 3m3.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied 5m3"
      name="accompanied"
      #accompanied
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Employee accompanied by spouse/cohabitant 5m3.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied by child/children 2m3"
      #accompaniedByChild
      name="accompaniedByChild"
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Employee accompanied by child/children +2m3 for each child.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied by spouse/cohabitant and child/children 2m3"
      name="accompaniedByCohabitantAndChild"
      #accompaniedByCohabitantAndChild
      [(ngModel)]="volume.VolumeLimit"
    />
    <span
      >Employee accompanied by spouse/cohabitant and child/children +2m3 for
      each child.</span
    >
  </label>

  <label
    *ngIf="
      (bussiness.PolicyType == 'Globetrotter Assignment' &&
        volume.VolumeLimit == 'Accompanied by child/children 2m3') ||
      volume.VolumeLimit ==
        'Accompanied by spouse/cohabitant and child/children 2m3'
    "
    class="icontainer label-children"
  >
    <select
      name="hmChildren"
      class="iselect"
      [(ngModel)]="extrainfo.NumberOfChildren"
      #hmChildren
    >
      <option value="none">No kids</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6 or more">6 or more</option>
    </select>
    <span
      class="itext"
      [ngClass]="{ 'itext-select': extrainfo.NumberOfChildren != undefined }"
    >
      Number of children
    </span>
  </label>

  <!-- NO FURNITURE -->
  <h4>
    Removals when H&M Group does not provide furniture for the accommodation
  </h4>

  <label class="radio">
    <input
      type="radio"
      value="Unaccompanied 25m3"
      name="unaccompanied"
      #unaccompanied
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Unaccompanied employee 25m3.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied 35m3"
      name="accompanied"
      #accompanied
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Employee accompanied by spouse/cohabitant 35m3.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied by child/children 5m3"
      #accompaniedByChild
      name="accompaniedByChild"
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Employee accompanied by child/children +5m3 for each child.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied by spouse/cohabitant and child/children 5m3"
      name="accompaniedByCohabitantAndChild"
      #accompaniedByCohabitantAndChild
      [(ngModel)]="volume.VolumeLimit"
    />
    <span
      >Employee accompanied by spouse/cohabitant and child/children +5m3 for
      each child.</span
    >
  </label>

  <label
    *ngIf="
      (bussiness.PolicyType == 'Globetrotter Assignment' &&
        volume.VolumeLimit == 'Accompanied by child/children 5m3') ||
      volume.VolumeLimit ==
        'Accompanied by spouse/cohabitant and child/children 5m3'
    "
    class="icontainer label-children"
  >
    <select
      name="hmChildren"
      class="iselect"
      [(ngModel)]="extrainfo.NumberOfChildren"
      #hmChildren
    >
      <option value="none">No kids</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6 or more">6 or more</option>
    </select>
    <span
      class="itext"
      [ngClass]="{ 'itext-select': extrainfo.NumberOfChildren != undefined }"
    >
      Number of children
    </span>
  </label>

  <!-- NONE OF THE ABOVE -->
  <h4>None of the above</h4>

  <label class="radio">
    <input
      type="radio"
      value="None of the options, check details"
      name="hmNone"
      #hmNone
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>None of the above options are applicable for my move.</span>
  </label>

  <label
    style="width: 100%"
    *ngIf="volume.VolumeLimit == 'None of the options, check details'"
    class="icontainer"
  >
    <input
      style="width: 100%"
      type="text"
      placeholder="&nbsp;"
      class="ifield"
      name="otherHmAllowanceText"
      [(ngModel)]="volume.VolumeLimitOtherText"
    />
    <span class="itext">Details</span>
  </label>

  <!-- END OF  GLOBETROTTER-->
</div>

<div
  *ngIf="
    (bussiness.PolicyType == 'Long-Term Assignment' &&
      extrainfo.ReturningHome == 'I am moving to a new country') ||
    (extrainfo.ReturningHome == 'Yes, under 2y' &&
      bussiness.PolicyType != 'Other')
  "
>
  <!-- IF LONG TERM ASSIGNMENT PART 1 (LESS THAN 2 YEARS) -->

  <h4>Removals when H&M Group provides furniture for the accommodation</h4>

  <label class="radio">
    <input
      type="radio"
      value="Unaccompanied 3m3"
      name="unaccompanied"
      #unaccompanied
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Unaccompanied employee 3m3.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied 5m3"
      name="accompanied"
      #accompanied
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Employee accompanied by spouse/cohabitant 5m3.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied by child/children 2m3"
      #accompaniedByChild
      name="accompaniedByChild"
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Employee accompanied by child/children +2m3 for each child.</span>
  </label>

  <div style="display: none">If children</div>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied by spouse/cohabitant and child/children 2m3"
      name="accompaniedByCohabitantAndChild"
      #accompaniedByCohabitantAndChild
      [(ngModel)]="volume.VolumeLimit"
    />
    <span
      >Employee accompanied by spouse/cohabitant and child/children +2m3 for
      each child.</span
    >
  </label>

  <label
    *ngIf="
      (bussiness.PolicyType == 'Long-Term Assignment' &&
        volume.VolumeLimit == 'Accompanied by child/children 2m3') ||
      volume.VolumeLimit ==
        'Accompanied by spouse/cohabitant and child/children 2m3'
    "
    class="icontainer label-children"
  >
    <select
      name="hmChildren"
      class="iselect"
      [(ngModel)]="extrainfo.NumberOfChildren"
      #hmChildren
    >
      <option value="none">No kids</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6 or more">6 or more</option>
    </select>
    <span
      class="itext"
      [ngClass]="{ 'itext-select': extrainfo.NumberOfChildren != undefined }"
    >
      Number of children
    </span>
  </label>

  <!-- No furniture-->
  <h4>
    Removals when H&M Group does not provide furniture for the accommodation
  </h4>
  <label class="radio">
    <input
      type="radio"
      value="Unaccompanied 20m3"
      name="unaccompanied"
      #unaccompanied
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Unaccompanied employee 20m3.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied 30m3"
      name="accompanied"
      #accompanied
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Employee accompanied by spouse/cohabitant 30m3.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied with a child/children 5m3"
      #accompaniedByChild
      name="accompaniedByChild"
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Employee accompanied by child/children +5m3 for each child.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied by spouse/cohabitant and child/children 5m3"
      name="accompaniedByCohabitantAndChild"
      #accompaniedByCohabitantAndChild
      [(ngModel)]="volume.VolumeLimit"
    />
    <span
      >Employee accompanied by spouse/cohabitant and child/children +5m3 for
      each child.</span
    >
  </label>

  <label
    *ngIf="
      (bussiness.PolicyType == 'Long-Term Assignment' &&
        volume.VolumeLimit == 'Accompanied with a child/children 5m3') ||
      volume.VolumeLimit ==
        'Accompanied by spouse/cohabitant and child/children 5m3'
    "
    class="icontainer label-children"
  >
    <select
      name="hmChildren"
      class="iselect"
      [(ngModel)]="extrainfo.NumberOfChildren"
      #hmChildren
    >
      <option value="none">No kids</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6 or more">6 or more</option>
    </select>
    <span
      class="itext"
      [ngClass]="{ 'itext-select': extrainfo.NumberOfChildren != undefined }"
    >
      Number of children
    </span>
  </label>

  <h4>None of the above</h4>

  <label class="radio">
    <input
      type="radio"
      value="None of the options, check details"
      #hmNone
      name="hmNone"
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>None of the above options are applicable for my move.</span>
  </label>

  <label
    *ngIf="volume.VolumeLimit == 'None of the options, check details'"
    class="icontainer"
  >
    <input
      type="text"
      placeholder="&nbsp;"
      class="ifield"
      name="otherHmAllowanceText"
      [(ngModel)]="volume.VolumeLimitOtherText"
    />
    <span class="itext">Details</span>
  </label>

  <!-- END OF LONG TERM ASSIGNMENT UNDER 2 YEARS SECTION -->
</div>

<div
  *ngIf="
    bussiness.PolicyType != 'Other' &&
    bussiness.PolicyType == 'Long-Term Assignment' &&
    extrainfo.ReturningHome == 'Yes, over 2y'
  "
>
  <!-- START OF LONG TERM ASSIGNMENT ***OVER** 2 YEARS SECTION -->
  <h4>
    Removals when H&M Group provides furniture for the accommodation for
    assignments <i>over</i> 2 years
  </h4>

  <label class="radio">
    <input
      type="radio"
      value="Unaccompanied 3.3m3"
      name="unaccompanied"
      #unaccompanied
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Unaccompanied employee 3.3m3</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied 5.5m3"
      name="accompanied"
      #accompanied
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Employee accompanied by spouse/cohabitant 5.5m3.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied by child/children 2.2m3"
      #accompaniedByChild
      name="accompaniedByChild"
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Employee accompanied by child/children +2.2m3 for each child.</span>
  </label>

  <div style="display: none">If children</div>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied by spouse/cohabitant and child/children 2.2m3"
      name="accompaniedByCohabitantAndChild"
      #accompaniedByCohabitantAndChild
      [(ngModel)]="volume.VolumeLimit"
    />
    <span
      >Employee accompanied by spouse/cohabitant and child/children +2.2m3 for
      each child.</span
    >
  </label>

  <label
    *ngIf="
      (bussiness.PolicyType == 'Long-Term Assignment' &&
        volume.VolumeLimit == 'Accompanied by child/children 2.2m3') ||
      volume.VolumeLimit ==
        'Accompanied by spouse/cohabitant and child/children 2.2m3'
    "
    class="icontainer label-children"
  >
    <select
      name="hmChildren"
      class="iselect"
      [(ngModel)]="extrainfo.NumberOfChildren"
      #hmChildren
    >
      <option value="none">No kids</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6 or more">6 or more</option>
    </select>
    <span
      class="itext"
      [ngClass]="{ 'itext-select': extrainfo.NumberOfChildren != undefined }"
    >
      Number of children
    </span>
  </label>

  <!-- No furniture-->
  <h4>
    Removals when H&M Group does not provide furniture for the accommodation for
    assignments <i>under</i> 2 years
  </h4>
  <label class="radio">
    <input
      type="radio"
      value="Unaccompanied 22m3"
      name="unaccompanied"
      #unaccompanied
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Unaccompanied employee 22m3.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied 33m3"
      name="accompanied"
      #accompanied
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Employee accompanied by spouse/cohabitant 33m3.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied with a child/children 5.5m3"
      #accompaniedByChild
      name="accompaniedByChild"
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>Employee accompanied by child/children +5.5m3 for each child.</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Accompanied by spouse/cohabitant and child/children 5.5m3"
      name="accompaniedByCohabitantAndChild"
      #accompaniedByCohabitantAndChild
      [(ngModel)]="volume.VolumeLimit"
    />
    <span
      >Employee accompanied by spouse/cohabitant and child/children +5.5m3 for
      each child.</span
    >
  </label>

  <label
    *ngIf="
      (bussiness.PolicyType == 'Long-Term Assignment' &&
        volume.VolumeLimit == 'Accompanied with a child/children 5.5m3') ||
      volume.VolumeLimit ==
        'Accompanied by spouse/cohabitant and child/children 5.5m3'
    "
    class="icontainer label-children"
  >
    <select
      name="hmChildren"
      class="iselect"
      [(ngModel)]="extrainfo.NumberOfChildren"
      #hmChildren
    >
      <option value="none">No kids</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6 or more">6 or more</option>
    </select>
    <span
      class="itext"
      [ngClass]="{ 'itext-select': extrainfo.NumberOfChildren != undefined }"
    >
      Number of children
    </span>
  </label>

  <h4>None of the above</h4>

  <label class="radio">
    <input
      type="radio"
      value="None of the options, check details"
      #hmNone
      name="hmNone"
      [(ngModel)]="volume.VolumeLimit"
    />
    <span>None of the above options are applicable for my move.</span>
  </label>

  <label
    style="width: 100%"
    *ngIf="volume.VolumeLimit == 'None of the options, check details'"
    class="icontainer"
  >
    <input
      style="width: 100%"
      type="text"
      placeholder="&nbsp;"
      class="ifield"
      name="otherHmAllowanceText"
      [(ngModel)]="volume.VolumeLimitOtherText"
    />
    <span class="itext">Details</span>
  </label>

  <!-- END OF LONG TERM ASSIGNMENT ***OVER** 2 YEARS SECTION -->
</div>

<p>
  For details about shipping allowances, please find the applicable handbook
  with terms and conditions on the Global Mobility SharePoint Site or contact
  the H&M group Global Mobility team at: globalmobility&#64;hm.com
</p>

<div>
  <h3>Other Details</h3>
  <hr />
</div>

<div class="radio-container">
  <h4>Please choose one of the boxes below*</h4>

  <label class="radio">
    <input
      type="radio"
      value="Retail CSO"
      [(ngModel)]="extrainfo.hmChina"
      name="Cso"
      #Cso
    />
    <span>Retail CSO</span>
  </label>
  <!-- TODO fix a better validation and required field -->
  <label class="radio">
    <input
      type="radio"
      value="Production GBC"
      [(ngModel)]="extrainfo.hmChina"
      name="Gbc"
      #Gbc
    />
    <span>Production GBC</span>
  </label>

  <div class="radio-container">
    <h4>Will you keep your residence in your home country?</h4>
    <!--TODO: revisit this entire thing. -->
    <label class="radio">
      <input
        type="radio"
        value="yes"
        [(ngModel)]="extrainfo.KeepHomeCountryResidence"
        name="KeepResidence"
      />
      <span>Yes</span>
    </label>

    <label class="radio">
      <input
        type="radio"
        value="no"
        [(ngModel)]="extrainfo.KeepHomeCountryResidence"
        name="KeepResidence"
      />
      <span>No</span>
    </label>
  </div>
  <!--HM SECTION 2 END-->
</div>

<div>
  <label for="textbox"><h4>Additional information</h4></label>
  <textarea
    class="itextfield"
    name="otherDetails"
    id="textbox"
    cols="30"
    rows="10"
    [(ngModel)]="extrainfo.OtherInfo"
    placeholder="Please let us know here here if there's anything we should know to best help you.."
  ></textarea>
</div>

<div class="button-container">
  <!--HM buttons-->
  <input
    class="button btn-form"
    type="button"
    value="Back"
    routerLink="../movedetailsto"
  />
  <input
    [disabled]="
      extrainfo.hmChina != 'Retail CSO' && extrainfo.hmChina != 'Production GBC'
    "
    class="button btn-form"
    type="button"
    value="Submit"
    (click)="formData.formSubmit()"
  />
</div>
