import { Component } from '@angular/core';
import { FormDataService } from 'src/app/services/form-data.service';
import { FormConfig } from 'src/app/services/form-config';
import { Content, Extrainfo } from 'src/app/models/form/book-data';

@Component({
  selector: 'app-northvolt',
  templateUrl: './northvolt.component.html',
  styleUrls: ['./northvolt.component.scss']
})
export class NorthvoltComponent {

content:Content;
extrainfo:Extrainfo;
  
  
  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.content.SokID = '53380';
    this.extrainfo.CompanyName = 'Northvolt';
    this.extrainfo.insurance = '';
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';

    this.formConfig.confirm = false; //Determines if form need approval by manager

    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'Northvolt';
  }

}
