<h3>Ericsson details</h3>
<h4>{{ content.SokID }}</h4>
  <hr />
  <h4>Please select applicable category below</h4>

  <div class="radio-container">
    <label class="radio">
      <input
        type="radio"
        value="SLTA"
        (click)="changeAssignment('SLTA')"
        [(ngModel)]="bussiness.PolicyType"
        name="policyType"

      />
      <span>Special Long Term Assignment (SLTA)</span> </label
    >

    <label class="radio">
      <input
        type="radio"
        value="FLH"
        (click)="changeAssignment('FLH')"
        [(ngModel)]="bussiness.PolicyType"
        name="policyType"
      />
      <span>Local Employment incl. Foreign Local Hire (FLH)</span> </label
    >

    <label class="radio">
      <input
        type="radio"
        value="RLTA"
        (click)="changeAssignment('RLTA')"
        [(ngModel)]="bussiness.PolicyType"
        name="policyType"
      />
      <span>Regular Long Term Assignment (RLTA)</span> </label
    >
  </div>

  <div class="animate-fadeIn" *ngIf="bussiness.PolicyType == 'FLH'">
    <!--If FLH-->

    <h3>Ericsson host company</h3>
    <hr />
    <p>
      Expatriating – Entity at destination / Repatriating – Entity at origin
    </p>
    <div class="form-container">
      <label class="icontainer">
        <select
          class="iselect"
          id="countries"
          [(ngModel)]="extrainfo.UXPayingCountry"
          (change)="onCountryChange(extrainfo.UXPayingCountry, 'ERICS')"
          #ericssonCountry="ngModel"
          required
        >
          <option *ngFor="let country of countries" [value]="country.countrycode">
            {{ country.countryname }}
          </option>
        </select>
        <span
          class="itext"
          [ngClass]="{
            'itext-select': extrainfo.UXPayingCountry != undefined
          }"
          >Country*</span
        >
        <span
          class="erval"
          *ngIf="!ericssonCountry.valid && ericssonCountry.touched"
          >Please select a country.</span
        > </label
      >

      <label class="icontainer">
        <select
          class="iselect"
          name="payingCompany"
          [(ngModel)]="extrainfo.payingCompany"
          #payingCompany="ngModel"
          required
        >
          <option *ngFor="let company of companies" [ngValue]="company">
            {{ company.companyname }}
          </option>
        </select>
        <span
          class="itext"
          [ngClass]="{'itext-select': extrainfo.payingCompany != undefined }"
          >Select company name*</span
        >
        <span
          class="erval"
          *ngIf="!payingCompany.valid && payingCompany.touched"
          >Please select a company after
          <b> first selecting a country.</b></span
        >
      </label>
    </div>

    <h3>Manager Details</h3>
    <hr />
    <div class="form-container">
      <label class="icontainer">
        <input
          class="ifield"
          placeholder="&nbsp;"
          type="email"
          name="ericssonHiringManager"
          [(ngModel)]="extrainfo.EricssonHiringManager"
          #ericssonHiringManager="ngModel"
          required
          email
        />
        <span class="itext">Email to approving / line manager*</span>
        <span
          class="erval"
          *ngIf="!ericssonHiringManager.valid && ericssonHiringManager.touched"
          >Please enter a correct email</span
        >
      </label>

      <label class="icontainer">
        <input
          class="ifield"
          placeholder="&nbsp;"
          type="email"
          name="ericssonLocalHiringContact"
          [(ngModel)]="extrainfo.EricssonLocalHiringContact"
          #ericssonLocalHiringContact="ngModel"
          required
          email
        />
        <span class="itext">Email to local HR contact in hiring country*</span>
        <span
          class="erval"
          *ngIf="
            !ericssonLocalHiringContact.valid &&
            ericssonLocalHiringContact.touched
          "
          >Please enter a correct email</span
        >
      </label>
    </div>

    <div class="button-container">
      <input
        class="button btn-form"
        type="button"
        value="Back"
        routerlink="../legalnotice"
      />
      <input
        [disabled]="
          !ericssonHiringManager.valid || !ericssonLocalHiringContact.valid
        "
        class="button btn-form"
        type="button"
        value="Next"
        routerLink="../yourdetails"
      />
    </div>
  </div>

  <div class="animate-fadeIn" *ngIf="bussiness.PolicyType == 'SLTA'">
    <!--If SLTA-->

    <div class="form-container">
      <label class="icontainer">
        <input
          class="ifield"
          placeholder="&nbsp;"
          type="text"
          name="ericssonID"
          [(ngModel)]="extrainfo.EricssonID"
          #ericssonID="ngModel"
          required
        />
        <span class="itext">Ericsson CP/ID number*</span>
        <span class="erval"
          >Please enter a correct Ericsson CP/ID number(should start with either
          3 or 0)</span
        >
      </label>

      <label class="datelabel date-text" for="ltaEnd">
        If returning to your home country, please specify the end date of your
        LTA contract*
      </label>
      <input
        class="dateinput"
        placeholder="Please choose a date"
        type="date"
        id="ltaEnd"
        name="ltaEnd"
        [(ngModel)]="extrainfo.LTAEnd"
        #ltaEnd="ngModel"
        required
      />
    </div>
    <h4>Manager Details</h4>
    <hr />
    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="email"
        name="ericssonHiringManager"
        [(ngModel)]="extrainfo.EricssonHiringManager"
        #ericssonHiringManager="ngModel"
        required
        email
      />
      <span class="itext">Hiring manager email in host country*</span>
      <span
        class="erval"
        *ngIf="!ericssonHiringManager.valid && ericssonHiringManager.touched"
        >Please enter a correct email</span
      >
    </label>

    <div class="button-container">
      <input
        class="button btn-form"
        type="button"
        value="Back"
        routerLink="../legalnotice"
      />
      <input
        [disabled]="
          !ericssonHiringManager.valid || !ltaEnd.valid || !ericssonID.valid
        "
        class="button btn-form"
        type="button"
        value="Next"
        routerLink="../yourdetails"
      />
    </div>
  </div>

  <div class="animate-fadeIn" *ngIf="bussiness.PolicyType == 'RLTA'">
    <!--If RLTA-->
    <h3>Regular Long-Term Assignments (RLTA)</h3>
    <hr />
    <p>
      The conditions for Regular Long-Term Assignments (RLTA) do not include
      removal/shipment or storage of personal belongings. Should you wish to
      request a quotation for a booking of removal/shipment or storage at your
      own expense, please click the box below:
    </p>
    <hr />
    <label class="checkbox">
      <input
        type="checkbox"
        name="attic"
        value="yes"
        [(ngModel)]="formC.ericssonConfirm"
        #attic
      />
      <span
        >I would like to request a quotation for removal/shipment or storage at
        my own expense</span
      > </label
    >
    <div class="button-container">
      <input
        [disabled]="!formC.ericssonConfirm"
        class="newrq-button btn-form"
        type="button"
        value="New Request"
        routerLink="../../request"
      />
    </div>
  </div>
