<div>
  <div>
    <h3>Other details</h3>
    <hr />
  </div>

  <h4>Select assignment type</h4>

  <label class="radio">
    <input
      type="radio"
      value="ALFALAVAL1"
      [(ngModel)]="extrainfo.PolicyTypeText"
      name="alfalavalAssignment"
      #alfalavalAssignment="ngModel"
      required
      (click)="FLH()"
    />
    <span>Long term assignment</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      name="alfalavalAssignment"
      [(ngModel)]="extrainfo.PolicyTypeText"
      value="ALFALAVAL2"
      #alfalavalAssignment="ngModel"
      required
      (click)="FLH()"
    />
    <span>Foreign local hire</span>
  </label>

  <!--   <label class="radio">
    <input
      type="radio"
      [(ngModel)]="extrainfo.PolicyTypeText"
      name="alfalavalAssignment"
      value="Domestic or national move"
      #alfalavalAssignment="ngModel"
      required
    />
    <span>Domestic/national move</span>
  </label> -->

  <h4>Family Size</h4>

  <label class="radio">
    <input
      type="radio"
      [(ngModel)]="extrainfo.FamilySize"
      name="alfalavalFamily"
      value="Single"
    />
    <span>Single / Unaccompanied</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      [(ngModel)]="extrainfo.FamilySize"
      name="alfalavalFamily"
      value="Couple"
    />
    <span>Couple</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      [(ngModel)]="extrainfo.FamilySize"
      name="alfalavalFamily"
      value="Family with children"
    />
    <span>Family with children</span>
  </label>

  <h4>Allowance</h4>
  <p>The allowance below is as per Alfa Laval Handbook and guidelines.</p>
  <ol>
    <h4>Unfurnished accommodation</h4>
    <span class="policymsg">(Long Term Assignment / Foreign Local Hire)</span>

    <div
      *ngIf="
        extrainfo.FamilySize == 'Single' || extrainfo.FamilySize == undefined
      "
    >
      <!-- if SINGLE -->
      <label class="radio">
        <input
          type="radio"
          [(ngModel)]="volume.VolumeLimit"
          name="alfalavalAllowance"
          value="Single or unaccompanied person 20 m3"
        />
        <span>Single or unaccompanied person 20 m3</span>
      </label>
    </div>

    <div
      *ngIf="
        extrainfo.FamilySize == 'Couple' || extrainfo.FamilySize == undefined
      "
    >
      <!-- IF COUPLE-->
      <label class="radio">
        <input
          type="radio"
          [(ngModel)]="volume.VolumeLimit"
          name="alfalavalAllowance"
          value="Couple 30 m3"
        />
        <span>Couple 30 m3</span>
      </label>
    </div>

    <div
      *ngIf="
        extrainfo.FamilySize == 'Family with children' ||
        extrainfo.FamilySize == undefined
      "
    >
      <!--IF FAMILY -->
      <label class="radio">
        <input
          type="radio"
          [(ngModel)]="volume.VolumeLimit"
          name="alfalavalAllowance"
          value="Family with children 60 m3"
        />
        <span>Family with children 60 m3</span>
      </label>
    </div>

    <h4>Furnished accommodation</h4>
    <span class="policymsg">(Long Term Assignment)</span>

    <div
      *ngIf="
        extrainfo.FamilySize == 'Single' || extrainfo.FamilySize == undefined
      "
    >
      <!--IF SINGLE -->
      <label class="radio">
        <input
          type="radio"
          [(ngModel)]="volume.VolumeLimit"
          name="alfalavalAllowance"
          value="Single or unaccompanied 4 m3"
          [disabled]="extrainfo.PolicyTypeText == 'foreign local hire'"
        />
        <span>Single or unaccompanied 4 m3</span>
      </label>
    </div>

    <div
      *ngIf="
        extrainfo.FamilySize == 'Family with children' ||
        extrainfo.FamilySize == 'Couple' ||
        extrainfo.FamilySize == undefined
      "
    >
      <!--IF FAMILY OR COUPLE -->
      <label class="radio">
        <input
          type="radio"
          [(ngModel)]="volume.VolumeLimit"
          name="alfalavalAllowance"
          value="Accompanied 8 m3"
          [disabled]="extrainfo.PolicyTypeText == 'foreign local hire'"
        />
        <span>Accompanied 8 m3</span>
      </label>
    </div>
  </ol>

  <label for="textbox"><h4>Additional information</h4></label>
  <textarea
    class="itextfield"
    name="extrainfo"
    id="textbox"
    cols="30"
    rows="10"
    [(ngModel)]="extrainfo.OtherInfo"
    placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
  >
  </textarea>

  <div class="button-container">
    <input
      class="button btn-form"
      type="button"
      value="Back"
      routerLink="../movedetailsto"
    />
    <input
      [disabled]="!alfalavalAssignment.valid"
      class="button btn-form"
      type="button"
      value="Submit"
      (click)="formData.formSubmit()"
    />
  </div>
</div>
