import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-ohrlings-pricewaterhousecoopers',
  templateUrl: './ohrlings-pricewaterhousecoopers.component.html',
  styleUrls: ['./ohrlings-pricewaterhousecoopers.component.scss']
})
export class OhrlingsPricewaterhousecoopersComponent {

  content:Content;
  extrainfo:Extrainfo;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.content.SokID = '22684'
    this.extrainfo.CompanyName = 'Öhrlings PricewaterhouseCoopers AB PWC';
    this.extrainfo.CompanyAddress = 'FE 1041';
    this.extrainfo.CompanyEmail = '';
    this.extrainfo.CompanyContact = '';
    this.extrainfo.CompanyCity = 'Frösön';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = '838 83';
    this.extrainfo.insurance = '';
    this.extrainfo.Legal = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';

    this.formConfig.confirm = false; //Determines if form need approval by manager

    //Form configuration
    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'pwc';
  }


}
