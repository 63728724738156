<app-private-legal
  *ngIf="!accepted"
  (accept)="accepted = true"
></app-private-legal>

<div *ngIf="accepted">
  <h3>Alfa Finland - Online Service Order</h3>
  <hr />
  <h4>Personal information of the person relocating.</h4>

  <label class="icontainer">
    <input
      class="ifield"
      placeholder="&nbsp;"
      type="text"
      name="inputName"
      [(ngModel)]="content.assigneeFirstName"
      #inputName="ngModel"
      required
    />
    <span class="itext">First name*</span>
  </label>

  <label class="icontainer">
    <input
      class="ifield"
      placeholder="&nbsp;"
      type="text"
      name="lastName"
      [(ngModel)]="content.assigneeLastName"
      required
      #lastName="ngModel"
    />
    <span class="itext">Last name*</span>
  </label>

  <label class="icontainer">
    <input
      class="ifield"
      placeholder="&nbsp;"
      type="email"
      name="fromEmail"
      [(ngModel)]="orgdata.orgMailPrimary"
      #fromEmail="ngModel"
      required
      email
    />
    <span class="itext">Email*</span>
    <span class="erval" *ngIf="!fromEmail.valid && fromEmail.touched"
      >Please enter a correct email</span
    >
  </label>

  <label class="icontainer">
    <input
      class="ifield"
      type="tel"
      placeholder="&nbsp;"
      name="toMobilePhone"
      [(ngModel)]="orgdata.orgMobilephone"
      #toMobilePhone="ngModel"
    />
    <span class="itext">Mobile phone</span>
  </label>

  <label class="icontainer">
    <select
      class="iselect"
      id="toCountry"
      [(ngModel)]="destdata.destCountry"
      #toCountry="ngModel"
      required
    >
      <option *ngFor="let country of countries" [value]="country.countrycode">
        {{ country.countryname }}
      </option>
    </select>

    <span class="itext" [ngClass]="{ 'itext-select': destdata.destCountry }"
      >Nationality of the person relocating</span
    >
  </label>

  <label class="icontainer">
    <input
      class="ifield"
      placeholder="&nbsp;"
      type="text"
      name="lastName"
      [(ngModel)]="orgdata.orgTown"
      required
      #lastName="ngModel"
    />
    <span class="itext"> Origin City of the relocation*</span>
  </label>

  <label class="icontainer">
    <input
      class="ifield"
      placeholder="&nbsp;"
      type="text"
      name="lastName"
      [(ngModel)]="destdata.destTown"
      required
      #lastName="ngModel"
    />
    <span class="itext"> Destination City of the relocation*</span>
  </label>

  <h4>Family size</h4>

  <label class="radio">
    <input
      required
      type="radio"
      value="Single"
      name="tetraFamily"
      #tetraFamily="ngModel"
      [(ngModel)]="extrainfo.FamilySize"
    />
    <span>Single</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="Couple"
      name="tetraFamily"
      [(ngModel)]="extrainfo.FamilySize"
    />
    <span>Couple</span>
  </label>

  <label class="icontainer label-children">
    <select
      name="tetraChildren"
      class="iselect"
      [(ngModel)]="extrainfo.NumberOfChildren"
      #tetraChildren
    >
      <option value="none">No kids</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6 or more">6 or more</option>
    </select>
    <span
      class="itext"
      [ngClass]="{ 'itext-select': extrainfo.NumberOfChildren != undefined }"
    >
      Number of children
    </span>
  </label>

  <h4>Assignment/employment dates</h4>
  <div class="form-container">
    <label class="datelabel" for="employmentStart">
      Assignment/Employment planned start date in the destination*</label
    >
    <input
      class="dateinput"
      type="date"
      id="employmentStart"
      name="employmentStart"
      [(ngModel)]="extrainfo.EmploymentStartDate"
      #employmentStart
      required
    />

    <label class="datelabel" for="employmentEnd">
      Assignment/Employment planned end in the destination</label
    >
    <input
      class="dateinput"
      type="date"
      id="employmentEnd"
      name="employmentEnd"
      [(ngModel)]="extrainfo.EmploymentEndDate"
      #employmentEnd
    />
  </div>

  <h4>Services authorized by:</h4>

  <label class="icontainer">
    <input
      class="ifield"
      placeholder="&nbsp;"
      type="text"
      name="inputName"
      [(ngModel)]="extrainfo.ApprovingManagerName"
      #inputName="ngModel"
      required
    />
    <span class="itext">Name*</span>
  </label>

  <label class="icontainer">
    <input
      class="ifield"
      placeholder="&nbsp;"
      type="text"
      name="lastName"
      [(ngModel)]="extrainfo.ApprovingManagerEmployeeNumber"
      required
      #lastName="ngModel"
    />
    <span class="itext">Title</span>
  </label>

  <label class="icontainer">
    <input
      class="ifield"
      placeholder="&nbsp;"
      type="email"
      name="fromEmail"
      [(ngModel)]="extrainfo.ApprovingManagerEmail"
      #fromEmail="ngModel"
      required
      email
    />
    <span class="itext">Email*</span>
    <span class="erval" *ngIf="!fromEmail.valid && fromEmail.touched"
      >Please enter a correct email</span
    >
  </label>

  <label class="icontainer">
    <input
      class="ifield"
      type="tel"
      placeholder="&nbsp;"
      name="toMobilePhone"
      [(ngModel)]="orgdata.orgPhoneOffice"
      #toMobilePhone="ngModel"
    />
    <span class="itext">Mobile phone*</span>
  </label>

  <h4>Please select authorized services from below:</h4>
  <!-- HERE -->
  <label class="checkbox">
    <input
      type="checkbox"
      name="immigration"
      [(ngModel)]="services.immigration"
      #immigration
    />
    <span>Immigration</span>
  </label>

  <label class="checkbox">
    <input
      type="checkbox"
      name="settlingIn"
      [(ngModel)]="services.settlingIn"
      #settlingIn
    />
    <span>Settling in (bundle)</span>
  </label>

  <label class="checkbox">
    <input
      type="checkbox"
      name="homeSearch"
      [(ngModel)]="services.homeSearch"
      #homeSearch
    />
    <span>Home Search (bundle)</span>
  </label>

  <label class="checkbox">
    <input
      type="checkbox"
      name="tempAccomodationBooking"
      [(ngModel)]="services.tempAccomodationBooking"
      #tempAccomodationBooking
    />
    <span>Temporary accommodation booking</span>
  </label>

  <label class="checkbox">
    <input
      type="checkbox"
      name="schoolSearch"
      [(ngModel)]="services.schoolSearch"
      #schoolSearch
    />
    <span>School Search</span>
  </label>

  <label class="checkbox">
    <input
      type="checkbox"
      name="spouseSupport"
      [(ngModel)]="services.spouseSupport"
      #spouseSupport
    />
    <span>Spouse Support</span>
  </label>

  <label class="checkbox">
    <input
      type="checkbox"
      name="preVisitOrientation"
      [(ngModel)]="services.preVisitOrientation"
      #preVisitOrientation
    />
    <span>Pre-visit / Orientation</span>
  </label>

  <!-- PLEXOR -->
  <label class="checkbox">
    <input
      type="checkbox"
      name="preHireRelocationConsultation"
      [(ngModel)]="services.preHireRelocationConsultation"
      #preHireRelocationConsultation
    />
    <span>Pre-hire/-relocation consultation</span>
  </label>

  <label class="checkbox">
    <input
      type="checkbox"
      name="consultation"
      [(ngModel)]="services.consultation"
      #consultation
    />
    <span>Consultation (please specify below)</span>
  </label>

  <label class="checkbox">
    <input
      type="checkbox"
      name="outboundRelocation"
      [(ngModel)]="services.outboundRelocation"
      #outboundRelocation
    />
    <span
      >Outbound relocation/immigration to other destinations than Finland
      (please specify in additional information below)</span
    >
  </label>

  <label class="checkbox">
    <input
      type="checkbox"
      name="otherSpecified"
      [(ngModel)]="services.otherSpecified"
      #otherSpecified
    />
    <span>Other (please specify in additional information below)</span>
  </label>

  <label class="icontainer">
    <input
      class="ifield"
      placeholder="&nbsp;"
      type="text"
      name="lastName"
      [(ngModel)]="extrainfo.CompanyDepartment"
      required
      #lastName="ngModel"
    />
    <span class="itext">Invoicing entity name*</span>
  </label>

  <label class="icontainer">
    <input
      class="ifield"
      placeholder="&nbsp;"
      type="text"
      name="lastName"
      [(ngModel)]="extrainfo.CostCenter"
      required
      #lastName="ngModel"
    />
    <span class="itext"
      >Cost center / PO-number / other invoicing reference</span
    >
  </label>

  <div class="radio-container">
    <h4>Are you a new customer?</h4>

    <div class="row">
      <label class="radio">
        <input
          type="radio"
          value="yes"
          [(ngModel)]="extrainfo.newCustomer"
          name="newCustomer"
        />
        <span>Yes</span>
      </label>
    </div>

    <div class="row">
      <label class="radio">
        <input
          type="radio"
          value="no"
          [(ngModel)]="extrainfo.newCustomer"
          name="newCustomer"
        />
        <span>No</span>
      </label>
    </div>
  </div>

  <div *ngIf="extrainfo.newCustomer === 'yes'">
    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="text"
        name="invoicingAdress"
        [(ngModel)]="extrainfo.invoicingAdress"
        required
        #invoicingAdress="ngModel"
      />
      <span class="itext">Invoicing address</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="text"
        name="companyVAT"
        [(ngModel)]="extrainfo.companyVAT"
        required
        #companyVAT="ngModel"
      />
      <span class="itext">Company VAT number</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="text"
        name="emailInvoice"
        [(ngModel)]="extrainfo.emailInvoice"
        required
        #emailInvoice="ngModel"
      />
      <span class="itext">Email address for sending invoice</span>
    </label>

    <label class="icontainer">
      <input
        class="ifield"
        placeholder="&nbsp;"
        type="text"
        name="ediCode"
        [(ngModel)]="extrainfo.ediCode"
        required
        #ediCode="ngModel"
      />
      <span class="itext">EDI code or other E-invoicing details</span>
    </label>

    <div class="radio-container">
      <h4>Payment terms</h4>

      <div class="row">
        <label class="radio">
          <input
            type="radio"
            value="14 days"
            [(ngModel)]="extrainfo.paymentTerms"
            name="paymentTerms"
          />
          <span>14 days</span>
        </label>
      </div>

      <div class="row">
        <label class="radio">
          <input
            type="radio"
            value="30 days"
            [(ngModel)]="extrainfo.paymentTerms"
            name="paymentTerms"
          />
          <span>30 days</span>
        </label>
      </div>
    </div>
  </div>

  <label for="textbox"><h4>Additional information</h4></label>
  <textarea
    class="itextfield"
    name="otherDetails"
    id="textbox"
    cols="30"
    rows="10"
    [(ngModel)]="extrainfo.OtherInfo"
    placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
  ></textarea>

  <div class="button-container">
    <!-- BASIC BUTTONS -->

    <input
      class="button btn-form"
      type="button"
      value="Submit"
      (click)="formData.privateSubmit()"
    />
  </div>
</div>
