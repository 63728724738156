<ng-http-loader></ng-http-loader>
<div class="booking-container">
  <img *ngIf="logoCode === 0"
    class="logo-top"
    src="./assets/image/alfa.png"
    alt="Alfa mobility logo"
  />
  <img *ngIf="logoCode === 1"
    class="logo-top"
    src="./assets/image/UniversityOfHelsinki.jpg"
    alt="University of Helsinki logo"
  />
  <div class="section-container">
    <router-outlet></router-outlet>
  </div>
  <!-- <div class="footer">
    <app-footer></app-footer>
  </div> -->
</div>
