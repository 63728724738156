<h3>Insurance</h3>
<hr />
<p>
  All shipments and storage portions are insured through Alfa. A valuation form
  will be sent to you by our move mangers upon confirming your move. It is very
  important that this form is filled in and returned prior to packing.
</p>


<h3>Other details</h3>
<hr />

<h4>Shipment to UNFURNISHED residence</h4>

<label class="radio">
  <input
    type="radio"
    value="single 20cbm"
    name="Shipment"
    [(ngModel)]="extrainfo.Shipment"
  />
  <span>Single (20 cbm)</span> </label
>

<label class="radio">
  <input
    type="radio"
    value="couple 30cbm"
    name="Shipment"
    [(ngModel)]="extrainfo.Shipment"
  />
  <span>Couple (30 cbm, a full 1 * 20' container)</span> </label
>

<label class="radio">
  <input
    type="radio"
    value="family with children 60cbm"
    name="Shipment"
    [(ngModel)]="extrainfo.Shipment"
  />
  <span>Family with children (60 cbm, a full 1 8 40' container)</span> </label
>

<h4>Shipment to FURNISHED residence</h4>

<label class="radio">
  <input
    type="radio"
    value="single 4cbm"
    name="Shipment"
    [(ngModel)]="extrainfo.Shipment"
  />
  <span>Single (4 cbm)</span> </label
>

<label class="radio">
  <input
    type="radio"
    value="couple 8cbm"
    name="Shipment"
    [(ngModel)]="extrainfo.Shipment"
  />
  <span>Couple (8 cbm)</span> </label
>

<h4>Do you have any goods in storage to be included in your move?</h4>

<label class="radio">
  <input
    type="radio"
    value="Yes"
    name="oriflameMoveStorage"
    [(ngModel)]="extrainfo.InStorage"
  />
  <span>Yes</span> </label
>

<label class="radio">
  <input
    type="radio"
    value="No"
    name="oriflameMoveStorage"
    [(ngModel)]="extrainfo.InStorage"
  />
  <span>No</span> </label
>

<!-- <h4>Do you need a storage of max 10 cbm?</h4>

<label class="radio">
  <input
    type="radio"
    value="Yes"
    name="oriflameStorage"
    [(ngModel)]="extrainfo.ToStorage"
  />
  <span>Yes</span> </label
>

<label class="radio">
  <input
    type="radio"
    value="No"
    name="oriflameStorage"
    [(ngModel)]="extrainfo.ToStorage"
  />
  <span>No</span> </label
> -->


<h4>Do you have any children?</h4>
<label class="icontainer label-children">
  <select
    name="NumberOfChildren"
    class="iselect"
    [(ngModel)]="extrainfo.NumberOfChildren"
    #NumberOfChildren
  >
    <option value="none">No kids</option>
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6 or more">6 or more</option>
  </select>
  <span
    class="itext"
    [ngClass]="{ 'itext-select': extrainfo.NumberOfChildren != undefined }"
  >
    Number of children
  </span> </label
>

<label for="textbox"><h4>Other info</h4></label>
<textarea
  class="itextfield"
  name="otherDetails"
  id="textbox"
  cols="30"
  rows="10"
  [(ngModel)]="extrainfo.OtherInfo"
  itext="Please type here if you have something to add.."
>
</textarea>

<div class="button-container">
  <input
    class="button btn-form"
    type="button"
    value="Back"
    routerLink="../movedetailsto"
  />
  <input
    class="button btn-form"
    type="button"
    value="Submit"
    (click)="formData.formSubmit()"
  />
</div>
