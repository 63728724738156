import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthService } from '../../services/auth.service';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {

  resetpwSubscription: Subscription = new Subscription();

  aFormGroup!: FormGroup;
  siteKey = environment['recaptcha']['siteKey'];

  iAmHuman = false;

  resetpwForm = new FormGroup({
    username: new FormControl('', Validators.required)
  });

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
  }

  resetPassword(): void {
    const user = this.resetpwForm.get('username')?.value;
    this.resetpwSubscription = this.authService
      .resetPassword(user)
      .subscribe({
        next: (res: any) => {
          // console.log(res);
          this.toastr.success('Request for new password sent');
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 2000);
        },
        error: (error) => {
          console.error(error);
          this.toastr.error('Request failed');
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 2000);
        },
      });
  }

  handleReset() {
    console.log('handleReset()');
  }

  handleExpire() {
    console.log('handleExpire()');
  }

  handleLoad() {
    console.log('handleLoad()');
  }

  handleSuccess(msg: string) {
    // console.log('handleSuccess()');
    this.iAmHuman = true;
  }

}
