import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { storeUser } from '../../store/actions';
import { UserState } from '../../store/reducers';

import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user';
import { _store } from '../../localstore/localstore';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  error = false;
  errorObj: any = {};

  loginSubscription: Subscription = new Subscription();

  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  constructor(
    private store: Store<UserState>,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    delete Object.getPrototypeOf(navigator).credentials;
    this.activatedRoute.queryParams.subscribe(params => {
      // console.log('URL params : ', params);
      if (params.username) {
        this.loginForm.controls['username'].setValue(params.username);
      }
    });
  }

  ngOnDestroy(): void {
    this.loginSubscription.unsubscribe();
  }

  login(): void {
    const username = this.loginForm.get('username')?.value;
    const password = this.loginForm.get('password')?.value;
    this.loginSubscription = this.authService
      .login(username, password)
      .subscribe({
        next: (res: User) => {
          console.log(res);
          if (res.response.result) {
            this.toastr.success('Login successful');
            // console.log('response: ', res);
            // Save user object to store.
            this.store.dispatch(storeUser({ payload: res }));
            // Save user information and sessionId object to local storage
            // as an encrypted string.
            const user = new _store();
            user.set(
              res.content.userName,
              res.content.userDisplayName,
              res.content.profilePicture,
              res.context.sessionId,
              true
            );
            user.push();
            this.router.navigate(['/home']);
          } else {
            // console.log('Authentication failed. Wrong username or password');
            this.toastr.error('Wrong username or password');
          }
        },
        error: (error) => {
          console.error(error);
          this.error = true;
          this.errorObj = error;
        },
      });
  }

  forgotPassword(): void {
    this.router.navigate(['/resetpw']);
  }
}
