import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-polisen',
  templateUrl: './polisen.component.html',
  styleUrls: ['./polisen.component.scss']
})
export class PolisenComponent {

  content:Content;
  extrainfo:Extrainfo;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.formDataService.reset();

    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.extrainfo.CompanyName = 'Polismyndigheten';
    this.content.SokID = '47017';
    this.extrainfo.CompanyAddress = ' ';
    this.extrainfo.CompanyCity = ' ';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = ' ';
    this.extrainfo.CompanyEmail = '';
    this.extrainfo.CompanyContact = '';
    this.extrainfo.insurance = '';
    this.extrainfo.Legal = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';
    this.extrainfo.lockCompany = true;

    this.formConfig.confirm = false; //Determines if form need approval by manager

    //form configuration
    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'Polisen';
  }

}
