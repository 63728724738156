import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Data } from '../../models/data';
import { Assignment } from '../../models/assignment';

@Component({
  selector: 'app-assignment-list',
  templateUrl: './assignment-list.component.html',
  styleUrls: ['./assignment-list.component.scss']
})
export class AssignmentListComponent implements OnInit {

  assignments: Assignment[] = [];
  loading = true;

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.getData('assignment', false);
  }

  getData(_function: string, _detail: boolean) {
    this.dataService.getData(_function, _detail).subscribe({
      next: (response: Data) => {
        if (response.response.result) {
          this.assignments = response.content.list;
          this.loading = false;
        } else {
          this.assignments = [];
          this.loading = false;
        }
      },
      error: (error: Error) => {
        console.error(error);
        this.assignments = [];
        this.loading = false;
      }
    });
  }



}
