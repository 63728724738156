import { Component } from '@angular/core';
import { FormDataService } from 'src/app/services/form-data.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';
import { FormConfig } from 'src/app/services/form-config';
import { BussinessAgreementinfo,  Destdata, Extrainfo, Orgdata, Volume } from 'src/app/models/form/book-data';



@Component({
  selector: 'app-other-details',
  templateUrl: './other-details.component.html',
  styleUrls: ['./other-details.component.scss']
})
export class OtherDetailsComponent {

  extrainfo:Extrainfo;
  volume:Volume;
  bussiness: BussinessAgreementinfo;
  destdata:Destdata;
  orgdata:Orgdata;
  
  formC : FormConfig;
  
constructor (
  private formDataService : FormDataService, 
  private http:HttpClient, 
  private dataService : DataService,
  private formConfig : FormConfig,
  public formData : FormDataService,
  ) 
  {

  this.formC = formConfig;


  this.extrainfo = formDataService.extrainfo;
  this.volume = formDataService.volume;
  this.bussiness = formDataService.bussiness;
  this.destdata = formDataService.destdata;
  this.orgdata = formDataService.orgdata;
}



}