<div class="resetpw-container">
  <div class="recaptcha-form" *ngIf="!iAmHuman">
    <form [formGroup]="aFormGroup">
      <ngx-recaptcha2 #captchaElem
        [siteKey]=siteKey
        (reset)="handleReset()"
        (expire)="handleExpire()"
        (load)="handleLoad()"
        (success)="handleSuccess($event)"
        [useGlobalDomain]="false"
        formControlName="recaptcha">
      </ngx-recaptcha2>
    </form>
  </div>
  <div class="resetpw-form" *ngIf="iAmHuman">
    <div class="resetpw-header">Reset password</div>
    <form [formGroup]="resetpwForm">
        
<input itext="Email or username" type="text" formControlName="username" />
      
      <div class="resetpw-buttons">
        <button class="btn-form" id="resetpw-button" (click)="resetPassword()">Submit</button>
      </div>
    </form>
  </div>
</div>
