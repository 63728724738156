import { Injectable } from "@angular/core";
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
export class MessageService {

  private subject = new Subject<number>();

  sendMessage(message: number) {
    this.subject.next(message);
  }

  clearMessage() {
    this.subject.next(0);
  }

  onMessage(): Observable<number> {
    return this.subject.asObservable();
  }

}
