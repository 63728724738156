import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-tetralaval',
  templateUrl: './tetralaval.component.html',
  styleUrls: ['./tetralaval.component.scss'],
})
export class TetralavalComponent {
  extrainfo: Extrainfo;
  content: Content;

  constructor(
    private formDataService: FormDataService,
    private formConfig: FormConfig
  ) {}

  ngOnInit() {
    // console.log('Initialize tetralaval component');
    this.formDataService.reset();

    this.content = this.formDataService.content;
    this.extrainfo = this.formDataService.extrainfo;

    //pre-assigned values for form.
    this.extrainfo.CompanyName = 'Tetra laval';
    this.content.SokID = '19273';
    this.extrainfo.CompanyEmail = '';
    // this.extrainfo.CompanyContact = '';
    // this.extrainfo.CompanyEmail = 'AL@ipmglobal.com';
    // this.extrainfo.CompanyAddress = 'Box 74';
    // this.extrainfo.CompanyCity = 'Lund';
    // this.extrainfo.CompanyCountry = 'SE';
    // this.extrainfo.CompanyZipCode = '221 00';
    // this.extrainfo.insurance = '';
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.GlobalIntensivePlan = 'no';
    this.formConfig.confirm = true;

    //Config
    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'Tetralaval';

    // this.formDataService.dump();
  }
}
