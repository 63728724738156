import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Assignment2DetailComponent } from '../assignment2-detail/assignment2-detail.component';

import { Assignment } from 'src/app/models/assignment';

@Component({
  selector: 'app-assignment2',
  templateUrl: './assignment2.component.html',
  styleUrls: ['./assignment2.component.scss']
})
export class Assignment2Component implements OnInit {

  @Input()
  assignment!: Assignment;
  fromCountryClass = '';
  toCountryClass = '';

  constructor(
    public modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.fromCountryClass = `flag-icon flag-icon-${this.assignment.fromCountry.toLocaleLowerCase()} flag-icon-squared` ;
    this.toCountryClass = `flag-icon flag-icon-${this.assignment.toCountry.toLocaleLowerCase()} flag-icon-squared` ;
  }

  openDetail(): void {
    const modalRef = this.modalService.open(Assignment2DetailComponent, {
      scrollable: true,
      animation: true,
      centered: true,
/*       windowClass: 'customer-select-modal',
      size: 'xl', */
    });
    modalRef.componentInstance.id = this.assignment.id;
    modalRef.result.then(
      (result: any) => {
        // console.log('result: ', result);
      },
      (reason: any) => {
        // console.log('reason: ', reason);
      }
    );
  }

}
