export const companies = [
  {
    id: '105',
    companynumber: '25609',
    companyname: 'Tetra Pak S.R.L',
    countrycode: 'AR',
    countryname: 'Argentina',
    groupcode: 'TETRA',
  },
  {
    id: '1105',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'AR',
    countryname: 'Argentina',
    groupcode: 'TETRA',
  },
  {
    id: '11105',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'AR',
    countryname: 'Argentina',
    groupcode: 'TETRA',
  },
  {
    id: '1106',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'AT',
    countryname: 'Austria',
    groupcode: 'TETRA',
  },
  {
    id: '11106',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'AT',
    countryname: 'Austria',
    groupcode: 'TETRA',
  },
  {
    id: '115',
    companynumber: '26785',
    companyname: 'Tetra Pak Marketing Pty Ltd',
    countrycode: 'AU',
    countryname: 'Australia',
    groupcode: 'TETRA',
  },
  {
    id: '205',
    companynumber: '54077',
    companyname: 'Sidel Oceania PTY LTD',
    countrycode: 'AU',
    countryname: 'Australia',
    groupcode: 'TETRA',
  },
  {
    id: '2205',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'AU',
    countryname: 'Australia',
    groupcode: 'TETRA',
  },
  {
    id: '22205',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'AU',
    countryname: 'Australia',
    groupcode: 'TETRA',
  },
  {
    id: '193',
    companynumber: '48969',
    companyname: 'Tetra Pak Global Distribution SA',
    countrycode: 'BY',
    countryname: 'Belarus',
    groupcode: 'TETRA',
  },
  {
    id: '1193',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'BY',
    countryname: 'Belarus',
    groupcode: 'TETRA',
  },
  {
    id: '11193',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'BY',
    countryname: 'Belarus',
    groupcode: 'TETRA',
  },
  {
    id: '77',
    companynumber: '22267',
    companyname: 'Tetra Pak Belgium NV',
    countrycode: 'BE',
    countryname: 'Belgium',
    groupcode: 'TETRA',
  },
  {
    id: '1177',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'BE',
    countryname: 'Belgium',
    groupcode: 'TETRA',
  },
  {
    id: '11177',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'BE',
    countryname: 'Belgium',
    groupcode: 'TETRA',
  },
  {
    id: '167',
    companynumber: '39945',
    companyname: 'Tetra Pak S.R.L.',
    countrycode: 'BO',
    countryname: 'Bolivia',
    groupcode: 'TETRA',
  },
  {
    id: '35',
    companynumber: '20157',
    companyname: 'Tetra Pak Ltda',
    countrycode: 'BR',
    countryname: 'Brazil',
    groupcode: 'TETRA',
  },
  {
    id: '41',
    companynumber: '20261',
    companyname: 'Tetra Pak Ltda.',
    countrycode: 'BR',
    countryname: 'Brazil',
    groupcode: 'TETRA',
  },
  {
    id: '1141',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'BR',
    countryname: 'Brazil',
    groupcode: 'TETRA',
  },
  {
    id: '11141',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'BR',
    countryname: 'Brazil',
    groupcode: 'TETRA',
  },
  {
    id: '62',
    companynumber: '21295',
    companyname: 'Sidel do Brasil Ltda',
    countrycode: 'BR',
    countryname: 'Brazil',
    groupcode: 'TETRA',
  },
  {
    id: '63',
    companynumber: '59745',
    companyname: 'Sidel Kazakhstan LLP',
    countrycode: 'KZ',
    countryname: 'Kazakhstan',
    groupcode: 'TETRA',
  },
  {
    id: '64',
    companynumber: '37905',
    companyname: 'Guerin Systems do Brasil Comercial Ltda.',
    countrycode: 'BR',
    countryname: 'Brazil',
    groupcode: '',
  },
  {
    id: '33',
    companynumber: '20152',
    companyname: 'Tetra Pak Canada Inc. ',
    countrycode: 'CA',
    countryname: 'Canada',
    groupcode: 'TETRA',
  },
  {
    id: '89',
    companynumber: '23642',
    companyname: 'Sidel Canada Inc',
    countrycode: 'CA',
    countryname: 'Canada',
    groupcode: 'TETRA',
  },
  {
    id: '195',
    companynumber: '50087',
    companyname: 'Novembal Canada Inc',
    countrycode: 'CA',
    countryname: 'Canada',
    groupcode: 'TETRA',
  },
  {
    id: '1133',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'CA',
    countryname: 'Canada',
    groupcode: 'TETRA',
  },
  {
    id: '11133',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'CA',
    countryname: 'Canada',
    groupcode: 'TETRA',
  },
  {
    id: '2',
    companynumber: '23062',
    companyname: 'Tetra Laval Finance Ltd',
    countrycode: 'KY',
    countryname: 'Cayman Islands',
    groupcode: 'TETRA',
  },
  {
    id: '88',
    companynumber: '23419',
    companyname: 'Tetra Pak de Chile Com. Ltda.',
    countrycode: 'CL',
    countryname: 'Chile',
    groupcode: 'TETRA',
  },
  {
    id: '1177',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'CL',
    countryname: 'Chile',
    groupcode: 'TETRA',
  },
  {
    id: '11177',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'CL',
    countryname: 'Chile',
    groupcode: 'TETRA',
  },
  {
    id: '138',
    companynumber: '31130',
    companyname: 'Sidel Chile S.A',
    countrycode: 'CL',
    countryname: 'Chile',
    groupcode: 'TETRA',
  },
  {
    id: '179',
    companynumber: '43320',
    companyname: 'Sidel Chile S.p.A.',
    countrycode: 'CL',
    countryname: 'Chile',
    groupcode: 'TETRA',
  },
  {
    id: '1',
    companynumber: '23365',
    companyname: 'Tetra Pak CHINA LTD - CHINA OPERATION',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '16',
    companynumber: '19576',
    companyname: 'Tetra Pak China Ltd',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '56',
    companynumber: '20842',
    companyname: 'Tetra Pak Hoyer Shanghai',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '57',
    companynumber: '20984',
    companyname: 'Sidel Shanghai (Machinery) Co., Ltd',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '71',
    companynumber: '21724',
    companyname: 'Tetra Pak Beijing co. Ltd',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '91',
    companynumber: '23808',
    companyname: 'Tetra Pak KUNSHAN OPERATION UNIT',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '94',
    companynumber: '24069',
    companyname: 'Tetra Pak Food Machinery',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '112',
    companynumber: '26480',
    companyname: 'Tetra Pak (Kunshan) Co. Ltd',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '124',
    companynumber: '28371',
    companyname: 'Sidel Machinery (Beijing) Co.Ltd.',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '125',
    companynumber: '28560',
    companyname: 'Tetra Pak Hohhot Co. Ltd',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '152',
    companynumber: '35891',
    companyname: 'Tetra Pak Trading (Shanghai) Co., Ltd.',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '158',
    companynumber: '37456',
    companyname: 'Tetra Pak (Foshan) Co., Ltd. ',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '183',
    companynumber: '44835',
    companyname: 'Tetra Pak Packaging Solutions (Shanghai) Co., Ltd.',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '11183',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '111183',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'CN',
    countryname: 'China',
    groupcode: 'TETRA',
  },
  {
    id: '65',
    companynumber: '21391',
    companyname: 'Tetra Pak Ltda (Colombia)',
    countrycode: 'CO',
    countryname: 'Colombia',
    groupcode: 'TETRA',
  },
  {
    id: '1165',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'CO',
    countryname: 'Colombia',
    groupcode: 'TETRA',
  },
  {
    id: '11165',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'CO',
    countryname: 'Colombia',
    groupcode: 'TETRA',
  },
  {
    id: '173',
    companynumber: '41098',
    companyname: 'Tetra Pak (T&T) Limited',
    countrycode: 'CR',
    countryname: 'Costa Rica',
    groupcode: 'TETRA',
  },
  {
    id: '11173',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'CZ',
    countryname: 'Czech Republic',
    groupcode: 'TETRA',
  },
  {
    id: '111173',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'CZ',
    countryname: 'Czech Republic',
    groupcode: 'TETRA',
  },
  {
    id: '67',
    companynumber: '21556',
    companyname: 'Tetra Pak d.o.o. (Adria)',
    countrycode: 'HR',
    countryname: 'Croatia',
    groupcode: 'TETRA',
  },
  {
    id: '39',
    companynumber: '20208',
    companyname: 'Tetra Pak Inventing A/S',
    countrycode: 'DK',
    countryname: 'Denmark',
    groupcode: 'TETRA',
  },
  {
    id: '120',
    companynumber: '27920',
    companyname: 'Tetra Pak Processing Systems A/S',
    countrycode: 'DK',
    countryname: 'Denmark',
    groupcode: 'TETRA',
  },
  {
    id: '127',
    companynumber: '28787',
    companyname: 'Tetra Pak Cheese and Powder Systems A/S',
    countrycode: 'DK',
    countryname: 'Denmark',
    groupcode: 'TETRA',
  },
  {
    id: '11127',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'DK',
    countryname: 'Denmark',
    groupcode: 'TETRA',
  },
  {
    id: '111173',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'DK',
    countryname: 'Denmark',
    groupcode: 'TETRA',
  },
  {
    id: '149',
    companynumber: '35440',
    companyname: 'Tetra Pak Dominicana, S.R.L.',
    countrycode: 'DO',
    countryname: 'Dominican Republic',
    groupcode: 'TETRA',
  },
  {
    id: '119',
    companynumber: '27880',
    companyname: 'Tetra Pak Cia Ltda Ecuador',
    countrycode: 'EC',
    countryname: 'Ecuador',
    groupcode: 'TETRA',
  },
  {
    id: '20',
    companynumber: '19656',
    companyname: 'Tetra Pak Egypt Ltd',
    countrycode: 'EG',
    countryname: 'Egypt',
    groupcode: 'TETRA',
  },
  {
    id: '1120',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'EG',
    countryname: 'Egypt',
    groupcode: 'TETRA',
  },
  {
    id: '11120',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'EG',
    countryname: 'Egypt',
    groupcode: 'TETRA',
  },
  {
    id: '9999',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'EE',
    countryname: 'Estonia',
    groupcode: 'TETRA',
  },
  {
    id: '99999',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'EE',
    countryname: 'Estonia',
    groupcode: 'TETRA',
  },
  {
    id: '81',
    companynumber: '22464',
    companyname: 'Tetra Pak Oy',
    countrycode: 'FI',
    countryname: 'Finland',
    groupcode: 'TETRA',
  },
  {
    id: '1181',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'FI',
    countryname: 'Finland',
    groupcode: 'TETRA',
  },
  {
    id: '11181',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'FI',
    countryname: 'Finland',
    groupcode: 'TETRA',
  },
  {
    id: '30',
    companynumber: '19889',
    companyname: 'Sidel Blowing & Services SAS',
    countrycode: 'FR',
    countryname: 'France',
    groupcode: 'TETRA',
  },
  {
    id: '51',
    companynumber: '20671',
    companyname: 'Sidel Blowing & Services',
    countrycode: 'FR',
    countryname: 'France',
    groupcode: 'TETRA',
  },
  {
    id: '59',
    companynumber: '21158',
    companyname: 'Papeteries de Dijon',
    countrycode: 'FR',
    countryname: 'France',
    groupcode: 'TETRA',
  },
  {
    id: '75',
    companynumber: '21893',
    companyname: 'Tetra Pak Service SNC',
    countrycode: 'FR',
    countryname: 'France',
    groupcode: 'TETRA',
  },
  {
    id: '80',
    companynumber: '22450',
    companyname: 'Tetra Pak Services SAS',
    countrycode: 'FR',
    countryname: 'France',
    groupcode: 'TETRA',
  },
  {
    id: '90',
    companynumber: '23774',
    companyname: 'Sidel Engineering & Conveying Solutions SAS',
    countrycode: 'FR',
    countryname: 'France',
    groupcode: 'TETRA',
  },
  {
    id: '107',
    companynumber: '25911',
    companyname: 'Sidel Participations S.A.S.',
    countrycode: 'FR',
    countryname: 'France',
    groupcode: 'TETRA',
  },
  {
    id: '131',
    companynumber: '29330',
    companyname: 'Tetra Pak & Cie',
    countrycode: 'FR',
    countryname: 'France',
    groupcode: 'TETRA',
  },
  {
    id: '146',
    companynumber: '34277',
    companyname: 'Tetra Pak Processing SNC',
    countrycode: 'FR',
    countryname: 'France',
    groupcode: 'TETRA',
  },
  {
    id: '169',
    companynumber: '40393',
    companyname: 'Tetra Pak Processing Equipment SAS',
    countrycode: 'FR',
    countryname: 'France',
    groupcode: 'TETRA',
  },
  {
    id: '175',
    companynumber: '42093',
    companyname: 'Cermex Newtec',
    countrycode: 'FR',
    countryname: 'France',
    groupcode: 'TETRA',
  },
  {
    id: '176',
    companynumber: '42174',
    companyname: 'Sidel Packing Solutions SAS',
    countrycode: 'FR',
    countryname: 'France',
    groupcode: 'TETRA',
  },
  {
    id: '11176',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'FR',
    countryname: 'France',
    groupcode: 'TETRA',
  },
  {
    id: '111176',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'FR',
    countryname: 'France',
    groupcode: 'TETRA',
  },
  {
    id: '73',
    companynumber: '21833',
    companyname: 'Tetra Pak Processing Gmbh',
    countrycode: 'DE',
    countryname: 'Germany',
    groupcode: 'TETRA',
  },
  {
    id: '139',
    companynumber: '31289',
    companyname: 'Tetra Pak GmbH & Co KG',
    countrycode: 'DE',
    countryname: 'Germany',
    groupcode: 'TETRA',
  },
  {
    id: '140',
    companynumber: '31382',
    companyname: 'Sidel GmbH',
    countrycode: 'DE',
    countryname: 'Germany',
    groupcode: 'TETRA',
  },
  {
    id: '154',
    companynumber: '36061',
    companyname: 'Tetra Holdings GmbH',
    countrycode: 'DE',
    countryname: 'Germany',
    groupcode: 'TETRA',
  },
  {
    id: '185',
    companynumber: '44957',
    companyname: 'Tetra Pak Produktions GmbH & Co KG',
    countrycode: 'DE',
    countryname: 'Germany',
    groupcode: 'TETRA',
  },
  {
    id: '192',
    companynumber: '48837',
    companyname: 'Tetra Pak Processing Equipment AB',
    countrycode: 'DE',
    countryname: 'Germany',
    groupcode: 'TETRA',
  },
  {
    id: '11192',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'DE',
    countryname: 'Germany',
    groupcode: 'TETRA',
  },
  {
    id: '111192',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'DE',
    countryname: 'Germany',
    groupcode: 'TETRA',
  },
  {
    id: '102',
    companynumber: '25008',
    companyname: 'Tetra Pak Hellas SA',
    countrycode: 'GR',
    countryname: 'Greece',
    groupcode: 'TETRA',
  },
  {
    id: '184',
    companynumber: '44956',
    companyname: 'Tetra Pak (Guatemala) Limited',
    countrycode: 'GT',
    countryname: 'Guatemala',
    groupcode: 'TETRA',
  },
  {
    id: '116',
    companynumber: '26802',
    companyname: 'Tetra Pak Hungary Trading Company Ltd.',
    countrycode: 'HU',
    countryname: 'Hungary',
    groupcode: 'TETRA',
  },
  {
    id: '180',
    companynumber: '43376',
    companyname: 'Tetra Pak Packaging Material Production Ltd.',
    countrycode: 'HU',
    countryname: 'Hungary',
    groupcode: 'TETRA',
  },
  {
    id: '197',
    companynumber: '50609',
    companyname: '2.Tetra Pak Packaging Material Production Ltd.',
    countrycode: 'HU',
    countryname: 'Hungary',
    groupcode: 'TETRA',
  },
  {
    id: '11197',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'HU',
    countryname: 'Hungary',
    groupcode: 'TETRA',
  },
  {
    id: '111197',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'HU',
    countryname: 'Hungary',
    groupcode: 'TETRA',
  },
  {
    id: '28',
    companynumber: '19772',
    companyname: 'Tetra Pak India Pvt. Ltd ',
    countrycode: 'IN',
    countryname: 'India',
    groupcode: 'TETRA',
  },
  {
    id: '160',
    companynumber: '37863',
    companyname: 'Sidel India Pvt. Ltd.',
    countrycode: 'IN',
    countryname: 'India',
    groupcode: 'TETRA',
  },
  {
    id: '1128',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'IN',
    countryname: 'India',
    groupcode: 'TETRA',
  },
  {
    id: '11128',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'IN',
    countryname: 'India',
    groupcode: 'TETRA',
  },
  {
    id: '98',
    companynumber: '24646',
    companyname: 'Tetra Pak Stainless Equipment ',
    countrycode: 'ID',
    countryname: 'Indonesia',
    groupcode: 'TETRA',
  },
  {
    id: '109',
    companynumber: '26101',
    companyname: 'Tetra Pak Indonesia',
    countrycode: 'ID',
    countryname: 'Indonesia',
    groupcode: 'TETRA',
  },
  {
    id: '172',
    companynumber: '41062',
    companyname: 'PT Sidel Indonesia',
    countrycode: 'ID',
    countryname: 'Indonesia',
    groupcode: 'TETRA',
  },
  {
    id: '132',
    companynumber: '29386',
    companyname: 'Tetra Pak Iran',
    countrycode: 'IR',
    countryname: 'Iran, Islamic Republic Of',
    groupcode: 'TETRA',
  },
  {
    id: '58',
    companynumber: '21011',
    companyname: 'Tetra Pak Ireland Ltd',
    countrycode: 'IE',
    countryname: 'Ireland',
    groupcode: 'TETRA',
  },
  {
    id: '134',
    companynumber: '29573',
    companyname: 'Tetra Pak (Ireland) Ltd',
    countrycode: 'IE',
    countryname: 'Ireland',
    groupcode: 'TETRA',
  },
  {
    id: '1158',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'IE',
    countryname: 'Ireland',
    groupcode: 'TETRA',
  },
  {
    id: '11158',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'IE',
    countryname: 'Ireland',
    groupcode: 'TETRA',
  },
  {
    id: '13',
    companynumber: '19568',
    companyname: 'Tetra Pak Packaging Solutions SpA',
    countrycode: 'IT',
    countryname: 'Italy',
    groupcode: 'TETRA',
  },
  {
    id: '32',
    companynumber: '20110',
    companyname: 'Tetra Pak Hoyer Spa',
    countrycode: 'IT',
    countryname: 'Italy',
    groupcode: 'TETRA',
  },
  {
    id: '48',
    companynumber: '20525',
    companyname: 'Tetra Pak Italiana S.p.A',
    countrycode: 'IT',
    countryname: 'Italy',
    groupcode: 'TETRA',
  },
  {
    id: '61',
    companynumber: '21234',
    companyname: 'Tetra Pak Carta S.p.A.',
    countrycode: 'IT',
    countryname: 'Italy',
    groupcode: 'TETRA',
  },
  {
    id: '70',
    companynumber: '21647',
    companyname: 'Tetra Pak Plastics Italia Srl',
    countrycode: 'IT',
    countryname: 'Italy',
    groupcode: 'TETRA',
  },
  {
    id: '92',
    companynumber: '23899',
    companyname: 'Tetra Pak Carta, Viale della Resistenza 56/a',
    countrycode: 'IT',
    countryname: 'Italy',
    groupcode: 'TETRA',
  },
  {
    id: '106',
    companynumber: '25661',
    companyname: 'Sidel S.p.A.',
    countrycode: 'IT',
    countryname: 'Italy',
    groupcode: 'TETRA',
  },
  {
    id: '182',
    companynumber: '44816',
    companyname: 'Sidel International Srl',
    countrycode: 'IT',
    countryname: 'Italy',
    groupcode: 'TETRA',
  },
  {
    id: '196',
    companynumber: '50477',
    companyname: 'Sidel End of Line & Tunnels Solutions S.r.l',
    countrycode: 'IT',
    countryname: 'Italy',
    groupcode: 'TETRA',
  },
  {
    id: '198',
    companynumber: '50618',
    companyname: 'Tetra Pak Food Engineering S.p.A ',
    countrycode: 'IT',
    countryname: 'Italy',
    groupcode: 'TETRA',
  },
  {
    id: '11198',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'IT',
    countryname: 'Italy',
    groupcode: 'TETRA',
  },
  {
    id: '111198',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'IT',
    countryname: 'Italy',
    groupcode: 'TETRA',
  },
  {
    id: '5',
    companynumber: '19274',
    companyname: 'Nihon Tetra Pak K.K. (Tokyo Head Office)',
    countrycode: 'JP',
    countryname: 'Japan',
    groupcode: 'TETRA',
  },
  {
    id: '72',
    companynumber: '21743',
    companyname: 'Gotemba Tetra Pak L.L.C',
    countrycode: 'JP',
    countryname: 'Japan',
    groupcode: 'TETRA',
  },
  {
    id: '113',
    companynumber: '26580',
    companyname: 'Sidel Japan K.K.',
    countrycode: 'JP',
    countryname: 'Japan',
    groupcode: 'TETRA',
  },
  {
    id: '11113',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'JP',
    countryname: 'Japan',
    groupcode: 'TETRA',
  },
  {
    id: '111113',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'JP',
    countryname: 'Japan',
    groupcode: 'TETRA',
  },
  {
    id: '137',
    companynumber: '30399',
    companyname: 'Tetra Pak Jordan LLC',
    countrycode: 'JO',
    countryname: 'Jordan',
    groupcode: 'TETRA',
  },
  {
    id: '135',
    companynumber: '30200',
    companyname: 'Tetra Pak Kazakhstan Ltd',
    countrycode: 'KZ',
    countryname: 'Kazakhstan',
    groupcode: 'TETRA',
  },
  {
    id: '11135',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'KZ',
    countryname: 'Kazakhstan',
    groupcode: 'TETRA',
  },
  {
    id: '111135',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'KZ',
    countryname: 'Kazakhstan',
    groupcode: 'TETRA',
  },
  {
    id: '83',
    companynumber: '22478',
    companyname: 'Tetra Pak Limited',
    countrycode: 'KE',
    countryname: 'Kenya',
    groupcode: 'TETRA',
  },
  {
    id: '200',
    companynumber: '51178',
    companyname: 'Tetra Pak (Kenya) Ltd.',
    countrycode: 'KE',
    countryname: 'Kenya',
    groupcode: 'TETRA',
  },
  {
    id: '11200',
    companynumber: '56239',
    companyname: 'Sidel Services East Africa Ltd',
    countrycode: 'KE',
    countryname: 'Kenya',
    groupcode: 'TETRA',
  },
  {
    id: '23',
    companynumber: '19660',
    companyname: 'Tetra Pak LTD',
    countrycode: 'KR',
    countryname: 'Korea, Republic of',
    groupcode: 'TETRA',
  },
  {
    id: '84',
    companynumber: '22479',
    companyname: 'Tetra Pak YOJU Ltd',
    countrycode: 'KR',
    countryname: 'Korea, Republic of',
    groupcode: 'TETRA',
  },
  {
    id: '1184',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'KR',
    countryname: 'Korea, Republic of',
    groupcode: 'TETRA',
  },
  {
    id: '11184',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'KR',
    countryname: 'Korea, Republic of',
    groupcode: 'TETRA',
  },
  {
    id: '147',
    companynumber: '35130',
    companyname: 'Tetra Pak Kuwait ',
    countrycode: 'KW',
    countryname: 'Kuwait',
    groupcode: 'TETRA',
  },
  {
    id: '11147',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'LV',
    countryname: 'Latvia',
    groupcode: 'TETRA',
  },
  {
    id: '111147',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'LV',
    countryname: 'Latvia',
    groupcode: 'TETRA',
  },
  {
    id: '8',
    companynumber: '19394',
    companyname: 'Tetra Pak Lebanon SARL',
    countrycode: 'LB',
    countryname: 'Lebanon',
    groupcode: 'TETRA',
  },
  {
    id: '151',
    companynumber: '35729',
    companyname: 'Tetra Pak Lebanon SARL (35729)',
    countrycode: 'LB',
    countryname: 'Lebanon',
    groupcode: 'TETRA',
  },
  {
    id: '11151',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'LT',
    countryname: 'Lithuania',
    groupcode: 'TETRA',
  },
  {
    id: '111151',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'LT',
    countryname: 'Lithuania',
    groupcode: 'TETRA',
  },
  {
    id: '22',
    companynumber: '19658',
    companyname: 'Tetra Pak Malaysia SDN BHD',
    countrycode: 'MY',
    countryname: 'Malaysia',
    groupcode: 'TETRA',
  },
  {
    id: '103',
    companynumber: '25221',
    companyname: 'Sidel Distribution (Malaysia) SDN. BHD.',
    countrycode: 'MY',
    countryname: 'Malaysia',
    groupcode: 'TETRA',
  },
  {
    id: '66',
    companynumber: '21484',
    companyname: 'Tetra Pak Plastics Mexico SA de CV',
    countrycode: 'MX',
    countryname: 'Mexico',
    groupcode: 'TETRA',
  },
  {
    id: '76',
    companynumber: '22081',
    companyname: 'Tetra Pak S.A. de C.V.',
    countrycode: 'MX',
    countryname: 'Mexico',
    groupcode: 'TETRA',
  },
  {
    id: '93',
    companynumber: '23913',
    companyname: 'Tetra Pak S.A. de C.V. (23913)',
    countrycode: 'MX',
    countryname: 'Mexico',
    groupcode: 'TETRA',
  },
  {
    id: '110',
    companynumber: '26157',
    companyname: 'Sidel de Mexico SA de CV',
    countrycode: 'MX',
    countryname: 'Mexico',
    groupcode: 'TETRA',
  },
  {
    id: '118',
    companynumber: '27578',
    companyname: 'Tetra Pak Queretaro S.A. de C.V.',
    countrycode: 'MX',
    countryname: 'Mexico',
    groupcode: 'TETRA',
  },
  {
    id: '199',
    companynumber: '51056',
    companyname: 'GEBO CERMEX S DE RL DE CV',
    countrycode: 'MX',
    countryname: 'Mexico',
    groupcode: 'TETRA',
  },
  {
    id: '11199',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'MX',
    countryname: 'Mexico',
    groupcode: 'TETRA',
  },
  {
    id: '111199',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'MX',
    countryname: 'Mexico',
    groupcode: 'TETRA',
  },
  {
    id: '78',
    companynumber: '22342',
    companyname: 'Tetra Pak Maghreb SARL',
    countrycode: 'MA',
    countryname: 'Morocco',
    groupcode: 'TETRA',
  },
  {
    id: '203',
    companynumber: '52811',
    companyname: 'Tetra Pak Myanmar Limited',
    countrycode: 'MM',
    countryname: 'Myanmar',
    groupcode: 'TETRA',
  },
  {
    id: '40',
    companynumber: '20213',
    companyname: 'Tetra Pak  Moerdijk BV',
    countrycode: 'NL',
    countryname: 'Netherlands',
    groupcode: 'TETRA',
  },
  {
    id: '42',
    companynumber: '22678',
    companyname: 'Tetra Pak North West Europe',
    countrycode: 'NL',
    countryname: 'Netherlands',
    groupcode: 'TETRA',
  },
  {
    id: '97',
    companynumber: '24530',
    companyname: 'Tetra Pak GmbH & Co',
    countrycode: 'NL',
    countryname: 'Netherlands',
    groupcode: 'TETRA',
  },
  {
    id: '121',
    companynumber: '27932',
    companyname: 'Tetra Laval International B.V.',
    countrycode: 'NL',
    countryname: 'Netherlands',
    groupcode: 'TETRA',
  },
  {
    id: '148',
    companynumber: '35325',
    companyname: 'Tetra Pak Cheese and Powder Systems BV',
    countrycode: 'NL',
    countryname: 'Netherlands',
    groupcode: 'TETRA',
  },
  {
    id: '11148',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'NL',
    countryname: 'Netherlands',
    groupcode: 'TETRA',
  },
  {
    id: '111148',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'NL',
    countryname: 'Netherlands',
    groupcode: 'TETRA',
  },
  {
    id: '159',
    companynumber: '37716',
    companyname: 'Tetra Pak (New Zealand) Ltd.',
    countrycode: 'NZ',
    countryname: 'New Zealand',
    groupcode: 'TETRA',
  },
  {
    id: '11159',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'NZ',
    countryname: 'New Zealand',
    groupcode: 'TETRA',
  },
  {
    id: '111159',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'NZ',
    countryname: 'New Zealand',
    groupcode: 'TETRA',
  },
  {
    id: '54',
    companynumber: '20819',
    companyname: 'Tetra Pak West Africa Ltd ',
    countrycode: 'NG',
    countryname: 'Nigeria',
    groupcode: 'TETRA',
  },
  {
    id: '11154',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'NO',
    countryname: 'Norway',
    groupcode: 'TETRA',
  },
  {
    id: '111154',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'NO',
    countryname: 'Norway',
    groupcode: 'TETRA',
  },
  {
    id: '69',
    companynumber: '21631',
    companyname: 'Tetra Pak Pakistan Ltd.',
    countrycode: 'PK',
    countryname: 'Pakistan',
    groupcode: 'TETRA',
  },
  {
    id: '79',
    companynumber: '22375',
    companyname: 'Tetra Pak SA [CENTRAL & CARRIBEAN]',
    countrycode: 'PA',
    countryname: 'Panama',
    groupcode: 'TETRA',
  },
  {
    id: '87',
    companynumber: '23265',
    companyname: 'Sidal Inc',
    countrycode: 'PA',
    countryname: 'Panama',
    groupcode: 'TETRA',
  },
  {
    id: '157',
    companynumber: '37049',
    companyname: 'Sidel Panama Corp.',
    countrycode: 'PA',
    countryname: 'Panama',
    groupcode: 'TETRA',
  },
  {
    id: '191',
    companynumber: '48449',
    companyname: 'Tetra Pak Ltda. Sucursal Paraguay',
    countrycode: 'PY',
    countryname: 'Paraguay',
    groupcode: 'TETRA',
  },
  {
    id: '95',
    companynumber: '24361',
    companyname: 'Tetra Pak S.A. ',
    countrycode: 'PE',
    countryname: 'Peru',
    groupcode: 'TETRA',
  },
  {
    id: '74',
    companynumber: '21794',
    companyname: 'Tetra Pak Philippines Inc',
    countrycode: 'PH',
    countryname: 'Philippines',
    groupcode: 'TETRA',
  },
  {
    id: '142',
    companynumber: '31967',
    companyname: 'Sidel Conveying Philippines',
    countrycode: 'PH',
    countryname: 'Philippines',
    groupcode: 'TETRA',
  },
  {
    id: '11142',
    companynumber: '56860',
    companyname: 'Sidel South Asia-Pacific Ltd. Phil Branch',
    countrycode: 'PH',
    countryname: 'Philippines',
    groupcode: 'TETRA',
  },
  {
    id: '141',
    companynumber: '31452',
    companyname: 'Tetra Pak Sp. z o.o.',
    countrycode: 'PL',
    countryname: 'Poland',
    groupcode: 'TETRA',
  },
  {
    id: '194',
    companynumber: '49631',
    companyname: 'TETRA POLAND Sp. Z o.o.',
    countrycode: 'PL',
    countryname: 'Poland',
    groupcode: 'TETRA',
  },
  {
    id: '11159',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'PL',
    countryname: 'Poland',
    groupcode: 'TETRA',
  },
  {
    id: '111159',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'PL',
    countryname: 'Poland',
    groupcode: 'TETRA',
  },
  {
    id: '55',
    companynumber: '20821',
    companyname: 'Tetra Pak Portugal S A',
    countrycode: 'PT',
    countryname: 'Portugal',
    groupcode: 'TETRA',
  },
  {
    id: '108',
    companynumber: '25993',
    companyname: 'Tetra Pak Tubex Ltda',
    countrycode: 'PT',
    countryname: 'Portugal',
    groupcode: 'TETRA',
  },
  {
    id: '206',
    companynumber: '54203',
    companyname: 'Sidel Conveying Portugal, S.A',
    countrycode: 'PT',
    countryname: 'Portugal',
    groupcode: 'TETRA',
  },
  {
    id: '150',
    companynumber: '35533',
    companyname: 'Tetra Pak Romania SRL',
    countrycode: 'RO',
    countryname: 'Romania',
    groupcode: 'TETRA',
  },
  {
    id: '85',
    companynumber: '23243',
    companyname: 'Tetra Pak Russia ',
    countrycode: 'RU',
    countryname: 'Russian Federation',
    groupcode: 'TETRA',
  },
  {
    id: '156',
    companynumber: '36959',
    companyname: 'Sidel Vostok O.O.O',
    countrycode: 'RU',
    countryname: 'Russian Federation',
    groupcode: 'TETRA',
  },
  {
    id: '11156',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'RU',
    countryname: 'Russian Federation',
    groupcode: 'TETRA',
  },
  {
    id: '111156',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'RU',
    countryname: 'Russian Federation',
    groupcode: 'TETRA',
  },
  {
    id: '18',
    companynumber: '19650',
    companyname: 'Tetra Pak Manufacturing Ltd',
    countrycode: 'SA',
    countryname: 'Saudi Arabia',
    groupcode: 'TETRA',
  },
  {
    id: '53',
    companynumber: '20778',
    companyname: 'Tetra Pak Manufacturing Co. Ltd.',
    countrycode: 'SA',
    countryname: 'Saudi Arabia',
    groupcode: 'TETRA',
  },
  {
    id: '128',
    companynumber: '28883',
    companyname: 'Tetra Pak Production d.o.o. Beograd ',
    countrycode: 'RS',
    countryname: 'Serbia',
    groupcode: 'TETRA',
  },
  {
    id: '19',
    companynumber: '19654',
    companyname: 'Tetra Pak Asia Pte Ltd',
    countrycode: 'SG',
    countryname: 'Singapore',
    groupcode: 'TETRA',
  },
  {
    id: '96',
    companynumber: '24446',
    companyname: 'Tetra Pak Jurong Pte Ltd ',
    countrycode: 'SG',
    countryname: 'Singapore',
    groupcode: 'TETRA',
  },
  {
    id: '136',
    companynumber: '30267',
    companyname: 'Tetra Pak South East Asia Pte Ltd ',
    countrycode: 'SG',
    countryname: 'Singapore',
    groupcode: 'TETRA',
  },
  {
    id: '170',
    companynumber: '40394',
    companyname: 'Guerin Systems Asia Pte Ltd',
    countrycode: 'SG',
    countryname: 'Singapore',
    groupcode: 'TETRA',
  },
  {
    id: '43',
    companynumber: '20367',
    companyname: 'Tetra Pak South Africa Ltd',
    countrycode: 'ZA',
    countryname: 'South Africa',
    groupcode: 'TETRA',
  },
  {
    id: '187',
    companynumber: '45480',
    companyname: 'Tetra Pak South Africa (Pty) Ltd',
    countrycode: 'ZA',
    countryname: 'South Africa',
    groupcode: 'TETRA',
  },
  {
    id: '188',
    companynumber: '45874',
    companyname: 'Sidel Packaging Systems South Africa (Pty) Ltd',
    countrycode: 'ZA',
    countryname: 'South Africa',
    groupcode: 'TETRA',
  },
  {
    id: '11188',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'ZA',
    countryname: 'South Africa',
    groupcode: 'TETRA',
  },
  {
    id: '111188',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'ZA',
    countryname: 'South Africa',
    groupcode: 'TETRA',
  },
  {
    id: '63',
    companynumber: '21348',
    companyname: 'Tetra Pak Hispania S.A.',
    countrycode: 'ES',
    countryname: 'Spain',
    groupcode: 'TETRA',
  },
  {
    id: '123',
    companynumber: '28058',
    companyname: 'Sidel Iberica SLU',
    countrycode: 'ES',
    countryname: 'Spain',
    groupcode: 'TETRA',
  },
  {
    id: '11123',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'ES',
    countryname: 'Spain',
    groupcode: 'TETRA',
  },
  {
    id: '111123',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'ES',
    countryname: 'Spain',
    groupcode: 'TETRA',
  },
  {
    id: '4',
    companynumber: '19273',
    companyname: 'Tetra Laval Group Transport & Travel ',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '6',
    companynumber: '19275',
    companyname: 'Tetra Pak Technical Service AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '7',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '9',
    companynumber: '19421',
    companyname: 'Tetra Pak Information Management AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '11',
    companynumber: '19448',
    companyname: 'AB Tetra Pak',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '12',
    companynumber: '19559',
    companyname: 'Tetra Pak Processing Solutions AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '14',
    companynumber: '19570',
    companyname: 'Tetra Pak Packaging Solutions AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '29',
    companynumber: '19864',
    companyname: 'Tetra Pak Sverige AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '31',
    companynumber: '20098',
    companyname: 'Tetra Pak Packaging Material Sunne AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '34',
    companynumber: '20156',
    companyname: 'Tetra Pak Inventing AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '44',
    companynumber: '20379',
    companyname: 'Tetra Pak Dairy & Beverage Systems AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '46',
    companynumber: '20513',
    companyname: 'Tetra Recart AB ',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '52',
    companynumber: '20775',
    companyname: 'Tetra Pak Packaging Material Lund AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '82',
    companynumber: '22468',
    companyname: 'Tetra Pak Processing Components AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '114',
    companynumber: '26684',
    companyname: 'Tetra Pak Norge AS',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '117',
    companynumber: '27562',
    companyname: 'Tetra Pak Inventing A/S',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '143',
    companynumber: '32806',
    companyname: 'Tetra Pak',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '145',
    companynumber: '33199',
    companyname: 'Tetra Laval AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '153',
    companynumber: '35900',
    companyname: 'Tetra Pak Production Oy',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '164',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '168',
    companynumber: '40221',
    companyname: 'Tetra Pak Denmark A/S',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '177',
    companynumber: '42347',
    companyname: 'Tetra Laval Credit AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '201',
    companynumber: '51240',
    companyname: 'Tetra Pak Processing Equipment AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '202',
    companynumber: '51520',
    companyname: 'Tetra Pak Processing Systems AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '11202',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '111202',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'SE',
    countryname: 'Sweden',
    groupcode: 'TETRA',
  },
  {
    id: '3',
    companynumber: '22800',
    companyname: 'Tetra Laval Finance & Treasury  BV (CH BR)',
    countrycode: 'CH',
    countryname: 'Switzerland',
    groupcode: 'TETRA',
  },
  {
    id: '10',
    companynumber: '19447',
    companyname: 'Tetra Laval International  SA',
    countrycode: 'CH',
    countryname: 'Switzerland',
    groupcode: 'TETRA',
  },
  {
    id: '15',
    companynumber: '19571',
    companyname: 'Tetra Pak International SA',
    countrycode: 'CH',
    countryname: 'Switzerland',
    groupcode: 'TETRA',
  },
  {
    id: '24',
    companynumber: '19661',
    companyname: 'Tetra Pak Global Distribution SA',
    countrycode: 'CH',
    countryname: 'Switzerland',
    groupcode: 'TETRA',
  },
  {
    id: '68',
    companynumber: '23351',
    companyname: 'Tetra Pak Global Ressources SA',
    countrycode: 'CH',
    countryname: 'Switzerland',
    groupcode: 'TETRA',
  },
  {
    id: '99',
    companynumber: '24652',
    companyname: 'Tetra Pak (Suisse) SA',
    countrycode: 'CH',
    countryname: 'Switzerland',
    groupcode: 'TETRA',
  },
  {
    id: '163',
    companynumber: '38397',
    companyname: 'Tetral SA',
    countrycode: 'CH',
    countryname: 'Switzerland',
    groupcode: 'TETRA',
  },
  {
    id: '165',
    companynumber: '39430',
    companyname: 'Tetra Pak Global Supply SA',
    countrycode: 'CH',
    countryname: 'Switzerland',
    groupcode: 'TETRA',
  },
  {
    id: '171',
    companynumber: '40596',
    companyname: 'Miteco AG',
    countrycode: 'CH',
    countryname: 'Switzerland',
    groupcode: 'TETRA',
  },
  {
    id: '11171',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'CH',
    countryname: 'Switzerland',
    groupcode: 'TETRA',
  },
  {
    id: '111171',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'CH',
    countryname: 'Switzerland',
    groupcode: 'TETRA',
  },
  {
    id: '25',
    companynumber: '19663',
    companyname: 'Tetra Pak Taiwan Ltd',
    countrycode: 'TW',
    countryname: 'Taiwan, Province of China',
    groupcode: 'TETRA',
  },
  {
    id: '111',
    companynumber: '26294',
    companyname: 'Sidel South Asia-Pacific Ltd.',
    countrycode: 'TH',
    countryname: 'Thailand',
    groupcode: 'TETRA',
  },
  {
    id: '122',
    companynumber: '28021',
    companyname: 'Tetra Pak Services (Thai) Ltd. // anv 29314',
    countrycode: 'TH',
    countryname: 'Thailand',
    groupcode: 'TETRA',
  },
  {
    id: '130',
    companynumber: '29314',
    companyname: 'Tetra Pak (Thailand) Ltd.',
    countrycode: 'TH',
    countryname: 'Thailand',
    groupcode: 'TETRA',
  },
  {
    id: '174',
    companynumber: '42028',
    companyname: 'Sidel End of Line Solutions (Thailand) Ltd.',
    countrycode: 'TH',
    countryname: 'Thailand',
    groupcode: 'TETRA',
  },
  {
    id: '11174',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'TH',
    countryname: 'Thailand',
    groupcode: 'TETRA',
  },
  {
    id: '111174',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'TH',
    countryname: 'Thailand',
    groupcode: 'TETRA',
  },
  {
    id: '155',
    companynumber: '36577',
    companyname: 'Immeuble Henda',
    countrycode: 'TN',
    countryname: 'Tunisia',
    groupcode: 'TETRA',
  },
  {
    id: '50',
    companynumber: '20565',
    companyname: 'Tetra Pak Paketleme Sanayi ve Ticaret Ltd. ?ti. ',
    countrycode: 'TR',
    countryname: 'Turkey',
    groupcode: 'TETRA',
  },
  {
    id: '1150',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'TR',
    countryname: 'Turkey',
    groupcode: 'TETRA',
  },
  {
    id: '11150',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'TR',
    countryname: 'Turkey',
    groupcode: 'TETRA',
  },
  {
    id: '129',
    companynumber: '29210',
    companyname: 'Tetra Pak Ukraine ',
    countrycode: 'UA',
    countryname: 'Ukraine',
    groupcode: 'TETRA',
  },
  {
    id: '11129',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'UA',
    countryname: 'Ukraine',
    groupcode: 'TETRA',
  },
  {
    id: '111129',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'UA',
    countryname: 'Ukraine',
    groupcode: 'TETRA',
  },
  {
    id: '133',
    companynumber: '29468',
    companyname: 'Tetra Pak Export FZE',
    countrycode: 'AE',
    countryname: 'united Arab Emirates',
    groupcode: 'TETRA',
  },
  {
    id: '162',
    companynumber: '38001',
    companyname: 'Sidel (Dubai) Greater Middle East & Africa FZ LLC',
    countrycode: 'AE',
    countryname: 'united Arab Emirates',
    groupcode: 'TETRA',
  },
  {
    id: '166',
    companynumber: '39591',
    companyname: 'Sidel GMEA FZ LLC',
    countrycode: 'AE',
    countryname: 'united Arab Emirates',
    groupcode: 'TETRA',
  },
  {
    id: '181',
    companynumber: '43849',
    companyname: 'Tetra Pak Technical Service ME',
    countrycode: 'AE',
    countryname: 'united Arab Emirates',
    groupcode: 'TETRA',
  },
  {
    id: '27',
    companynumber: '19743',
    companyname: 'Tetra Pak Business Services Ltd',
    countrycode: 'GB',
    countryname: 'United Kingdom',
    groupcode: 'TETRA',
  },
  {
    id: '38',
    companynumber: '20207',
    companyname: 'Tetra Pak Ltd',
    countrycode: 'GB',
    countryname: 'United Kingdom',
    groupcode: 'TETRA',
  },
  {
    id: '45',
    companynumber: '20433',
    companyname: 'Tetra Pak Processing Systems UK Ltd',
    countrycode: 'GB',
    countryname: 'United Kingdom',
    groupcode: 'TETRA',
  },
  {
    id: '47',
    companynumber: '20519',
    companyname: 'Tetra Pak Manufacturing UK',
    countrycode: 'GB',
    countryname: 'United Kingdom',
    groupcode: 'TETRA',
  },
  {
    id: '144',
    companynumber: '32922',
    companyname: 'Sidel UK Ltd',
    countrycode: 'GB',
    countryname: 'United Kingdom',
    groupcode: 'TETRA',
  },
  {
    id: '190',
    companynumber: '47871',
    companyname: 'Tetra Pak CPS',
    countrycode: 'GB',
    countryname: 'United Kingdom',
    groupcode: 'TETRA',
  },
  {
    id: '204',
    companynumber: '52906',
    companyname: 'Tetra Pak CPS Ltd.',
    countrycode: 'GB',
    countryname: 'United Kingdom',
    groupcode: 'TETRA',
  },
  {
    id: '11204',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'GB',
    countryname: 'United Kingdom',
    groupcode: 'TETRA',
  },
  {
    id: '111204',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'GB',
    countryname: 'United Kingdom',
    groupcode: 'TETRA',
  },
  {
    id: '17',
    companynumber: '19631',
    companyname: 'Tetra Pak Materials',
    countrycode: 'US',
    countryname: 'United States',
    groupcode: 'TETRA',
  },
  {
    id: '21',
    companynumber: '19657',
    companyname: 'Tetra Pak Processing Equipment Inc.',
    countrycode: 'US',
    countryname: 'United States',
    groupcode: 'TETRA',
  },
  {
    id: '26',
    companynumber: '19664',
    companyname: 'Tetra Pak Inc.',
    countrycode: 'US',
    countryname: 'United States',
    groupcode: 'TETRA',
  },
  {
    id: '36',
    companynumber: '20189',
    companyname: 'Tetra Pak Global Information Management Americas Inc',
    countrycode: 'US',
    countryname: 'United States',
    groupcode: 'TETRA',
  },
  {
    id: '49',
    companynumber: '20551',
    companyname: 'Tetra Pak Technical Service',
    countrycode: 'US',
    countryname: 'United States',
    groupcode: 'TETRA',
  },
  {
    id: '60',
    companynumber: '21224',
    companyname: 'Tetra Pak Gable Top Systems Inc.',
    countrycode: 'US',
    countryname: 'United States',
    groupcode: 'TETRA',
  },
  {
    id: '100',
    companynumber: '24721',
    companyname: 'Tetra Pak Inc Materials Sikeston ',
    countrycode: 'US',
    countryname: 'United States',
    groupcode: 'TETRA',
  },
  {
    id: '101',
    companynumber: '24946',
    companyname: 'Sidel Inc Atlanta ',
    countrycode: 'US',
    countryname: 'United States',
    groupcode: 'TETRA',
  },
  {
    id: '104',
    companynumber: '25354',
    companyname: 'Tetra Pak Tubex',
    countrycode: 'US',
    countryname: 'United States',
    groupcode: 'TETRA',
  },
  {
    id: '126',
    companynumber: '28647',
    companyname: 'Tetra Pak Processing Equipment Inc.',
    countrycode: 'US',
    countryname: 'United States',
    groupcode: 'TETRA',
  },
  {
    id: '178',
    companynumber: '42635',
    companyname: 'Tetra Pak Inc.',
    countrycode: 'US',
    countryname: 'United States',
    groupcode: 'TETRA',
  },
  {
    id: '186',
    companynumber: '45160',
    companyname: 'Tetra Pak Materials LLC',
    countrycode: 'US',
    countryname: 'United States',
    groupcode: 'TETRA',
  },
  {
    id: '11186',
    companynumber: '19304',
    companyname: 'DeLaval International AB',
    countrycode: 'US',
    countryname: 'United States',
    groupcode: 'TETRA',
  },
  {
    id: '111186',
    companynumber: '38692',
    companyname: 'DeLaval Sales AB',
    countrycode: 'US',
    countryname: 'United States',
    groupcode: 'TETRA',
  },
  {
    id: '64',
    companynumber: '21357',
    companyname: 'Tetra Pak C.A',
    countrycode: 'VE',
    countryname: 'Venezuela',
    groupcode: 'TETRA',
  },
  {
    id: '37',
    companynumber: '20196',
    companyname: 'Tetra Pak Vietnam Joint Stock Company',
    countrycode: 'VN',
    countryname: 'Viet Nam',
    groupcode: 'TETRA',
  },
  {
    id: '189',
    companynumber: '47629',
    companyname: 'Tetra Pak Binh Duong Joint Stock Company',
    countrycode: 'VN',
    countryname: 'Viet Nam',
    groupcode: 'TETRA',
  },
  {
    id: '11189',
    companynumber: '57592',
    companyname: 'Sidel South Asia-Pacific Ltd',
    countrycode: 'VN',
    countryname: 'Viet Nam',
    groupcode: 'TETRA',
  },
  {
    id: '86',
    companynumber: '23246',
    companyname: 'Tetra Pak Yemen',
    countrycode: 'YE',
    countryname: 'Yemen',
    groupcode: 'TETRA',
  },
  {
    id: '27',
    companyname: 'Competek SAS',
    companynumber: '58643',
    countrycode: 'FR',
    groupcode: 'TETRA',
    countryname: 'France',
  },
  {
    id: '28',
    companyname: 'Tetra Pak GmbH Austria',
    companynumber: '58665',
    countrycode: 'AT',
    groupcode: 'TETRA',
    countryname: 'Austria',
  },
  {
    id: '29',
    companyname: 'Tetra Pak Closures Spain, S.L.U',
    companynumber: '58669',
    countrycode: 'ES',
    groupcode: 'TETRA',
    countryname: 'Spain',
  },
  {
    id: '30',
    companyname: 'Novembal USA Inc.',
    companynumber: '59264',
    countrycode: 'US',
    groupcode: 'TETRA',
    countryname: 'United States',
  },
  {
    id: '43',
    companyname: 'Alfa Laval Australia Pty Ltd',
    companynumber: '36976',
    countrycode: 'AU',
    groupcode: 'LAVALQ',
    countryname: 'Australia',
  },
  {
    id: '45',
    companyname: 'Alfa Laval Aalborg Pty. Ltd.',
    companynumber: '38214',
    countrycode: 'AU',
    groupcode: 'LAVALQ',
    countryname: 'Australia',
  },
  {
    id: '22',
    companyname: 'Alfa Laval Mid Europe GmbH',
    companynumber: '30805',
    countrycode: 'AT',
    groupcode: 'LAVALQ',
    countryname: 'Austria',
  },
  {
    id: '48',
    companyname: 'Alfa Laval Inc',
    companynumber: '40012',
    countrycode: 'CA',
    groupcode: 'LAVALQ',
    countryname: 'Canada',
  },
  {
    id: '12',
    companyname: 'Alfa Laval Spa (Santigo)',
    companynumber: '24375',
    countrycode: 'CL',
    groupcode: 'LAVALQ',
    countryname: 'Chile',
  },
  {
    id: '7',
    companyname: 'Alfa Laval Shanghai Technologies Co Ltd',
    companynumber: '21325',
    countrycode: 'CN',
    groupcode: 'LAVALQ',
    countryname: 'China',
  },
  {
    id: '18',
    companyname: 'Regional Human Resource Manager &',
    companynumber: '29396',
    countrycode: 'CN',
    groupcode: 'LAVALQ',
    countryname: 'China',
  },
  {
    id: '20',
    companyname: 'Tranter Heat Exchangers (Beijing) Co Ltd',
    companynumber: '30588',
    countrycode: 'CN',
    groupcode: 'LAVALQ',
    countryname: 'China',
  },
  {
    id: '31',
    companyname: 'Alfa Laval Flow Equipment(Kunshan) Co Ltd',
    companynumber: '33855',
    countrycode: 'CN',
    groupcode: 'LAVALQ',
    countryname: 'China',
  },
  {
    id: '37',
    companyname: 'Alfa Laval (Jiangyin) Manufacturing Co. Ltd',
    companynumber: '35116',
    countrycode: 'CN',
    groupcode: 'LAVALQ',
    countryname: 'China',
  },
  {
    id: '40',
    companyname: 'Alfa Laval Qingdao Ltd',
    companynumber: '35438',
    countrycode: 'CN',
    groupcode: 'LAVALQ',
    countryname: 'China',
  },
  {
    id: '41',
    companyname: 'Alfa Laval Packinox',
    companynumber: '35599',
    countrycode: 'CN',
    groupcode: 'LAVALQ',
    countryname: 'China',
  },
  {
    id: '42',
    companyname: 'Alfa Laval (Kunshan) Manufacturing Co., Ltd ',
    companynumber: '35796',
    countrycode: 'CN',
    groupcode: 'LAVALQ',
    countryname: 'China',
  },
  {
    id: '51',
    companyname: 'Alfa Laval Aalborg Quingdao',
    companynumber: '42235',
    countrycode: 'CN',
    groupcode: 'LAVALQ',
    countryname: 'China',
  },
  {
    id: '27',
    companyname: 'Alfa Laval S A',
    companynumber: '31850',
    countrycode: 'CO',
    groupcode: 'LAVALQ',
    countryname: 'Colombia',
  },
  {
    id: '5',
    companyname: 'Alfa Laval Copenhagen A/S',
    companynumber: '19589',
    countrycode: 'DK',
    groupcode: 'LAVALQ',
    countryname: 'Denmark',
  },
  {
    id: '44',
    companyname: 'Alfa Laval Aalborg Marine Boiler & Heater',
    companynumber: '37148',
    countrycode: 'DK',
    groupcode: 'LAVALQ',
    countryname: 'Denmark',
  },
  {
    id: '59',
    companyname:
      'Alfa Laval Aalborg A/S, Marine & Diesel Systems, Parts & Service',
    companynumber: '50181',
    countrycode: 'DK',
    groupcode: 'LAVALQ',
    countryname: 'Denmark',
  },
  {
    id: '30',
    companyname: 'Alfa Laval Vantaa Oy',
    companynumber: '33251',
    countrycode: 'FI',
    groupcode: 'LAVALQ',
    countryname: 'Finland',
  },
  {
    id: '14',
    companyname: 'Alfa Laval Packinox',
    companynumber: '25494',
    countrycode: 'FR',
    groupcode: 'LAVALQ',
    countryname: 'France',
  },
  {
    id: '24',
    companyname: 'Alfa Laval sas',
    companynumber: '31393',
    countrycode: 'FR',
    groupcode: 'LAVALQ',
    countryname: 'France',
  },
  {
    id: '32',
    companyname: 'Alfa Laval Vicarb sas',
    companynumber: '33921',
    countrycode: 'FR',
    groupcode: 'LAVALQ',
    countryname: 'France',
  },
  {
    id: '33',
    companyname: 'Alfa Laval Spiral sas ',
    companynumber: '34006',
    countrycode: 'FR',
    groupcode: 'LAVALQ',
    countryname: 'France',
  },
  {
    id: '38',
    companyname: 'Alfa Laval HES sas',
    companynumber: '35283',
    countrycode: 'FR',
    groupcode: 'LAVALQ',
    countryname: 'France',
  },
  {
    id: '39',
    companyname: 'Alfa Laval Moatti sas',
    companynumber: '35288',
    countrycode: 'FR',
    groupcode: 'LAVALQ',
    countryname: 'France',
  },
  {
    id: '29',
    companyname: 'Alfa Laval Holding GmbH',
    companynumber: '33222',
    countrycode: 'DE',
    groupcode: 'LAVALQ',
    countryname: 'Germany',
  },
  {
    id: '36',
    companyname: 'Alfa Laval Mid Europe GmbH',
    companynumber: '34782',
    countrycode: 'DE',
    groupcode: 'LAVALQ',
    countryname: 'Germany',
  },
  {
    id: '13',
    companyname: 'Alfa Laval (China) Ltd.(C32301)',
    companynumber: '25105',
    countrycode: 'HK',
    groupcode: 'LAVALQ',
    countryname: 'Hong Kong',
  },
  {
    id: '11',
    companyname: 'Alfa Laval (India) Limited',
    companynumber: '23322',
    countrycode: 'IN',
    groupcode: 'LAVALQ',
    countryname: 'India',
  },
  {
    id: '9',
    companyname: 'Alfa Laval SpA (Monza)',
    companynumber: '22352',
    countrycode: 'IT',
    groupcode: 'LAVALQ',
    countryname: 'Italy',
  },
  {
    id: '28',
    companyname: 'Alfa Laval SpA (Parma)',
    companynumber: '31925',
    countrycode: 'IT',
    groupcode: 'LAVALQ',
    countryname: 'Italy',
  },
  {
    id: '55',
    companyname: 'Alfa Laval Olmi Spa',
    companynumber: '48562',
    countrycode: 'IT',
    groupcode: 'LAVALQ',
    countryname: 'Italy',
  },
  {
    id: '61',
    companyname: 'Soteco International Srl',
    companynumber: '50939',
    countrycode: 'IT',
    groupcode: 'LAVALQ',
    countryname: 'Italy',
  },
  {
    id: '4',
    companyname: 'Alfa Laval K.K',
    companynumber: '19289',
    countrycode: 'JP',
    groupcode: 'LAVALQ',
    countryname: 'Japan',
  },
  {
    id: '35',
    companyname: 'Alfa Laval Korea Ltd',
    companynumber: '34474',
    countrycode: 'KR',
    groupcode: 'LAVALQ',
    countryname: 'undefined',
  },
  {
    id: '58',
    companyname: 'Alfa Laval Malaysia Sdn Bhd',
    companynumber: '50137',
    countrycode: 'MY',
    groupcode: 'LAVALQ',
    countryname: 'Malaysia',
  },
  {
    id: '49',
    companyname: 'Alfa Laval S.A DE C.V',
    companynumber: '40205',
    countrycode: 'MX',
    groupcode: 'LAVALQ',
    countryname: 'Mexico',
  },
  {
    id: '56',
    companyname: 'Alfa Laval Nijmegen BV',
    companynumber: '48883',
    countrycode: 'NL',
    groupcode: 'LAVALQ',
    countryname: 'Netherlands (Holland, Europe)',
  },
  {
    id: '53',
    companyname: 'Framo AS',
    companynumber: '46997',
    countrycode: 'NO',
    groupcode: 'LAVALQ',
    countryname: 'Norway',
  },
  {
    id: '57',
    companyname: 'Alfa Laval Krakow Sp.z o.o.',
    companynumber: '49705',
    countrycode: 'PL',
    groupcode: 'LAVALQ',
    countryname: 'Poland',
  },
  {
    id: '62',
    companyname: 'Alfa Laval Polska Sp. z o.o.',
    companynumber: '51633',
    countrycode: 'PL',
    groupcode: 'LAVALQ',
    countryname: 'Poland',
  },
  {
    id: '50',
    companyname: 'AO Alfa Laval Potok',
    companynumber: '42022',
    countrycode: 'RU',
    groupcode: 'LAVALQ',
    countryname: 'Russia',
  },
  {
    id: '34',
    companyname: 'Alfa Laval Middle East Ltd',
    companynumber: '34375',
    countrycode: 'SA',
    groupcode: 'LAVALQ',
    countryname: 'Saudi Arabia',
  },
  {
    id: '8',
    companyname: 'Alfa Laval Singapore Pte Ltd',
    companynumber: '21535',
    countrycode: 'SG',
    groupcode: 'LAVALQ',
    countryname: 'Singapore',
  },
  {
    id: '17',
    companyname: 'Alfa Laval (Pty) Ltd ',
    companynumber: '29224',
    countrycode: 'ZA',
    groupcode: 'LAVALQ',
    countryname: 'South Africa',
  },
  {
    id: '1',
    companyname: 'Alfa Laval Nordic AB (Tumba)',
    companynumber: '22985',
    countrycode: 'SE',
    groupcode: 'LAVALQ',
    countryname: 'Sweden',
  },
  {
    id: '2',
    companyname: 'Alfa Laval Corporate AB (Lund)',
    companynumber: '16373',
    countrycode: 'SE',
    groupcode: 'LAVALQ',
    countryname: 'Sweden',
  },
  {
    id: '3',
    companyname: 'Alfa Laval Lund AB',
    companynumber: '18687',
    countrycode: 'SE',
    groupcode: 'LAVALQ',
    countryname: 'Sweden',
  },
  {
    id: '19',
    companyname: 'Alfa Laval Tumba AB',
    companynumber: '30430',
    countrycode: 'SE',
    groupcode: 'LAVALQ',
    countryname: 'Sweden',
  },
  {
    id: '52',
    companyname: 'Alfdex AB',
    companynumber: '42300',
    countrycode: 'SE',
    groupcode: 'LAVALQ',
    countryname: 'Sweden',
  },
  {
    id: '54',
    companyname: 'AlfaWall AB',
    companynumber: '47038',
    countrycode: 'SE',
    groupcode: 'LAVALQ',
    countryname: 'Sweden',
  },
  {
    id: '16',
    companyname: 'Alfa Laval Taiwan Ltd.',
    companynumber: '29068',
    countrycode: 'TW',
    groupcode: 'LAVALQ',
    countryname: 'Taiwan',
  },
  {
    id: '6',
    companyname: 'Alfa Laval (Thailand) Ltd',
    companynumber: '20212',
    countrycode: 'TH',
    groupcode: 'LAVALQ',
    countryname: 'Thailand',
  },
  {
    id: '10',
    companyname: 'Alfa Laval Middle East Ltd',
    companynumber: '22458',
    countrycode: 'AE',
    groupcode: 'LAVALQ',
    countryname: 'United Arab Emirates',
  },
  {
    id: '21',
    companyname: 'Alfa Laval Ltd',
    companynumber: '30804',
    countrycode: 'GB',
    groupcode: 'LAVALQ',
    countryname: 'United Kingdom',
  },
  {
    id: '15',
    companyname: 'Alfa Laval Inc. (Richmond, VA)',
    companynumber: '26454',
    countrycode: 'US',
    groupcode: 'LAVALQ',
    countryname: 'United States of America',
  },
  {
    id: '25',
    companyname: 'Tranter, Inc. (Wichita Falls)',
    companynumber: '31485',
    countrycode: 'US',
    groupcode: 'LAVALQ',
    countryname: 'United States of America',
  },
  {
    id: '46',
    companyname: 'Tranter Inc. (Texas)',
    companynumber: '38406',
    countrycode: 'US',
    groupcode: 'LAVALQ',
    countryname: 'United States of America',
  },
  {
    id: '47',
    companyname: 'Alfa Laval Inc (Wood Dale, IL)',
    companynumber: '38734',
    countrycode: 'US',
    groupcode: 'LAVALQ',
    countryname: 'United States of America',
  },
  {
    id: '60',
    companyname: 'Alfa Laval Inc (Kenosha, WI)',
    companynumber: '50934',
    countrycode: 'US',
    groupcode: 'LAVALQ',
    countryname: 'United States of America',
  },
  {
    id: '26',
    companyname: 'Alfa Laval Representative Office',
    companynumber: '31664',
    countrycode: 'VN',
    groupcode: 'LAVALQ',
    countryname: 'Vietnam',
  },
  /* Ericsson */
  {
    id: '38',
    companyname: 'Compania Ericsson SACI',
    companynumber: '29933',
    countryname: 'Argentina',
    countrycode: 'AR',
    groupcode: 'ERICS',
  },
  {
    id: '39',
    companyname: 'Ericsson Australia Pty Ltd (anv 42616)',
    companynumber: '30057',
    countryname: 'Australia',
    countrycode: 'AU',
    groupcode: 'ERICS',
  },
  {
    id: '88',
    companyname: 'Ericsson Australia Pty Ltd (EPA)',
    companynumber: '42616',
    countryname: 'Australia',
    countrycode: 'AU',
    groupcode: 'ERICS',
  },
  {
    id: '93',
    companyname: 'Ericsson Austria GmbH',
    companynumber: '43546',
    countryname: 'Austria',
    countrycode: 'AT',
    groupcode: 'ERICS',
  },
  {
    id: '115',
    companyname: 'Ericsson AB Branch Office Azerbaijan',
    companynumber: '48943',
    countryname: 'Azerbaijan',
    countrycode: 'AZ',
    groupcode: 'ERICS',
  },
  {
    id: '99',
    companyname: 'Ericsson AB, Bahrain Branch (BBH)',
    companynumber: '44443',
    countryname: 'Bahrain',
    countrycode: 'BH',
    groupcode: 'ERICS',
  },
  {
    id: '36',
    companyname: 'LM Ericsson Bangladesh Ltd',
    companynumber: '29743',
    countryname: 'Bangladesh',
    countrycode: 'BD',
    groupcode: 'ERICS',
  },
  {
    id: '40',
    companyname: 'Ericsson SA/NV (30167)',
    companynumber: '30167',
    countryname: 'Belgium',
    countrycode: 'BE',
    groupcode: 'ERICS',
  },
  {
    id: '41',
    companyname: 'Ericsson NV',
    companynumber: '30404',
    countryname: 'Belgium',
    countrycode: 'BE',
    groupcode: 'ERICS',
  },
  {
    id: '47',
    companyname: 'Ericsson SA/NV (30903)',
    companynumber: '30903',
    countryname: 'Belgium',
    countrycode: 'BE',
    groupcode: 'ERICS',
  },
  {
    id: '97',
    companyname: 'Ericsson AB Benin',
    companynumber: '44277',
    countryname: 'Benin',
    countrycode: 'BJ',
    groupcode: 'ERICS',
  },
  {
    id: '76',
    companyname: 'ERICSSON BOTSWANA (PTY) LTD.',
    companynumber: '39842',
    countryname: 'Botswana',
    countrycode: 'BW',
    groupcode: 'ERICS',
  },
  {
    id: '43',
    companyname: 'Ericsson Telecomunicacoes SA',
    companynumber: '30619',
    countryname: 'Brazil',
    countrycode: 'BR',
    groupcode: 'ERICS',
  },
  {
    id: '109',
    companyname: 'Ericsson Gestao E Servicos de Telecomunicacoes SA',
    companynumber: '46593',
    countryname: 'Brazil',
    countrycode: 'BR',
    groupcode: 'ERICS',
  },
  {
    id: '116',
    companyname: 'ERICSSON TELECOMMUNICATIONS BULGARIA EOOD (ETB)',
    companynumber: '48944',
    countryname: 'Bulgaria',
    countrycode: 'BG',
    groupcode: 'ERICS',
  },
  {
    id: '117',
    companyname: 'Ericsson AB Branch Office Cambodia',
    companynumber: '48945',
    countryname: 'Cambodia',
    countrycode: 'KH',
    groupcode: 'ERICS',
  },
  {
    id: '106',
    companyname: 'Ericsson Cameroon S.A.',
    companynumber: '45773',
    countryname: 'Cameroon',
    countrycode: 'CM',
    groupcode: 'ERICS',
  },
  {
    id: '15',
    companyname: 'Ericsson Chile SA',
    companynumber: '25504',
    countryname: 'Chile',
    countrycode: 'CL',
    groupcode: 'ERICS',
  },
  {
    id: '1',
    companyname: 'Ericsson (China) Company Ltd (ETC)',
    companynumber: '11676',
    countryname: 'China',
    countrycode: 'CN',
    groupcode: 'ERICS',
  },
  {
    id: '13',
    companyname: 'Nanjing Ericsson Panda Communication Co Ltd ENC',
    companynumber: '20854',
    countryname: 'China',
    countrycode: 'CN',
    groupcode: 'ERICS',
  },
  {
    id: '24',
    companyname: 'Ericsson(China)Communications Company Ltd',
    companynumber: '27930',
    countryname: 'China',
    countrycode: 'CN',
    groupcode: 'ERICS',
  },
  {
    id: '63',
    companyname: 'Ericsson (China) Communications',
    companynumber: '36140',
    countryname: 'China',
    countrycode: 'CN',
    groupcode: 'ERICS',
  },
  {
    id: '83',
    companyname: 'Ericsson SSC Beijing AP',
    companynumber: '41354',
    countryname: 'China',
    countrycode: 'CN',
    groupcode: 'ERICS',
  },
  {
    id: '100',
    companyname: 'Ericsson (China) Communication Company Ltd',
    companynumber: '44938',
    countryname: 'China',
    countrycode: 'CN',
    groupcode: 'ERICS',
  },
  {
    id: '77',
    companyname: 'Ericsson de Colombia S.A',
    companynumber: '39851',
    countryname: 'Colombia',
    countrycode: 'CO',
    groupcode: 'ERICS',
  },
  {
    id: '46',
    companyname: 'Ericsson De Costa Rica S.A',
    companynumber: '30873',
    countryname: 'Costa Rica',
    countrycode: 'CR',
    groupcode: 'ERICS',
  },
  {
    id: '86',
    companyname: 'Ericsson AB Ivory Coast',
    companynumber: '41697',
    countryname: "Côte d'Ivoire",
    countrycode: 'CI',
    groupcode: 'ERICS',
  },
  {
    id: '44',
    companyname: 'Ericsson Nikola Tesla d.d.',
    companynumber: '30641',
    countryname: 'Croatia',
    countrycode: 'HR',
    groupcode: 'ERICS',
  },
  {
    id: '96',
    companyname: 'BCY MAIN PLANT CYPRUS',
    companynumber: '44062',
    countryname: 'Cyprus',
    countrycode: 'CY',
    groupcode: 'ERICS',
  },
  {
    id: '49',
    companyname: 'Ericsson Spol.s r. o.',
    companynumber: '31290',
    countryname: 'Czech Republic',
    countrycode: 'CZ',
    groupcode: 'ERICS',
  },
  {
    id: '81',
    companyname: 'Ericsson AB Branch Office Dominican Republic',
    companynumber: '40390',
    countryname: 'Dominican Republic',
    countrycode: 'DO',
    groupcode: 'ERICS',
  },
  {
    id: '6',
    companyname: 'Ericsson Egypt Ltd/EEL',
    companynumber: '11687',
    countryname: 'Egypt',
    countrycode: 'EG',
    groupcode: 'ERICS',
  },
  {
    id: '22',
    companyname: 'Ericsson Egypt Ltd',
    companynumber: '27567',
    countryname: 'Egypt',
    countrycode: 'EG',
    groupcode: 'ERICS',
  },
  {
    id: '59',
    companyname: 'Ericsson Eesti AS',
    companynumber: '34978',
    countryname: 'Estonia',
    countrycode: 'EE',
    groupcode: 'ERICS',
  },
  {
    id: '110',
    companyname: 'Oy L M Ericsson Ab (46806)',
    companynumber: '46806',
    countryname: 'Finland',
    countrycode: 'FI',
    groupcode: 'ERICS',
  },
  {
    id: '119',
    companyname: 'OY L M Ericsson AB (48951)',
    companynumber: '48951',
    countryname: 'Finland',
    countrycode: 'FI',
    groupcode: 'ERICS',
  },
  {
    id: '82',
    companyname: 'Ericsson IT Solutions & Services',
    companynumber: '41229',
    countryname: 'France',
    countrycode: 'FR',
    groupcode: 'ERICS',
  },
  {
    id: '149',
    companyname: 'Ericsson France',
    companynumber: '50543',
    countryname: 'France',
    countrycode: 'FR',
    groupcode: 'ERICS',
  },
  {
    id: '120',
    companyname: 'ERICSSON AB GABON BRANCH',
    companynumber: '48953',
    countryname: 'Gabon',
    countrycode: 'GA',
    groupcode: 'ERICS',
  },
  {
    id: '121',
    companyname: 'Ericsson Georgia',
    companynumber: '48954',
    countryname: 'Georgia',
    countrycode: 'GE',
    groupcode: 'ERICS',
  },
  {
    id: '9',
    companyname: 'Ericsson GmbH',
    companynumber: '11694',
    countryname: 'Germany',
    countrycode: 'DE',
    groupcode: 'ERICS',
  },
  {
    id: '89',
    companyname: 'Ericsson Telekommunikation GmbH',
    companynumber: '42848',
    countryname: 'Germany',
    countrycode: 'DE',
    groupcode: 'ERICS',
  },
  {
    id: '108',
    companyname: 'Ericsson GMBH',
    companynumber: '46114',
    countryname: 'Germany',
    countrycode: 'DE',
    groupcode: 'ERICS',
  },
  {
    id: '150',
    companyname: 'Ericsson Services GmbH',
    companynumber: '51491',
    countryname: 'Germany',
    countrycode: 'DE',
    groupcode: 'ERICS',
  },
  {
    id: '52',
    companyname: 'Ericsson Radio Systems Ghana',
    companynumber: '31982',
    countryname: 'Ghana',
    countrycode: 'GH',
    groupcode: 'ERICS',
  },
  {
    id: '69',
    companyname: 'Ericsson AB Ghana Branch (BGH) ',
    companynumber: '38058',
    countryname: 'Ghana',
    countrycode: 'GH',
    groupcode: 'ERICS',
  },
  {
    id: '122',
    companyname: 'Ericsson Hellas SA',
    companynumber: '48955',
    countryname: 'Greece',
    countrycode: 'GR',
    groupcode: 'ERICS',
  },
  {
    id: '113',
    companyname: 'Ericsson de Guatemala S.A',
    companynumber: '48121',
    countryname: 'Guatemala',
    countrycode: 'GT',
    groupcode: 'ERICS',
  },
  {
    id: '102',
    companyname: 'ERICSSON DE HONDURAS S.A',
    companynumber: '45260',
    countryname: 'Honduras',
    countrycode: 'HN',
    groupcode: 'ERICS',
  },
  {
    id: '66',
    companyname: 'Ericsson Limited',
    companynumber: '36485',
    countryname: 'Hong Kong',
    countrycode: 'HK',
    groupcode: 'ERICS',
  },
  {
    id: '105',
    companyname: 'ERICSSON LIMITED (EHK)',
    companynumber: '45600',
    countryname: 'Hong Kong',
    countrycode: 'HK',
    groupcode: 'ERICS',
  },
  {
    id: '123',
    companyname: 'ERICSSON HUNGARY LTD.',
    companynumber: '48956',
    countryname: 'Hungary',
    countrycode: 'HU',
    groupcode: 'ERICS',
  },
  {
    id: '48',
    companyname: 'Ericsson India Private Limited',
    companynumber: '31163',
    countryname: 'India',
    countrycode: 'IN',
    groupcode: 'ERICS',
  },
  {
    id: '67',
    companyname: 'Ericsson India Global Serv Pvt Ltd',
    companynumber: '37321',
    countryname: 'India',
    countrycode: 'IN',
    groupcode: 'ERICS',
  },
  {
    id: '25',
    companyname: 'Ericsson Indonesia ',
    companynumber: '27981',
    countryname: 'Indonesia',
    countrycode: 'ID',
    groupcode: 'ERICS',
  },
  {
    id: '90',
    companyname: 'Ericsson System & Services PJSC, IRN',
    companynumber: '42918',
    countryname: 'Iran',
    countrycode: 'IR',
    groupcode: 'ERICS',
  },
  {
    id: '11',
    companyname: 'LM Ericsson Limited (LMI)',
    companynumber: '13677',
    countryname: 'Ireland',
    countrycode: 'IE',
    groupcode: 'ERICS',
  },
  {
    id: '20',
    companyname: 'Ericsson Television Limited',
    companynumber: '27225',
    countryname: 'Italy',
    countrycode: 'IT',
    groupcode: 'ERICS',
  },
  {
    id: '23',
    companyname: 'Ericsson Telecomunicazioni S.p.A.',
    companynumber: '27569',
    countryname: 'Italy',
    countrycode: 'IT',
    groupcode: 'ERICS',
  },
  {
    id: '35',
    companyname: 'Ericsson Telecomunicazioni S.p.A',
    companynumber: '29727',
    countryname: 'Italy',
    countrycode: 'IT',
    groupcode: 'ERICS',
  },
  {
    id: '64',
    companyname: 'Ericsson Branch Office Jamaica',
    companynumber: '36278',
    countryname: 'Jamaica',
    countrycode: 'JM',
    groupcode: 'ERICS',
  },
  {
    id: '10',
    companyname: 'Ericsson JAPAN K.K Reception',
    companynumber: '11732',
    countryname: 'Japan',
    countrycode: 'JP',
    groupcode: 'ERICS',
  },
  {
    id: '61',
    companyname: 'Ericsson AB (BJO)',
    companynumber: '35394',
    countryname: 'Jordan',
    countrycode: 'JO',
    groupcode: 'ERICS',
  },
  {
    id: '31',
    companyname: 'Ericsson Kenya Ltd',
    companynumber: '29097',
    countryname: 'Kenya',
    countrycode: 'KE',
    groupcode: 'ERICS',
  },
  {
    id: '70',
    companyname: 'Ericsson - LG',
    companynumber: '38061',
    countryname: 'South Korea',
    countrycode: 'KR',
    groupcode: 'ERICS',
  },
  {
    id: '73',
    companyname: 'ERICSSON-LG CO.,LTD',
    companynumber: '39639',
    countryname: 'South Korea',
    countrycode: 'KR',
    groupcode: 'ERICS',
  },
  {
    id: '124',
    companyname: 'Kuwait Ericsson Telephone',
    companynumber: '48971',
    countryname: 'Kuwait',
    countrycode: 'KW',
    groupcode: 'ERICS',
  },
  {
    id: '125',
    companyname: 'Ericsson Latvia SIA',
    companynumber: '48972',
    countryname: 'Latvia',
    countrycode: 'LV',
    groupcode: 'ERICS',
  },
  {
    id: '151',
    companyname: 'ERICSSON LEBANON COMMUNICATIONS S.A.R.L.',
    companynumber: '51621',
    countryname: 'Lebanon',
    countrycode: 'LB',
    groupcode: 'ERICS',
  },
  {
    id: '5',
    companyname: 'Ericsson Expertise Center Sdn Bhd',
    companynumber: '11685',
    countryname: 'Malaysia',
    countrycode: 'MY',
    groupcode: 'ERICS',
  },
  {
    id: '16',
    companyname: 'Ericsson (Malaysia) Sdn Bhd',
    companynumber: '26271',
    countryname: 'Malaysia',
    countrycode: 'MY',
    groupcode: 'ERICS',
  },
  {
    id: '71',
    companyname: 'Ericsson Resource & Competence',
    companynumber: '38982',
    countryname: 'Malaysia',
    countrycode: 'MY',
    groupcode: 'ERICS',
  },
  {
    id: '87',
    companyname: 'ERICSSON (MALAYSIA) SDN BHD ',
    companynumber: '42191',
    countryname: 'Malaysia',
    countrycode: 'MY',
    groupcode: 'ERICS',
  },
  {
    id: '94',
    companyname: 'Ericsson Resource & Competence Center SDN.BHD (877527-X)',
    companynumber: '43619',
    countryname: 'Malaysia',
    countrycode: 'MY',
    groupcode: 'ERICS',
  },
  {
    id: '104',
    companyname: 'Ericsson AB Mali',
    companynumber: '45488',
    countryname: 'Mali',
    countrycode: 'ML',
    groupcode: 'ERICS',
  },
  {
    id: '127',
    companyname: 'Ericsson AB Malta',
    companynumber: '48974',
    countryname: 'Malta',
    countrycode: 'MT',
    groupcode: 'ERICS',
  },
  {
    id: '148',
    companyname: 'Ericsson Telecom S. A. de C.V.',
    companynumber: '50241',
    countryname: 'Mexico',
    countrycode: 'MX',
    groupcode: 'ERICS',
  },
  {
    id: '14',
    companyname: 'Ericsson International Services B.V (ISN)',
    companynumber: '23448',
    countryname: 'Netherlands',
    countrycode: 'NL',
    groupcode: 'ERICS',
  },
  {
    id: '27',
    companyname: 'ERICSSON TELECOMMUNICATIE B.V. (ETM)',
    companynumber: '28514',
    countryname: 'Netherlands',
    countrycode: 'NL',
    groupcode: 'ERICS',
  },
  {
    id: '128',
    companyname: 'ERICSSON NETWORK SERVICES B.V. (ENN)',
    companynumber: '48976',
    countryname: 'Netherlands',
    countrycode: 'NL',
    groupcode: 'ERICS',
  },
  {
    id: '129',
    companyname: 'ERICSSON COMMUNICATIONS LTD. (ENZ)',
    companynumber: '48977',
    countryname: 'New Zealand',
    countrycode: 'NZ',
    groupcode: 'ERICS',
  },
  {
    id: '56',
    companyname: 'LM Ericsson (Nigeria) Ltd',
    companynumber: '34031',
    countryname: 'Nigeria',
    countrycode: 'NG',
    groupcode: 'ERICS',
  },
  {
    id: '130',
    companyname: 'Ericsson AS',
    companynumber: '48978',
    countryname: 'Norway',
    countrycode: 'NO',
    groupcode: 'ERICS',
  },
  {
    id: '19',
    companyname: 'Ericsson Oman LLC',
    companynumber: '27079',
    countryname: 'Oman',
    countrycode: 'OM',
    groupcode: 'ERICS',
  },
  {
    id: '32',
    companyname: 'Ericsson Pakistan (PVT) Limited',
    companynumber: '29212',
    countryname: 'Pakistan',
    countrycode: 'PK',
    groupcode: 'ERICS',
  },
  {
    id: '53',
    companyname: 'Ericsson De Panama S.A',
    companynumber: '32409',
    countryname: 'Panama',
    countrycode: 'PA',
    groupcode: 'ERICS',
  },
  {
    id: '72',
    companyname: 'Ericsson AB, Branch Office Panama',
    companynumber: '39438',
    countryname: 'Panama',
    countrycode: 'PA',
    groupcode: 'ERICS',
  },
  {
    id: '131',
    companyname: 'ERICSSON DEL PARAGUAY S.A',
    companynumber: '48979',
    countryname: 'Paraguay',
    countrycode: 'PY',
    groupcode: 'ERICS',
  },
  {
    id: '103',
    companyname: 'CIA Ericsson SA',
    companynumber: '45308',
    countryname: 'Peru',
    countrycode: 'PE',
    groupcode: 'ERICS',
  },
  {
    id: '132',
    companyname: 'COMPANIA ERICSSON S.A',
    companynumber: '48980',
    countryname: 'Peru',
    countrycode: 'PE',
    groupcode: 'ERICS',
  },
  {
    id: '18',
    companyname: 'Ericsson Telecommunications Inc',
    companynumber: '26620',
    countryname: 'Philippines',
    countrycode: 'PH',
    groupcode: 'ERICS',
  },
  {
    id: '54',
    companyname: 'Ericsson Telecommunications INC',
    companynumber: '33190',
    countryname: 'Philippines',
    countrycode: 'PH',
    groupcode: 'ERICS',
  },
  {
    id: '133',
    companyname: 'Ericsson SP. Z O.O.',
    companynumber: '48981',
    countryname: 'Poland',
    countrycode: 'PL',
    groupcode: 'ERICS',
  },
  {
    id: '12',
    companyname: 'Ericsson ',
    companynumber: '17092',
    countryname: 'Portugal',
    countrycode: 'PT',
    groupcode: 'ERICS',
  },
  {
    id: '55',
    companyname: 'Ericsson Caribbean Inc',
    companynumber: '33217',
    countryname: 'Puerto Rico',
    countrycode: 'PR',
    groupcode: 'ERICS',
  },
  {
    id: '34',
    companyname: 'Ericsson AB',
    companynumber: '29554',
    countryname: 'Qatar',
    countrycode: 'QA',
    groupcode: 'ERICS',
  },
  {
    id: '135',
    companyname: 'Ericsson AB - Qatar Branch',
    companynumber: '48988',
    countryname: 'Qatar',
    countrycode: 'QA',
    groupcode: 'ERICS',
  },
  {
    id: '65',
    companyname: 'Ericsson AB Rwanda',
    companynumber: '36305',
    countryname: 'Rwanda',
    countrycode: 'RW',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Romania',
    id: '26',
    companyname: 'Ericsson Telecommunications Romania S.R.L (ETR)',
    companynumber: '28481',
    countrycode: 'RO',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Russia',
    id: '136',
    companyname: 'ZAO Ericsson Corporatia AO',
    companynumber: '48989',
    countrycode: 'RU',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Saudi Arabia',
    id: '21',
    companyname: 'Ericsson AB',
    companynumber: '27554',
    countrycode: 'SA',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Saudi Arabia',
    id: '29',
    companyname: 'Saudi Ericsson Communications Co. Ltd',
    companynumber: '28653',
    countrycode: 'SA',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Saudi Arabia',
    id: '92',
    companyname: 'Ericsson AB, Saudi Arabia Branch',
    companynumber: '43523',
    countrycode: 'SA',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Senegal',
    id: '101',
    companyname: 'Ericsson Senegal SARL',
    companynumber: '45116',
    countrycode: 'SN',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Serbia and Montenegro',
    id: '137',
    companyname: 'Ericsson DOO za Telekomunikacije',
    companynumber: '48991',
    countrycode: 'CS',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Sierra Leone',
    id: '138',
    companyname: 'ERICSSON AB SIERRA LEONE BRANCH',
    companynumber: '48992',
    countrycode: 'SL',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Singapore',
    id: '30',
    companyname: 'Ericsson Telecommunications PTE Ltd',
    companynumber: '28989',
    countrycode: 'SG',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Slovakia',
    id: '114',
    companyname: 'Ericsson Slovakia SPOL. S.R.O. (SBB)',
    companynumber: '48701',
    countrycode: 'SK',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Slovenia',
    id: '139',
    companyname: 'ERICSSON D.O.O',
    companynumber: '48993',
    countrycode: 'SI',
    groupcode: 'ERICS',
  },
  {
    countryname: 'South Africa',
    id: '51',
    companyname: 'Ericsson South Africa (Pty) Ltd',
    companynumber: '31825',
    countrycode: 'ZA',
    groupcode: 'ERICS',
  },
  {
    countryname: 'South Africa',
    id: '60',
    companyname: 'Ericsson sub-Saharan Africa Pty Ltd (ASL)',
    companynumber: '35037',
    countrycode: 'ZA',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Spain',
    id: '8',
    companyname: 'Ericsson Espana S.A',
    companynumber: '11689',
    countrycode: 'ES',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Spain',
    id: '134',
    companyname: 'Ericsson',
    companynumber: '48982',
    countrycode: 'ES',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Spain',
    id: '147',
    companyname: 'Ericsson Technology Services GmbH',
    companynumber: '49577',
    countrycode: 'ES',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Sri Lanka',
    id: '28',
    companyname: 'Ericsson Telecomm Lanka ',
    companynumber: '28547',
    countrycode: 'LK',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Sudan',
    id: '112',
    companyname: 'Ericsson AB Ltd Branch South Sudan ',
    companynumber: '47456',
    countrycode: 'SD',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Sweden',
    id: '2',
    companyname: 'Ericsson AB (EAB)',
    companynumber: '11677',
    countrycode: 'SE',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Sweden',
    id: '3',
    companyname: 'Ericsson AB (ESE)',
    companynumber: '11678',
    countrycode: 'SE',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Sweden',
    id: '42',
    companyname: 'Telefonaktiebolaget LM Ericsson',
    companynumber: '30587',
    countrycode: 'SE',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Sweden',
    id: '58',
    companyname: 'Ericsson Danmark A/S (LMD)',
    companynumber: '34612',
    countrycode: 'SE',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Sweden',
    id: '74',
    companyname: 'Ericsson Modems JVS AB',
    companynumber: '39697',
    countrycode: 'SE',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Sweden',
    id: '78',
    companyname: 'Ericsson AB (ECA)',
    companynumber: '39876',
    countrycode: 'SE',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Sweden',
    id: '79',
    companyname: 'Ericsson AB',
    companynumber: '40228',
    countrycode: 'SE',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Sweden',
    id: '85',
    companyname: 'Ericsson Modems AB',
    companynumber: '41657',
    countrycode: 'SE',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Switzerland',
    id: '4',
    companyname: 'Ericsson AG ',
    companynumber: '11680',
    countrycode: 'CH',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Taiwan',
    id: '140',
    companyname: 'ERICSSON TAIWAN LTD.',
    companynumber: '48994',
    countrycode: 'TW',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Tanzania, United Republic of',
    id: '68',
    companyname: 'Ericsson AB (BTZ)',
    companynumber: '37769',
    countrycode: 'TZ',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Tanzania, United Republic of',
    id: '141',
    companyname: 'Ericsson Branch Office Tanzania',
    companynumber: '48995',
    countrycode: 'TZ',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Thailand',
    id: '17',
    companyname: 'Ericsson (Thailand) Ltd.',
    companynumber: '26610',
    countrycode: 'TH',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Togo',
    id: '146',
    companyname: 'ERICSSON AB TOGO',
    companynumber: '49016',
    countrycode: 'TG',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Tunisia',
    id: '142',
    companyname: 'ERICSSON AB BRANCH OFFICE TUNISIA',
    companynumber: '48996',
    countrycode: 'TN',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Turkey',
    id: '80',
    companyname: 'Ericsson AR-GE Ve Bilislim',
    companynumber: '40231',
    countrycode: 'TR',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Turkey',
    id: '107',
    companyname: 'Ericsson Telekomï¿½nikasyon A.S. (ENK)',
    companynumber: '45807',
    countrycode: 'TR',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Uganda',
    id: '57',
    companyname: 'Ericsson AB Uganda Branch',
    companynumber: '34379',
    countrycode: 'UG',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Uganda',
    id: '75',
    companyname: 'LM Ericsson Int. Uganda',
    companynumber: '39708',
    countrycode: 'UG',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Ukraine',
    id: '143',
    companyname: 'Ericsson Ukraine',
    companynumber: '48997',
    countrycode: 'UA',
    groupcode: 'ERICS',
  },
  {
    countryname: 'United Arab Emirates',
    id: '33',
    companyname: 'Ericsson AB Dubai Branch',
    companynumber: '29472',
    countrycode: 'AE',
    groupcode: 'ERICS',
  },
  {
    countryname: 'United Arab Emirates',
    id: '37',
    companyname: 'Ericsson AB',
    companynumber: '29806',
    countrycode: 'AE',
    groupcode: 'ERICS',
  },
  {
    countryname: 'The United Kingdom',
    id: '7',
    companyname: 'Ericsson Ltd',
    companynumber: '11688',
    countrycode: 'GB',
    groupcode: 'ERICS',
  },
  {
    countryname: 'The United Kingdom',
    id: '62',
    companyname: 'Ericsson Television Ltd',
    companynumber: '35988',
    countrycode: 'GB',
    groupcode: 'ERICS',
  },
  {
    countryname: 'The United Kingdom',
    id: '111',
    companyname: 'Ericsson Media Services UK Ltd T/A ',
    companynumber: '47436',
    countrycode: 'GB',
    groupcode: 'ERICS',
  },
  {
    countryname: 'United States',
    id: '50',
    companyname: 'Ericsson Television ',
    companynumber: '31547',
    countrycode: 'US',
    groupcode: 'ERICS',
  },
  {
    countryname: 'United States',
    id: '95',
    companyname: 'Ericsson Canada Inc',
    companynumber: '43759',
    countrycode: 'US',
    groupcode: 'ERICS',
  },
  {
    countryname: 'United States',
    id: '98',
    companyname: 'Ericsson Inc',
    companynumber: '44290',
    countrycode: 'US',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Uruguay',
    id: '145',
    companyname: 'COMPANIA ERICSSON URUGUAY S.A',
    companynumber: '49011',
    countrycode: 'UY',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Viet Nam',
    id: '45',
    companyname: 'Ericsson Vietnam Company Ltd (ETV)',
    companynumber: '30709',
    countrycode: 'VN',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Zambia',
    id: '144',
    companyname: 'ERICSSON AB ZAMBIA BRANCH',
    companynumber: '48998',
    countrycode: 'ZM',
    groupcode: 'ERICS',
  },
  {
    countryname: 'Zimbabwe',
    id: '91',
    companyname: 'Ericsson Zimbabwe PTY Ltd',
    companynumber: '43105',
    countrycode: 'ZW',
    groupcode: 'ERICS',
  },
];
