import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { UserState } from '../../store/reducers';
import { selectUserAllowFunctions } from '../../store/selectors';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  allowedFunctions: any[] = [];
  links: any = [];

  isNavbarCollapsed = true;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private store: Store<UserState>
  ) {}

  async ngOnInit() {
    this.allowedFunctions = this.getValue(
      this.store.pipe(select(selectUserAllowFunctions))
    );
    // console.log('allowedFunctions: ', this.allowedFunctions);
    this.links = await this.generateLinks(this.allowedFunctions);
    // console.log('links: ', this.links);
  }

  generateLinks(allowedFunctions: any[]): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const links: any = [];
      allowedFunctions.forEach((f: any) => {
        const obj = {
          title: f.Name,
          fragment: `/${f.Code.toLowerCase()}`,
        };
        links.push(obj);
      });
      resolve(links);
    });
  }

  getValue(obj: Observable<any>) {
    let value: any;
    obj.subscribe((v) => (value = v));
    return value;
  }

  goto(link: string) {
    this.router.navigate([ link ]);
  }

}
