<h3>Insurance</h3>
<hr />
<br />
<p>Alfa will cover insurance</p>

<h3>Other details</h3>
<hr />
<br />

<div>
  <h4>Do you have any goods in storage to be included in your move?</h4>

  <label class="radio">
    <input
      type="radio"
      value="Yes"
      name="InStorage"
      [(ngModel)]="extrainfo.InStorage"
    />
    <span>Yes</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="No"
      name="InStorage"
      [(ngModel)]="extrainfo.InStorage"
    />
    <span>No</span>
  </label>

  <!-- <h4>Do you need storage of max 10 cbm</h4>

  <label class="radio">
    <input
      type="radio"
      value="Yes"
      name="ToStorage"
      [(ngModel)]="extrainfo.ToStorage"
    />
    <span>Yes</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="No"
      name="ToStorage"
      [(ngModel)]="extrainfo.ToStorage"
    />
    <span>No</span>
  </label> -->

  <h4>
    Would you like for us to arrange move-out cleaning of your current
    residence?
  </h4>

  <label class="radio">
    <input
      type="radio"
      value="Yes"
      name="CleaningOffer"
      [(ngModel)]="extrainfo.CleaningOffer"
    />
    <span>Yes</span>
  </label>

  <label class="radio">
    <input
      type="radio"
      value="No"
      name="CleaningOffer"
      [(ngModel)]="extrainfo.CleaningOffer"
    />
    <span>No</span>
  </label>

  <div>
    <label for="textbox"><h4>Other info</h4></label>
    <textarea
      class="itextfield"
      name="otherDetails"
      id="textbox"
      cols="30"
      rows="10"
      [(ngModel)]="extrainfo.OtherInfo"
      itext="Please type here if you have something to add.."
    ></textarea>
  </div>

  <div class="button-container">
    <input
      class="button btn-form"
      type="button"
      value="Back"
      routerLink="../movedetailsto"
    />
    <input
      class="button btn-form"
      type="button"
      value="Submit"
      (click)="formData.formSubmit()"
    />
  </div>
</div>
