import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';

import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-aires',
  templateUrl: './aires.component.html',
  styleUrls: ['./aires.component.scss']
})
export class AiresComponent {

 content: Content;
extrainfo : Extrainfo;


  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;

    this.content.SokID = '31952';
    this.extrainfo.CompanyName = 'AIReS';
    this.extrainfo.CompanyContact = '412-7880461';
    this.extrainfo.CompanyEmail = 'aires@aires.com';
    this.extrainfo.CompanyAddress = '6 Penn Center West Suite 200';
    this.extrainfo.CompanyCity = 'Pittsburgh, PA';
    this.extrainfo.CompanyCountry = 'US';
    this.extrainfo.CompanyZipCode = '15276';
    this.extrainfo.insurance = 'AQM will cover insurance.';
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';

    this.formConfig.confirm = false;

    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'aires';
  }
}
