import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUserAllowFunctions } from '../../store/selectors';
import { DataService } from '../../services/data.service';
import { Data } from '../../models/data';
import { Assignment } from '../../models/assignment';
import { Assignment2DetailComponent } from '../assignment2-list/subcomponents/assignment2-detail/assignment2-detail.component';

@Component({
  selector: 'app-ny-lista',
  templateUrl: './ny-lista.component.html',
  styleUrls: ['./ny-lista.component.scss']
})
export class NyListaComponent implements OnInit {

  assignments: Assignment[] = [];
  functions: string[] = [];
  searchText: string = '';
  loading = true;

  constructor(
    private dataService: DataService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.select(selectUserAllowFunctions).subscribe((f) => {
      // console.log('Allowed functions: ', f);
      // console.log('Function: ', f[0].Code);
      this.getData(f[0].Code, false);
    });
  }
  getData(_function: string, _detail: boolean) {
    this.dataService.getData(_function, _detail).subscribe({
      next: (response: Data) => {
        // console.log(response);
        if (response.response.result) {
          this.assignments = response.content.list;
          this.loading = false;
        } else {
          this.assignments = [];
          this.loading = false;
        }
        // console.log(this.assignments)
      },
      error: (error: Error) => {
        console.error(error);
        this.assignments = [];
        this.loading = false;
      }
    });
  }



}
