import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { Data } from '../models/data';
import { User } from '../models/user';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient) {}

  /* Fetches data for assignee list */
  getData(_function: string, _detail: boolean): Observable<Data> {
    const url = `${environment['backendUrl']}/api/portal/getData`;
    const body = {
      function: _function,
      detail: _detail,
    };
    return this.http.post<Data>(url, body);
  }

  /* Fetches data for assignee details */
  getDataDetail(
    _function: string,
    _detail: boolean,
    _id: string
  ): Observable<Data> {
    const url = `${environment['backendUrl']}/api/portal/getData`;
    const body = {
      function: _function,
      detail: _detail,
      id: _id,
    };
    return this.http.post<Data>(url, body);
  }

  /* Editing Profile save */
  saveProfile(userData: User): Observable<Data> {
    const url = `${environment['backendUrl']}/api/portal/updateProfile`;
    const body = userData;
    return this.http.post<any>(url, body);
  }

  // Submit for booking pages
  formSubmit(body: any): Observable<any> {
    //
    // console.log(JSON.stringify(body, null, 4));
    //
    const url = `${environment['proxyUrl']}/api/proxy/v1/apimSubmit`;
    return this.http.post(url, body, { responseType: 'text' });
  }

  /* Fetches approval data for Manager approval.  */
  getConfirm(_function: string, _detail: boolean, _id?: string ): Observable<Data> {
    const url = `${environment['backendUrl']}/api/portal/getData`;
    const optionalProps = {
      id: _id,
    }
    const body = {
      function: _function,
      detail: _detail,
      ...optionalProps,
    };
    return this.http.post<Data>(url, body);
  }

  /* Pending manager approval */
  approvalUpdate(_id: string, _status: number, _data?: any): Observable<Data> {
    const url = `${environment['backendUrl']}/api/portal/updateData`;
    const body = {
      id: _id,
      status: _status,
      function: 'Exbook',
      data: _data
    };

    if (_data !== undefined) {
      body.data = _data;
    }

    return this.http.put<Data>(url, body);
  }
}
