<div>
  <div>
    <div>
      <h3>Application reviews</h3>
      <!-- <hr />
      <p>
        These applications are pending and require manager approval in order to
        proceed.
      </p> -->
    </div>
  </div>

  <div *ngIf="loaded && !error">
    <div class="include-div">
      Include approved and rejected
      <input
        type="radio"
        name="includeAll"
        [(ngModel)]="includeAll"
        (click)="toggleIncludeAll()"
        [value]="true"
      />
    </div>
    <div class="fixTableHead">
      <table>
        <thead>
          <tr>
            <th id="truncateLongTexts" (dblclick)="sortOnAssigneeName()">Assignee name</th>
            <th style="min-width: 80px" (dblclick)="sortOnMove()">Moving</th>
            <th id="truncateLongTexts" (dblclick)="sortOnMoveOwner()">Move owner</th>
            <th (dblclick)="sortOnStatus()">Status</th>
            <th>Submitted</th>
          </tr>
        </thead>
        <tbody *ngFor="let confirm of confirmList.content.list; index as ix">
          <tr
            *ngIf="!confirm.selected"
            id="assignee-list"
            (click)="selectAssignment(ix)"
          >
            <td id="truncateLongTexts">
              {{ confirm.data.content.assigneeLastName }},
              {{ confirm.data.content.assigneeFirstName }}
            </td>
            <td>
              {{ confirm.data.content.orgdata.orgCountry }} ->
              {{ confirm.data.content.destdata.destCountry }}
            </td>
            <td id="truncateLongTexts">
              {{ confirm.data.content.extrainfo.moveOwner }}
            </td>
            <td>{{ translateStatus(confirm.status) }}</td>
            <td>
              {{
                confirm.data.content.extrainfo.submitDate
                  | date : "yyyy-MM-dd HH:mm"
              }}
            </td>
          </tr>
          <tr
            *ngIf="confirm.selected"
            id="assignee-list"
            class="selected-row"
            (click)="selectAssignment(ix)"
          >
            <td id="truncateLongTexts">
              {{ confirm.data.content.assigneeFirstName }}
              {{ confirm.data.content.assigneeLastName }}
            </td>
            <td>
              {{ confirm.data.content.orgdata.orgCountry }} ->
              {{ confirm.data.content.destdata.destCountry }}
            </td>
            <td id="truncateLongTexts">
              {{ confirm.data.content.extrainfo.moveOwner }}
            </td>
            <td>{{ translateStatus(confirm.status) }}</td>
            <td>
              {{
                confirm.data.content.extrainfo.submitDate
                  | date : "yyyy-MM-dd HH:mm"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="assignment-view" *ngIf="currentAssignment">
      <app-alfa-laval
        *ngIf="customer === 'ALFALAVAL'"
        [assignment]="currentAssignment"
        (removeAssignment)="removeAssignment()"
      ></app-alfa-laval>
      <app-tetra-laval
        *ngIf="customer === 'TETRALAVAL'"
        [assignment]="currentAssignment"
        (removeAssignment)="removeAssignment()"
        (setPostCommand)="setPostCommand($event)"
      ></app-tetra-laval>
    </div>
  </div>
  <div *ngIf="error">
    <app-error [errorObj]="errorObj"></app-error>
  </div>
</div>
