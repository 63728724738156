import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllCountries } from '../../../store/selectors';
import { Destdata, Orgdata, Surveyinfo } from 'src/app/models/form/book-data';
import { Country } from '../../../models/country';
import { FormDataService } from 'src/app/services/form-data.service';
import { FormConfig } from 'src/app/services/form-config';

@Component({
  selector: 'app-move-details-from',
  templateUrl: './move-details-from.component.html',
  styleUrls: ['./move-details-from.component.scss'],
})
export class MoveDetailsFromComponent implements OnInit {
  orgdata: Orgdata;
  surveyinfo: Surveyinfo;
  destdata: Destdata;
  formC: FormConfig;

  countries: Country[];

  @ViewChild('topScroll') topScroll: ElementRef;

  constructor(
    private formDataService: FormDataService,
    private formConfig: FormConfig,
    private store: Store
  ) {
    this.formC = formConfig;
    this.surveyinfo = formDataService.surveyinfo;
    this.destdata = formDataService.destdata;
    this.orgdata = formDataService.orgdata;
  }

  ngOnInit(): void {
    this.store.select(selectAllCountries).subscribe((data) => {
      this.countries = data;
    });
  }

}
