<div>

  <div>
  <h3>Insurance</h3>
  <hr />
  <p>Alfa will cover insurance.</p>
</div>

<div>
  <h3>Other details</h3>
  <hr>
  <br>
</div>


    <div class="radio-container">
      <h4>
        Is shipper having a current storage portion at destination that shall be
        delivered upon arrival?*
      </h4>
  
      <label class="radio">
        <input
          type="radio"
          value="yes"
          name="assignmentType"
          [(ngModel)]="extrainfo.InStorage"
        />
        <span>Yes</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="no"
          name="assignmentType"
          [(ngModel)]="extrainfo.InStorage"
        />
        <span>No</span> </label
      >
  
      <h4>Will shipper have any goods to be placed in long term storage at origin?</h4>
  
      <label class="radio">
        <input
          type="radio"
          value="yes"
          name="AiresLongStorage"
          [(ngModel)]="extrainfo.ToStorage"
        />
        <span>Yes</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="no"
          name="AiresLongStorage"
          [(ngModel)]="extrainfo.ToStorage"
        />
        <span>No</span> </label
      >
  
      <h4>Shipment to unfurnished residence</h4>
  
      <label class="radio">
        <input
          type="radio"
          value="unfurnished 15"
          name="airesUnfurnished"
          [(ngModel)]="extrainfo.Shipment"
        />
        <span>Single person (15 cbm)</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="unfurnished 20"
          name="airesUnfurnished"
          [(ngModel)]="extrainfo.Shipment"
        />
        <span>Couple (20 cbm)</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="unfurnished 24"
          name="airesUnfurnished"
          [(ngModel)]="extrainfo.Shipment"
        />
        <span>Accompanied family with one child (24 cbm)</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="unfurnished 28"
          name="airesUnfurnished"
          [(ngModel)]="extrainfo.Shipment"
        />
        <span>Accompanied family with two children (28 cbm)</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="unfurnished 32"
          name="airesUnfurnished"
          [(ngModel)]="extrainfo.Shipment"
        />
        <span>Accompanied family with three children (32 cbm)</span> </label
      >
  
      <h4>Shipment to furnished residence</h4>
  
      <label class="radio">
        <input
          type="radio"
          value="furnished 3 single"
          name="airesFurnished"
          [(ngModel)]="extrainfo.Shipment"
        />
        <span>Single person (3 cbm)</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="furnished 5 family"
          name="airesFurnished"
          [(ngModel)]="extrainfo.Shipment"
        />
        <span>Couple (5 cbm)</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="furnished 6"
          name="airesFurnished"
          [(ngModel)]="extrainfo.Shipment"
        />
        <span>Accompanied family with one child (6 cbm)</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="furnished 7"
          name="airesFurnished"
          [(ngModel)]="extrainfo.Shipment"
        />
        <span>Accompanied family with two children (7 cbm)</span> </label
      >
  
      <label class="radio">
        <input
          type="radio"
          value="furnished 8"
          name="airesFurnished"
          [(ngModel)]="extrainfo.Shipment"
        />
        <span>Accompanied family with three children (8 cbm)</span> </label
      >

      <label class="icontainer label-children">
        <select
          name="inputChildren"
          class="iselect"
          [(ngModel)]="extrainfo.NumberOfChildren"
          #inputChildren="ngModel"
          required
        >
          <option value="none">No kids</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6 or more">6 or more</option>
        </select>
        <span
          class="itext"
          [ngClass]="{ 'itext-select': extrainfo.NumberOfChildren != undefined }"
        >
          Number of children
        </span>
      </label>
    </div>

    <div>
        <label for="textbox"></label>
        <textarea
          class="itextfield"
          name="extrainfo"
          id="textbox"
          cols="30"
          rows="10"
          [(ngModel)]="extrainfo.OtherInfo"
          placeholder="Please let us know here here if there's anything we should know to best help you.."
        >
        </textarea>
    </div>
  
  </div>

<div class="button-container">
    
    
    <input
      class="button btn-form"
      type="button"
      value="Back"
      routerLink="../movedetailsto"
    />
    <!-- TODO add a better validation and styling. -->
    <input
      [disabled]="extrainfo.InStorage == undefined"
      class="button btn-form"
      type="button"
      value="Submit"
      (click)="formData.formSubmit()"
    />
  </div>