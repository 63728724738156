<div *ngIf="formC.formCompanyName == 'ABB'">
    <!-- INSURANCE SECTION -->
    <h3>Insurance</h3>
    <hr />
    <p>Alfa will cover insurance.</p>
</div>

<div *ngIf="formC.formCompanyName == 'fba'">
    <!-- FBA SECTION -->
    <h3>Insurance</h3>
    <hr />
    <p>Insurance will be arranged by: Kammarkollegiet.</p>
</div>

<div *ngIf="formC.formCompanyName == 'absolut'">
    <!-- ABSOLUT & MTG SECTION -->

    <h3>Insurance</h3>
    <hr />

    <p>
        All shipments and storage portions are insured through Alfa. A valuation
        form will be sent to you by our move mangers upon confirming your move.
        It is very important that this form is filled in and returned prior to
        packing.
    </p>

    <h3>Other details</h3>
    <hr />

    <!-- ABSOLUT & MTG SECTION END-->
</div>

<div *ngIf="formC.formCompanyName == 'alfalaval'">
    <app-alfalaval-other-details></app-alfalaval-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'scania'">
    <app-scania-other-details></app-scania-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'aires'">
    <app-aires-other-details></app-aires-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'Sida'">
    <app-sida-other-details></app-sida-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'Tetralaval'">
    <app-tetralaval-other-details></app-tetralaval-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'Oriflame'">
    <app-oriflame-other-details></app-oriflame-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'ericsson'">
    <app-ericsson-other-details></app-ericsson-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'EF'">
    <app-ef-other-details></app-ef-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'Swedbank'">
    <app-swedbank-other-details></app-swedbank-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'Sandvik'">
    <app-sandvik-other-details></app-sandvik-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'SEB'">
    <app-seb-other-details></app-seb-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'Handelsbanken'">
    <app-handelsbanken-other-details></app-handelsbanken-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'saab'">
    <app-saab-other-details></app-saab-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'CEVT'">
    <app-cevt-other-details></app-cevt-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'ud'">
    <app-ud-other-details></app-ud-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'lunds'">
    <app-lund-other-details></app-lund-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'pwc'">
    <app-pwc-other-details></app-pwc-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'hm'">
    <app-hm-policy></app-hm-policy>
</div>

<div *ngIf="formC.formCompanyName == 'Northvolt'">
    <app-northvolt-other-details></app-northvolt-other-details>
</div>

<div *ngIf="formC.formCompanyName == 'migrationsverket'">
    <app-migrationsverket-other-details></app-migrationsverket-other-details>
</div>

<!-- Submit button and text area for all.
     Unless BACK button goes elsewhere, check list -->

<!-- TODO: revisit and style, add placeholder -->
<div
    *ngIf="
        formC.formCompanyName == 'general' ||
        formC.formCompanyName == 'ABB' ||
        formC.formCompanyName == 'fba' ||
        formC.formCompanyName == 'absolut' ||
        formC.formCompanyName == 'mtg' ||
        formC.formCompanyName == 'uoh' ||
        formC.formCompanyName == 'Polisen' ||
        formC.formCompanyName == 'umdk' ||
        formC.formCompanyName == 'udno'
    "
>
    <h3>Other details</h3>
    <hr />

    <label
        *ngIf="
            formC.formCompanyName == 'umdk' || formC.formCompanyName == 'udno'
        "
        class="icontainer"
    >
        <h4>Allowance removal in cbm:</h4>
        <input
            class="ifield"
            type="number"
            name="ApprovedRelocationAllowance"
            [(ngModel)]="extrainfo.ApprovedRelocationAllowance"
            #ApprovedRelocationAllowance="ngModel"
        />
    </label>

    <label *ngIf="formC.formCompanyName == 'udno'" class="icontainer">
        <h4>Allowance long term storage in cbm:</h4>
        <input
            class="ifield"
            type="number"
            name="ApprovedLongTermAllowance"
            [(ngModel)]="extrainfo.ApprovedLongTermAllowance"
            #ApprovedRelocationAllowance="ngModel"
        />
    </label>

    <label for="textbox"><h4>Additional information</h4></label>
    <textarea
        class="itextfield"
        name="otherDetails"
        id="textbox"
        cols="30"
        rows="10"
        [(ngModel)]="extrainfo.OtherInfo"
        placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
    ></textarea>

    <div class="button-container">
        <!-- BASIC BUTTONS -->
        <input
            class="button btn-form"
            type="button"
            value="Back"
            routerLink="../movedetailsto"
        />
        <input
            class="button btn-form"
            type="button"
            value="Submit"
            (click)="formData.formSubmit()"
            [disabled]="
                extrainfo.ApprovedRelocationAllowance == '' ||
                extrainfo.ApprovedRelocationAllowance == undefined
            "
        />
    </div>
</div>
