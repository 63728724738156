import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-general-booking',
  templateUrl: './general-booking.component.html',
  styleUrls: ['./general-booking.component.scss']
})
export class GeneralBookingComponent {

  content: Content;
  extrainfo : Extrainfo;

  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;

    this.formConfig.formDisabled = false;
    this.formConfig.formDisableAll = false;
    this.formConfig.formCompanyName = 'general';

    this.content.SokID = '12487';

    //Not sure if this is actually needed. 
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.Avtal = '';

    this.formConfig.confirm = false; //Determines if form need approval by manager
  }

}
