<div style="text-align: center;font-size: large;font-weight: bolder;">
  <p>Kolla här då i min lilla låda!</p>
</div>

<div class="ny-lista" *ngFor="let myItem of assignments;">
  <div class="grid-container">
    <div>
      {{myItem.id}}
    </div>
    <div>
      {{myItem.externalId}}
    </div>
    <div>
      {{myItem.bookingReference}}
    </div>
  </div>

</div>

