import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { fetchCountries, storeUser } from '../../store/actions';

import { AuthService } from '../../services/auth.service';

import { User } from '../../models/user';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent implements OnInit {

  validateSubscription: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    // console.log('** BaseLayoutComponent **');
    this.validateSubscription = this.authService.checkAuthorization()
      .subscribe({
        next: (res: User) => {
          // console.log('Response: ', res);
          if (res.response.result) {
            // console.log('User already authorized');
            this.store.dispatch(storeUser({ payload: res }));
            this.router.navigate(['/home']);
          } else {
            // console.log('User not logged in, redirecting to /login');
            this.router.navigate(['/login']);
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.router.navigate(['/login']);
        },
      });
    // Fetch countries into store.
    this.store.dispatch(fetchCountries());
  }

  ngOnDestroy(): void {
    this.validateSubscription.unsubscribe();
  }

}
