import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-lund',
  templateUrl: './lund.component.html',
  styleUrls: ['./lund.component.scss']
})
export class LundComponent {


  content:Content;
  extrainfo:Extrainfo;


  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.extrainfo.CompanyName = 'Lunds Universitet';
    this.content.SokID = '42549';
    this.extrainfo.CompanyContact = '';
    this.extrainfo.CompanyAddress = 'Box 188';
    this.extrainfo.CompanyCity = 'Lund';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = '221 00';
    this.extrainfo.CompanyEmail = 'neworders@alfarelocation.com';
    this.extrainfo.insurance = '';
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';

    this.formConfig.confirm = false; //Determines if form need approval by manager

    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'lunds';
  }

}
