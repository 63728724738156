import { Component } from '@angular/core';
import { Content, Extrainfo } from 'src/app/models/form/book-data';
import { FormConfig } from 'src/app/services/form-config';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-mtg',
  templateUrl: './mtg.component.html',
  styleUrls: ['./mtg.component.scss']
})
export class MtgComponent {

  extrainfo:Extrainfo;
  content:Content;
  
  constructor(private formDataService : FormDataService, private formConfig : FormConfig) {
    this.content = formDataService.content;
    this.extrainfo = formDataService.extrainfo;
    //preassigned
    this.extrainfo.CompanyName = 'MTG Modern Times Group';
    this.content.SokID = '22026';
    this.extrainfo.CompanyContact = '08-56232700';
    this.extrainfo.CompanyAddress = 'Box 17088';
    this.extrainfo.CompanyCity = 'Stockholm';
    this.extrainfo.CompanyCountry = 'SE';
    this.extrainfo.CompanyZipCode = '10462';
    this.extrainfo.CompanyEmail = '';
    this.extrainfo.insurance = 'All shipments and storage portions are insured through Alfa. A valuation form will be sent to you by our move mangers upon confirming your move. It is very important that this form is filled in and returned prior to packing.';
    this.extrainfo.Legal = true;
    this.extrainfo.lockCompany = true;
    this.extrainfo.language = 'en';
    this.extrainfo.Avtal = '';

    this.formConfig.confirm = false; //Determines if form need approval by manager

    this.formConfig.formDisabled = true;
    this.formConfig.formDisableAll = true;
    this.formConfig.formCompanyName = 'mtg';
  }

}
