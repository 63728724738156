<div>
  <h3>Other details</h3>
  <hr />

  <label class="icontainer">
    <h4>Allowance in cbm:</h4>
    <input
      class="ifield"
      type="number"
      name="ApprovedRelocationAllowance"
      [(ngModel)]="extrainfo.ApprovedRelocationAllowance"
      #ApprovedRelocationAllowance="ngModel"
    />
  </label>

  <label for="textbox"><h4>Additional information</h4></label>
  <textarea
    class="itextfield"
    name="otherDetails"
    id="textbox"
    cols="30"
    rows="10"
    [(ngModel)]="extrainfo.OtherInfo"
    placeholder="Please let us know here here if there's anything we should know to best help you.."
  >
  </textarea>

  <div class="button-container">
    <!--LUND buttons-->

    <input
      class="button btn-form"
      type="button"
      value="Back"
      routerLink="../transferee"
    />
    <input
      class="button btn-form"
      type="button"
      value="Submit"
      (click)="formData.formSubmit()"
    />
  </div>
</div>
