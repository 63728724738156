<div>
    <h3>Insurance</h3>
    <hr />
    <p>
        All shipments and storage portions are insured through Alfa. A valuation
        form will be sent to you by our move managers upon confirming your move.
        It is very important that this form is completed and returned prior to
        packing.
    </p>

    <h3>Other details</h3>
    <hr />
</div>

<div class="radio-container">
    <h4>
        Do you have any current storage portion which shall be delivered upon
        arrival to your home country (valid if repatriating)
    </h4>

    <label class="radio">
        <input
            type="radio"
            value="Yes"
            name="InStorage"
            [(ngModel)]="extrainfo.InStorage"
        />
        <span>Yes</span>
    </label>

    <label class="radio">
        <input
            type="radio"
            value="No"
            name="InStorage"
            [(ngModel)]="extrainfo.InStorage"
        />
        <span>No</span>
    </label>

    <h4>
        Do you have items in your current residence which needs to be placed at
        long term storage during your assignment (valid if expatriating)
    </h4>

    <label class="radio">
        <input
            type="radio"
            value="Yes"
            name="ToStorage"
            [(ngModel)]="extrainfo.ToStorage"
        />
        <span>Yes</span>
    </label>

    <label class="radio">
        <input
            type="radio"
            value="No"
            name="ToStorage"
            [(ngModel)]="extrainfo.ToStorage"
        />
        <span>No</span>
    </label>

    <h4>
        Would you like for us to arrange move-out cleaning of your current
        residence?
    </h4>

    <div class="radio-container">
        <label class="radio">
            <input
                type="radio"
                value="yes"
                [(ngModel)]="extrainfo.CleaningOffer"
                name="CleaningOffer"
            />
            <span>Yes</span>
        </label>

        <label class="radio">
            <input
                type="radio"
                value="no"
                [(ngModel)]="extrainfo.CleaningOffer"
                name="CleaningOffer"
            />
            <span>No</span>
        </label>
    </div>

    <h4>Family size relocating</h4>

    <div class="radio-container">
        <label class="radio">
            <input
                type="radio"
                value="Single"
                [(ngModel)]="extrainfo.FamilySize"
                name="FamilySize"
            />
            <span>Single</span>
        </label>

        <label class="radio">
            <input
                type="radio"
                value="Couple"
                [(ngModel)]="extrainfo.FamilySize"
                name="FamilySize"
            />
            <span>Couple</span>
        </label>

        <label class="radio">
            <input
                type="radio"
                value="Family"
                [(ngModel)]="extrainfo.FamilySize"
                name="FamilySize"
            />
            <span>Family</span>
        </label>
    </div>

    <div *ngIf="extrainfo.FamilySize == 'Family'">
        <div class="form-container">
            <label class="icontainer">
                <input
                    type="number"
                    class="ifield"
                    placeholder="&nbsp;"
                    name="NumberOfChildren"
                    [(ngModel)]="extrainfo.NumberOfChildren"
                    #numberOfChildren="ngModel"
                />
                <span class="itext">Number of children*</span>
            </label>
        </div>
    </div>

    <label for="textbox"><h4>Other info</h4></label>
    <textarea
        class="itextfield"
        name="otherDetails"
        id="textbox"
        cols="30"
        rows="10"
        [(ngModel)]="extrainfo.OtherInfo"
        placeholder="Please let us know here if there's anything else we should know in order to help you the best way we can.."
    ></textarea>

    <div class="button-container">
        <input
            class="button btn-form"
            type="button"
            value="Back"
            routerLink="../movedetailsto"
        />
        <input
            class="button btn-form"
            type="button"
            value="Submit"
            (click)="formData.formSubmit()"
            [disabled]="extrainfo.FamilySize == '' || extrainfo.FamilySize == undefined"
        />
    </div>
</div>
