import { Component } from '@angular/core';
import { Extrainfo } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-cevt-other-details',
  templateUrl: './cevt-other-details.component.html',
  styleUrls: ['./cevt-other-details.component.scss']
})
export class CevtOtherDetailsComponent {

  extrainfo:Extrainfo;
  
constructor (
  private formDataService : FormDataService ,  
  public formData : FormDataService
  ) 
  {

  this.extrainfo = formDataService.extrainfo;

}

}
