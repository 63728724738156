import * as CryptoJS from 'crypto-js';
import { LocalStore } from '../models/localstore';

import { environment } from '../../environments/environment';

export class _store {
  userName = '';
  userDisplayName = '';
  profilePicture: any = {};
  sessionId = '';
  loggedIn = false;

  constructor() {}

  // public print(): void {
  //   console.log(this);
  // }

  public set(
    _userName: string,
    _userDisplayName: string,
    _profilePicture: any,
    _sessionId: string,
    _loggedIn: boolean
  ) {
    this.userName = _userName;
    this.userDisplayName = _userDisplayName;
    this.profilePicture = _profilePicture;
    this.sessionId = _sessionId;
    this.loggedIn = _loggedIn;
  }

  public push(): void {
    const objStr = JSON.stringify(this);
    const cryStr = CryptoJS.AES.encrypt(
      objStr.trim(),
      environment['secretKey'].trim()
    ).toString();
    localStorage.setItem('user', cryStr);
  }

  public pull(): LocalStore {
    const cryStr = localStorage.getItem('user');
    if (!cryStr) {
      return new _store();
    } else {
      const objStr = CryptoJS.AES.decrypt(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        cryStr!.trim(),
        environment['secretKey'].trim()
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(objStr);
    }
  }

  public setLoggedIn(): void {
    const tmpStore = new _store();
    const tmpObj = tmpStore.pull();
    tmpObj.loggedIn = true;
    tmpStore.set(
      tmpObj.userName,
      tmpObj.userDisplayName,
      tmpObj.profilePicture,
      tmpObj.sessionId,
      tmpObj.loggedIn
    );
  }

  public remove(item: string): void {
    localStorage.removeItem(item);
  }

}
