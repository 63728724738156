import { Component } from '@angular/core';
import { Extrainfo } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-lund-other-details',
  templateUrl: './lund-other-details.component.html',
  styleUrls: ['./lund-other-details.component.scss']
})
export class LundOtherDetailsComponent {

  extrainfo:Extrainfo;

  constructor (
    private formDataService : FormDataService ,  
    public formData : FormDataService
    ) 
    {
  
    this.extrainfo = formDataService.extrainfo;
  
  }

}
