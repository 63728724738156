<div>

    <h3>Insurance</h3>
    <hr>
    <div>
        ”Scania CV AB holds a global transport insurance for all transports
        including shipment and storage of household goods for Scania’s
        expatriates, with the exception of returning Brazilian residents.
        Due to the legal legislation in Brazil, returning expatriates needs
         to arrange local insurance in Brazil for transports to Brazil as
         well as for storage within Brazil during the expatriation.
         The insurance is valid provided the transport or storage arrangement
         are arranged through Global Assignments (XCM) at Scania. Furthermore,
          items being transported and/or stored with a
          value of SEK 15,000 or more, such as furs, electronics,
           paintings, antiquities, jewelry etc needs to be specified.
            The specification, together with pictures, copies of valuation,
             receipt etc should be available for
              Scania Corporate Insurance (ZFI) in case of a claim.
              In case of damage or loss you should immediately inform
               Corporate Insurance at Scania.”
    </div>

    <h3>Other details</h3>
    <hr>
    <div>
        <h4>Do you have any goods in storage to be included in your move?</h4>
        <label class="radio">
            <input type="radio" value="Yes" name="InStorage"  [(ngModel)]="extrainfo.InStorage">
            <span>Yes</span>
        </label>

        <label class="radio">
            <input type="radio" value="No" name="InStorage"  [(ngModel)]="extrainfo.InStorage">
            <span>No</span>
        </label>
    </div>

    <div>
        <!-- <h4>Do you need storage of max 10 cbm?</h4>
        <label class="radio">
            <input type="radio" value="Yes" name="ToStorage"  [(ngModel)]="extrainfo.ToStorage">
            <span>Yes</span>
        </label>

        <label class="radio">
            <input type="radio" value="No" name="ToStorage"  [(ngModel)]="extrainfo.ToStorage">
            <span>No</span>
        </label> -->

        <label class="icontainer label-children">
            <select
            name="NumberOfChildren"
            class="iselect"
            [(ngModel)]="extrainfo.NumberOfChildren"
            #NumberOfChildren
            >
                <option value="none">No kids</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6 or more" >6 or more</option>
            </select>
            <span
            class="itext"
            [ngClass]="{'itext-select': extrainfo.NumberOfChildren != undefined}">
            Number of children
            </span>
        </label>
    </div>

    <div>
        <h3>Allowance*</h3>
        <hr>
        <h4>
            Scania's allowance is net volumes, one cubic meter is equal to ten standard sized swedish moving boxes. If the host country housing is furnished the Employee is entitled to bring household goods within the following limits:
        </h4>

        <label class="radio">
            <input type="radio" value="Expatriation (4 m3)" name="VolumeLimit"  [(ngModel)]="volume.VolumeLimit">
            <span>Expatriation (4 m3)</span>
        </label>

        <label class="radio">
            <input type="radio" value="Repatriation (5 m3)" name="VolumeLimit"  [(ngModel)]="volume.VolumeLimit">
            <span>Repatriation (5 m3)</span>
        </label>

        <label class="radio">
            <input type="radio" value="Expatriation, if accompanied by spouse (7 m3)" name="VolumeLimit"  [(ngModel)]="volume.VolumeLimit">
            <span>Expatriation, if accompanied by spouse (7 m3)</span>
        </label>

        <label class="radio">
            <input type="radio" value="Repatriation, if accompanied by spouse (8 m3)" name="VolumeLimit"  [(ngModel)]="volume.VolumeLimit">
            <span>Repatriation, if accompanied by spouse (8 m3)</span>
        </label>

        <p>
            For every accompanying child: 1 m3 (expatriation), 1,5 m3 (repatriation)
        </p>

        <h4>If the host country housing is unfurnished the Employee is entitled to bring household goods within the following limits:</h4>

        <label class="radio">
            <input type="radio" value="Expatriation (30 m3)" name="VolumeLimit"  [(ngModel)]="volume.VolumeLimit">
            <span>Expatriation (30 m3)</span>
        </label>

        <label class="radio">
            <input type="radio" value="Repatriation (+10% more than actual m3 moved out)" name="VolumeLimit"  [(ngModel)]="volume.VolumeLimit">
            <span>Repatriation (+10% more than actual m3 moved out)</span>
        </label>

        <label class="radio">
            <input type="radio" value="Expatriation, if accompanied by spouse (45 m3)" name="VolumeLimit"  [(ngModel)]="volume.VolumeLimit">
            <span>Expatriation, if accompanied by spouse (45 m3)</span>
        </label>

        <label class="radio">
            <input type="radio" value="Repatriation, if accompanied by spouse (+10% more than actual m3 moved out)" name="VolumeLimit"  [(ngModel)]="volume.VolumeLimit">
            <span>Repatriation, if accompanied by spouse (+10% more than actual m3 moved out)</span>
        </label>

        <p>
            For every accompanying child: 5 m3 (expatriation), 10% more than actual m3 moved out (repatriation)
        </p>

        <h4>Do you want the residence cleaned when moving out?</h4>

        <label class="radio">
            <input type="radio" value="Yes" name="CleaningOffer"  [(ngModel)]="extrainfo.CleaningOffer">
            <span>Yes</span>
        </label>

        <label class="radio">
            <input type="radio" value="No" name="CleaningOffer"  [(ngModel)]="extrainfo.CleaningOffer">
            <span>No</span>
        </label>

    </div>

    <textarea
    class="itextfield"
    name="extrainfo"
    id="textbox"
    cols="30"
    rows="10"
    [(ngModel)]="extrainfo.OtherInfo"
    itext="Please type here if you have something to add..">

    </textarea>

    <div class="button-container">

            <input
            class="button btn-form"
            type="button"
            value="Back"
            routerLink="../movedetailsto">

            <input
            [disabled]=" "
            class="button btn-form"
            type="button"
            value="Submit"
            (click)="formData.formSubmit()"
            >
        </div>
</div>

<!-- TODO finish this up. Add the component to Other Details.  -->
