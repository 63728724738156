import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'AlfaAssistPortal';
  messageSubscription: Subscription = new Subscription();
  logoCode = 0;
  // logoCode 0 : Alfa standard logo
  // logoCode 1 : University of Helsinki logo

  constructor (
    private messageService: MessageService,
    private router: Router
  ) {
    this.messageSubscription = this.messageService
      .onMessage()
      .subscribe((message: number) => {
        console.log('Received a message: ', message);
        this.logoCode = message;
      });
  }

    ngOnInit() {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          if (!!event.url && event.url.match(/^\/#/)) {
            this.router.navigate([event.url.replace('/#', '')])
              .then(() => {
                // console.log('Navigation worked');
              })
              .catch(() => {
                // console.error(err);
                this.router.navigate(['notfound']);
              })
          }
        }
      });
    }

}
