import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormDataService } from 'src/app/services/form-data.service';
import { FormConfig } from 'src/app/services/form-config';
import {
  BussinessAgreementinfo,
  Content,
  Extrainfo,
} from 'src/app/models/form/book-data';
import { fetchCountries, fetchCompanies, fetchDepartments } from '../../../store/actions';
import { selectAllCountries, selectCompaniesByCountryAndGroup, selectAllDepartments, selectDepartmentByName } from '../../../store/selectors';
import { Country } from '../../../models/country';
import { Company } from '../../../models/company';
import { UDdepartment } from '../../../models/ud-department';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit {
  countries: Country[];
  companies: Company[];
  departments: UDdepartment[];
  progress: number;

  formC: FormConfig;
  extrainfo: Extrainfo;
  bussiness: BussinessAgreementinfo;
  content: Content;

  constructor(
    private formDataService: FormDataService,
    private formConfig: FormConfig,
    private store: Store,
  ) {
    this.formC = formConfig;

    this.bussiness = formDataService.bussiness;
    this.extrainfo = formDataService.extrainfo;
    this.content = formDataService.content;
  }

  ngOnInit() {
    this.progress = 0; //TODO remove if no progressbar

    this.store.dispatch(fetchCountries());
    this.store.dispatch(fetchCompanies());
    this.store.dispatch(fetchDepartments());

    this.store.select(selectAllCountries).subscribe((data) => {
      this.countries = data;
    });
    this.store.select(selectAllDepartments).subscribe((data) => {
      this.departments = data;
    })
  }

  onCountryChange(country, group) {
    this.store.select(selectCompaniesByCountryAndGroup(country, group)).subscribe((data) => {
      data.sort((a, b) => a.companyname.localeCompare(b.companyname));
      this.companies = data;
    });
  }

  onDepartmentChange(name: string) {
    console.log();
    this.store.select(selectDepartmentByName(name)).subscribe((data) => {
      this.extrainfo.UXPayingCompany = data.alfaCustomerNo;
    });
  }

  onCompanyChange() {
    this.extrainfo.UXPayingCountry = this.extrainfo.payingCompany.countrycode;
    this.extrainfo.UXPayingCompany = this.extrainfo.payingCompany.companynumber;
  }

}
