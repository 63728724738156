import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllCountries } from '../../../../store/selectors';
import { Content, Destdata, Extrainfo, Orgdata } from 'src/app/models/form/book-data';
import { FormDataService } from 'src/app/services/form-data.service';
import { Country } from '../../../../models/country';

@Component({
  selector: 'app-transferee-details',
  templateUrl: './transferee-details.component.html',
  styleUrls: ['./transferee-details.component.scss'],
})
export class TransfereeDetailsComponent implements OnInit {
  content: Content;
  destdata: Destdata;
  orgdata: Orgdata;
  extrainfo: Extrainfo;

  countries: Country[];

  constructor(
    private formDataService: FormDataService,
    public formData: FormDataService,
    private store: Store
  ) {
    this.destdata = formDataService.destdata;
    this.content = formDataService.content;
    this.orgdata = formDataService.orgdata;
    this.extrainfo = formDataService.extrainfo;
  }

  ngOnInit(): void {
    this.store.select(selectAllCountries).subscribe((data) => {
      this.countries = data;
    });
  }
}
